import { ITenant, TenantStatus } from "./tenants-types";
import { RootState } from "../../store";
import {
    getActiveEnvironmentFilterSearchTextSelector,
    getActiveEnvironmentFilterStatusSelector,
} from "../../filters/filters-selectors";
import { createSelector } from "reselect";
import constants from "utils/constants";
import { getIsEnrollAllowed } from "../../environments/environment-selectors";
import { getPingInfoTenantList } from "./tenant-ping/tenant-ping-selectors";
import { getComplexStatus, getPingStatus } from "utils/status-helper";
import { CustomerType } from "../../customers/add-new-customer/add-new-customer-types";

export const getTenants = (state: RootState): ITenant[] => {
    return state.tenantsReducer.tenants;
};

export const getTenantDisplayName = (state: RootState): string => {
    return state.tenantsReducer.activeTenant.versionInfo.name;
};

export const getTenantMoniker = (state: RootState): string => {
    return state.tenantsReducer.activeTenant.moniker;
};

export const getIsTenantFetched = (state: RootState): boolean => {
    return state.tenantsReducer.isTenantFetched;
};

export const getTenantCustomerType = (
    state: RootState
): CustomerType | null => {
    return state.tenantsReducer.activeTenant.customerType;
};

export const getActiveTenantMoniker = (state: RootState): string => {
    return state.tenantsReducer.activeTenant?.moniker || "";
};

export const getAuthenticationMethod = (state: RootState): string =>
    state.tenantsReducer.activeTenant.versionInfo.authenticationMethod || "";

export const getIsActiveTenantSet = (state: RootState): boolean =>
    state.tenantsReducer.isActiveTenantSet;

export const getEnrollAllowedTenants = createSelector(
    [getTenants, getIsEnrollAllowed],
    (tenants: ITenant[], isEnrollAllowed: boolean) => {
        if (isEnrollAllowed) {
            return tenants;
        }

        return tenants.filter(
            (tenant) => tenant.versionInfo.fetchStatus !== TenantStatus.Enroll
        );
    }
);

export const getReservedTenantNames = createSelector(
    [getTenants],
    (tenants: ITenant[]) => {
        const reservedTenantNames: string[] = [];

        tenants.forEach((tenant) => {
            reservedTenantNames.push(tenant.versionInfo?.name || "");
        });

        return reservedTenantNames;
    }
);

export const getTenantNames = createSelector(
    [getEnrollAllowedTenants],
    (tenants) =>
        tenants.map((tenant) => ({
            name: tenant.versionInfo.name,
            id: tenant.versionInfo.id,
        }))
);

//  Returns List of tenants with applied Status/Search filters
export const getTenantsFilteredSelector = createSelector(
    [
        getEnrollAllowedTenants,
        getPingInfoTenantList,
        getActiveEnvironmentFilterStatusSelector,
        getActiveEnvironmentFilterSearchTextSelector,
    ],
    (tenants, tenantsPingInfo, activeEnvStatus, activeEnvSearchText) => {
        return [...tenants]
            .filter((tenant: ITenant) => {
                if (
                    activeEnvStatus === constants.defaultIndex ||
                    activeEnvStatus === undefined
                ) {
                    return true;
                }
                const pingInfo = tenantsPingInfo.find(
                    (item) => item.tenantId === tenant.versionInfo.id
                );
                const status = getComplexStatus(
                    tenant.versionInfo.fetchStatus,
                    getPingStatus(pingInfo)
                );
                return activeEnvStatus === status;
            })
            .filter((tenant: ITenant) => {
                const tenantName = tenant.versionInfo.name.toLowerCase();
                const tenantMoniker = tenant.moniker.toLowerCase();
                const tenantId = tenant.versionInfo.id.toLowerCase();
                const searchPattern = activeEnvSearchText
                    ? activeEnvSearchText.toLowerCase()
                    : "";

                return (
                    tenantName.includes(searchPattern) ||
                    tenantMoniker.includes(searchPattern) ||
                    tenantId.includes(searchPattern)
                );
            });
    }
);

export const getEnrollAllowedSortedTenantsSelector = createSelector(
    [getEnrollAllowedTenants],
    (tenantList: ITenant[]) => [...tenantList.sort(compareTenants)]
);
export const getFilteredTenantsSortedByName = createSelector(
    [getTenantsFilteredSelector],
    (tenantList: ITenant[]) => [...tenantList.sort(compareTenants)]
);
export const getFilteredTenantsSortedByNameAndStatus = createSelector(
    [getFilteredTenantsSortedByName],
    (tenantList: ITenant[]) => {
        const tenantsWithoutEnrolling: ITenant[] = [];
        const enrollingTenants: ITenant[] = [];

        tenantList.forEach((tenant) => {
            if (tenant.versionInfo.fetchStatus !== TenantStatus.Enroll) {
                tenantsWithoutEnrolling.push(tenant);
            } else {
                enrollingTenants.push(tenant);
            }
        });

        return tenantsWithoutEnrolling.concat(enrollingTenants);
    }
);
const compareTenants = (tenantA: ITenant, tenantB: ITenant): number => {
    const aDisplayName = tenantA.versionInfo.name.toLocaleLowerCase() || "";
    const bDisplayName = tenantB.versionInfo.name.toLocaleLowerCase() || "";

    if (aDisplayName < bDisplayName) {
        return -1;
    }

    if (aDisplayName > bDisplayName) {
        return 1;
    }

    return 0;
};
