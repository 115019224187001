import { API } from "utils/api-config";
import { defaultErrorResponse } from "./defaults";

export const logout = async (): Promise<[string | null, number]> => {
    const response = await API.post(`/logout`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
