import React, { useEffect, useState } from "react";
import MainLayoutContainer from "../../layouts/MainLayout/MainLayoutContainer";
import {
    useMsalAuthentication,
    useMsal,
    useAccount,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    AccountIdentifiers,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { acquireToken } from "utils/auth-provider";

interface ComponentProps {
    setModalTitle: (title: string) => void;
    setModalMessage: (message: string) => void;
    setUserName: (userName: string) => void;
}
const App: React.FC<ComponentProps> = ({
    setModalTitle,
    setModalMessage,
    setUserName,
}: ComponentProps) => {
    useMsalAuthentication(InteractionType.Redirect);
    const { accounts } = useMsal();
    const accountInfo = useAccount(accounts[0] || ({} as AccountIdentifiers));
    const [isTokenAquired, setIsTokenAquired] = useState<boolean>();
    useEffect(() => {
        if (accountInfo) {
            const accountName = accountInfo.name || "";
            setUserName(accountName);
            setModalTitle(accountName);
            setModalMessage(accountInfo.username);
        }
    }, [accountInfo]);

    // Under this useEffect the token is acquired silently before the App is
    // rendered so it will be cached for future usage. This was used to get token
    // before the API's from the App are requested which are running
    // simuntaniously and cause token acquisition for each request.
    useEffect(() => {
        if (!isTokenAquired) {
            setIsTokenAquired(false);
            acquireToken().then(() => {
                setIsTokenAquired(true);
            });
        }
    }, []);

    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                {isTokenAquired && <MainLayoutContainer />}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate />
        </React.Fragment>
    );
};

export default App;
