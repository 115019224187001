import {
    ConsentStatusType,
    IConsent,
} from "../../../customers/customer-details/customer-details-types";

export interface IGuestTenant {
    id: string;
    name: string;
    aadIssuerId?: string;
    activeInDooapMain?: boolean;
    adminUserEmail?: string;
    adminUserName?: string;
    regionConsents: IGuestTenantRegionConsent;
    consentWizardAppLink: string;
}

export interface IGuestTenantInput {
    name: string;
}

export interface IGuestTenantRegionConsent {
    region: string;
    consents: IConsent[];
}

export interface IGuestTenantDooapAppRegistration {
    region: string;
    description: string;
    environmentName: string;
}

export interface IUpdateConsentStatus {
    type: ConsentStatusType;
    message?: string;
}

export const SET_ADD_NEW_GUEST_TENANT_OPENED =
    "GuestTenants/SET_ADD_NEW_GUEST_TENANT_OPENED";

export interface ISetAddNewGuestTenantOpened {
    type: typeof SET_ADD_NEW_GUEST_TENANT_OPENED;
    isAddNewGuestTenantOpened: boolean;
}

export interface State {
    isAddNewGuestTenantOpened: boolean;
}

export type Actions = ISetAddNewGuestTenantOpened;
