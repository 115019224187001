import axios from "axios";
import { acquireToken } from "./auth-provider";

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use(
    async (config) => {
        const accessToken = await acquireToken();
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
        };
        return config;
    },
    async (error) => {
        return await error;
    }
);

API.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        return await error.response;
    }
);
