import { RootState } from "../../../redux/store";
import { compose } from "redux";
import { connect } from "react-redux";
import DeletePartnerButton from "./DeletePartnerButton";
import {
    getPartnerId,
    getPartnerName,
} from "../../../redux/partners/partners-selectors";
import { setLoaderVisibility } from "../../../redux/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "../../../redux/common/unsaved-data/unsaved-data-actions";

const mapStateToProps = (state: RootState) => {
    return {
        partnerName: getPartnerName(state),
        partnerId: getPartnerId(state),
    };
};

const DeletePartnerButtonContainer = compose(
    connect(mapStateToProps, {
        setLoaderVisibility,
        setIsUnsavedChangeAvailable,
    })
)(DeletePartnerButton);

export default DeletePartnerButtonContainer;
