import { RootState } from "redux/store";
import TenantFilters from "./TenantFilters";
import { connect } from "react-redux";
import { setEnvironmentFilterStatus } from "redux/filters/filters-actions";
import {
    getActiveEnvironmentId,
    getActiveEnvironmentMoniker,
} from "redux/environments/environment-selectors";
import { getActiveEnvironmentFilterStatusSelector } from "redux/filters/filters-selectors";
import constants from "utils/constants";
import { TenantStatus } from "redux/environments/tenants/tenants-types";
import { getEnrollAllowedSortedTenantsSelector } from "redux/environments/tenants/tenants-selectors";
import { getTenantsPingInfoSelector } from "redux/environments/tenants/tenant-ping/tenant-ping-selectors";

const mapStateToProps = (state: RootState) => {
    const status = getActiveEnvironmentFilterStatusSelector(state);
    return {
        activeStatus:
            status === TenantStatus.Normal ||
            status === TenantStatus.Error ||
            status === TenantStatus.Enroll ||
            status === TenantStatus.Deleting
                ? status
                : constants.defaultIndex,
        activeEnvironmentId: getActiveEnvironmentId(state),
        tenants: getEnrollAllowedSortedTenantsSelector(state),
        tenantsPingInfo: getTenantsPingInfoSelector(
            state,
            getActiveEnvironmentMoniker(state)
        ),
    };
};
const TenantFiltersContainer = connect(mapStateToProps, {
    setEnvironmentFilterStatus,
})(TenantFilters);

export default TenantFiltersContainer;
