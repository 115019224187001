import { connect } from "react-redux";
import { compose } from "redux";
import { RootState } from "redux/store";
import AddNewCustomer from "./AddNewCustomer";
import {
    cleanAddNewCustomerData,
    setNewCustomerAdded,
    setNewCustomerFormValue,
} from "redux/customers/add-new-customer/add-new-customer-actions";
import { fetchCustomerMetadata } from "redux/customers/add-new-customer/add-new-customer-thunks";
import { fetchCustomers } from "redux/customers/customers-thunks";
import { setIsModalOpen } from "redux/common/modal-window/modal-window-actions";
import { getCustomers } from "redux/customers/customers-selectors";
import { getRegions } from "redux/customers/add-new-customer/add-new-customer-selector";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import { getIsUserPartner } from "redux/app-global/app-global-selectors";
import { getAppSettings } from "redux/app-settings/app-settings-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        isModalBusy: state.modalWindowReducer.isModalLoaderVisible,
        consents:
            state.addNewCustomersReducer.newCustomer
                .customerAadMasterTenantConsents,
        customerTypes: state.addNewCustomersReducer.customerTypes,
        isAddNewCustomerModalOpen: state.modalWindowReducer.isModalOpen,
        newCustomerFormValue: state.addNewCustomersReducer.newCustomerFormValue,
        regions: getRegions(state),
        newCustomer: state.addNewCustomersReducer.newCustomer,
        isNewCustomerAdded: state.addNewCustomersReducer.isNewCustomerAdded,
        customerNames: getCustomers(state),
        isPartner: getIsUserPartner(state),
        appSettings: getAppSettings(state),
    };
};
const AddNewCustomerContainer = compose(
    connect(mapStateToProps, {
        fetchCustomerMetadata,
        fetchCustomers,
        setNewCustomerFormValue,
        cleanAddNewCustomerData,
        setIsModalOpen,
        setNewCustomerAdded,
        setModalLoaderVisibility,
    })
)(AddNewCustomer);

export default AddNewCustomerContainer;
