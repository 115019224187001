export interface IVersionHistory {
    currentVersion: string | null;
    versionUpdates: IVersionUpdate[];
}

export interface IVersionUpdate {
    versionNumber: string;
    updateTime: Date;
}

export enum VersionType {
    Dooap = 0,
    Application,
    PlatForm,
}
