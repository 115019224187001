import {
    SET_ACTIVE_ENVIRONMENT_BY_ID,
    SET_ACTIVE_ENVIRONMENT_BY_NAME,
    SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS,
    SET_APP_REGISTRATION_INFO_OPENED,
    SET_APP_REGISTRATIONS,
    SET_ENVIRONMENTS,
    IDooapAppRegistration,
    IEnvironment,
    ISetAppRegistrations,
} from "./environment-types";

export const setEnvironments = (environments: IEnvironment[]) => ({
    type: SET_ENVIRONMENTS,
    environments,
});
export const setAppRegistrations = (
    appRegistrations: IDooapAppRegistration[]
): ISetAppRegistrations => ({
    type: SET_APP_REGISTRATIONS,
    appRegistrations,
});
export const setActiveEnvironmentById = (id: string) => ({
    type: SET_ACTIVE_ENVIRONMENT_BY_ID,
    id,
});
export const setActiveEnvironmentByName = (name: string) => ({
    type: SET_ACTIVE_ENVIRONMENT_BY_NAME,
    name,
});
export const setActiveEnvironmentTenantsMonikers = () => ({
    type: SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS,
});
export const setAppRegistrationInfoOpened = (isOpened: boolean) => ({
    type: SET_APP_REGISTRATION_INFO_OPENED,
    isOpened,
});
