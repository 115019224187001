import {
    Actions,
    SET_MODAL_MESSAGE,
    SET_MODAL_TITLE,
    SET_IS_MODAL_OPEN,
    SET_MODAL_LOADER_VISIBILITY,
    State,
} from "./modal-window-types";

const initialState = {
    isModalLoaderVisible: false,
    isModalOpen: false,
    modalTitle: "",
    modalMessage: "",
};
const modalWindowReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_IS_MODAL_OPEN:
            return {
                ...state,
                isModalOpen: action.isModalOpen,
            };

        case SET_MODAL_TITLE:
            return {
                ...state,
                modalTitle: action.title,
            };

        case SET_MODAL_MESSAGE:
            return {
                ...state,
                modalMessage: action.message,
            };

        case SET_MODAL_LOADER_VISIBILITY:
            return {
                ...state,
                isModalLoaderVisible: action.isModalLoaderVisible,
            };

        default:
            return state;
    }
};

export default modalWindowReducer;
