import { connect } from "react-redux";
import { RootState } from "redux/store";
import AppRegistrationInformation from "./AppRegistrationInformation";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { setAppRegistrationInfoOpened } from "redux/environments/environment-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        isOpened: state.environmentsReducer.isAppRegistrationInfoOpened,
    };
};
const AppRegistrationInformationContainer = connect(mapStateToProps, {
    setAppRegistrationInfoOpened,
})(AppRegistrationInformation);

export default AppRegistrationInformationContainer;
