export interface ICustomField {
    code: string;
    databaseColumnName: string;
    xmlPath: string | null;
    uiColumnName: string | null;
    invoices?: ICustomFieldInvoice[] | null;
}

export interface ICustomFieldInvoice {
    id: string | null;
    enabled: boolean;
    invoiceType: CustomFieldInvoiceType;
    targetTable: string;
    targetField: string;
    excludedLineTypes?: string[];
    deleteAction?: boolean;
}

export interface ILineType {
    name: string;
    type: number;
}

export enum CustomFieldInvoiceType {
    None = 0,
    Po = 1,
    NonPo = 2,
}

export interface ICustomFieldsResponse {
    data: ICustomField[];
    hasTenantErpCustomFieldSupport: boolean;
    hasTenantErpConnectivityError: boolean;
}
export interface ICustomFieldsUpdateResponse {
    hasTenantErpConnectivityError: boolean;
}
