import i18n from "utils/i18n";

import {
    setErrorMessageInactiveTenant,
    setErrorResponseMessage,
    setLoaderVisibility,
} from "../../common/initialization/initialization-actions";
import { AppThunk } from "../../store";
import { setTenants, setActiveTenantById } from "./tenants-actions";
import { loadTenants } from "api/environments/tenants/tenant-info-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";

export const fetchTenants = (
    envMoniker: string,
    activeTenantId?: string
): AppThunk => async (dispatch) => {
    dispatch(setLoaderVisibility(true));
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadTenants(envMoniker);

    if (payload && status === constants.statusCode.OK) {
        dispatch(setTenants(payload));

        if (activeTenantId) {
            dispatch(setActiveTenantById(activeTenantId));
        } else {
            dispatch(
                setErrorMessageInactiveTenant(i18n.t("error.noActiveTenant"))
            );
        }
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }

    dispatch(setLoaderVisibility(false));
};

export const fetchTenantsWithoutLoader = (
    envMoniker: string
): AppThunk => async (dispatch) => {
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadTenants(envMoniker);
    const isResponseOk = status === constants.statusCode.OK;

    if (payload && isResponseOk) {
        dispatch(setTenants(payload));
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }
};
