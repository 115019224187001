import { API } from "utils/api-config";
import {
    ICustomer,
    ICustomerDeliverablesInfo,
} from "redux/customers/customers-types";
import {
    IConsentMetadata,
    ICustomerAadMasterTenant,
} from "redux/customers/add-new-customer/add-new-customer-types";
import { defaultErrorResponse } from "../defaults";

export const loadCustomers = async (): Promise<
    [ICustomer[] | null, number]
> => {
    const response = await API.get("/customers");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadCustomersDeliverablesInfo = async (): Promise<
    [ICustomerDeliverablesInfo[] | null, number]
> => {
    const response = await API.get("/customers/deliverables-info");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadCustomerMetadata = async (): Promise<
    [IConsentMetadata | null, number]
> => {
    const response = await API.get("/customers/metadata");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const addCustomer = async (
    customer: ICustomerAadMasterTenant
): Promise<[IConsentMetadata | null, number]> => {
    const response = await API.post("/customers", customer);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const deleteCustomer = async (
    customerId: string
): Promise<[IConsentMetadata | null, number]> => {
    const response = await API.delete(`/customers/${customerId}`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
