import { RootState } from "../../../redux/store";
import CustomerFilters from "./CustomerFilters";
import { connect } from "react-redux";
import {
    getCustomerFilterStatus,
    getCustomerStatusCalculated,
} from "../../../redux/filters/filters-selectors";
import {
    setCustomerFilterStatus,
    setCustomersFilterStatusCalculated,
} from "../../../redux/filters/filters-actions";
import { getCustomers } from "../../../redux/customers/customers-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        customerFilterStatus: getCustomerFilterStatus(state),
        isStatusCalculated: getCustomerStatusCalculated(state),
        customers: getCustomers(state),
    };
};
const CustomerFiltersContainer = connect(mapStateToProps, {
    setCustomerFilterStatus,
    setCustomersFilterStatusCalculated,
})(CustomerFilters);

export default CustomerFiltersContainer;
