import { connect } from "react-redux";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import {
    getActiveEnvironmentMoniker,
    getAppRegistrations,
} from "redux/environments/environment-selectors";
import { RootState } from "redux/store";
import ErpConnectionInfo from "./ErpConnectionInfo";
import { fetchDooapAppRegistrationsWithoutLoader as fetchDooapAppRegistrations } from "redux/environments/environment-thunks";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        appRegistrations: getAppRegistrations(state),
    };
};

const ErpConnectionInfoContainer = connect(mapStateToProps, {
    fetchDooapAppRegistrations,
    setIsUnsavedChangeAvailable,
})(ErpConnectionInfo);

export default ErpConnectionInfoContainer;
