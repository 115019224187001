import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, ButtonToolbar, Message, Nav, Toggle } from "rsuite";
import "./CustomFields.less";
import {
    ICustomField,
    ILineType,
    CustomFieldInvoiceType,
} from "./custom-fields-types";
import useRefreshPage from "hooks/useRefreshPage";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import constants from "utils/constants";
import FieldFormContainer from "components/Environments/Tenants/LeftMenu/CustomFields/FieldForm/FieldFormContainer";
import {
    deleteCustomFields,
    loadCustomFields,
    loadLineTypes,
    loadTargetFields,
    loadTargetTables,
    updateCustomFields,
} from "api/environments/tenants/tenant-custom-fields-api";
import { getErrorMessage } from "api/defaults";
import { cloneDeep, isEqual } from "lodash";
import { IConfluenceDocumentLinks } from "redux/information-links/information-links-types";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface FieldFormObject {
    validate: () => boolean;
    cleanErrors: () => void;
}

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isConfluenceDocumentLinksFetched: boolean;
    confluenceDocumentLinks: IConfluenceDocumentLinks;
    isUnsavedChangeAvailable: boolean;
    errorMessage: string;
    showLoader: (isVisible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    fetchConfluenceDocumentLinks: () => void;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}

const emptyCustomFieldsDefaultValue: ICustomField[] = [
    {
        invoices: [],
        code: "CustomField1",
        xmlPath: null,
        databaseColumnName: "CustomData1",
        uiColumnName: null,
    },
    {
        invoices: [],
        code: "CustomField2",
        xmlPath: null,
        databaseColumnName: "CustomData2",
        uiColumnName: null,
    },
    {
        invoices: [],
        code: "CustomField3",
        xmlPath: null,
        databaseColumnName: "CustomData3",
        uiColumnName: null,
    },
];

const CustomFields: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isConfluenceDocumentLinksFetched,
    confluenceDocumentLinks,
    isUnsavedChangeAvailable,
    errorMessage,
    showLoader,
    setIsUnsavedChangeAvailable,
    fetchConfluenceDocumentLinks,
    setModalConfirmCallback,
    setRefreshActivated,
}: ComponentProps) => {
    const tab1 = "1";
    const tab2 = "2";
    const tab3 = "3";
    const customData1 = "CustomData1";
    const customData2 = "CustomData2";
    const customData3 = "CustomData3";

    const [customFieldsList, setCustomFieldsList] = useState<ICustomField[]>(
        emptyCustomFieldsDefaultValue
    );
    const [customFieldsOriginal, setCustomFieldsOriginalList] = useState<
        ICustomField[]
    >(emptyCustomFieldsDefaultValue);
    const [isCustomFieldsToggled, setIsCustomFieldsToggled] = useState(false);
    const [backedFields, setBackedFields] = useState<ICustomField[] | null>();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const isCustomFieldsInitialized =
        getCustomFieldsInitialized(customFieldsList);

    const [isTableInitialized, setTableInitialization] = useState(
        Boolean(isCustomFieldsInitialized)
    );

    const [activeTab, setActiveTab] = useState(tab1);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [firstSetupCompleted, setFirstSetupCompleted] = useState(false);
    const { t } = useTranslation();
    const [field0, setField0] = useState<ICustomField | null>(null);
    const [field1, setField1] = useState<ICustomField | null>(null);
    const [field2, setField2] = useState<ICustomField | null>(null);

    const [targetTablesPo, setTargetTablesPo] = useState<string[]>([]);
    const [targetTablesNonPo, setTargetTabledNonPo] = useState<string[]>([]);
    const [lineTypes, setLineTypes] = useState<ILineType[]>([]);
    const [targetFieldsCache, setTargetFieldsCache] = useState<any>({});
    const [fieldsSaved, setFieldsSaved] = useState(false);
    const [hasTenantErpCustomFieldSupport, setHasTenantErpCustomFieldSupport] =
        useState(false);
    const [customFieldsErrorMessage, setCustomFieldsErrorMessage] =
        useState("");

    const ref1 = useRef<FieldFormObject>(null);
    const ref2 = useRef<FieldFormObject>(null);
    const ref3 = useRef<FieldFormObject>(null);
    const refs = [ref1, ref2, ref3];

    const emptyField: ICustomField = {
        code: "",
        databaseColumnName: "",
        uiColumnName: "",
        xmlPath: "",
    };

    useEffect(() => {
        if (isFetched) {
            setField0(customFieldsList[0]);
            setField1(customFieldsList[1]);
            setField2(customFieldsList[2]);
        }
    }, [customFieldsList, isFetched]);

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isFetched) {
            loadData();
        }
    }, [envMoniker, tenantMoniker]);

    useEffect(() => {
        setCurrentStep(Number(activeTab));
    }, [activeTab]);

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            cleanFormErrors();
            setCustomFieldsErrorMessage("");
            setIsFetched(false);
            loadData();
            setTableInitialization(
                Boolean(getCustomFieldsInitialized(customFieldsOriginal))
            );
            setActiveTab(tab1);
        }
    });

    useEffect(() => {
        if (!isConfluenceDocumentLinksFetched) {
            fetchConfluenceDocumentLinks();
        }
    }, [isConfluenceDocumentLinksFetched, fetchConfluenceDocumentLinks]);

    useEffect(() => {
        if (isFetched) {
            checkHasUnsavedChanges();
        }

        if (getCustomFieldsInitialized(customFieldsList)) {
            setTableInitialization(true);
            setFirstSetupCompleted(true);
        }
    }, [customFieldsList, customFieldsOriginal]);

    useInactiveTenant();
    useInactiveEnvironment();

    function getCustomFieldsInitialized(list: ICustomField[]) {
        return (
            list &&
            list.length > 0 &&
            list.every(
                (value) => value.uiColumnName && value.uiColumnName.length !== 0
            )
        );
    }

    const cleanFormErrors = (): void => {
        const currentRef = refs[currentStep - 1];
        currentRef.current?.cleanErrors();
    };

    const getCustomFieldsWithDefaults = (customFields: ICustomField[]) => {
        return [
            {
                ...customFields[0],
                uiColumnName: customFields[0].uiColumnName || customData1,
            },
            {
                ...customFields[1],
                uiColumnName: customFields[1].uiColumnName || customData2,
            },
            {
                ...customFields[2],
                uiColumnName: customFields[2].uiColumnName || customData3,
            },
        ];
    };

    const handleToggleChange = (checked: boolean, event: SyntheticEvent) => {
        event.preventDefault();

        if (isTableInitialized) {
            //  Reset Form Data on Un-initialization
            cleanFormErrors();
            setBackedFields(customFieldsList);
            setCustomFieldsList(emptyCustomFieldsDefaultValue);
        } else {
            if (backedFields) {
                setCustomFieldsList(getCustomFieldsWithDefaults(backedFields));
                setBackedFields(null);
            } else {
                setCustomFieldsList(customFieldsOriginal);
            }
        }
        setIsCustomFieldsToggled(!isCustomFieldsToggled);
        checkHasUnsavedChanges();
        setTableInitialization(!isTableInitialized);
    };

    const setCustomFieldProperty = (
        name: string,
        value: any,
        rowIndex: number
    ) => {
        const newList = [...customFieldsList];

        newList[rowIndex] = {
            ...customFieldsList[rowIndex],
            [name]: value,
        };

        setCustomFieldsList(newList);
        checkHasUnsavedChanges();
    };

    function loadData() {
        setCustomFieldsErrorMessage("");
        showLoader(true);
        const promises: any[] = [];
        promises.push(
            getCustomFields(),
            getPoTables(),
            getNonPoTables(),
            getLineTypes()
        );
        Promise.all(promises)
            .then((res) => {
                const allTables = [...res[1]];
                allTables.push(...res[2]);
                const fieldsPromises = allTables.map((value) =>
                    getTargetField(value)
                );
                Promise.all(fieldsPromises)
                    .then((res) => {
                        const tmpCache: any = {};
                        res.forEach((value) => {
                            tmpCache[value[0].toString()] = value[1];
                        });
                        setTargetFieldsCache(tmpCache);
                        showLoader(false);
                        setIsFetched(true);
                    })
                    .catch((err) => {
                        setCustomFieldsErrorMessage(err.toString());
                        showLoader(false);
                    });
            })
            .catch((err) => {
                setCustomFieldsErrorMessage(err.toString());
                showLoader(false);
            });
    }

    async function getCustomFields(skipSetFieldsSaved = false) {
        const [payload, status] = await loadCustomFields(
            envMoniker,
            tenantMoniker
        );
        if (payload && status === constants.statusCode.OK) {
            setCustomFieldsList(payload.data);
            setCustomFieldsOriginalList(cloneDeep(payload.data));
            setBackedFields(cloneDeep(payload.data));
            setHasTenantErpCustomFieldSupport(
                payload.hasTenantErpCustomFieldSupport
            );
            if (!skipSetFieldsSaved) {
                if (payload?.hasTenantErpConnectivityError) {
                    Alert.error(
                        t(
                            "tenantConfig.customFieldsPage.failedToFetchInvoicesFromD365"
                        ),
                        constants.alertDurationMsec
                    );
                }

                if (getCustomFieldsInitialized(payload.data)) {
                    setFieldsSaved(true);
                }
            }
        } else {
            const errorMessage = getErrorMessage(String(payload), status);
            throw new Error(errorMessage);
        }
    }

    async function getPoTables() {
        const [tablesPO, poStatus] = await loadTargetTables(
            envMoniker,
            tenantMoniker,
            CustomFieldInvoiceType.Po
        );
        if (tablesPO && poStatus === constants.statusCode.OK) {
            setTargetTablesPo(tablesPO);
            return tablesPO;
        } else {
            const errorMessage = getErrorMessage(String(tablesPO), poStatus);
            throw new Error(errorMessage);
        }
    }

    async function getNonPoTables() {
        const [tablesNonPO, nonPoStatus] = await loadTargetTables(
            envMoniker,
            tenantMoniker,
            CustomFieldInvoiceType.NonPo
        );
        if (tablesNonPO && nonPoStatus === constants.statusCode.OK) {
            setTargetTabledNonPo(tablesNonPO);
            return tablesNonPO;
        } else {
            const errorMessage = getErrorMessage(
                String(tablesNonPO),
                nonPoStatus
            );
            throw new Error(errorMessage);
        }
    }

    async function getLineTypes() {
        const [lines, linesStatus] = await loadLineTypes(
            envMoniker,
            tenantMoniker
        );
        if (lines && linesStatus === constants.statusCode.OK) {
            setLineTypes(lines);
        } else {
            const errorMessage = getErrorMessage(String(lines), linesStatus);
            throw new Error(errorMessage);
        }
    }

    async function getTargetField(targetTable: string) {
        const [payload, status] = await loadTargetFields(
            envMoniker,
            tenantMoniker,
            targetTable
        );
        if (payload && status === constants.statusCode.OK) {
            return [targetTable, payload];
        } else {
            const errorMessage = getErrorMessage(String(payload), status);
            throw new Error(errorMessage);
        }
    }

    const handleSave = () => {
        if (isTableInitialized) {
            const currentRef = refs[currentStep - 1];
            if (currentRef.current) {
                if (!currentRef.current.validate()) {
                    Alert.error(
                        t("tenantConfig.customFieldsPage.warning"),
                        constants.alertDurationMsec
                    );
                    return;
                }
            } else {
                return;
            }
        }

        showLoader(true);
        capitalizeCustomFields();

        if (isTableInitialized) {
            updateCustomFields(
                envMoniker,
                tenantMoniker,
                customFieldsList
            ).then(([payload, status]) => {
                if (status === constants.statusCode.OK) {
                    const updatedList = cloneDeep(customFieldsList);
                    updatedList.forEach((value) => {
                        if (value.invoices) {
                            value.invoices = value.invoices.filter(
                                (i) => !i.deleteAction
                            );
                        }
                    });
                    setIsUnsavedChangeAvailable(false);
                    getCustomFields(true)
                        .then(() => {
                            setFirstSetupCompleted(true);
                            setFieldsSaved(true);

                            Alert.success(
                                t("tenantConfig.customFieldsPage.successSent"),
                                constants.alertDurationMsec
                            );
                            if (payload?.hasTenantErpConnectivityError) {
                                Alert.error(
                                    t(
                                        "tenantConfig.customFieldsPage.failedToUpdateInvoicesInD365"
                                    ),
                                    constants.alertDurationMsec
                                );
                            }
                            showLoader(false);
                        })
                        .catch((err) => {
                            setCustomFieldsErrorMessage(err.toString());
                            showLoader(false);
                        });
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    Alert.error(errorMessage, constants.alertDurationMsec);
                    showLoader(false);
                }
            });
        } else {
            deleteCustomFields(envMoniker, tenantMoniker).then(
                ([payload, status]) => {
                    if (status === constants.statusCode.OK) {
                        setFirstSetupCompleted(false);
                        loadData();
                        setActiveTab(tab1);
                        setFieldsSaved(false);

                        Alert.success(
                            t(
                                "tenantConfig.customFieldsPage.deletedSuccessfully"
                            ),
                            constants.alertDurationMsec
                        );
                        if (payload?.hasTenantErpConnectivityError) {
                            Alert.error(
                                t(
                                    "tenantConfig.customFieldsPage.failedToDeleteInvoicesFromD365"
                                ),
                                constants.alertDurationMsec
                            );
                        }
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        Alert.error(errorMessage, constants.alertDurationMsec);
                        showLoader(false);
                    }
                }
            );
        }
    };

    function capitalizeCustomFields() {
        const newList = customFieldsList.map((item) => {
            if (item.xmlPath) {
                item.xmlPath = item.xmlPath.toUpperCase();
            }

            return item;
        });
        setCustomFieldsList(newList);
    }

    function handleFieldSelect(tabIndex: string) {
        if (activeTab === tabIndex) {
            return;
        }

        if (firstSetupCompleted && fieldsSaved) {
            const currentRef = refs[currentStep - 1];
            if (currentRef.current) {
                if (!currentRef.current.validate()) {
                    Alert.error(
                        t("tenantConfig.customFieldsPage.warning"),
                        constants.alertDurationMsec
                    );
                    return;
                }
            }
        }

        if (isUnsavedChangeAvailable && tabIndex !== activeTab && fieldsSaved) {
            setModalConfirmCallback({
                id: uuid(),
                isAutoDeleteResistant: false,
                isPageRedirect: true,
                callback: () => {
                    setCustomFieldsList(customFieldsOriginal);
                    setIsUnsavedChangeAvailable(false);
                    setActiveTab(tabIndex);
                },
            });
            setRefreshActivated(true);
            return;
        }

        setActiveTab(tabIndex);
    }

    function handleNext() {
        const currentRef = refs[currentStep - 1];
        if (currentRef.current) {
            if (!currentRef.current.validate()) {
                Alert.error(
                    t("tenantConfig.customFieldsPage.warning"),
                    constants.alertDurationMsec
                );
                return;
            }
        }
        setCurrentStep(currentStep + 1);
        setActiveTab(`${currentStep + 1}`);
    }

    function handleBack() {
        setCurrentStep(currentStep - 1);
        setActiveTab(`${currentStep - 1}`);
    }

    function checkHasUnsavedChanges() {
        const hasChanges = !isEqual(
            customFieldsList,
            getCustomFieldsWithDefaults(customFieldsOriginal)
        );

        setIsUnsavedChangeAvailable(hasChanges);
    }

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    if (customFieldsErrorMessage.length > 0) {
        return <ErrorSection errorMessage={customFieldsErrorMessage} />;
    }

    return (
        <div className={"general-configurations__container"}>
            <div className={"general-configurations__content"}>
                <h1 className={"general-configurations__page-header"}>
                    {t("tenantConfig.customFieldsPage.header")}
                </h1>
                <div className="toggleContainer">
                    <Toggle
                        checked={isTableInitialized}
                        className={"toggleBtn"}
                        checkedChildren={t("toggler.onTitle")}
                        unCheckedChildren={t("toggler.offTitle")}
                        onChange={handleToggleChange}
                    />
                    <span className={"toggleText"}>
                        {t("tenantConfig.customFieldsPage.toggleControl")}
                    </span>
                </div>

                <Nav
                    appearance="subtle"
                    onSelect={handleFieldSelect}
                    activeKey={activeTab}
                    className={"fieldsNavigation"}
                >
                    <Nav.Item
                        eventKey={tab1}
                        disabled={
                            !(
                                isTableInitialized &&
                                (currentStep > 0 ||
                                    isCustomFieldsInitialized ||
                                    firstSetupCompleted)
                            )
                        }
                        style={
                            activeTab === tab1
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" }
                        }
                    >
                        Custom Field 1
                    </Nav.Item>
                    <Nav.Item
                        eventKey={tab2}
                        disabled={
                            !(
                                isTableInitialized &&
                                (currentStep > 1 ||
                                    isCustomFieldsInitialized ||
                                    firstSetupCompleted)
                            )
                        }
                        style={
                            activeTab === tab2
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" }
                        }
                    >
                        Custom Field 2
                    </Nav.Item>
                    <Nav.Item
                        eventKey={tab3}
                        disabled={
                            !(
                                isTableInitialized &&
                                (currentStep > 2 ||
                                    isCustomFieldsInitialized ||
                                    firstSetupCompleted)
                            )
                        }
                        style={
                            activeTab === tab3
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" }
                        }
                    >
                        Custom Field 3
                    </Nav.Item>
                </Nav>

                <Message
                    showIcon
                    type="info"
                    description={
                        <>
                            {t(
                                "tenantConfig.customFieldsPage.table.skipFieldTooltip"
                            )}
                            <br />
                            {t(
                                "tenantConfig.customFieldsPage.table.confluenceLinkStart"
                            )}
                            <a
                                className="confluenceLink"
                                href={
                                    confluenceDocumentLinks.customFieldsConfiguration
                                }
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t(
                                    "tenantConfig.customFieldsPage.table.confluenceLinkEnd"
                                )}
                            </a>
                        </>
                    }
                    className={"skipFieldTooltip"}
                />

                {activeTab === tab1 && (
                    <FieldFormContainer
                        customField={
                            field0 || {
                                ...emptyField,
                                uiColumnName: customData1,
                            }
                        }
                        isFetched={isFetched}
                        isTableInitialized={isTableInitialized}
                        ref={ref1}
                        setCustomFieldProperty={setCustomFieldProperty}
                        firstSetupCompleted={firstSetupCompleted}
                        tablesPO={targetTablesPo}
                        tablesNonPO={targetTablesNonPo}
                        lineTypes={lineTypes}
                        customFieldsSaved={fieldsSaved}
                        targetFieldsCache={targetFieldsCache}
                        hasTenantErpCustomFieldSupport={
                            hasTenantErpCustomFieldSupport
                        }
                        handleSave={handleSave}
                        fieldIndex={0}
                    />
                )}
                {activeTab === tab2 && (
                    <FieldFormContainer
                        customField={
                            field1 || {
                                ...emptyField,
                                uiColumnName: customData2,
                            }
                        }
                        isFetched={isFetched}
                        isTableInitialized={isTableInitialized}
                        setCustomFieldProperty={setCustomFieldProperty}
                        firstSetupCompleted={firstSetupCompleted}
                        tablesPO={targetTablesPo}
                        tablesNonPO={targetTablesNonPo}
                        lineTypes={lineTypes}
                        customFieldsSaved={fieldsSaved}
                        targetFieldsCache={targetFieldsCache}
                        ref={ref2}
                        hasTenantErpCustomFieldSupport={
                            hasTenantErpCustomFieldSupport
                        }
                        handleSave={handleSave}
                        fieldIndex={1}
                    />
                )}
                {activeTab === tab3 && (
                    <FieldFormContainer
                        customField={
                            field2 || {
                                ...emptyField,
                                uiColumnName: customData3,
                            }
                        }
                        isFetched={isFetched}
                        isTableInitialized={isTableInitialized}
                        setCustomFieldProperty={setCustomFieldProperty}
                        firstSetupCompleted={firstSetupCompleted}
                        tablesPO={targetTablesPo}
                        tablesNonPO={targetTablesNonPo}
                        lineTypes={lineTypes}
                        customFieldsSaved={fieldsSaved}
                        targetFieldsCache={targetFieldsCache}
                        ref={ref3}
                        hasTenantErpCustomFieldSupport={
                            hasTenantErpCustomFieldSupport
                        }
                        handleSave={handleSave}
                        fieldIndex={2}
                    />
                )}
                {(!fieldsSaved ||
                    (firstSetupCompleted &&
                        fieldsSaved &&
                        !isTableInitialized)) && (
                    <div className={"comment customFieldsComment"}>
                        <sup className={"sup"}>*</sup>
                        {t("tenantConfig.customFieldsPage.requiredFields")}
                    </div>
                )}
            </div>

            <ButtonToolbar style={{ marginTop: "40px" }}>
                {((isTableInitialized &&
                    !firstSetupCompleted &&
                    !firstSetupCompleted) ||
                    !fieldsSaved ||
                    (firstSetupCompleted &&
                        fieldsSaved &&
                        !isTableInitialized)) && (
                    <SettingsFooterContainer
                        customButton={
                            <>
                                {isTableInitialized &&
                                    currentStep > 1 &&
                                    (!firstSetupCompleted || !fieldsSaved) && (
                                        <Button
                                            className={"back"}
                                            type={"submit"}
                                            appearance="ghost"
                                            onClick={handleBack}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.buttonBack"
                                            )}
                                        </Button>
                                    )}
                                {isTableInitialized &&
                                    currentStep < 3 &&
                                    (!firstSetupCompleted || !fieldsSaved) && (
                                        <Button
                                            disabled={!isTableInitialized}
                                            className={"save"}
                                            type={"submit"}
                                            appearance="primary"
                                            onClick={handleNext}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.buttonNext"
                                            )}
                                        </Button>
                                    )}
                                {((isTableInitialized &&
                                    currentStep === 3 &&
                                    !fieldsSaved) ||
                                    (firstSetupCompleted &&
                                        fieldsSaved &&
                                        !isTableInitialized)) && (
                                    <Button
                                        className={"save"}
                                        type={"submit"}
                                        appearance="primary"
                                        onClick={handleSave}
                                    >
                                        {t(
                                            "tenantConfig.customFieldsPage.buttonSave"
                                        )}
                                    </Button>
                                )}
                            </>
                        }
                    />
                )}
            </ButtonToolbar>
        </div>
    );
};

export default CustomFields;
