import React from "react";

import css from "./ContentEntryToCopy.module.css";

export interface IEntry {
    keyName: string;
    value: any;
    maskedUnicodeDecCharacter?: string | null;
}

const ContentEntryToCopy: React.FC<IEntry> = ({
    keyName,
    value,
    maskedUnicodeDecCharacter,
}: IEntry) => {
    return (
        <tr>
            <td width="25%">
                <div>{keyName}:</div>
            </td>
            <td align="left" width="100%">
                <div className={css.overflowContent}>
                    <b>
                        {(maskedUnicodeDecCharacter?.length ?? 0) === 1
                            ? [...value].map((_) => maskedUnicodeDecCharacter)
                            : value}
                    </b>
                </div>
            </td>
        </tr>
    );
};

export default ContentEntryToCopy;
