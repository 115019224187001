import { RootState } from "redux/store";
import { connect } from "react-redux";
import {
    getActiveStep,
    getEnrollId,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import { setActiveStepData } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";
import SelectCustomer from "./SelectCustomer";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { fetchCustomersWithoutLoader } from "redux/customers/customers-thunks";
import { getCustomers } from "redux/customers/customers-selectors";
import { getReservedTenantNames } from "redux/environments/tenants/tenants-selectors";
import { fetchTenantsWithoutLoader } from "redux/environments/tenants/tenants-thunks";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";

const mapStateToProps = (state: RootState) => ({
    activeStep: getActiveStep(state),
    customers: getCustomers(state),
    orchestratorInstanceId: getEnrollId(state),
    reservedTenantNames: getReservedTenantNames(state),
    activeEnvMoniker: getActiveEnvironmentMoniker(state),
});
const SelectCustomerContainer = compose(
    connect(mapStateToProps, {
        setActiveStepData,
        setIsUnsavedChangeAvailable,
        fetchCustomers: fetchCustomersWithoutLoader,
        fetchTenants: fetchTenantsWithoutLoader,
    }),
    withLoader
)(SelectCustomer);

export default SelectCustomerContainer;
