import React, { useEffect, useState } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import cn from "classnames";

import css from "./Collapsible.module.css";

interface ComponentProps {
    className?: string;
    open?: boolean;
    title: string | React.ReactElement;
    children: React.ReactNode;
    handleClick?: () => void;
}

const Collapsible: React.FC<ComponentProps> = ({
    className,
    open = false,
    title,
    children,
    handleClick,
}: ComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(open);
    const iconName = isOpen ? "up" : "down";

    const handleCollapse = () => {
        setIsOpen(!isOpen);
        if (handleClick) {
            handleClick();
        }
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    return (
        <div className={cn(className)}>
            <FlexboxGrid
                className={css.collapsibleHeaderContainer}
                align="middle"
                justify="start"
            >
                <FlexboxGrid.Item>{title}</FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <IconButton
                        appearance="subtle"
                        circle
                        size="sm"
                        onClick={() => {
                            handleCollapse();
                        }}
                        icon={<Icon icon={iconName} />}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className={isOpen ? "" : css.hidden}>{children}</div>
        </div>
    );
};

export default Collapsible;
