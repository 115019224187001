import { API } from "utils/api-config";
import { IHubConnection } from "utils/types";
import { defaultErrorResponse } from "../../defaults";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowToggleValue,
    ChangeEntityFrameworkSettingToggleValue,
    IFailedStepHandle,
} from "utils/tenant-workflows/tenant-workflows-types";
import Axios, { CancelTokenSource } from "axios";

export const retryTenantFeatureSetting = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    workflowId: string
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || !settingName || !workflowId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}/workflows/${workflowId}/retry`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const handleFailedTenantFeatureSetting = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    workflowId: string,
    data: IFailedStepHandle
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || !settingName || !workflowId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}/workflows/${workflowId}/handle-fail`,
        data
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const approveTenantFeatureSetting = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    workflowId: string
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || !settingName || !workflowId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}/workflows/${workflowId}/approve`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const retryCefTenantFeatureSetting = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    workflowId: string
): Promise<[void | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || !settingName || !workflowId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}/change-entity-framework/workflows/${workflowId}/retry`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getTenantFeatureSettingWorkFlows = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureSettingWorkFlow[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/setting-workflows`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const getTenantFeatureSettingWorkFlow = async (
    environmentMoniker: string,
    tenantMoniker: string,
    workflowType: number,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureSettingWorkFlow[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/setting-workflows/${workflowType}`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const getTenantChangeEntityFrameworkSettingWorkflows = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureSettingWorkFlow[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/change-entity-framework`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const toggleTenantChangeEntityFrameworkSettings = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settings: ChangeEntityFrameworkSettingToggleValue[]
): Promise<[string[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || settings.length === 0) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/change-entity-framework`,
        settings
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const toggleTenantFeatureSettings = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settings: TenantFeatureSettingWorkFlowToggleValue[]
): Promise<[string[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || settings.length === 0) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings`,
        settings
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const toggleTenantFeatureSetting = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    setting: TenantFeatureSettingWorkFlowToggleValue
): Promise<[string[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || !settingName || !setting) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}`,
        setting
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const getHubConnection = async (
    tenantId: string
): Promise<[IHubConnection | null, number]> => {
    if (!tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.get("hub/connection", {
        headers: {
            "x-ms-signalr-userid": tenantId,
            "x-ms-signalr-hub-name": "TenantFeatureSettings",
        },
    });

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const terminateSettingWorkflow = async (
    environmentMoniker: string,
    tenantMoniker: string,
    settingName: string,
    workflowId: string
): Promise<[void | null, number]> => {
    if (!tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/settings/${settingName}/workflows/${workflowId}/terminate`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
