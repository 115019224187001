import {
    ICustomer,
    SET_CUSTOMERS,
    SET_ACTIVE_CUSTOMERS_BY_ID,
    SORT_CUSTOMERS_BY_NAME,
    SET_ACTIVE_CUSTOMER_NAME,
} from "./customers-types";

export const setCustomers = (customers: ICustomer[]) => ({
    type: SET_CUSTOMERS,
    customers,
});
export const sortCustomersByName = () => ({
    type: SORT_CUSTOMERS_BY_NAME,
});
export const setActiveCustomerById = (customerId: string) => ({
    type: SET_ACTIVE_CUSTOMERS_BY_ID,
    customerId,
});
export const setActiveCustomerName = (name: string) => ({
    type: SET_ACTIVE_CUSTOMER_NAME,
    name,
});
