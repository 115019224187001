import { AppThunk } from "../../../store";
import {
    setErrorMessageInactiveEnvironment,
    setErrorMessageInactiveTenant,
    setErrorResponseMessage,
} from "../../../common/initialization/initialization-actions";
import {
    getTenantScheduler,
    putTenantScheduler,
} from "../../../../api/environments/tenants/tenant-scheduler-settings-api";
import {
    IScheduler,
    ScheduleEventType,
    ScheduleEventSubtype,
} from "./tenant-scheduler-types";
import constants from "../../../../utils/constants";
import { getErrorMessage } from "../../../../api/defaults";
import { CancelTokenSource } from "axios";

export const getScheduler =
    (
        envMoniker: string,
        tenantMoniker: string,
        eventType: ScheduleEventType,
        subtype: ScheduleEventSubtype,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk<Promise<[IScheduler | null, number]>> =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, statusCode] = await getTenantScheduler(
            envMoniker,
            tenantMoniker,
            eventType,
            subtype,
            cancelTokenSource
        );

        return [payload, statusCode];
    };

export const pushScheduler =
    (
        envMoniker: string,
        tenantMoniker: string,
        data: IScheduler
    ): AppThunk<Promise<number>> =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, statusCode] = await putTenantScheduler(
            envMoniker,
            tenantMoniker,
            data
        );

        if (statusCode !== constants.statusCode.OK) {
            const errorMessage = getErrorMessage(String(payload), statusCode);
            dispatch(setErrorResponseMessage(errorMessage));
        }

        return statusCode;
    };
