import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import { RootState } from "../../../store";

export const getActiveTenantDeleteWorkflow = (
    state: RootState
): ITenantDeleteWorkflow | null => {
    return state.tenantDeleteReducer.activeTenantDeleteWorkflow;
};

export const getIsDeleteFetched = (state: RootState): boolean => {
    return state.tenantDeleteReducer.isDeleteFetched;
};
