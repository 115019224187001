import { createSelector } from "reselect";
import { RootState } from "../../../store";
import {
    IEnrollCache,
    IEnrollStep,
    IEnrollWorkflow,
    StepStatus,
} from "./enroll-tenant-types";
import { orderBy } from "lodash";

export const getEnrollWorkflow = (state: RootState): IEnrollWorkflow | null =>
    state.enrollTenantReducer.enrollTenant;

export const getEnrollId = (state: RootState): string =>
    state.enrollTenantReducer.orchestratorInstanceId || "";

export const getActiveStep = (state: RootState): IEnrollStep | null => {
    return state.enrollTenantReducer.activeStep;
};

export const getEnrollCache = (state: RootState): IEnrollCache | null =>
    state.enrollTenantReducer.enrollCache;

export const getEnrollSteps = createSelector(
    [getEnrollWorkflow],
    (enrollTenant) => {
        return (
            enrollTenant &&
            orderBy(enrollTenant?.steps, [(step) => step.order], ["asc"])
        );
    }
);

export const getRunningStepIndex = createSelector(
    [getEnrollSteps],
    (enrollSteps: IEnrollStep[] | null) => {
        if (!enrollSteps) return 0;

        const runningStepIndex = enrollSteps.findIndex(
            (step) =>
                step.status === StepStatus.Running ||
                step.status === StepStatus.Failed ||
                step.status === StepStatus.Pending
        );

        if (runningStepIndex !== -1) {
            return runningStepIndex;
        }

        return enrollSteps.filter((step) => step.status === StepStatus.Success)
            .length;
    }
);
