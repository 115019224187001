export const SET_LOADER_VISIBILITY = "Initialization/SET_LOADER_VISIBILITY";
export const SET_ERROR_RESPONSE_MESSAGE =
    "Initialization/SET_ERROR_RESPONSE_MESSAGE";
export const SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL =
    "Initialization/SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL";
export const SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT =
    "Initialization/SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT";
export const SET_ERROR_MESSAGE_INACTIVE_TENANT =
    "Initialization/SET_ERROR_MESSAGE_INACTIVE_TENANT";
export const SET_ERROR_ENROLL_STEP_MESSAGE =
    "Initialization/SET_ERROR_ENROLL_STEP_MESSAGE";
export const SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE =
    "Initialization/SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE";
export const SET_ERROR_TENANT_DELETE_MESSAGE =
    "Initialization/SET_ERROR_TENANT_DELETE_MESSAGE";

export interface IState {
    loadersCount: number;
    errorResponseMessage: string;
    errorResponseMessageForModal: string;
    errorMessageInactiveEnvironment: string;
    errorMessageInactiveTenant: string;
    errorMessageEnrollStep: string;
    errorEnrollManualStepResponseMessage: string;
    errorTenantDeleteMessage: string;
}
export interface ISetAppInitialization {
    type: typeof SET_LOADER_VISIBILITY;
    isInitialized?: boolean;
}
export interface ISetErrorResponseMessage {
    type: typeof SET_ERROR_RESPONSE_MESSAGE;
    message: string;
}
export interface ISetErrorResponseMessageForModal {
    type: typeof SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL;
    message: string;
}
export interface ISetErrorMessageInactiveEnvironment {
    type: typeof SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT;
    message: string;
}
export interface ISetErrorMessageInactiveTenant {
    type: typeof SET_ERROR_MESSAGE_INACTIVE_TENANT;
    message: string;
}
export interface ISetErrorMessageIEnrollStep {
    type: typeof SET_ERROR_ENROLL_STEP_MESSAGE;
    message: string;
}
export interface ISetErrorEnrollManualStepResponseMessage {
    type: typeof SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE;
    message: string;
}
export interface ISetErrorTenantDeleteMessage {
    type: typeof SET_ERROR_TENANT_DELETE_MESSAGE;
    message: string;
}

export type Actions =
    | ISetAppInitialization
    | ISetErrorResponseMessage
    | ISetErrorResponseMessageForModal
    | ISetErrorMessageInactiveEnvironment
    | ISetErrorMessageInactiveTenant
    | ISetErrorMessageIEnrollStep
    | ISetErrorEnrollManualStepResponseMessage
    | ISetErrorTenantDeleteMessage;
