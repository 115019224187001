import { format } from "date-fns";
import i18next from "./i18n";

export const isPreviousDate = (date: Date): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return date < currentDate;
};

export const formatDate = (date: Date): string => {
    return format(date, "dd.MM.yyyy");
};

export const formatDateWithTime = (date: Date): string => {
    return format(date, "yyyy-MM-dd hh:mm:ss");
};

export const formatDateWithNameOfMonth = (date: Date): string => {
    return format(date, "dd MMMM yyyy - hh:mm:ss");
};

export const compareDates = (date1: Date, date2: Date): number => {
    return date2.getTime() - date1.getTime();
};

export const getDateWithoutTime = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const compareDatesWithoutTime = (date1: Date, date2: Date): number => {
    const newDate1 = getDateWithoutTime(date1);
    const newDate2 = getDateWithoutTime(date2);

    return newDate2.getTime() - newDate1.getTime();
};

export const timeSince = (date: Date): string => {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const localTime = new Date(now.getTime() + offset * 60 * 1000);
    const secondsPast: number = Math.floor(
        (localTime.getTime() - date.getTime()) / 1000
    );

    const timeUnits = [
        { unit: "years", duration: 29030400 },
        { unit: "months", duration: 2419200 },
        { unit: "weeks", duration: 604800 },
        { unit: "days", duration: 86400 },
        { unit: "hours", duration: 3600 },
        { unit: "minutes", duration: 60 },
        { unit: "seconds", duration: 1 },
    ];

    for (const { unit, duration } of timeUnits) {
        const count = Math.floor(secondsPast / duration);
        if (count >= 1) {
            return i18next.t(`time.${unit}Ago`, { count });
        }
    }

    // Default case: less than 1 second ago
    return i18next.t("time.secondsAgo", { count: 0 });
};
