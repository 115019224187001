import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware, { ThunkAction } from "redux-thunk";
import modalWindowReducer from "./common/modal-window/modal-window-reducer";
import authenticationReducer from "./authentication/authentication-reducer";
import initializationReducer from "./common/initialization/initialization-reducer";
import environmentsReducer from "./environments/environment-reducer";
import tenantsReducer from "./environments/tenants/tenants-reducer";
import tenantCultureSettingsReducer from "./environments/tenants/tenant-culture-settings/tenant-culture-settings-reducer";
import pageRefreshReducer from "./common/page-refresh/page-refresh-reducer";
import unsavedDataReducer from "./common/unsaved-data/unsaved-data-reducer";
import appSettingsReducer from "./app-settings/app-settings-reducer";
import customersReducer from "./customers/customers-reducer";
import customerDetailsReducer from "./customers/customer-details/customer-details-reducer";
import addNewCustomersReducer from "./customers/add-new-customer/add-new-customer-reducer";
import filtersReducer from "./filters/filters-reducer";
import pingReducer from "./environments/tenants/tenant-ping/tenant-ping-reducer";
import enrollTenantReducer from "./environments/tenants/enroll-tenant/enroll-tenant-reducer";
import informationLinksReducer from "./information-links/information-links-reducer";
import guestTenantsReducer from "./environments/tenants/tenant-guest-tenants/tenant-guest-tenants-reducer";
import environmentHistoryReducer from "./environments/environment-history/environment-history-reducer";
import appGlobalReducer from "./app-global/app-global-reducer";
import partnersReducer from "./partners/partners-reducer";
import packagesReducer from "./deliverables/erp-packages/erp-packages-reducer";
import tenantDeleteReducer from "./environments/tenants/tenant-delete/tenant-delete-reducer";
import emailTemplatesReducer from "./deliverables/email-templates/email-templates-reducer";
import tenantStatusReducer from "./environments/tenants/tenant-status/tenant-status-reducer";
import captureMailboxesReducer from "./environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-reducer";

const rootReducer = combineReducers({
    appGlobalReducer,
    modalWindowReducer,
    authenticationReducer,
    initializationReducer,
    environmentsReducer,
    tenantsReducer,
    tenantCultureSettingsReducer,
    pageRefreshReducer,
    unsavedDataReducer,
    appSettingsReducer,
    customersReducer,
    customerDetailsReducer,
    addNewCustomersReducer,
    filtersReducer,
    pingReducer,
    enrollTenantReducer,
    informationLinksReducer,
    guestTenantsReducer,
    environmentHistoryReducer,
    partnersReducer,
    packagesReducer,
    tenantDeleteReducer,
    emailTemplatesReducer,
    tenantStatusReducer,
    captureMailboxesReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export type AppDispatch = typeof store.dispatch;

export default store;
