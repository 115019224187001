import {
    SET_CONFLUENCE_DOCUMENT_LINKS,
    State,
    Actions,
} from "./information-links-types";

const initialState = {
    isConfluenceDocumentLinksFetched: false,
    confluenceDocumentLinks: {
        machineLearningArticleUri: "",
        customFieldsConfiguration: "",
        featuresAndSettingsOverview: "",
        bankAccountValidation: "",
        recurringInvoices: "",
    },
};

const informationLinksReducer = (
    state: State = initialState,
    action: Actions
) => {
    switch (action.type) {
        case SET_CONFLUENCE_DOCUMENT_LINKS:
            return {
                ...state,
                isConfluenceDocumentLinksFetched: true,
                confluenceDocumentLinks: { ...action.confluenceDocumentLinks },
            };

        default:
            return state;
    }
};

export default informationLinksReducer;
