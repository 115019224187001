import { RootState } from "redux/store";
import { connect } from "react-redux";
import { compose } from "redux";
import withLoader from "hocs/withLoader/withLoader";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";
import VersionHistory from "./VersionHistory";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant,
    };
};
const VersionHistoryContainer = compose(
    connect(mapStateToProps, {
        setErrorResponseMessage,
    }),
    withLoader
)(VersionHistory);

export default VersionHistoryContainer;
