import { RootState } from "redux/store";
import { compose } from "redux";
import DeleteTenantEnrollBar from "./DeleteTenantEnrollBar";
import { connect } from "react-redux";
import {
    getActiveEnvironmentMoniker,
    getActiveEnvironmentName,
} from "redux/environments/environment-selectors";
import { getActiveTenantDeleteWorkflow } from "redux/environments/tenants/tenant-delete/tenant-delete-selectors";
import { setLoaderVisibility } from "redux/common/initialization/initialization-actions";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { setTenantDeleteWorkflow } from "redux/environments/tenants/tenant-delete/tenant-delete-actions";
import {
    getEnrollSteps,
    getEnrollWorkflow,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import {
    fetchEnrollCache,
    fetchEnrollWorkflow,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-thunks";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        activeEnvName: getActiveEnvironmentName(state),
        enrollSteps: getEnrollSteps(state),
        activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
        orchestratorInstanceId:
            state.enrollTenantReducer.orchestratorInstanceId,
        enrollCache: state.enrollTenantReducer.enrollCache,
        enrollWorkflow: getEnrollWorkflow(state),
    };
};

const DeleteTenantEnrollBarContainer = compose(
    connect(mapStateToProps, {
        setLoaderVisibility,
        setIsUnsavedChangeAvailable,
        setTenantDeleteWorkflow,
        fetchEnrollWorkflow,
        fetchEnrollCache,
    })
)(DeleteTenantEnrollBar);

export default DeleteTenantEnrollBarContainer;
