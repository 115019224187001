import React from "react";
import { Icon, IconButton, Table, TableCellProps } from "rsuite";
import { IconNames } from "rsuite/lib/Icon";

interface ComponentProps extends TableCellProps {
    iconName: IconNames;
    onChange: (rowData?: any) => void;
}

const ExpandableCell: React.FC<ComponentProps> = ({
    onChange,
    iconName,
    ...props
}: ComponentProps) => {
    return (
        <Table.Cell {...props}>
            <IconButton
                appearance="subtle"
                circle
                size="sm"
                onClick={() => {
                    onChange(props.rowData);
                }}
                icon={<Icon icon={iconName} />}
            />
        </Table.Cell>
    );
};

export default ExpandableCell;
