import { AppThunk } from "../../../store";
import {
    setErrorMessageInactiveEnvironment,
    setErrorMessageInactiveTenant,
    setErrorResponseMessage,
} from "../../../common/initialization/initialization-actions";
import {
    getCultureSettings as getTenantCultureSettings,
    putCultureSettings,
    getCurrencies as getTenantCurrencies,
    getSupportedCultures as getLocaleSupportedCultures,
    getCultures as getLocaleCultures,
} from "../../../../api/environments/tenants/tenant-culture-settings-api";
import {
    setCultureSettings,
    setCurrencies,
    setSupportedCultures,
    setCultureSettingsSaved,
    setCultures,
} from "./tenant-culture-settings-actions";
import { ICultureSettings } from "./tenant-culture-settings-types";
import constants from "../../../../utils/constants";
import { getErrorMessage } from "../../../../api/defaults";
import { CancelTokenSource } from "axios";

export const getCultureSettings =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, status] = await getTenantCultureSettings(
            environmentMoniker,
            tenantMoniker,
            cancelTokenSource
        );

        if (payload && status === constants.statusCode.OK) {
            dispatch(setCultureSettings(payload));
        } else if (status === constants.statusCode.NoContent) {
            dispatch(setCultureSettings(null));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };

export const updateCultureSettings =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cultureSettings: ICultureSettings
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await putCultureSettings(
            environmentMoniker,
            tenantMoniker,
            cultureSettings
        );
        const isResponseStatusCodeExpected =
            status === constants.statusCode.NoContent ||
            status === constants.statusCode.OK;

        if (isResponseStatusCodeExpected) {
            dispatch(setCultureSettingsSaved(true));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };

export const getCurrencies =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));
        dispatch(setErrorMessageInactiveEnvironment(""));
        dispatch(setErrorMessageInactiveTenant(""));

        const [payload, status] = await getTenantCurrencies(
            environmentMoniker,
            tenantMoniker,
            cancelTokenSource
        );

        if (payload && status === constants.statusCode.OK) {
            dispatch(setCurrencies(payload));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };

export const getSupportedCultures =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk =>
    async (dispatch) => {
        const [payload, status] = await getLocaleSupportedCultures(
            environmentMoniker,
            tenantMoniker,
            cancelTokenSource
        );

        if (payload && status === constants.statusCode.OK) {
            dispatch(setSupportedCultures(payload));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };

export const getCultures =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await getLocaleCultures(
            environmentMoniker,
            tenantMoniker,
            cancelTokenSource
        );

        if (payload && status === constants.statusCode.OK) {
            dispatch(setCultures(payload));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };
