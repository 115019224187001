export const SET_IS_UNSAVED_CHANGE_AVAILABLE =
    "unsavedData/SET_IS_UNSAVED_CHANGE_AVAILABLE";
export const SET_MODAL_CONFIRM_CALL_BACK =
    "unsavedData/SET_MODAL_CONFIRM_CALL_BACK";
export const REMOVE_MODAL_CONFIRM_CALL_BACK =
    "unsavedData/REMOVE_MODAL_CONFIRM_CALL_BACK";

export interface IModalCallback {
    id: string;
    /**
     * Defines if callback is automatically deleted after confirming or canceling unsaved data popup.
     */
    isAutoDeleteResistant: boolean;
    /**
     * Defines if the callback will redirect the user to another page.
     * This type of callback is processed in the first place, all other callbacks are ignored,
     * there is no sense in running a page callbacks if the user is redirected out from this page.
     */
    isPageRedirect: boolean;
    callback: () => void;
}

export interface State {
    isUnsavedChangeAvailable: boolean;
    modalConfirmCallbacks: IModalCallback[];
}

export interface ISetIsUnsavedChangeAvailable {
    type: typeof SET_IS_UNSAVED_CHANGE_AVAILABLE;
    isUnsavedChangeAvailable: boolean;
}

export interface ISetModalConfirmCallback {
    type: typeof SET_MODAL_CONFIRM_CALL_BACK;
    modalConfirmCallback: IModalCallback;
}

export interface IRemoveModalConfirmCallback {
    type: typeof REMOVE_MODAL_CONFIRM_CALL_BACK;
    predicate: (modalCallback: IModalCallback) => boolean;
}

export type Actions =
    | ISetIsUnsavedChangeAvailable
    | ISetModalConfirmCallback
    | IRemoveModalConfirmCallback;
