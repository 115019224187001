import React from "react";
import { Steps } from "rsuite";
import { convertStepStatusToString } from "utils/status-helper";
import {
    EnrollStatus,
    IEnrollStep,
    IEnrollWorkflow,
    StepStatus,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import styles from "./StepsNavigation.module.css";
import cn from "classnames";

interface IComponentProps {
    enrollWorkflow: IEnrollWorkflow | null;
    enrollSteps: IEnrollStep[] | null;
    currentStepIndex: number;
    onStepClick: (index: number) => void;
}
const StepsNavigation: React.FC<IComponentProps> = ({
    enrollWorkflow,
    enrollSteps,
    currentStepIndex,
    onStepClick,
}: IComponentProps) => {
    return (
        enrollSteps && (
            <Steps
                current={currentStepIndex}
                vertical
                className={styles.stepsNavigation}
            >
                {enrollSteps.map((step, index) => {
                    const openedStepClass =
                        index === currentStepIndex ? styles.openedStep : "";
                    const failedStepClass =
                        step.status === StepStatus.Failed
                            ? styles.stepFailed
                            : "";
                    const skippedStepClass =
                        step.status === StepStatus.Skipped
                            ? styles.stepSkipped
                            : "";
                    const deletingStepClass =
                        index === currentStepIndex
                            ? styles.deletingCurrentStep
                            : styles.deletingPreviousStep;
                    const isStepStatusChanged =
                        currentStepIndex < index &&
                        (step.status === StepStatus.Running ||
                            step.status === StepStatus.Failed ||
                            step.status === StepStatus.Skipped ||
                            step.status === StepStatus.Pending);

                    return (
                        <Steps.Item
                            className={cn(
                                enrollWorkflow?.status ===
                                    EnrollStatus.Terminating
                                    ? deletingStepClass
                                    : openedStepClass,
                                failedStepClass,
                                skippedStepClass
                            )}
                            status={
                                isStepStatusChanged
                                    ? convertStepStatusToString(
                                          StepStatus.Success
                                      )
                                    : convertStepStatusToString(step.status)
                            }
                            key={String(index)}
                            title={step.name}
                            onClick={() => {
                                onStepClick(index);
                            }}
                        />
                    );
                })}
            </Steps>
        )
    );
};

export default StepsNavigation;
