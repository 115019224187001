import { getErrorMessage } from "api/defaults";
import {
    loadTenantMaintenanceModeConfigurations,
    updateTenantMaintenanceModeConfigurations,
} from "api/environments/tenants/tenant-maintenance-mode-api";
import React, { MutableRefObject, useRef } from "react";
import { useEffect, useState } from "react";
import useRefreshPage from "hooks/useRefreshPage";
import { useTranslation } from "react-i18next";
import { ITenantMaintenanceMode } from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import {
    Alert,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Schema,
    Toggle,
} from "rsuite";
import constants from "utils/constants";
import "./MaintenanceMode.less";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios from "axios";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
}

const MaintenanceMode: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    setIsUnsavedChangeAvailable,
    showLoader,
    showError,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();

    const formRef = useRef<HTMLFormElement>();
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [maintenanceModeConfigs, setMaintenanceModeConfigs] =
        useState<ITenantMaintenanceMode | null>(null);

    const fetchData = (envMoniker: string, tenantMoniker: string) => {
        showError("");
        showLoader(true);
        loadTenantMaintenanceModeConfigurations(
            envMoniker,
            tenantMoniker,
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setMaintenanceModeConfigs(payload);
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            })
            .then(() => {
                showLoader(false);
            });
        setIsDataFetched(true);
    };

    const handleFormSubmit = () => {
        if (maintenanceModeConfigs && envMoniker && tenantMoniker) {
            if (
                maintenanceModeConfigs.maintenanceModeEnabled &&
                !formRef.current?.check()
            ) {
                return;
            }
            showError("");
            showLoader(true);
            updateTenantMaintenanceModeConfigurations(
                envMoniker,
                tenantMoniker,
                maintenanceModeConfigs
            ).then(([payload, status]) => {
                showLoader(false);
                setIsUnsavedChangeAvailable(false);
                if (status === constants.statusCode.NoContent) {
                    Alert.success(
                        t("tenantConfig.common.successfulUpdateAlertMessage"),
                        constants.alertDurationMsec
                    );
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
            });
        }
    };

    const handleConfigChange = (
        name: keyof ITenantMaintenanceMode,
        value: string | boolean
    ) => {
        if (maintenanceModeConfigs) {
            setMaintenanceModeConfigs({
                ...maintenanceModeConfigs,
                [name]: value,
            });
        }
        setIsUnsavedChangeAvailable(true);
    };

    const cleanValidation = (
        formRef: MutableRefObject<HTMLFormElement | undefined>
    ) => {
        if (formRef && formRef.current) {
            formRef.current.cleanErrors();
        }
    };

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isDataFetched) {
            fetchData(envMoniker, tenantMoniker);
        }
    }, [envMoniker, tenantMoniker, isDataFetched]);

    useEffect(() => {
        if (!maintenanceModeConfigs?.maintenanceModeEnabled) {
            cleanValidation(formRef);
        }
    }, [maintenanceModeConfigs]);

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            setIsDataFetched(false);
            cleanValidation(formRef);
        }
    });

    const { StringType } = Schema.Types;
    const formModel = Schema.Model({
        maintenanceModeSystemDictionaryKey: StringType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        maintenanceModeDooapDictionaryKey: StringType(),
    });

    const formKey: Record<
        keyof ITenantMaintenanceMode,
        keyof ITenantMaintenanceMode
    > = {
        maintenanceModeEnabled: "maintenanceModeEnabled",
        maintenanceModeDooapDictionaryKey: "maintenanceModeDooapDictionaryKey",
        maintenanceModeSystemDictionaryKey:
            "maintenanceModeSystemDictionaryKey",
    };

    return (
        <>
            {isDataFetched && maintenanceModeConfigs && (
                <div className="general-configurations__container">
                    <div className="general-configurations__content">
                        <h1 className="general-configurations__page-header">
                            {t("tenantConfig.maintenanceMode.header")}
                        </h1>
                        <Form
                            ref={formRef}
                            model={formModel}
                            formValue={maintenanceModeConfigs}
                            className="maintenance-mode__form"
                        >
                            <FormGroup className="maintenance-mode__form-toggler">
                                <Toggle
                                    value={formKey.maintenanceModeEnabled}
                                    checked={
                                        maintenanceModeConfigs.maintenanceModeEnabled
                                    }
                                    onChange={(checked: boolean) =>
                                        handleConfigChange(
                                            formKey.maintenanceModeEnabled,
                                            checked
                                        )
                                    }
                                    checkedChildren={t("toggler.onTitle")}
                                    unCheckedChildren={t("toggler.offTitle")}
                                />
                                <div>
                                    {t(
                                        "tenantConfig.maintenanceMode.checkboxTitle"
                                    )}
                                </div>
                            </FormGroup>
                            <div className="maintenance-mode__form-helper">
                                {t("tenantConfig.maintenanceMode.helpText")}
                            </div>
                            <FormGroup className="maintenance-mode__form-group">
                                <ControlLabel>
                                    {t(
                                        "tenantConfig.maintenanceMode.headerField.title"
                                    )}
                                    <HelpBlock
                                        className={
                                            "general-configurations__help"
                                        }
                                        tooltip
                                    >
                                        {t(
                                            "tenantConfig.maintenanceMode.headerField.tooltip"
                                        )}
                                    </HelpBlock>
                                </ControlLabel>
                                <FormControl
                                    className="general-configurations__form-control"
                                    name={
                                        formKey.maintenanceModeSystemDictionaryKey
                                    }
                                    onChange={(value: string) => {
                                        handleConfigChange(
                                            formKey.maintenanceModeSystemDictionaryKey,
                                            value
                                        );
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="maintenance-mode__form-group">
                                <ControlLabel>
                                    {t(
                                        "tenantConfig.maintenanceMode.description.title"
                                    )}
                                    <HelpBlock
                                        className={
                                            "general-configurations__help"
                                        }
                                        tooltip
                                    >
                                        {t(
                                            "tenantConfig.maintenanceMode.description.tooltip"
                                        )}
                                    </HelpBlock>
                                </ControlLabel>
                                <FormControl
                                    className="general-configurations__form-control"
                                    name={
                                        formKey.maintenanceModeDooapDictionaryKey
                                    }
                                    onChange={(value: string) => {
                                        handleConfigChange(
                                            formKey.maintenanceModeDooapDictionaryKey,
                                            value
                                        );
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                    <SettingsFooterContainer
                        handleFormSubmit={handleFormSubmit}
                    />
                </div>
            )}
        </>
    );
};

export default MaintenanceMode;
