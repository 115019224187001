import { AppThunk } from "redux/store";
import { setLoaderVisibility } from "redux/common/initialization/initialization-actions";
import {
    setEnrollTenant,
    setOrchestratorInstanceId,
    setEnrollCache,
} from "./enroll-tenant-actions";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";
import {
    startTenantEnroll,
    getEnrollCache,
    loadEnrollWorkflow,
    approveEnrollStep as approveStep,
    loadSubstepActions,
    terminateTenantEnroll,
} from "api/environments/tenants/tenant-enrollments-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import {
    IEnrollCache,
    InformationActions,
    StepApproveDataTypes,
} from "./enroll-tenant-types";

export const startEnroll =
    (environmentMoniker: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));

        const [payload, statusCode] = await startTenantEnroll(
            environmentMoniker
        );

        if (payload && statusCode === constants.statusCode.OK) {
            dispatch(setOrchestratorInstanceId(payload));
        } else {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                )
            );
        }

        dispatch(setLoaderVisibility(false));
    };

export const fetchEnrollWorkflow =
    (environmentMoniker: string, orchestratorInstanceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        const [payload, statusCode] = await loadEnrollWorkflow(
            environmentMoniker,
            orchestratorInstanceId
        );

        if (payload && statusCode === constants.statusCode.OK) {
            dispatch(setEnrollTenant(payload));
        } else {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        }
        dispatch(setLoaderVisibility(false));
    };

export const fetchEnrollCache =
    (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ): AppThunk<Promise<[IEnrollCache | null, number]>> =>
    async (dispatch) => {
        const [payload, statusCode] = await getEnrollCache(
            environmentMoniker,
            orchestratorInstanceId
        );

        if (payload && statusCode === constants.statusCode.OK) {
            dispatch(setEnrollCache(payload));
        } else if (statusCode === constants.statusCode.NoContent) {
            dispatch(setEnrollTenant(null));
        } else if (statusCode === constants.statusCode.UnauthenticatedError) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        }

        return [payload, statusCode];
    };

export const fetchSubstepActions =
    (
        orchestratorInstanceId: string,
        environmentMoniker: string,
        stepName: string
    ): AppThunk<Promise<InformationActions | null>> =>
    async (dispatch) => {
        const [payload, statusCode] = await loadSubstepActions(
            orchestratorInstanceId,
            environmentMoniker,
            stepName
        );

        const isStatusSuccessful = statusCode === constants.statusCode.OK;
        if (!isStatusSuccessful) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        }

        return isStatusSuccessful ? payload : null;
    };

export const approveEnrollStep =
    (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        eventName: string,
        data: StepApproveDataTypes
    ): AppThunk =>
    async (dispatch) => {
        const [payload, statusCode] = await approveStep(
            environmentMoniker,
            orchestratorInstanceId,
            eventName,
            data
        );

        if (payload && statusCode !== constants.statusCode.OK) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                )
            );
        }
    };

export const terminateEnroll =
    (environmentMoniker: string, orchestratorInstanceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        const [payload, statusCode] = await terminateTenantEnroll(
            environmentMoniker,
            orchestratorInstanceId
        );
        if (payload && statusCode !== constants.statusCode.OK) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                )
            );
        }
        dispatch(setLoaderVisibility(false));
    };

export const retryEnrollStep =
    (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        data: StepApproveDataTypes
    ): AppThunk =>
    async (dispatch) => {
        const [payload, statusCode] = await approveStep(
            environmentMoniker,
            orchestratorInstanceId,
            constants.retryFailedStepEventName,
            data
        );

        if (payload && statusCode !== constants.statusCode.OK) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                )
            );
        }
    };

export const continueEnrollStep =
    (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        data: StepApproveDataTypes
    ): AppThunk =>
    async (dispatch) => {
        const [payload, statusCode] = await approveStep(
            environmentMoniker,
            orchestratorInstanceId,
            constants.completeStepEventName,
            data
        );

        if (payload && statusCode !== constants.statusCode.OK) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                )
            );
        }
    };
