import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Whisper } from "rsuite";

interface ComponentProps {
    children?: React.ReactNode;
}

const EllipsisLabel: React.FC<ComponentProps> = ({
    children,
}: ComponentProps) => {
    const overflowingText = useRef<HTMLDivElement | null>(null);
    const [overflowActive, setOverflowActive] = useState<boolean>(false);

    const checkOverflow = (textContainer: HTMLDivElement | null): boolean => {
        if (textContainer) {
            return (
                textContainer.offsetHeight < textContainer.scrollHeight ||
                textContainer.offsetWidth < textContainer.scrollWidth
            );
        }
        return false;
    };
    useEffect(() => {
        if (checkOverflow(overflowingText.current)) {
            setOverflowActive(true);
            return;
        }

        setOverflowActive(false);
    }, [overflowActive]);

    const content = overflowActive ? (
        <Whisper
            trigger={"hover"}
            placement={"autoVertical"}
            speaker={<Tooltip>{children}</Tooltip>}
        >
            <div
                ref={overflowingText}
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {children}
            </div>
        </Whisper>
    ) : (
        <div
            ref={overflowingText}
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {children}
        </div>
    );

    return content;
};
export default EllipsisLabel;
