import i18n from "utils/i18n";
import { IEmailRecipient } from "../customer-details/customer-details-types";

export const SET_CONSENT_METADATA = "addNewCustomer/SET_CONSENT_METADATA";
export const SET_NEW_CUSTOMER_FORM_VALUE =
    "addNewCustomer/SET_NEW_CUSTOMER_FORM_VALUE";
export const CLEAN_ADD_NEW_CUSTOMER_DATA =
    "addNewCustomer/CLEAN_ADD_NEW_CUSTOMER_DATA";
export const SET_NEW_CUSTOMER_ADDED = "addNewCustomer/SET_NEW_CUSTOMER_ADDED";
export const SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE =
    "addNewCustomer/SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE";

export enum CustomerType {
    D365,
    AX2012,
    Reseller,
    Guest,
}
export const regionNames: Record<string, string> = {
    us: i18n.t("regions.us"),
    use: i18n.t("regions.use"),
    euw: i18n.t("regions.euw"),
};
export interface IConsentsList {
    title: string;
    consentNames: string[];
}
export interface IConsentMetadata {
    customerTypes: ICustomerType[];
    regions: string[];
    dooapApplications: ICustomerDooapApplication[];
}
export interface ICustomerType {
    id: string;
    description: string;
    type: CustomerType;
}
export interface ICustomerAadMasterTenant {
    id: string;
    customerName: string;
    customerTypeId: string;
    emailTemplateId: string;
    customerType: ICustomerType | null;
    partnerCustomerAadMasterTenants: IPartnerCustomerAadMasterTenants[];
    customerAadMasterTenantConsents: ICustomerConsentsMaster[];
    emailRecipients: IEmailRecipient[] | null;
}
export interface IPartnerCustomerAadMasterTenants {
    partnerId: string;
    customerAadMasterTenantId: string;
}
export interface ICustomerConsentsMaster {
    id: string;
    dooapEnvironmentAppRegistrationId: string;
    dooapEnvironmentAppRegistration: IDooapEnvironmentAppRegistration | null;
}
export interface ICustomerDooapApplication {
    id: string;
    customerType: ICustomerType;
    dooapEnvironmentAppRegistration: IDooapEnvironmentAppRegistration;
}
export interface IDooapEnvironmentAppRegistration {
    id: string;
    clientId: string;
    dooapEnvironment: IDooapEnvironment;
    dooapAadApplicationType: IDooapApplication;
}
export interface IDooapApplication {
    id: string;
    description: string;
    order: number;
    area: number;
}
export interface IDooapEnvironment {
    id: string;
    name: string;
    moniker: string;
    region: string;
}
export interface INewCustomerFormValue {
    customerName: string;
    customerType: ICustomerType;
    partnerIds: string[];
    regions: string[];
    emailTemplateId: string;
}

export interface ISetConsentMetaData {
    type: typeof SET_CONSENT_METADATA;
    consentMetaData: IConsentMetadata;
}
export interface ISetNewCustomerFormValue {
    type: typeof SET_NEW_CUSTOMER_FORM_VALUE;
    newCustomerFormValue: INewCustomerFormValue;
}
export interface ICleanAddNewCustomerData {
    type: typeof CLEAN_ADD_NEW_CUSTOMER_DATA;
}
export interface ISetNewCustomerAdded {
    type: typeof SET_NEW_CUSTOMER_ADDED;
    isNewCustomerAdded: boolean;
}
export interface ISetDefaultNewCustomerFormValue {
    type: typeof SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE;
}
export interface State {
    isNewCustomerAdded: boolean;
    customerTypes: ICustomerType[];
    customerConsents: ICustomerConsentsMaster[];
    dooapApplications: ICustomerDooapApplication[];
    newCustomer: ICustomerAadMasterTenant;
    newCustomerFormValue: INewCustomerFormValue;
    regions: string[];
}
export type Actions =
    | ISetConsentMetaData
    | ISetNewCustomerFormValue
    | ICleanAddNewCustomerData
    | ISetNewCustomerAdded
    | ISetDefaultNewCustomerFormValue;
