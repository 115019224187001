import { defaultErrorResponse } from "api/defaults";
import { CancelTokenSource } from "axios";
import {
    ISftpAccount,
    ISftpUserWithIpAddresses,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { API } from "utils/api-config";
import { handleApiResponse } from "utils/api-request-cancellation";

export const loadSftpUserIpAddresses = async (
    environmentMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ISftpUserWithIpAddresses, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantId}/sftp/ip-addresses`,
        { cancelToken: cancelTokenSource?.token }
    );

    return handleApiResponse(response, cancelTokenSource);
};

export const updateSftpUserIpAddresses = async (
    environmentMoniker: string,
    tenantId: string,
    sftpUser: ISftpUserWithIpAddresses
): Promise<[string | null, number]> => {
    if (!environmentMoniker || !tenantId || !sftpUser) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `environments/${environmentMoniker}/tenants/${tenantId}/sftp/ip-addresses`,
        sftpUser
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadSftpUserCredentials = async (
    environmentMoniker: string,
    tenantId: string
): Promise<[ISftpAccount | null, number]> => {
    if (!environmentMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantId}/sftp/credentials`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
