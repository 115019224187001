import constants from "utils/constants";
import {
    State,
    Actions,
    CustomerType,
    ICustomerConsentsMaster,
    SET_CONSENT_METADATA,
    SET_NEW_CUSTOMER_FORM_VALUE,
    CLEAN_ADD_NEW_CUSTOMER_DATA,
    SET_NEW_CUSTOMER_ADDED,
    SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE,
    INewCustomerFormValue,
    ICustomerDooapApplication,
    IPartnerCustomerAadMasterTenants,
} from "./add-new-customer-types";

const initialState = {
    isNewCustomerAdded: false,
    dooapApplications: [],
    customerTypes: [],
    customerConsents: [],
    newCustomer: {
        id: "",
        customerName: "",
        customerTypeId: "",
        customerType: null,
        partnerCustomerAadMasterTenants: [],
        customerAadMasterTenantConsents: [],
        emailTemplateId: "",
        emailRecipients: null,
    },
    newCustomerFormValue: {
        customerName: "",
        customerType: {
            id: "",
            description: "",
            type: CustomerType.AX2012,
        },
        partnerIds: [],
        regions: [],
        emailTemplateId: "",
        emailRecipients: null,
    },
    regions: [],
};
const addNewCustomersReducer = (
    state: State = initialState,
    action: Actions
): State => {
    const filterAppsByRegionsAndCustomerType = (
        newCustomerFormValue: INewCustomerFormValue
    ): ICustomerDooapApplication[] => {
        return state.dooapApplications
            .filter((app) => {
                return newCustomerFormValue.regions?.includes(
                    app.dooapEnvironmentAppRegistration.dooapEnvironment.region
                );
            })
            .filter(
                (app) =>
                    app.customerType.id ===
                    newCustomerFormValue.customerType?.id
            );
    };

    const assembleCustomerConsentsMasters = (
        newCustomerFormValue: INewCustomerFormValue
    ): ICustomerConsentsMaster[] => {
        return filterAppsByRegionsAndCustomerType(newCustomerFormValue).map<
            ICustomerConsentsMaster
        >((app) => {
            return {
                id: constants.defaultGuid,
                dooapEnvironmentAppRegistrationId:
                    app.dooapEnvironmentAppRegistration.id,
                dooapEnvironmentAppRegistration: null,
            };
        });
    };

    const assembleCustomerPartnerRelations = (
        newCustomerFormValue: INewCustomerFormValue
    ): IPartnerCustomerAadMasterTenants[] => {
        return newCustomerFormValue.partnerIds.map((partnerId) => {
            return {
                partnerId: partnerId,
                customerAadMasterTenantId: constants.defaultGuid,
            };
        });
    };

    const assembleCustomerConsents = (
        newCustomerFormValue: INewCustomerFormValue
    ): ICustomerConsentsMaster[] => {
        return filterAppsByRegionsAndCustomerType(newCustomerFormValue).map<
            ICustomerConsentsMaster
        >((app) => {
            return {
                id: constants.defaultGuid,
                dooapEnvironmentAppRegistrationId:
                    app.dooapEnvironmentAppRegistration.id,
                dooapEnvironmentAppRegistration:
                    app.dooapEnvironmentAppRegistration,
            };
        });
    };

    switch (action.type) {
        case SET_CONSENT_METADATA:
            return {
                ...state,
                regions: action.consentMetaData.regions,
                customerTypes: action.consentMetaData.customerTypes,
                dooapApplications: action.consentMetaData.dooapApplications,
            };
        case SET_NEW_CUSTOMER_FORM_VALUE:
            return {
                ...state,
                newCustomerFormValue: { ...action.newCustomerFormValue },
                customerConsents: [
                    ...assembleCustomerConsents(action.newCustomerFormValue),
                ],
                newCustomer: {
                    ...state.newCustomer,
                    id: constants.defaultGuid,
                    customerName: action.newCustomerFormValue.customerName,
                    emailTemplateId:
                        action.newCustomerFormValue.emailTemplateId,
                    customerTypeId:
                        action.newCustomerFormValue.customerType?.id,
                    partnerCustomerAadMasterTenants: [
                        ...assembleCustomerPartnerRelations(
                            action.newCustomerFormValue
                        ),
                    ],
                    customerAadMasterTenantConsents: [
                        ...assembleCustomerConsentsMasters(
                            action.newCustomerFormValue
                        ),
                    ],
                },
            };
        case CLEAN_ADD_NEW_CUSTOMER_DATA:
            return {
                ...initialState,
            };
        case SET_NEW_CUSTOMER_ADDED:
            return {
                ...state,
                isNewCustomerAdded: action.isNewCustomerAdded,
            };
        case SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE:
            const defaultNewCustomerFormValue = {
                ...state.newCustomerFormValue,
                customerType:
                    state.customerTypes.find(
                        (ct) => ct.type === CustomerType.D365
                    ) ?? state.newCustomerFormValue.customerType,
                regions: state.regions,
            };
            return {
                ...state,
                newCustomerFormValue: {
                    ...defaultNewCustomerFormValue,
                },
                customerConsents: [
                    ...assembleCustomerConsents(defaultNewCustomerFormValue),
                ],
                newCustomer: {
                    ...state.newCustomer,
                    customerName: state.newCustomerFormValue.customerName,
                    customerAadMasterTenantConsents: [
                        ...assembleCustomerConsentsMasters(
                            defaultNewCustomerFormValue
                        ),
                    ],
                },
            };
        default:
            return state;
    }
};

export default addNewCustomersReducer;
