import React, {
    MutableRefObject,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
    EmailRecipientType,
    ICustomerDetails,
    IEmailRecipient,
} from "redux/customers/customer-details/customer-details-types";
import useRefreshPage from "hooks/useRefreshPage";
import EmailAddressesBox from "components/Common/EmailAddressesBox/EmailAddressesBox";
import { SelectPicker } from "rsuite";
import css from "../CustomerDetails.module.css";
import { loadEmailTemplates } from "api/deliverables/email-templates-api";
import constants from "utils/constants";
import cn from "classnames";
import { getErrorMessage } from "api/defaults";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    containerRef: RefObject<HTMLDivElement>;
    customerDetails: ICustomerDetails;
    originalDetails: ICustomerDetails;
    isPartner: boolean;
    handleFormSubmit: (isValid: boolean) => void;
    setIsDataLoading: (isDataLoading: boolean) => void;
    setCustomerDetails: (customerDetails: ICustomerDetails | null) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}

const PackageDelivery: React.FC<ComponentProps> = ({
    containerRef,
    customerDetails,
    originalDetails,
    // TODO: Should be used permission-based approach
    isPartner,
    handleFormSubmit,
    setIsDataLoading,
    setCustomerDetails,
    setIsUnsavedChangeAvailable,
    showLoader,
}: ComponentProps) => {
    const { t } = useTranslation();
    const mainFormRef = useRef<HTMLFormElement>();
    const copyFormRef = useRef<HTMLFormElement>();
    const [mainEmailAddress, setMainEmailAddress] = useState("");
    const [resultMainEmailAddresses, setResultMainEmailAddresses] = useState<
        string[]
    >([]);
    const isMainResultArray = resultMainEmailAddresses.length > 0;

    const [copyEmailAddress, setCopyEmailAddress] = useState("");
    const [resultCopyEmailAddresses, setResultCopyEmailAddresses] = useState<
        string[]
    >([]);
    const isCopyResultArray = resultCopyEmailAddresses.length > 0;
    const [isEmailTemplatesLoaded, setIsEmailTemplatesLoaded] =
        useState<boolean>(false);
    const [emailTemplates, setEmailTemplates] = useState<Record<string, any>[]>(
        []
    );
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const fetchEmailTemplates = () => {
        showLoader(true);
        setIsDataLoading(true);
        loadEmailTemplates().then(([payload, status]) => {
            showLoader(false);
            setIsDataLoading(false);
            if (payload && status === constants.statusCode.OK) {
                setEmailTemplates(
                    payload.map(
                        (template) =>
                            ({
                                value: {
                                    id: template.id,
                                    name: template.name,
                                    locale: template.locale,
                                },
                                label: template.name,
                            } || [])
                    )
                );
            } else {
                setErrorResponseMessage(
                    getErrorMessage(String(payload), status)
                );
            }
            setInitialValues();
            setIsEmailTemplatesLoaded(true);
        });
    };

    const cleanValidation = (
        formRef: MutableRefObject<HTMLFormElement | undefined>
    ) => {
        if (formRef && formRef.current) {
            containerRef.current?.scrollTo(0, 0);
            formRef.current.cleanErrors();
        }
    };

    const getEmailAddresses = (
        recipients: IEmailRecipient[],
        type: EmailRecipientType
    ) => {
        return recipients.filter((r) => r.type === type).map((r) => r.address);
    };

    const setInitialValues = () => {
        setMainEmailAddress("");
        setResultMainEmailAddresses(
            getEmailAddresses(
                originalDetails?.emailRecipients,
                EmailRecipientType.Main
            )
        );
        setCopyEmailAddress("");
        setResultCopyEmailAddresses(
            getEmailAddresses(
                originalDetails?.emailRecipients,
                EmailRecipientType.Copy
            )
        );
        setCustomerDetails({
            ...customerDetails,
            emailTemplate: originalDetails?.emailTemplate,
        });
    };

    const handleTemplateChange = (value: any) => {
        setCustomerDetails({
            ...customerDetails,
            emailTemplate: value,
        });
        setIsUnsavedChangeAvailable(true);
    };

    const handleSubmit = () => {
        if (!mainFormRef.current?.check() || !copyFormRef.current?.check()) {
            return;
        }
        if (mainEmailAddress) {
            resultMainEmailAddresses.push(mainEmailAddress);
            setMainEmailAddress("");
        }
        if (copyEmailAddress) {
            resultCopyEmailAddresses.push(copyEmailAddress);
            setCopyEmailAddress("");
        }
        const emailRecipients = [
            ...resultCopyEmailAddresses.map((address) => ({
                address,
                type: EmailRecipientType.Copy,
            })),
            ...resultMainEmailAddresses.map((address) => ({
                address,
                type: EmailRecipientType.Main,
            })),
        ];
        setCustomerDetails({
            ...customerDetails,
            emailRecipients: emailRecipients,
        });
        setIsSubmitted(true);
    };

    const compareArrays = (a: string[], b: string[]) =>
        a.length === b.length &&
        a.every((element, index) => element === b[index]);

    useEffect(() => {
        if (isSubmitted) {
            handleFormSubmit(true);
            setIsSubmitted(false);
        }
    }, [isSubmitted]);

    useEffect(() => {
        if (isEmailTemplatesLoaded && customerDetails) {
            const isCopyResultChanged = !compareArrays(
                resultCopyEmailAddresses,
                getEmailAddresses(
                    originalDetails?.emailRecipients,
                    EmailRecipientType.Copy
                )
            );
            const isMainResultChanged = !compareArrays(
                resultMainEmailAddresses,
                getEmailAddresses(
                    originalDetails?.emailRecipients,
                    EmailRecipientType.Main
                )
            );

            if (isCopyResultChanged || isMainResultChanged) {
                const emailRecipients = [
                    ...resultCopyEmailAddresses.map((address) => ({
                        address,
                        type: EmailRecipientType.Copy,
                    })),
                    ...resultMainEmailAddresses.map((address) => ({
                        address,
                        type: EmailRecipientType.Main,
                    })),
                ];
                setCustomerDetails({
                    ...customerDetails,
                    emailRecipients: emailRecipients,
                });
                setIsUnsavedChangeAvailable(true);
            }
        }
    }, [resultCopyEmailAddresses, resultMainEmailAddresses]);

    useEffect(() => {
        if (!isEmailTemplatesLoaded) {
            fetchEmailTemplates();
        }
    }, [isEmailTemplatesLoaded]);

    useRefreshPage(() => {
        fetchEmailTemplates();
        cleanValidation(mainFormRef);
        cleanValidation(copyFormRef);
    });

    return (
        <>
            <p className={css.packageRecipientsTitle}>
                {t("customerDetails.packageDelivery.recipientsTitle")}
            </p>
            <EmailAddressesBox
                formRef={mainFormRef}
                emailAddress={mainEmailAddress}
                resultEmailAddresses={resultMainEmailAddresses}
                isResultArray={isMainResultArray}
                boxLabel={t(
                    "customerDetails.packageDelivery.mainRecipientTitle"
                )}
                tooltipText={t(
                    "customerDetails.packageDelivery.emailBoxTooltip"
                )}
                setEmailAddress={setMainEmailAddress}
                setResultEmailAddresses={setResultMainEmailAddresses}
            />
            <EmailAddressesBox
                formRef={copyFormRef}
                emailAddress={copyEmailAddress}
                resultEmailAddresses={resultCopyEmailAddresses}
                isResultArray={isCopyResultArray}
                boxLabel={t(
                    "customerDetails.packageDelivery.copyRecipientTitle"
                )}
                tooltipText={t(
                    "customerDetails.packageDelivery.emailBoxTooltip"
                )}
                setEmailAddress={setCopyEmailAddress}
                setResultEmailAddresses={setResultCopyEmailAddresses}
            />
            {!isPartner && (
                <div>
                    <p className={css.templateTitle}>
                        {t(
                            "customerDetails.packageDelivery.emailTemplateTitle"
                        )}
                    </p>
                    <SelectPicker
                        placeholder={t(
                            "customerDetails.packageDelivery.emailTemplatePlaceholder"
                        )}
                        placement={"top"}
                        value={customerDetails.emailTemplate}
                        data={emailTemplates}
                        searchable={false}
                        className={cn(css.templateField)}
                        onChange={(value) => {
                            handleTemplateChange(value);
                        }}
                    />
                </div>
            )}
            <SettingsFooterContainer
                isFullWidth={true}
                handleFormSubmit={handleSubmit}
            />
        </>
    );
};

export default PackageDelivery;
