import {
    SetAddNewPartnerOpened,
    SetActivePartner,
    SET_ADD_NEW_PARTNER_OPENED,
    SET_ACTIVE_PARTNER,
    IActivePartner,
    IPartner,
    SetPartners,
    SET_PARTNERS,
} from "./partners-types";

export const setAddNewPartner = (
    isAddNewPartnerOpened: boolean
): SetAddNewPartnerOpened => ({
    type: SET_ADD_NEW_PARTNER_OPENED,
    isAddNewPartnerOpened,
});

export const setActivePartner = (
    activePartner: IActivePartner
): SetActivePartner => ({
    type: SET_ACTIVE_PARTNER,
    activePartner,
});

export const setPartners = (partners: IPartner[]): SetPartners => ({
    type: SET_PARTNERS,
    partners,
});
