import { connect } from "react-redux";
import { RootState } from "redux/store";
import NewGuestTenant from "./NewGuestTenant";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
    };
};

const NewGuestTenantContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
})(NewGuestTenant);

export default NewGuestTenantContainer;
