import React from "react";
import ProgressModal from "components/WorkflowLog/ProgressModal";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import StepList from "components/WorkflowLog/StepList/StepList";
import TenantDeleteProgressModalHeader from "./TenantDeleteProgressModalHeader";
import TenantDeleteProgressModalFooterContainer from "./TenantDeleteProgressModalFooter/TenantDeleteProgressModalFooterContainer";
import TenantDeleteProgressMessage from "./TenantDeleteProgressMessage";
import TenantDeleteUsernameMessage from "./TenantDeleteUsernameMessage";

interface IComponentProps {
    isModalOpened: boolean;
    workflow: ITenantDeleteWorkflow;
    onClose?: () => void;
}

const TenantDeleteProgressModal: React.FC<IComponentProps> = ({
    isModalOpened,
    workflow,
    onClose,
}: IComponentProps) => {
    const tenantDeleteProgressModalProps = {
        header: (
            <TenantDeleteProgressModalHeader tenantDeleteWorkflow={workflow} />
        ),
        userMessage: <TenantDeleteUsernameMessage workflow={workflow} />,
        completeMessage: <TenantDeleteProgressMessage workflow={workflow} />,
        footer: (
            <TenantDeleteProgressModalFooterContainer
                onClose={onClose}
                workflow={workflow}
            />
        ),
        stepList: <StepList workflowSteps={workflow?.steps} />,
    };

    return (
        <ProgressModal
            isModalOpened={isModalOpened}
            isModalLoaderVisible={false}
            onClose={onClose}
            {...tenantDeleteProgressModalProps}
        />
    );
};

export default TenantDeleteProgressModal;
