import { IEmailTemplate } from "redux/deliverables/email-templates/email-templates-types";

export const SET_CUSTOMER_DETAILS = "customerDetails/SET_CUSTOMER_DETAILS";
export const SET_CONSENT_STATUS = "customerDetails/SET_CONSENT_STATUS";
export const SET_CUSTOMER_NAME = "customerDetails/SET_CUSTOMER_NAME";
export const SET_EMAIL_SENT = "customerDetails/SET_EMAIL_SENT";

export enum ConsentStatusType {
    Pending,
    Success,
    Failed,
}

export interface ICustomerDetails {
    id: string;
    name: string;
    aadIssuerId: string | null;
    type: CustomerType;
    emailTemplate: IEmailTemplate | null;
    emailRecipients: IEmailRecipient[];
    partners: ICustomerPartner[];
    regionConsents: IRegionConsent[];
    consentWizardAppLink: string;
    adminUserName: string | null;
    adminUserEmail: string | null;
}

export enum CustomerType {
    AX2012 = "AX2012",
    D365 = "D365",
}

export enum EmailRecipientType {
    Main = 0,
    Copy = 1,
}

export enum CustomerSubpage {
    General = "general",
    PackageDelivery = "packageDelivery",
}

export interface ICustomerPartner {
    id: string;
    name: string;
}

export interface IEmailRecipient {
    address: string;
    type: EmailRecipientType;
}

export interface IConsent {
    id: string;
    name: string;
    status: ConsentStatusType;
    environmentName: string;
}

export interface IRegionConsent {
    id: string;
    region: string;
    consents: IConsent[];
}

export interface IState {
    customerDetails: ICustomerDetails | null;
    isEmailSent: boolean;
}

export interface IEmailDetails {
    userId: string;
    receivers: string[];
}

export interface ISetEmailSent {
    type: typeof SET_EMAIL_SENT;
    isEmailSent: boolean;
}

export interface ISetCustomerDetails {
    type: typeof SET_CUSTOMER_DETAILS;
    customerDetails: ICustomerDetails | null;
}

export interface ISetConsentStatus {
    type: typeof SET_CONSENT_STATUS;
    instanceId: string;
    consentId: string;
    consentStatus: ConsentStatusType;
}
export interface ISetCustomerName {
    type: typeof SET_CUSTOMER_NAME;
    customerName: string;
}

export type Actions =
    | ISetCustomerDetails
    | ISetConsentStatus
    | ISetCustomerName
    | ISetEmailSent;
