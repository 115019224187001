import { connect } from "react-redux";
import { RootState } from "redux/store";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import ManageEmailTemplate from "./ManageEmailTemplate";
import { compose } from "redux";
import { setManageTemplateOpened } from "redux/deliverables/email-templates/email-templates-actions";

const mapStateToProps = (state: RootState) => {
    return {
        isOpened: state.emailTemplatesReducer.isManageTemplateOpened,
        templateDetails: state.emailTemplatesReducer.templateDetails,
    };
};
const ManageEmailTemplateContainer = compose(
    connect(mapStateToProps, {
        setManageTemplateOpened,
        setModalLoaderVisibility,
    })
)(ManageEmailTemplate);

export default ManageEmailTemplateContainer;
