import React from "react";
import { Icon, IconButton, Table, TableCellProps } from "rsuite";

interface ComponentProps extends TableCellProps {
    expandedRowKey: string;
    rowDataKey?: string | number | symbol;
    onChange: (rowData?: any) => void;
}

const ExpandableCell: React.FC<ComponentProps> = ({
    expandedRowKey,
    rowDataKey,
    onChange,
    ...props
}: ComponentProps) => {
    const isRowExpanded = () => {
        type RowDataObjectKey = keyof typeof props.rowData;
        const rowDataIdKey = rowDataKey as RowDataObjectKey;
        if (props.rowData)
            return expandedRowKey === props.rowData[rowDataIdKey];
    };

    const iconName = isRowExpanded() ? "minus-square-o" : "plus-square-o";

    return (
        <Table.Cell {...props}>
            <IconButton
                appearance="subtle"
                circle
                size="sm"
                onClick={() => {
                    onChange(props.rowData);
                }}
                icon={<Icon icon={iconName} />}
            />
        </Table.Cell>
    );
};

export default ExpandableCell;
