export enum TenantFeatureSettingWorkFlowType {
    EnableSftp = 20,
    D365RoleDooapRequiredToAccessDooap = 10,
    KeyVaultAppRegistrationSecretRotation = 16,
    DocumentsTransfer = 5,
    CodingPrediction = 7,
    SalesTaxPrediction = 8,
    WorkflowPrediction = 9,
    VendorChangeEntityFramework = 1,
    PurchaseOrderChangeEntityFramework = 2,
    ProductReceiptChangeEntityFramework = 3,
    PaymentInformationChangeEntityFramework = 4,
    BankAccountChangeEntityFramework = 6,
    PaymentTermChangeEntityFramework = 11,
    CashDiscCodeChangeEntityFramework = 12,
    MiscChargeChangeEntityFramework = 13,
    PowerBiWorkspaceDeployment = 21,
    ProjectActivitiesChangeEntityFramework = 14,
    SalesTaxChangeEntityFramework = 15,
    MainDimensionChangeEntityFramework = 17,
    ProjectDefaultLinePropertiesChangeEntityFramework = 18,
    ProjectLinePropertiesChangeEntityFramework = 19,
    DatabaseAlertRuleCreation = 22,
    CaptureMailboxesCreation = 23,
    SearchServiceSelection = 24,
}

export enum WorkflowModalType {
    Feature = 0,
    TenantDeletion = 1,
}

export enum WorkflowStepStatus {
    Pending,
    InProcess,
    Completed,
    Failed,
    CompletedWithError,
}

export enum WorkflowStatus {
    NotStarted,
    Running,
    Failed,
    Success,
    Pending,
    Terminated,
    WaitForApprove,
}

export enum FailoverType {
    Retry = 0,
    Skip = 1,
}

export interface IWorkflowBase {
    id: string;
    tenantId: string;
    status: WorkflowStatus;
    steps: IWorkflowStepBase[];
}

export interface ITenantFeatureSettingWorkFlow extends IWorkflowBase {
    featureName: string;
    type: TenantFeatureSettingWorkFlowType;
    toggleValue: number;
    userName: string;
    data: any | null;
}

export interface ITenantDeleteWorkflow extends IWorkflowBase {
    dooapEnvironmentId: string;
    userName: string;
}

export interface IFailedStepHandle {
    failoverType: FailoverType;
}

export enum EnvironmentStatus {
    Normal = 0,
    Warning,
    Error,
}

export interface IWorkflowStepBase {
    id: string;
    actionDescription: string;
    order: number;
    status: WorkflowStepStatus;
    logs: string | null;
    errorMessage: string | null;
    processDateTime: Date | null;
}
export interface ITenantFeatureSettingStep extends IWorkflowStepBase {
    tenantFeatureSettingWorkFlowId: string;
}

export interface ITenantDeleteWorkflowStep extends IWorkflowStepBase {
    deleteWorkflowId: string;
}

export interface TenantFeatureSettingWorkFlowToggleValue {
    settingName: string;
    toggleValue: boolean;

    data: any | null;
}

export interface ChangeEntityFrameworkSettingToggleValue {
    settingName: string;
    settingToggleValue: boolean;
    axImportToggleValue: boolean;
}

export enum ToggleValueType {
    None = 0,
    Setting = 1 << 0,
    AxImport = 1 << 1,
}

export const mlTenantFeatureSettingTypes = [
    TenantFeatureSettingWorkFlowType.WorkflowPrediction,
    TenantFeatureSettingWorkFlowType.SalesTaxPrediction,
    TenantFeatureSettingWorkFlowType.CodingPrediction,
];
