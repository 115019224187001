import { RootState } from "../redux/store";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setErrorMessageInactiveEnvironment } from "../redux/common/initialization/initialization-actions";
import { useTranslation } from "react-i18next";
import { getIsActiveEnvironmentSet } from "redux/environments/environment-selectors";

const useInactiveEnvironment = (): void => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isActiveEnvironment = useSelector((state: RootState) =>
        getIsActiveEnvironmentSet(state)
    );

    useEffect(() => {
        dispatch(
            setErrorMessageInactiveEnvironment(
                !isActiveEnvironment ? t("error.noActiveEnvironment") : ""
            )
        );
    }, [isActiveEnvironment]);
};

export default useInactiveEnvironment;
