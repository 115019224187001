import { RootState } from "../../store";
import { createSelector } from "reselect";
import { orderBy } from "lodash";

export const getRegions = (state: RootState) => {
    return state.addNewCustomersReducer.regions;
};

export const getCustomerConsents = (state: RootState) => {
    return state.addNewCustomersReducer.customerConsents;
};

export const getOrderedCustomerConsents = createSelector(
    [getCustomerConsents],
    (consents) => {
        return orderBy(
            consents,
            [
                (consent) =>
                    consent.dooapEnvironmentAppRegistration
                        ?.dooapAadApplicationType.order,
                (consent) =>
                    consent.dooapEnvironmentAppRegistration?.dooapEnvironment
                        .name,
            ],
            ["asc", "asc"]
        );
    }
);
