import { CustomerType } from "redux/customers/customer-details/customer-details-types";
import { SecretExpirationStatus } from "redux/environments/tenants/tenants-types";

export enum TenantMailerService {
    NotSet = 0,
    SmtpClient = 1,
    SendGrid = 2,
    Pepipost = 3,
    Postmark = 4,
    Office365Relay = 5,
}
export enum PdfImageExtractorService {
    Aspose = 0,
    Pdfium = 1,
}
export enum TenantFeatureGroup {
    automaticVendorAssigment = 0,
    mlWorkflow = 1,
    mlCompany = 2,
    mlCoding = 3,
    autoTtp = 4,
    poSettings = 5,
    vendor = 6,
    sftp = 7,
    ocr = 8,
    search = 9,
    other = 10,
}

export interface ITenantFeatures {
    autoTTPMaxBatchSize: number;
    userSessionIdleTimeoutInMinutes: number;
    workflowPredictionImportMinScore: number;
    companyPredictionImportMinScore: number;
    codingPredictionImportMinScore: number;
    vendorPredictionVendorAddressMinScore: number;
    vendorPredictionVendorNameMinScore: number;
    pdfImageExtractor: number;
    mailerService: number;
}

export interface ITenantFeatureConfigurations extends ITenantFeatures {
    autoTTPEnabled: boolean;
    currentPostingDate: boolean;
    d365RoleDooapRequiredToAccessDooap: boolean;
    updateInvoiceWithPoData: boolean;
    codingPredictionEnabled: boolean;
    dimensionDataLiveFiltering: boolean;
    positionHierarchyEnabled: boolean;
    salesTaxPredictionEnabled: boolean;
    remitToAddressEnabled: boolean;
    searchEnabled: boolean;
    vendorSearchByAddress: boolean;
    vendorPrepaymentRegisterCheck: boolean;
    transferInvoiceDocumentsToAxDuringTransferToPayment: boolean;
    workflowPredictionEnabled: boolean;
    poPurchStatusInvoicedFilteringEnabled: boolean;
    poStatusFilteringEnabled: boolean;
    fillMissingProjectDimensionsFromVendorDefaults: boolean;
    dueDateFromPO: boolean;
    dbaDefaultApprovers: boolean;
    poLinkEnabled: boolean;
    waitForPrEnabled: boolean;
    bankAccountComparisonLogicEnabled: boolean;
    companyLogoUrl: string;
    useImportedSigningLimits: boolean;
    codingDeliveryAddressEnabled: boolean;
    ssrEnabled: boolean;
    sftpEnabled: boolean;
    recurringInvoicesEnabled: boolean;
    axTurboSwitch: boolean;
    companyPredictionEnabled: boolean;
    poChargesGroupEnabled: boolean;
    vendorPredictionEnabled: boolean;
    searchVendorBySearchName: boolean;
    procurementLineEnabled: boolean;
    ocrErrorReportingEnabled: boolean;
    contractsEnabled: boolean;
}

export interface ITenantFeatureAppConfigurations {
    improvedCapture: boolean;
}

export interface IErpInfo {
    appRegSecretExpiration: ISecretExpiration;
    axSecretExpiration: ISecretExpiration;
}

export interface ISecretExpiration {
    secretExpirationTimeInDays: number | null;
    secretExpirationStatus: SecretExpirationStatus | null;
    secretRotationDate: string | null;
}
export interface ITenantGeneralConfigurations {
    id: string;
    moniker: string;
    displayName: string;
    customerName: string | null;
    customerType: CustomerType;
    partners: string[];
    dooapMainUrl: string | null;
    additionalInformation: string | null;
    azureFunctionsBaseUrl: string | null;
    azureSearchApiBaseUrl: string | null;
}
export enum AuthenticationType {
    UsernamePassword = 0,
    ClientSecret,
}
export interface AuthenticationMethod {
    authenticationType: AuthenticationType;
    authenticationDescription: string;
}
export interface IErpConnectionInfo {
    url: string;
    username: string;
    password: string;
    clientId: string;
    clientSecret: string;
    secretExpirationDate: Date | null;
}
export enum ThrottlingPriority {
    Low = 0,
    Medium = 1,
    High = 2,
}
export interface IErpThrottlingPriority {
    priority: ThrottlingPriority;
    priorityName: string;
}
export interface ICredentialsValidation {
    message: string;
    isValid: boolean;
}

export interface ITenantDefaultKeyVaultCompany {
    companyId: string;
}

export interface ITenantMaintenanceMode {
    maintenanceModeEnabled: boolean;
    maintenanceModeDooapDictionaryKey: string | null;
    maintenanceModeSystemDictionaryKey: string | null;
}

export interface IAllowedIpAddress {
    id: number | null;
    label: string;
    cidr: string;
    editing: boolean | null;
}

export interface ISftpUserWithIpAddresses {
    id: number;
    allowedIpAddresses: IAllowedIpAddress[];
}

export interface ISftpAccount {
    host: string;
    port: string;
    username: string;
    password: string;
}

export interface IIndexCounter {
    usage: number;
    quota: number;
}

export interface ISearchService {
    name: string;
    indexCounter: IIndexCounter | null;
}
