import {
    State,
    Actions,
    ICustomer,
    SET_CUSTOMERS,
    SET_ACTIVE_CUSTOMERS_BY_ID,
    SORT_CUSTOMERS_BY_NAME,
    SET_ACTIVE_CUSTOMER_NAME,
} from "./customers-types";

const compareCustomers = (a: ICustomer, b: ICustomer): number => {
    const aDisplayName = a.name.toLocaleLowerCase() || "";
    const bDisplayName = b.name.toLocaleLowerCase() || "";

    if (aDisplayName < bDisplayName) {
        return -1;
    }
    if (aDisplayName > bDisplayName) {
        return 1;
    }

    return 0;
};
const initialState = {
    customers: [],
    activeCustomer: {
        id: "",
        aadIssuerId: "",
        adminUserEmail: "",
        name: "",
        customerId: "",
        totalStepsNumber: 0,
        completedSteps: 0,
        tenantsAmount: 0,
        partners: [],
        guestTenantsAmount: 0,
        consentWizardAppLink: "",
        emailTemplate: null,
        dooapEnvironments: [],
    },
};
const customersReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_CUSTOMERS:
            return {
                ...state,
                customers: [...action.customers],
            };

        case SET_ACTIVE_CUSTOMERS_BY_ID:
            const activeCustomer = state.customers.find(
                (customer) => customer.id === action.customerId
            );
            if (!activeCustomer) {
                return {
                    ...state,
                };
            }

            return {
                ...state,
                activeCustomer: { ...activeCustomer },
            };

        case SORT_CUSTOMERS_BY_NAME:
            if (!state.customers.length) {
                return { ...state };
            }

            return {
                ...state,
                customers: [...state.customers.sort(compareCustomers)],
            };

        case SET_ACTIVE_CUSTOMER_NAME:
            return {
                ...state,
                activeCustomer: {
                    ...state.activeCustomer,
                    name: action.name,
                },
            };

        default:
            return state;
    }
};

export default customersReducer;
