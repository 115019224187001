import { Actions, SET_USER_NAME, State } from "./authentication-types";

const initialState: State = {
    userName: "",
};
const authenticationReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_USER_NAME:
            if (!action.userName) {
                return state;
            }
            return {
                ...state,
                userName: action.userName,
            };
        default:
            return state;
    }
};

export default authenticationReducer;
