import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonToolbar } from "rsuite";
import { useTranslation } from "react-i18next";
import { IEmailDetails } from "redux/customers/customer-details/customer-details-types";
import ModalContainer from "components/Common/Modal/ModalContainer";
import EmailAddressesBox from "components/Common/EmailAddressesBox/EmailAddressesBox";
import constants from "utils/constants";
import css from "./SendEmail.module.css";

interface ComponentProps {
    envMoniker: string;
    isOpened: boolean;
    userId: string;
    isEmailSent: boolean;
    setIsOpened: (isOpened: boolean) => void;
    sendEmailWithLink: (
        envMoniker: string,
        emailDetails: IEmailDetails
    ) => void;
    setEmailSent: (isEmailSent: boolean) => void;
}
const SendEmail: React.FC<ComponentProps> = ({
    envMoniker,
    isOpened,
    userId,
    isEmailSent,
    setIsOpened,
    sendEmailWithLink,
    setEmailSent,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>();
    const [emailAddress, setEmailAddress] = useState("");
    const [resultEmailAdresses, setResultEmailAdresses] = useState<string[]>(
        []
    );
    const isResultArray = resultEmailAdresses.length > 0;

    useEffect(() => {
        if (isEmailSent) {
            Alert.success(
                t("sendEmail.successMessage"),
                constants.alertDurationMsec
            );
            setEmailSent(false);
        }
    });

    const onModalCloseHandler = () => {
        setIsOpened(false);
        setEmailAddress("");
        setResultEmailAdresses([]);
    };

    const onSubmitHandler = () => {
        if (!formRef.current?.check()) {
            return;
        }
        if (isResultArray || emailAddress) {
            if (emailAddress) {
                resultEmailAdresses.push(emailAddress);
            }
            const sendObject = {
                userId: userId,
                receivers: resultEmailAdresses,
            };
            sendEmailWithLink(envMoniker, sendObject);
            onModalCloseHandler();
        }
    };

    const header = <h1>{t("sendEmail.title")}</h1>;
    const footer = (
        <ButtonToolbar>
            <Button
                type="submit"
                appearance="primary"
                disabled={!isResultArray && !emailAddress}
                className={css.saveButton}
                onClick={onSubmitHandler}
            >
                {t("sendEmail.sendButton")}
            </Button>
        </ButtonToolbar>
    );

    return (
        <ModalContainer
            show={isOpened}
            onHide={onModalCloseHandler}
            header={header}
            footer={footer}
        >
            <p>{t("sendEmail.info")}</p>
            <EmailAddressesBox
                formRef={formRef}
                emailAddress={emailAddress}
                resultEmailAddresses={resultEmailAdresses}
                isResultArray={isResultArray}
                boxLabel={t("sendEmail.emailAddressLabel")}
                tooltipText={null}
                setEmailAddress={setEmailAddress}
                setResultEmailAddresses={setResultEmailAdresses}
            />
        </ModalContainer>
    );
};

export default SendEmail;
