import React, { useState } from "react";
import { IActivityLog } from "redux/environments/tenants/tenant-activity-log/tenant-activity-log-types";
import { Button, Col, Drawer, Grid, Icon, Row } from "rsuite";
import { useTranslation } from "react-i18next";
import JSONViewer from "./JSONViewer/JSONViewer";
import { formatDateWithNameOfMonth } from "utils/date-helper";
import "./ActivityLogDetails.less";

interface ComponentProps {
    activityLog: IActivityLog | null;
    isActivityLogDetailsOpen: boolean;
    setActivityLogDetailsOpen: (isOpened: boolean) => void;
}

const ActivityLogDetails: React.FC<ComponentProps> = ({
    activityLog,
    isActivityLogDetailsOpen,
    setActivityLogDetailsOpen,
}: ComponentProps) => {
    const { t } = useTranslation();

    const [showFullText, setShowFullText] = useState<boolean>(false);

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    return (
        <>
            {activityLog && (
                <Drawer
                    show={isActivityLogDetailsOpen}
                    onExit={() => setActivityLogDetailsOpen(false)}
                    onHide={() => setActivityLogDetailsOpen(false)}
                    size="md"
                    className="activity-log-details__drawer"
                >
                    <Drawer.Header>
                        <Drawer.Title>
                            {t(
                                "tenantConfig.activityLog.activityDetails.header"
                            )}
                        </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body className="activity-log-details__drawer-body">
                        <Grid fluid>
                            <Row className="activity-log-details__drawer-row">
                                <Col
                                    xs={10}
                                    className="activity-log-details__drawer-col"
                                >
                                    <span>
                                        {t(
                                            "tenantConfig.activityLog.activityDetails.actionTypeName"
                                        )}
                                    </span>
                                    {activityLog.actionTypeName ??
                                        t("tenantConfig.activityLog.unknown")}
                                </Col>
                                <Col
                                    xs={14}
                                    className="activity-log-details__drawer-col"
                                >
                                    <span>
                                        {t(
                                            "tenantConfig.activityLog.activityDetails.userName"
                                        )}
                                    </span>
                                    {activityLog.userName}
                                </Col>
                            </Row>
                            <Row className="activity-log-details__drawer-row">
                                <Col
                                    xs={10}
                                    className="activity-log-details__drawer-col"
                                >
                                    <span>
                                        {t(
                                            "tenantConfig.activityLog.activityDetails.status"
                                        )}
                                    </span>
                                    <div
                                        className={
                                            activityLog.failureReason
                                                ? "activity-log__status-block--error"
                                                : "activity-log__status-block--success"
                                        }
                                    >
                                        {activityLog.failureReason
                                            ? t(
                                                  "tenantConfig.activityLog.table.statusFailed"
                                              )
                                            : t(
                                                  "tenantConfig.activityLog.table.statusSuccess"
                                              )}
                                    </div>
                                </Col>
                                <Col
                                    xs={14}
                                    className="activity-log-details__drawer-col"
                                >
                                    <span>
                                        {t(
                                            "tenantConfig.activityLog.activityDetails.timestamp"
                                        )}
                                    </span>
                                    {activityLog.timeStamp &&
                                        formatDateWithNameOfMonth(
                                            new Date(activityLog.timeStamp)
                                        )}
                                </Col>
                            </Row>
                            <Row className="activity-log-details__drawer-row">
                                <Col
                                    xs={24}
                                    className="activity-log-details__drawer-col"
                                >
                                    <span>
                                        {t(
                                            "tenantConfig.activityLog.activityDetails.failureReason"
                                        )}
                                    </span>
                                    <div
                                        className={`activity-log-details__failure-reason ${
                                            showFullText ? "expanded" : ""
                                        }`}
                                    >
                                        {activityLog.failureReason || "-"}
                                    </div>
                                    {activityLog.failureReason && (
                                        <Button
                                            className="activity-log-details__see-more-btn"
                                            appearance="ghost"
                                            onClick={toggleShowFullText}
                                        >
                                            {showFullText
                                                ? t(
                                                      "tenantConfig.activityLog.activityDetails.seeLess"
                                                  )
                                                : t(
                                                      "tenantConfig.activityLog.activityDetails.seeMore"
                                                  )}
                                            <Icon
                                                icon={
                                                    showFullText ? "up" : "down"
                                                }
                                            />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                        <div className="activity-log-details__entry-data-section">
                            <span className="activity-log-details__drawer-label">
                                {t(
                                    "tenantConfig.activityLog.activityDetails.entryData"
                                )}
                            </span>
                            {activityLog.entryData && (
                                <JSONViewer
                                    json={JSON.parse(activityLog.entryData)}
                                />
                            )}
                        </div>
                    </Drawer.Body>
                    <Drawer.Footer className="activity-log-details__drawer-footer">
                        <Button
                            onClick={() => setActivityLogDetailsOpen(false)}
                            appearance="primary"
                            className="sidebar-button"
                        >
                            {t(
                                "tenantConfig.activityLog.activityDetails.closeButton"
                            )}
                        </Button>
                    </Drawer.Footer>
                </Drawer>
            )}
        </>
    );
};

export default ActivityLogDetails;
