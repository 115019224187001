import React from "react";
import {
    ITenant,
    ITenantPingInfo,
    TenantStatus,
} from "redux/environments/tenants/tenants-types";
import TenantCard from "../../TenantCard/TenantCard";
import "./CardView.less";
import { CancelTokenSource } from "axios";

interface ComponentProps {
    modifiedTenants: ITenant[];
    activeEnvMoniker: string;
    activeEnvironmentName: string;
    tenantsPingInfo: ITenantPingInfo[];
    isD365ParametersRefreshAllowed: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
    seqLogsLink: string;
    isUserPartner: boolean;
    isDeliverablesEnabled: boolean;
    routeToTenant: (tenant: ITenant) => void;
    getErpVersion: (tenant: ITenant, value: string | null) => JSX.Element;
    getFetchStatusText: (fetchStatus: TenantStatus) => string;
    getPingStatusText: (pingInfo: ITenantPingInfo | undefined) => string;
    getRefreshParametersText: (
        status: number,
        message: string | null
    ) => string;
    pingTenantInD365: (
        envMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
}
const CardView: React.FC<ComponentProps> = ({
    modifiedTenants,
    activeEnvMoniker,
    activeEnvironmentName,
    tenantsPingInfo,
    isD365ParametersRefreshAllowed,
    containerRef,
    seqLogsLink,
    isUserPartner,
    isDeliverablesEnabled,
    routeToTenant,
    getErpVersion,
    getFetchStatusText,
    getPingStatusText,
    getRefreshParametersText,
    pingTenantInD365,
}: ComponentProps) => {
    const tenantsCards = modifiedTenants.map((tenant: ITenant) => {
        return (
            <TenantCard
                envName={activeEnvironmentName}
                onRoteTo={(event) => {
                    event.preventDefault();
                    routeToTenant(tenant);
                }}
                key={String(tenant.versionInfo.id)}
                pingInfo={tenantsPingInfo.find(
                    (item) => item.tenantId === tenant.versionInfo.id
                )}
                tenantInfo={tenant}
                envMoniker={activeEnvMoniker}
                isD365ParametersRefreshAllowed={isD365ParametersRefreshAllowed}
                seqLogsLink={seqLogsLink}
                isUserPartner={isUserPartner}
                isDeliverablesEnabled={isDeliverablesEnabled}
                getErpVersion={getErpVersion}
                getFetchStatusText={getFetchStatusText}
                getPingStatusText={getPingStatusText}
                getRefreshParametersText={getRefreshParametersText}
                pingTenantInD365={pingTenantInD365}
            />
        );
    });

    return (
        <div ref={containerRef} className={"cards-layout"}>
            {tenantsCards}
        </div>
    );
};

export default CardView;
