import { connect } from "react-redux";
import { RootState } from "redux/store";
import MailboxesCreation from "./MailboxesCreation";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import { setCreateMailboxesOpened } from "redux/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
        isOpened: state.captureMailboxesReducer.isCreateMailboxesOpened,
    };
};

const MailboxesCreationContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setIsOpened: setCreateMailboxesOpened,
})(MailboxesCreation);

export default MailboxesCreationContainer;
