import { AppThunk } from "../../store";
import { setLoaderVisibility } from "../../common/initialization/initialization-actions";
import { setCustomerDetails, setEmailSent } from "./customer-details-actions";
import { setErrorResponseMessage } from "../../common/initialization/initialization-actions";
import { IEmailDetails } from "./customer-details-types";
import {
    getCustomerDetails as getCustomerDetailsById,
    sendEmail,
} from "../../../api/customers/customer-details-api";
import constants from "../../../utils/constants";
import { getErrorMessage } from "../../../api/defaults";

export const getCustomerDetails =
    (customerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        const [payload, statusCode] = await getCustomerDetailsById(customerId);
        if (payload && statusCode === constants.statusCode.OK) {
            dispatch(setCustomerDetails(payload));
        } else if (statusCode === constants.statusCode.NoContent) {
            dispatch(setCustomerDetails(null));
        } else if (statusCode === constants.statusCode.UnauthenticatedError) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        }
        dispatch(setLoaderVisibility(false));
    };

export const getCustomerDetailsWithoutLoader =
    (customerId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        const [payload, statusCode] = await getCustomerDetailsById(customerId);
        if (payload && statusCode === constants.statusCode.OK) {
            dispatch(setCustomerDetails(payload));
        } else if (statusCode === constants.statusCode.NoContent) {
            dispatch(setCustomerDetails(null));
        } else if (statusCode === constants.statusCode.UnauthenticatedError) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        }
    };

export const sendEmailWithLink =
    (customerId: string, emailDetails: IEmailDetails): AppThunk =>
    async (dispatch) => {
        dispatch(setLoaderVisibility(true));
        const [payload, statusCode] = await sendEmail(customerId, emailDetails);
        if (
            statusCode !== constants.statusCode.OK &&
            statusCode !== constants.statusCode.NoContent
        ) {
            dispatch(
                setErrorResponseMessage(
                    getErrorMessage(String(payload || null), statusCode)
                )
            );
        } else {
            dispatch(setEmailSent(true));
        }
        dispatch(setLoaderVisibility(false));
    };
