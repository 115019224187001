import { RootState } from "redux/store";
import { connect } from "react-redux";
import {
    getActiveStep,
    getEnrollId,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import GuestTenantsStep from "./GuestTenantsStep";
import withLoader from "hocs/withLoader/withLoader";
import { fetchEnrollCache } from "redux/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import { compose } from "redux";
import { setActiveStepData } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";

const mapStateToProps = (state: RootState) => ({
    activeStep: getActiveStep(state),
    orchestratorInstanceId: getEnrollId(state),
    activeEnvMoniker: getActiveEnvironmentMoniker(state),
});

const GuestTenantsStepContainer = compose(
    connect(mapStateToProps, { fetchEnrollCache, setActiveStepData }),
    withLoader
)(GuestTenantsStep);

export default GuestTenantsStepContainer;
