import constants from "../../utils/constants";

//  Seems like we need this Map, because new pages will appear later and
//  BreadCrumb logic may become more complicated each time
export default [
    {
        path: constants.routePath.customers,
        breadcrumb: [],
    },
    {
        path: constants.routePath.environments,
        breadcrumb: [],
    },
    {
        path: constants.routePath.tenants,
        breadcrumb: [constants.routePath.tenants],
    },
    {
        path: constants.routePath.generalConfig,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.generalConfig,
        ],
    },
    {
        path: constants.routePath.featureConfig,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.featureConfig,
        ],
    },
    {
        path: constants.routePath.erpConfig,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.erpConfig,
        ],
    },
    {
        path: constants.routePath.customFields,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.customFields,
        ],
    },
    {
        path: constants.routePath.dataImport,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.dataImport,
        ],
    },
    {
        path: constants.routePath.scheduler,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.scheduler,
        ],
    },
    {
        path: constants.routePath.cultureSettings,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.cultureSettings,
        ],
    },
    {
        path: constants.routePath.guestTenants,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.guestTenants,
        ],
    },
    {
        path: constants.routePath.dopeSubscriptions,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.dopeSubscriptions,
        ],
    },
    {
        path: constants.routePath.versionHistory,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.versionHistory,
        ],
    },
    {
        path: constants.routePath.dataRefreshHistory,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.dataRefreshHistory,
        ],
    },
    {
        path: constants.routePath.maintenanceMode,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.maintenanceMode,
        ],
    },
    {
        path: constants.routePath.captureMailboxes,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.captureMailboxes,
        ],
    },
    {
        path: constants.routePath.activityLog,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.activityLog,
        ],
    },
    {
        path: constants.routePath.customerDetails,
        breadcrumb: [constants.routePath.customerDetails],
    },
    {
        path: constants.routePath.enrollTenant,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.enrollTenant,
        ],
    },
    {
        path: constants.routePath.enrollNewTenant,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.enrollNewTenant,
        ],
    },
    {
        path: constants.routePath.guestTenantsEnroll,
        breadcrumb: [
            constants.routePath.tenants,
            constants.routePath.enrollTenant,
            constants.routePath.guestTenantsEnroll,
        ],
    },
    {
        path: constants.routePath.deletedTenant,
        breadcrumb: [constants.routePath.tenants],
    },
    {
        path: constants.routePath.partnerDetails,
        breadcrumb: [constants.routePath.partnerDetails],
    },
];
