import * as React from "react";
import { useTranslation } from "react-i18next";
import "./PartnerCard.less";
import { IPartner } from "../../../redux/partners/partners-types";
import { Panel, Popover, Whisper } from "rsuite";
import constants from "utils/constants";

interface ComponentProps {
    partner: IPartner;
    onRoteTo: (event: any) => void;
}

const PartnerCard: React.FC<ComponentProps> = ({
    partner,
    onRoteTo,
}: ComponentProps) => {
    const { t } = useTranslation();

    const isPartnerNameTooltipVisible = partner
        ? partner.name.length > constants.partnerCardHeaderLimit
        : false;

    const header = (
        <div className="PartnerCard__header">
            <h2 className="PartnerCard__header-title">{partner.name}</h2>
        </div>
    );

    return (
        <>
            <Panel
                className={"card PartnerCard__container"}
                onClick={onRoteTo}
                bordered
                shaded
                bodyFill
            >
                <div className="card_body">
                    <div className="PartnerCard__layout">
                        {isPartnerNameTooltipVisible ? (
                            <Whisper
                                trigger="hover"
                                placement={"bottomStart"}
                                speaker={
                                    <Popover
                                        className="PartnerCard__popover"
                                        visible={true}
                                    >
                                        {partner.name}
                                    </Popover>
                                }
                            >
                                {header}
                            </Whisper>
                        ) : (
                            <>{header}</>
                        )}
                        <span className="PartnerCard__tenants-title">
                            {t("partners.card.tenants") + ":"}
                        </span>
                        <span className="PartnerCard__tenants-value">{`${partner.tenantsAmount}`}</span>
                        <span className="PartnerCard__users-title">
                            {t("partners.card.users") + ":"}
                        </span>
                        <span className="PartnerCard__users-value">
                            {partner.usersAmount}
                        </span>
                    </div>
                </div>
            </Panel>
        </>
    );
};

export default PartnerCard;
