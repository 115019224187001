export const SET_REFRESH_ACTIVATED = "pageRefresh/SET_REFRESH_ACTIVATED";

export interface State {
    isRefreshActivated: boolean;
}

export interface ISetRefreshActivated {
    type: typeof SET_REFRESH_ACTIVATED;
    isRefreshActivated: boolean;
}

export type Actions = ISetRefreshActivated;
