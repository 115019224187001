import {
    Actions,
    State,
    SET_IS_UNSAVED_CHANGE_AVAILABLE,
    SET_MODAL_CONFIRM_CALL_BACK,
    REMOVE_MODAL_CONFIRM_CALL_BACK,
} from "./unsaved-data-types";

const initialState: State = {
    isUnsavedChangeAvailable: false,
    modalConfirmCallbacks: [],
};
const unsavedDataReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_IS_UNSAVED_CHANGE_AVAILABLE:
            return {
                ...state,
                isUnsavedChangeAvailable: action.isUnsavedChangeAvailable,
            };

        case SET_MODAL_CONFIRM_CALL_BACK:
            return {
                ...state,
                modalConfirmCallbacks: [
                    ...state.modalConfirmCallbacks,
                    action.modalConfirmCallback,
                ],
            };

        case REMOVE_MODAL_CONFIRM_CALL_BACK:
            return {
                ...state,
                modalConfirmCallbacks: state.modalConfirmCallbacks.filter(
                    (callback) => !action.predicate(callback)
                ),
            };

        default:
            return state;
    }
};

export default unsavedDataReducer;
