import { RootState } from "redux/store";
import { connect } from "react-redux";
import { compose } from "redux";
import CultureSettings from "./CultureSettings";
import {
    setCulture,
    setNumberAndDateCulture,
    setPreciseScale,
    setDefaultCurrency,
    setCultureSettingsSaved,
} from "redux/environments/tenants/tenant-culture-settings/tenant-culture-settings-actions";
import {
    getCultureSettings,
    updateCultureSettings,
    getCurrencies,
    getSupportedCultures,
    getCultures,
} from "redux/environments/tenants/tenant-culture-settings/tenant-culture-settings-thunk";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import withLoader from "hocs/withLoader/withLoader";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";

const mapStateToProps = (state: RootState) => {
    return {
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment ||
            state.initializationReducer.errorMessageInactiveTenant,
        culture: state.tenantCultureSettingsReducer.culture,
        numberAndDateCulture:
            state.tenantCultureSettingsReducer.numberAndDateCulture,
        preciseScale: state.tenantCultureSettingsReducer.preciseScale,
        defaultCurrency: state.tenantCultureSettingsReducer.defaultCurrency,
        currencies: state.tenantCultureSettingsReducer.currencies,
        supportedCultures: state.tenantCultureSettingsReducer.supportedCultures,
        cultures: state.tenantCultureSettingsReducer.cultures,
        isCultureSettingsSaved:
            state.tenantCultureSettingsReducer.isCultureSettingsSaved,
        environmentMoniker: getActiveEnvironmentMoniker(state) || null,
        tenantMoniker: state.tenantsReducer.activeTenant.moniker || null,
    };
};
const CultureSettingsContainer = compose(
    connect(mapStateToProps, {
        setCulture,
        setNumberAndDateCulture,
        setPreciseScale,
        setDefaultCurrency,
        getCultureSettings,
        updateCultureSettings,
        getCurrencies,
        getSupportedCultures,
        getCultures,
        setIsUnsavedChangeAvailable,
        setCultureSettingsSaved,
        setErrorResponseMessage,
    }),
    withLoader
)(CultureSettings);

export default CultureSettingsContainer;
