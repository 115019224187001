import {
    SET_IS_UNSAVED_CHANGE_AVAILABLE,
    SET_MODAL_CONFIRM_CALL_BACK,
    REMOVE_MODAL_CONFIRM_CALL_BACK,
    ISetIsUnsavedChangeAvailable,
    ISetModalConfirmCallback,
    IRemoveModalConfirmCallback,
    IModalCallback,
} from "./unsaved-data-types";

export const setIsUnsavedChangeAvailable = (
    isUnsavedChangeAvailable: boolean
): ISetIsUnsavedChangeAvailable => ({
    type: SET_IS_UNSAVED_CHANGE_AVAILABLE,
    isUnsavedChangeAvailable,
});

export const setModalConfirmCallback = (
    modalConfirmCallback: IModalCallback
): ISetModalConfirmCallback => ({
    type: SET_MODAL_CONFIRM_CALL_BACK,
    modalConfirmCallback,
});

export const removeModalConfirmCallback = (
    predicate: (modalCallback: IModalCallback) => boolean
): IRemoveModalConfirmCallback => ({
    type: REMOVE_MODAL_CONFIRM_CALL_BACK,
    predicate,
});
