import {
    PublicClientApplication,
    Configuration,
    BrowserCacheLocation,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";

// Msal Configurations
const config: Configuration = {
    auth: {
        authority: process.env.REACT_APP_AUTHORITY as string,
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
    },
};

// authentication Parameters
export const authenticationParameters = {
    scopes: [process.env.REACT_APP_API_SCOPE_TENANTS_ACCESS as string],
};

export const authProvider = new PublicClientApplication(config);

export const acquireToken = async (): Promise<string | undefined> => {
    const accounts = authProvider.getAllAccounts();
    const account = accounts[0] || null;
    try {
        const { accessToken } = await authProvider.acquireTokenSilent({
            account: account,
            scopes: authenticationParameters.scopes,
        });
        return accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await authProvider.acquireTokenRedirect({
                scopes: authenticationParameters.scopes,
            });
        }
    }
};
