import {
    Actions,
    SET_ADD_NEW_PARTNER_OPENED,
    SET_ACTIVE_PARTNER,
    State,
    SET_PARTNERS,
} from "./partners-types";

const initialState = {
    partners: [],
    activePartner: {
        name: "",
        id: "",
    },
    isAddNewPartnerOpened: false,
};

const partnersReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_PARTNERS:
            return {
                ...state,
                partners: action.partners,
            };
        case SET_ADD_NEW_PARTNER_OPENED:
            return {
                ...state,
                isAddNewPartnerOpened: action.isAddNewPartnerOpened,
            };
        case SET_ACTIVE_PARTNER:
            return {
                ...state,
                activePartner: { ...action.activePartner },
            };
        default:
            return state;
    }
};

export default partnersReducer;
