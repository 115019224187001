import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./components/App/AppContainer";
import "./utils/i18n";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setModalConfirmCallback } from "./redux/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "./redux/common/page-refresh/page-refresh-actions";
import { MsalProvider } from "@azure/msal-react";
import { authProvider } from "./utils/auth-provider";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import _ from "lodash";
import { v4 as uuid } from "uuid";

interface IndexProps {
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}

document.title = `${process.env.REACT_APP_ENV_NAME} Control App`;

const Index: React.FC<IndexProps> = ({
    setModalConfirmCallback,
    setRefreshActivated,
}: IndexProps) => {
    return (
        <BrowserRouter
            getUserConfirmation={(_message, _callback) => {
                setModalConfirmCallback({
                    id: uuid(),
                    isAutoDeleteResistant: false,
                    isPageRedirect:
                        decodeURIComponent(window?.location?.pathname) !==
                        decodeURIComponent(_message),
                    callback: () => {
                        _callback(true);
                    },
                });
                setRefreshActivated(true);
            }}
        >
            <AppContainer />
        </BrowserRouter>
    );
};

const IndexContainer = connect(null, {
    setModalConfirmCallback,
    setRefreshActivated,
})(Index);

ReactDOM.render(
    <Provider store={store}>
        <MsalProvider instance={authProvider}>
            <IndexContainer />
        </MsalProvider>
    </Provider>,
    document.getElementById("root")
);
