import {
    SET_CUSTOMER_DETAILS,
    SET_CONSENT_STATUS,
    SET_CUSTOMER_NAME,
    ICustomerDetails,
    ISetCustomerDetails,
    ISetConsentStatus,
    ISetCustomerName,
    ConsentStatusType,
    ISetEmailSent,
    SET_EMAIL_SENT,
} from "./customer-details-types";

export const setCustomerDetails = (
    customerDetails: ICustomerDetails | null
): ISetCustomerDetails => ({
    type: SET_CUSTOMER_DETAILS,
    customerDetails,
});

export const setConsentStatus = (
    instanceId: string,
    consentId: string,
    consentStatus: ConsentStatusType
): ISetConsentStatus => ({
    type: SET_CONSENT_STATUS,
    instanceId,
    consentId,
    consentStatus,
});

export const setCustomerName = (customerName: string): ISetCustomerName => ({
    type: SET_CUSTOMER_NAME,
    customerName,
});

export const setEmailSent = (isEmailSent: boolean): ISetEmailSent => ({
    type: SET_EMAIL_SENT,
    isEmailSent,
});
