import {
    IWorkflowBase,
    WorkflowStatus,
    WorkflowStepStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import React from "react";
import { TypeAttributes } from "rsuite/lib/@types/common";
import { useTranslation } from "react-i18next";
import {
    isEveryWorkflowStepCompleted,
    isWorkflowTerminated,
} from "utils/tenant-workflows/tenant-workflows-helper";
import { Message } from "rsuite";

interface IComponentProps {
    workflow: IWorkflowBase;
}

const TenantFeatureProgressMessage: React.FC<IComponentProps> = ({
    workflow,
}: IComponentProps) => {
    const { t } = useTranslation();

    const getCompletionMessageProps = (
        workflow: IWorkflowBase
    ): {
        type: TypeAttributes.Status;
        description: string;
    } => {
        if (
            workflow.steps.some(
                (step) => step.status === WorkflowStepStatus.CompletedWithError
            ) &&
            workflow.status === WorkflowStatus.Success
        ) {
            return {
                type: "warning",
                description: t(
                    "tenantFeatureSetting.progressModal.warningMessage"
                ),
            };
        } else if (workflow.status === WorkflowStatus.Terminated) {
            return {
                type: "warning",
                description: t(
                    "tenantFeatureSetting.progressModal.terminationMessage"
                ),
            };
        } else {
            switch (workflow.status) {
                case WorkflowStatus.Failed:
                    return {
                        type: "error",
                        description: t(
                            "tenantFeatureSetting.progressModal.errorMessage"
                        ),
                    };
                default:
                    return {
                        type: "info",
                        description: t(
                            "tenantFeatureSetting.progressModal.successMessage"
                        ),
                    };
            }
        }
    };

    const completionMessageProps = getCompletionMessageProps(workflow);
    return (
        <>
            {(isEveryWorkflowStepCompleted(workflow.steps) ||
                isWorkflowTerminated(workflow)) && (
                <Message showIcon {...completionMessageProps} />
            )}
        </>
    );
};

export default TenantFeatureProgressMessage;
