import {
    SET_CONFLUENCE_DOCUMENT_LINKS,
    IConfluenceDocumentLinks,
} from "./information-links-types";

export const setConfluenceDocumentLinks = (
    confluenceDocumentLinks: IConfluenceDocumentLinks
) => ({
    type: SET_CONFLUENCE_DOCUMENT_LINKS,
    confluenceDocumentLinks: confluenceDocumentLinks,
});
