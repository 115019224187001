import { TenantFeatureSettingWorkFlowType } from "./tenant-workflows-types";

const cefTenantFeatureSettingTypes = [
    TenantFeatureSettingWorkFlowType.BankAccountChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.PaymentInformationChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.ProductReceiptChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.PurchaseOrderChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.VendorChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.PaymentTermChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.CashDiscCodeChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.MiscChargeChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.ProjectActivitiesChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.SalesTaxChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.MainDimensionChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.ProjectDefaultLinePropertiesChangeEntityFramework,
    TenantFeatureSettingWorkFlowType.ProjectLinePropertiesChangeEntityFramework,
];

export default cefTenantFeatureSettingTypes;
