import React, { useEffect, useState } from "react";
import "./GuestTenantsEnroll.less";

import { useParams } from "react-router";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import { IEnrollCache } from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import GuestTenantsContainer from "components/Environments/Tenants/LeftMenu/GuestTenants/GuestTenantsContainer";
import Preloader from "components/Common/Preloader/Preloader";
import css from "hocs/withLoader/withLoader.module.css";
import constants from "utils/constants";
import DeletingTenantPage from "components/Environments/Tenants/DeleteTenant/DeleteTenantPage/DeletingTenantPage";
import { DeletionType } from "redux/environments/tenants/tenants-types";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    errorMessage: string;
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    fetchEnvironments: (envName?: string) => void;
    fetchTenants: (envMoniker: string, activeTenantId?: string) => void;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
    fetchEnrollWorkflow: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => void;
}

const GuestTenantsEnroll: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    activeTenantDeleteWorkflow,
    fetchTenants,
    fetchEnvironments,
    fetchEnrollCache,
    fetchEnrollWorkflow,
}: ComponentProps) => {
    const { environmentName } = useParams<{ environmentName: string }>();
    const { instanceId } = useParams<{ instanceId: string }>();
    const [isLoading, setIsLoading] = useState(false);
    const [enrollCache, setEnrollCache] = useState<IEnrollCache | null>(null);

    useEffect(() => {
        if (!envMoniker) {
            fetchEnvironments(environmentName);
        }
    }, [envMoniker, environmentName, fetchEnvironments]);

    useEffect(() => {
        if (envMoniker && instanceId && !enrollCache) {
            setIsLoading(true);
            fetchEnrollCache(envMoniker, instanceId).then(
                ([payload, statusCode]) => {
                    if (payload && statusCode === constants.statusCode.OK) {
                        setEnrollCache(payload);
                    }
                    setIsLoading(false);
                }
            );
        }
    }, [instanceId, enrollCache, envMoniker, fetchEnrollCache]);

    useEffect(() => {
        if (envMoniker && enrollCache && !tenantMoniker) {
            fetchTenants(envMoniker, enrollCache.tenantId);
        }
    }, [envMoniker, tenantMoniker, enrollCache]);

    useEffect(() => {
        if (envMoniker && instanceId) {
            fetchEnrollWorkflow(envMoniker, instanceId);
        }
    }, [envMoniker, instanceId]);

    useInactiveEnvironment();

    return (
        <div className="guest-tenant-enroll__container">
            {isLoading && (
                <Preloader isLoaderVisible={isLoading} className={css.loader} />
            )}
            {activeTenantDeleteWorkflow && (
                <DeletingTenantPage
                    deletionType={DeletionType.TenantDeletion}
                />
            )}
            {!activeTenantDeleteWorkflow &&
                enrollCache &&
                enrollCache.tenantMoniker &&
                instanceId && (
                    <GuestTenantsContainer
                        hideActivateColumn={true}
                        tenantMoniker={enrollCache.tenantMoniker}
                    ></GuestTenantsContainer>
                )}
        </div>
    );
};

export default GuestTenantsEnroll;
