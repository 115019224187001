import {
    State,
    Action,
    SET_ENVIRONMENT_FILTER_SEARCH,
    SET_ENVIRONMENT_FILTER_STATUS,
    UPDATE_ENVIRONMENT_FILTERS,
    SET_CUSTOMER_FILTERS_STATUS,
    SET_CUSTOMER_FILTERS_SEARCH,
    SET_CUSTOMERS_FILTER_STATUS_CALCULATED,
    SET_PARTNER_FILTERS_SEARCH,
} from "./filters-types";
import constants from "../../utils/constants";

const initialState = {
    environmentFilters: [],
    customerFilters: {
        status: constants.defaultIndex,
        search: "",
        isStatusCalculated: false,
    },
    partnerFilters: {
        search: "",
    },
};
const filtersReducer = (state: State = initialState, action: Action): State => {
    const updateEnvironment = (
        actionId: string,
        fieldName: string,
        fieldValue: string | number
    ) => {
        state.environmentFilters.forEach((item, index) => {
            if (item.id === actionId || item.name === actionId) {
                state.environmentFilters[index] = {
                    ...state.environmentFilters[index],
                    id:
                        item.id === actionId
                            ? actionId
                            : state.environmentFilters[index].id,
                    name:
                        item.name === actionId
                            ? actionId
                            : state.environmentFilters[index].name,
                    [fieldName]: fieldValue,
                };
            }
        });
    };
    switch (action.type) {
        case UPDATE_ENVIRONMENT_FILTERS:
            action.envList.forEach((env, index) => {
                const envStatus = state.environmentFilters[index]?.status;

                state.environmentFilters[index] = {
                    id: env.id,
                    name: env.name,
                    status:
                        typeof envStatus === "number" &&
                        envStatus !== constants.defaultIndex
                            ? envStatus
                            : constants.defaultIndex,
                    search: state.environmentFilters[index]?.search || "",
                };
            });

            return {
                ...state,
            };

        case SET_ENVIRONMENT_FILTER_STATUS:
            updateEnvironment(action.id, "status", action.status);

            return {
                ...state,
            };

        case SET_CUSTOMER_FILTERS_STATUS:
            return {
                ...state,
                customerFilters: {
                    ...state.customerFilters,
                    status: action.status,
                },
            };

        case SET_ENVIRONMENT_FILTER_SEARCH:
            updateEnvironment(action.id, "search", action.search);

            return {
                ...state,
            };

        case SET_CUSTOMER_FILTERS_SEARCH:
            return {
                ...state,
                customerFilters: {
                    ...state.customerFilters,
                    search: action.search,
                },
            };

        case SET_PARTNER_FILTERS_SEARCH:
            return {
                ...state,
                partnerFilters: {
                    search: action.search,
                },
            };

        case SET_CUSTOMERS_FILTER_STATUS_CALCULATED:
            return {
                ...state,
                customerFilters: {
                    ...state.customerFilters,
                    isStatusCalculated: true,
                },
            };

        default:
            return state;
    }
};

export default filtersReducer;
