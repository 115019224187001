import React, { useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Schema, Form, FormControl } from "rsuite";
import constants from "utils/constants";

interface IpAddressProps {
    cidr: string;
    rowIndex: number;
    handleIpAddressChange: (index: number, value: string) => void;
}

const IpAddressRow = React.forwardRef(
    ({ cidr, rowIndex, handleIpAddressChange }: IpAddressProps, ref) => {
        const { t } = useTranslation();
        const formRef = useRef<HTMLFormElement>();
        useImperativeHandle(ref, () => ({ validate }));

        const { StringType } = Schema.Types;
        const formModel = Schema.Model({
            cidr: StringType()
                .isRequired(t("generalValidationMessages.isRequired"))
                .pattern(
                    constants.cidrRegex,
                    t("generalValidationMessages.ipAddressNotCorrect")
                ),
        });

        function validate(): boolean {
            return formRef.current?.check();
        }

        return (
            <Form ref={formRef} model={formModel} formValue={{ cidr: cidr }}>
                <FormControl
                    name="cidr"
                    value={cidr}
                    onChange={(value: string) =>
                        handleIpAddressChange(rowIndex, value)
                    }
                />
            </Form>
        );
    }
);

export default IpAddressRow;
