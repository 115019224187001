import { SET_REFRESH_ACTIVATED, State, Actions } from "./page-refresh-types";

const initialState: State = {
    isRefreshActivated: false,
};

const pageRefreshReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_REFRESH_ACTIVATED:
            return {
                ...state,
                isRefreshActivated: action.isRefreshActivated,
            };

        default:
            return state;
    }
};

export default pageRefreshReducer;
