import {
    SET_CULTURE_SETTINGS,
    SET_CULTURE,
    SET_NUMBER_AND_DATE_CULTURE,
    SET_PRECISE_SCALE,
    SET_DEFAULT_CURRENCY,
    SET_CURRENCIES,
    SET_SUPPORTED_CULTURES,
    ISetCultureSettings,
    ISetCulture,
    ISetNumberAndDateCulture,
    ISetPreciseScale,
    ISetDefaultCurrency,
    ISetCurrencies,
    ISetSupportedCultures,
    ICultureSettings,
    ICurrency,
    SET_CULTURE_SETTINGS_SAVED,
    ISetCultures,
    SET_CULTURES,
    ISetCultureSettingsSaved,
} from "./tenant-culture-settings-types";

export const setCultureSettings = (
    cultureSettings: ICultureSettings | null
): ISetCultureSettings => ({
    type: SET_CULTURE_SETTINGS,
    cultureSettings,
});
export const setCulture = (culture: string): ISetCulture => ({
    type: SET_CULTURE,
    culture,
});
export const setNumberAndDateCulture = (
    numberAndDateCulture: string
): ISetNumberAndDateCulture => ({
    type: SET_NUMBER_AND_DATE_CULTURE,
    numberAndDateCulture,
});
export const setPreciseScale = (preciseScale: number): ISetPreciseScale => ({
    type: SET_PRECISE_SCALE,
    preciseScale,
});
export const setDefaultCurrency = (
    defaultCurrency: string
): ISetDefaultCurrency => ({
    type: SET_DEFAULT_CURRENCY,
    defaultCurrency,
});
export const setCurrencies = (currencies: ICurrency[]): ISetCurrencies => ({
    type: SET_CURRENCIES,
    currencies,
});
export const setCultureSettingsSaved = (
    isSaved: boolean
): ISetCultureSettingsSaved => ({
    type: SET_CULTURE_SETTINGS_SAVED,
    isSaved,
});
export const setSupportedCultures = (
    supportedCultures: string[]
): ISetSupportedCultures => ({
    type: SET_SUPPORTED_CULTURES,
    supportedCultures,
});
export const setCultures = (cultures: string[]): ISetCultures => ({
    type: SET_CULTURES,
    cultures,
});
