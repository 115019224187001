import React, { useEffect, useState } from "react";
import Modal from "components/Common/Modal/Modal";
import { Button } from "rsuite";
import { useTranslation } from "react-i18next";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { IAppRegistrationSecretExpiration } from "redux/environments/environment-types";
import { loadDooapAppRegistrationInfo } from "api/environments/dooap-app-registration-api";
import { formatDateWithTime } from "utils/date-helper";
import "./AppRegistrationInformation.less";

interface ComponentProps {
    envMoniker: string;
    isOpened: boolean;
    setAppRegistrationInfoOpened: (isOpened: boolean) => void;
}

const AppRegistrationInformation: React.FC<ComponentProps> = ({
    envMoniker,
    isOpened,
    setAppRegistrationInfoOpened,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [appRegistrationInfo, setAppRegistrationInfo] =
        useState<IAppRegistrationSecretExpiration | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        if (isOpened && envMoniker) {
            getActionHistory();
        }
    }, [isOpened, envMoniker]);

    const getActionHistory = () => {
        setLoading(true);
        loadDooapAppRegistrationInfo(envMoniker).then(([payload, status]) => {
            setLoading(false);
            if (payload && status === constants.statusCode.OK) {
                setAppRegistrationInfo(payload);
            } else {
                setErrorMessage(getErrorMessage(String(payload), status));
            }
        });
    };

    const header = <h1>{t("appRegistrationInfo.modalTitle")}</h1>;
    const footer = (
        <>
            <Button
                className={"app_registration-information__button"}
                type="submit"
                appearance="ghost"
                onClick={() => {
                    setAppRegistrationInfoOpened(false);
                }}
            >
                {t("appRegistrationInfo.closeBtnTitle")}
            </Button>
        </>
    );

    return (
        <Modal
            isModalOpen={isOpened}
            onHide={() => setAppRegistrationInfoOpened(false)}
            className={"app_registration-information__modal"}
            header={header}
            footer={footer}
            isModalLoaderVisible={loading}
            closeButton={true}
            errorMessage={errorMessage}
        >
            {appRegistrationInfo && (
                <div className="app_registration-information__content">
                    <div>
                        <span>{t("appRegistrationInfo.idLabel")}</span>
                        <span>{appRegistrationInfo.id}</span>
                    </div>
                    <div>
                        <span>{t("appRegistrationInfo.nameLabel")}</span>
                        <span>{appRegistrationInfo.name}</span>
                    </div>
                    <div>
                        <span>{t("appRegistrationInfo.linkLabel")}</span>
                        <a
                            href={appRegistrationInfo.azureLink}
                            target={"_blank"}
                        >
                            {t("appRegistrationInfo.linkTitle")}
                        </a>
                    </div>
                    <div>
                        <span>
                            {t("appRegistrationInfo.expirationDateLabel")}
                        </span>
                        <span>
                            {formatDateWithTime(
                                new Date(appRegistrationInfo.expirationDate)
                            )}
                        </span>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default AppRegistrationInformation;
