import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    setModalConfirmCallback,
    removeModalConfirmCallback,
} from "../redux/common/unsaved-data/unsaved-data-actions";
import { v4 as uuid } from "uuid";

const useRefreshPage = (refreshPageCallback: () => void): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        const callbackId = uuid();
        dispatch(
            setModalConfirmCallback({
                id: callbackId,
                isAutoDeleteResistant: true,
                isPageRedirect: false,
                callback: refreshPageCallback,
            })
        );
        return () => {
            dispatch(
                removeModalConfirmCallback(
                    (modalCallback) => modalCallback.id === callbackId
                )
            );
        };
    }, [
        dispatch,
        setModalConfirmCallback,
        refreshPageCallback,
        removeModalConfirmCallback,
    ]);
};

export default useRefreshPage;
