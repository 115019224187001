import { IEnvironment } from "../../environment-types";

export const UPDATE_ENVIRONMENT_PING_INFO = "ping/UPDATE_ENVIRONMENT_PING_INFO";
export const UPDATE_TENANT_PING_INFO = "ping/UPDATE_TENANT_PING_INFO";

export interface ITenantPingInfo {
    tenantId: string;
    tenantPingStatus: number;
    errorMessage: string;
}
export interface IEnvironmentPingInfo {
    envMoniker: string;
    tenantListPingInfo: ITenantPingInfo[];
}
export interface State {
    pingInfo: IEnvironmentPingInfo[];
}
export interface IUpdateEnvironmentPingInfo {
    type: typeof UPDATE_ENVIRONMENT_PING_INFO;
    envList: IEnvironment[];
}
export interface IUpdateTenantPingInfo {
    type: typeof UPDATE_TENANT_PING_INFO;
    envMoniker: string;
    pingInfo: ITenantPingInfo;
}
export type Actions = IUpdateTenantPingInfo | IUpdateEnvironmentPingInfo;
