import {
    Actions,
    SET_CREATE_MAILBOXES_OPENED,
    State,
} from "./tenant-capture-mailboxes-types";

const initialState = {
    isCreateMailboxesOpened: false,
};
const captureMailboxesReducer = (
    state: State = initialState,
    action: Actions
) => {
    switch (action.type) {
        case SET_CREATE_MAILBOXES_OPENED:
            return {
                ...state,
                isCreateMailboxesOpened: action.isCreateMailboxesOpened,
            };
    }

    return state;
};

export default captureMailboxesReducer;
