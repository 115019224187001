import {
    Actions,
    SET_TENANT_MONIKER,
    SET_TENANT_STATUS_INFO,
    SET_TENANT_STATUS_OPENED,
    State,
} from "./tenant-status-types";

const initialState = {
    tenantMoniker: "",
    tenantVersionInfo: {
        name: "",
        id: "",
        d365ResponseTime: 0,
        fetchStatus: 0,
        fetchErrorMessage: null,
        refreshParametersStatus: 0,
        refreshParametersErrorMessage: null,
        applicationVersion: null,
        dooapVersion: null,
        platformVersion: null,
        lastSyncTime: null,
        authenticationMethod: null,
        d365ThrottlingPriority: null,
        d365ThrottlingPriorityErrorMessage: null,
        d365ThrottlingPriorityStatus: null,
    },
    isTenantStatusOpened: false,
};
const tenantStatusReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_TENANT_STATUS_OPENED:
            return {
                ...state,
                isTenantStatusOpened: action.isTenantStatusOpened,
            };
        case SET_TENANT_STATUS_INFO:
            return {
                ...state,
                tenantVersionInfo: action.versionInfo,
            };
        case SET_TENANT_MONIKER:
            return {
                ...state,
                tenantMoniker: action.tenantMoniker,
            };
    }

    return state;
};
export default tenantStatusReducer;
