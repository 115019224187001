import { addEmailTemplate } from "api/deliverables/email-templates-api";
import {
    Actions,
    emailTemplateDefault,
    SET_MANAGE_TEMPLATE_DETAILS,
    SET_MANAGE_TEMPLATE_OPENED,
    State,
} from "./email-templates-types";

const initialState = {
    isManageTemplateOpened: false,
    templateDetails: {
        title: "",
        confirmButtonName: "",
        actionName: "",
        emailTemplate: emailTemplateDefault,
        apiMethod: addEmailTemplate,
    },
};

const emailTemplatesReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_MANAGE_TEMPLATE_OPENED:
            return {
                ...state,
                isManageTemplateOpened: action.isManageTemplateOpened,
            };
        case SET_MANAGE_TEMPLATE_DETAILS:
            return {
                ...state,
                templateDetails: action.templateDetails,
            };

        default:
            return state;
    }
};

export default emailTemplatesReducer;
