import { connect } from "react-redux";
import { RootState } from "redux/store";
import {
    setIsUnsavedChangeAvailable,
    removeModalConfirmCallback,
} from "redux/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "redux/common/page-refresh/page-refresh-actions";
import UnsavedDataModal from "./UnsavedDataModal";

const mapStateToProps = (state: RootState) => {
    return {
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
        isRefreshActivated: state.pageRefreshReducer.isRefreshActivated,
        modalConfirmCallbacks: state.unsavedDataReducer.modalConfirmCallbacks,
    };
};
const UnsavedDataModalContainer = connect(mapStateToProps, {
    removeModalConfirmCallback,
    setIsUnsavedChangeAvailable,
    setRefreshActivated,
})(UnsavedDataModal);

export default UnsavedDataModalContainer;
