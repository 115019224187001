export const SET_MODAL_TITLE = "modalWindow/SET_MODAL_TITLE";
export const SET_MODAL_MESSAGE = "modalWindow/SET_MODAL_MESSAGE";
export const SET_IS_MODAL_OPEN =
    "modalWindow/SET_IS_ADD_NEW_CUSTOMER_MODAL_OPEN";
export const SET_MODAL_LOADER_VISIBILITY =
    "modalWindow/SET_MODAL_LOADER_VISIBILITY";

export interface State {
    isModalLoaderVisible: boolean;
    isModalOpen: boolean;
    modalTitle: string;
    modalMessage: string;
}
export interface ISetModalTitle {
    type: typeof SET_MODAL_TITLE;
    title: string;
}
export interface ISetModalMessage {
    type: typeof SET_MODAL_MESSAGE;
    message: string;
}
export interface ISetIsModalOpen {
    type: typeof SET_IS_MODAL_OPEN;
    isModalOpen: boolean;
}
export interface ISetModalLoaderVisibility {
    type: typeof SET_MODAL_LOADER_VISIBILITY;
    isModalLoaderVisible: boolean;
}
export type Actions =
    | ISetModalTitle
    | ISetModalMessage
    | ISetIsModalOpen
    | ISetModalLoaderVisibility;
