import React from "react";
import "themes/components/custom-modal.less";
import Modal from "components/Common/Modal/Modal";
import cn from "classnames";

import "./ProgressModal.less";

interface IComponentProps {
    isModalOpened: boolean;
    isModalLoaderVisible: boolean;
    header?: React.ReactNode;
    footer: React.ReactNode;
    userMessage?: React.ReactNode;
    completeMessage?: React.ReactNode;
    stepList?: React.ReactNode;
    onClose?: () => void;
}

const ProgressModal: React.FC<IComponentProps> = ({
    isModalOpened,
    isModalLoaderVisible,
    header,
    footer,
    userMessage,
    completeMessage,
    stepList,
    onClose,
}: IComponentProps) => {
    return (
        <Modal
            isModalOpen={isModalOpened}
            isModalLoaderVisible={isModalLoaderVisible}
            errorMessage=""
            backdrop="static"
            size={"md"}
            header={header}
            footer={footer}
            className={cn(
                "custom-modal__container",
                "progress-modal__container"
            )}
            onHide={onClose}
        >
            {stepList}
            {userMessage}
            {completeMessage}
        </Modal>
    );
};

export default ProgressModal;
