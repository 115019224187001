import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    ButtonToolbar,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Schema,
    SelectPicker,
} from "rsuite";
import ModalContainer from "components/Common/Modal/ModalContainer";
import { FormInstance } from "rsuite/lib/Form";
import constants from "utils/constants";
import { addPartner, loadPartnerGroups } from "api/partners/partners-api";
import { IPartner } from "redux/partners/partners-types";
import { getErrorMessage } from "api/defaults";
import "./AddNewPartner.less";

const defaultFormValue = {
    partnerName: "",
    aadGroupName: "",
};

interface ComponentProps {
    isOpened: boolean;
    existingPartners: IPartner[];
    setIsOpened: (isOpened: boolean) => void;
    refreshPartners: () => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}

const AddNewPartner: React.FC<ComponentProps> = ({
    isOpened,
    existingPartners,
    setIsOpened,
    refreshPartners,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance>(null);
    const [partnerName, setPartnerName] = useState<string>("");
    const [aadGroupName, setAadGroupName] = useState<string>("");
    const [partnerNames, setPartnerNames] = useState<string[]>([]);
    const [existingPartnerGroups, setExistingPartnerGroups] = useState<
        string[]
    >([]);
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [partnerGroups, setPartnerGroups] = useState<Record<string, any>[]>(
        []
    );
    const [isGroupsFetched, setIsGroupFetched] = useState(false);

    const fetchPartnerGroups = () => {
        setModalLoaderVisibility(true);
        setIsGroupFetched(false);
        loadPartnerGroups().then(([payload, status]) => {
            setModalLoaderVisibility(false);
            setIsGroupFetched(true);
            if (payload && status === constants.statusCode.OK) {
                const groups = payload.map((value) => {
                    return {
                        label: value,
                        value: value,
                    };
                });
                setPartnerGroups(groups);
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                Alert.error(errorMessage);
            }
        });
    };

    useEffect(() => {
        if (!isGroupsFetched && isOpened) {
            fetchPartnerGroups();
        }
    }, [isGroupsFetched, setIsGroupFetched, fetchPartnerGroups]);

    const handleAddPartner = () => {
        if (!formRef.current?.check()) {
            return;
        }
        setModalLoaderVisibility(true);
        const finalName = partnerName.trim();
        const newPartner = {
            name: finalName,
            aadGroupName: aadGroupName,
        };
        addPartner(newPartner).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                handleCloseModal();
                refreshPartners();
            } else {
                const errorMessage = getErrorMessage(String(result), status);
                Alert.error(errorMessage);
            }

            setModalLoaderVisibility(false);
        });
    };

    useEffect(() => {
        if (existingPartners) {
            setPartnerNames(existingPartners.map((value) => value.name));
            setExistingPartnerGroups(
                existingPartners.map((value) => value.aadGroupName)
            );
        }
    }, [existingPartners]);

    const handleChangeForm = (value: any) => {
        setPartnerName(value.partnerName);
        setAadGroupName(value.aadGroupName);
        setFormValue(value);
    };

    const handleCloseModal = () => {
        setFormValue(defaultFormValue);
        setIsOpened(false);
        setIsGroupFetched(false);
    };

    const formKey = {
        partnerName: "partnerName",
        aadGroupName: "aadGroupName",
    };

    const model = Schema.Model({
        partnerName: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            )
            .maxLength(
                constants.maxPartnerNameLengthLimit,
                t("generalValidationMessages.maxLength500")
            )
            .addRule((enteredValue: string) => {
                return partnerNames.indexOf(enteredValue.trim()) < 0;
            }, t("generalValidationMessages.isUnique"))
            .addRule((enteredValue: string) => {
                const final = enteredValue.trim();
                return final.length >= constants.minNameLengthLimit;
            }, t("generalValidationMessages.minLength")),
        aadGroupName: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .addRule((enteredValue: string) => {
                return existingPartnerGroups.indexOf(enteredValue.trim()) < 0;
            }, t("partners.newPartnerDialog.aadGroupValidation")),
    });

    const header = <h1>{t("partners.newPartnerDialog.title")}</h1>;

    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                appearance="primary"
                disabled={!isGroupsFetched}
                onClick={handleAddPartner}
            >
                {t("partners.newPartnerDialog.create")}
            </Button>
            <Button
                appearance="ghost"
                disabled={!isGroupsFetched}
                onClick={handleCloseModal}
            >
                {t("partners.newPartnerDialog.cancel")}
            </Button>
        </ButtonToolbar>
    );

    return (
        <ModalContainer
            show={isOpened}
            onHide={handleCloseModal}
            header={header}
            footer={footer}
            className="add-modal__container"
            backdrop="static"
        >
            <>
                <Form
                    className="add-modal__form"
                    ref={formRef}
                    model={model}
                    formValue={formValue}
                    onChange={handleChangeForm}
                >
                    <FormGroup className="add-modal__form-group">
                        <ControlLabel>
                            {t("partners.newPartnerDialog.nameTitle")}
                        </ControlLabel>
                        <FormControl
                            placeholder={t(
                                "partners.newPartnerDialog.namePlaceholder"
                            )}
                            name={formKey.partnerName}
                        />
                    </FormGroup>
                    <FormGroup className="add-modal__form-group">
                        <ControlLabel>
                            {t("partners.newPartnerDialog.aadGroup")}
                        </ControlLabel>
                        <FormControl
                            accepter={SelectPicker}
                            data={partnerGroups}
                            virtualized={false}
                            cleanable={false}
                            searchable={false}
                            placeholder={t(
                                "partners.newPartnerDialog.aadGroupPlaceholder"
                            )}
                            name={formKey.aadGroupName}
                            className={"tablePicker"}
                            disabled={!isGroupsFetched}
                        />
                    </FormGroup>
                </Form>
            </>
        </ModalContainer>
    );
};

export default AddNewPartner;
