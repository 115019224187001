import { defaultErrorResponse } from "api/defaults";
import Axios, { CancelTokenSource } from "axios";
import {
    IMailboxCreation,
    IMailbox,
    IMailboxSave,
    IMailboxErrorLog,
} from "redux/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";
import { API } from "utils/api-config";

export const loadTenantCaptureMailboxes = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IMailbox[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/mailboxes`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const loadTenantDefaultCaptureMailboxes = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IMailboxCreation[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/mailboxes/default`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const loadMailboxesCreationErrorLogs = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IMailboxErrorLog[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/mailboxes/errors`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const createTenantCaptureMailboxes = async (
    environmentMoniker: string,
    tenantMoniker: string,
    mailboxes: IMailboxSave[]
): Promise<[string[] | null, number]> => {
    if (!environmentMoniker || !tenantMoniker || mailboxes.length === 0) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${environmentMoniker}/tenants/${tenantMoniker}/mailboxes`,
        mailboxes
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
