import {
    ITenant,
    SET_ACTIVE_TENANT,
    SET_TENANTS,
    SET_ACTIVE_TENANT_BY_ID,
    UPDATE_ACTIVE_TENANT_DISPLAY_NAME,
    SET_IS_TENANT_FETCHED,
    IVersionInfo,
    UPDATE_TENANT_VERSION_INFO,
} from "./tenants-types";

export const setTenants = (tenants: ITenant[] | null) => ({
    type: SET_TENANTS,
    tenants,
});
export const setActiveTenant = (activeTenant: ITenant) => ({
    type: SET_ACTIVE_TENANT,
    activeTenant,
});
export const setActiveTenantById = (id: string) => ({
    type: SET_ACTIVE_TENANT_BY_ID,
    id,
});
export const updateActiveTenantDisplayName = (name: string) => ({
    type: UPDATE_ACTIVE_TENANT_DISPLAY_NAME,
    name,
});

export const setIsTenantFetched = (isFetched: boolean) => ({
    type: SET_IS_TENANT_FETCHED,
    isFetched,
});

export const updateTenantVersionInfo = (
    moniker: string,
    versionInfo: IVersionInfo
) => ({
    type: UPDATE_TENANT_VERSION_INFO,
    moniker,
    versionInfo,
});
