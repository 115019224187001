import React from "react";
import {
    IWorkflowStepBase,
    WorkflowStepStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import { format } from "date-fns";
import css from "./StepListItem.module.css";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { template } from "lodash";

interface IComponentProps {
    step: IWorkflowStepBase;
}

const StepListItem: React.FC<IComponentProps> = ({ step }: IComponentProps) => {
    const { t } = useTranslation();
    const getRenderSettingsByStatus = (status: WorkflowStepStatus) => {
        switch (status) {
            case WorkflowStepStatus.Pending:
                return {
                    class: css.pending,
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.common"
                    ),
                    showProcessDateTime: false,
                };
            case WorkflowStepStatus.InProcess:
                return {
                    class: css.inProcess,
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.inProcess"
                    ),
                    showProcessDateTime: false,
                };
            case WorkflowStepStatus.Completed:
                return {
                    class: css.completed,
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.common"
                    ),
                    showProcessDateTime: true,
                };
            case WorkflowStepStatus.Failed:
                return {
                    class: css.failed,
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.failed"
                    ),
                    showProcessDateTime: true,
                };
            case WorkflowStepStatus.CompletedWithError:
                return {
                    class: css.failed,
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.failed"
                    ),
                    showProcessDateTime: true,
                };
            default:
                return {
                    class: "",
                    template: t(
                        "tenantFeatureSetting.progressModal.step.template.common"
                    ),
                    showProcessDateTime: false,
                };
        }
    };

    const showErrorMessage =
        step.status === WorkflowStepStatus.CompletedWithError ||
        step.status === WorkflowStepStatus.Failed;
    const renderSettings = getRenderSettingsByStatus(step.status);
    const actionDescriptionTemplate = template(renderSettings.template);
    const logs: string[] = step.logs !== null ? JSON.parse(step.logs) : null;
    return (
        <li className={cn(css.container, renderSettings.class)}>
            <div className={css.actionDescriptionContainer}>
                {parse(
                    actionDescriptionTemplate({
                        message: step.actionDescription,
                    })
                )}
                {logs !== null && (
                    <ul>
                        {logs.map((log, index) => (
                            <li key={index}>{parse(log)}</li>
                        ))}
                    </ul>
                )}
            </div>
            {step.processDateTime !== null &&
                renderSettings.showProcessDateTime && (
                    <div className={css.dateTimeContainer}>
                        {format(
                            new Date(step.processDateTime),
                            "d MMMM y - HH:mm:ss"
                        )}
                    </div>
                )}
            {showErrorMessage && step.errorMessage !== null && (
                <div className={css.errorStackContainer}>
                    {parse(step.errorMessage)}
                </div>
            )}
        </li>
    );
};

export default StepListItem;
