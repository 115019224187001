import { loadCustomers } from "../../api/customers/customers-api";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "../common/initialization/initialization-actions";
import { AppThunk } from "../store";
import { sortCustomersByName, setCustomers } from "./customers-actions";
import constants from "../../utils/constants";
import { getErrorMessage } from "../../api/defaults";

export const fetchCustomers = (): AppThunk => async (dispatch) => {
    dispatch(setLoaderVisibility(true));
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadCustomers();

    if (payload && status === constants.statusCode.OK) {
        dispatch(setCustomers(payload));
        dispatch(sortCustomersByName());
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }

    dispatch(setLoaderVisibility(false));
};

export const fetchCustomersWithoutLoader = (): AppThunk => async (dispatch) => {
    dispatch(setErrorResponseMessage(""));

    const [payload, status] = await loadCustomers();

    if (payload && status === constants.statusCode.OK) {
        dispatch(setCustomers(payload));
        dispatch(sortCustomersByName());
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessage(errorMessage));
    }
};
