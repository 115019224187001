import React from "react";
import "./SettingsFooter.less";
import cn from "classnames";
import { Button, ButtonProps } from "rsuite";
import { useTranslation } from "react-i18next";

interface ComponentProps {
    children?: React.ReactNode;
    className?: string;
    isFullWidth?: boolean;
    isUnsavedChangeAvailable?: boolean;
    handleFormSubmit?: () => void;
    saveButtonProps?: ButtonProps;
    customButton?: React.ReactNode;
}

const SettingsFooter: React.FC<ComponentProps> = ({
    children,
    className,
    isFullWidth = false,
    handleFormSubmit,
    isUnsavedChangeAvailable,
    saveButtonProps,
    customButton,
}: ComponentProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                className,
                isFullWidth ? "footer__full-width" : "footer__container"
            )}
        >
            {customButton ? (
                customButton
            ) : (
                <Button
                    className={"footer__save-btn"}
                    appearance="primary"
                    onClick={handleFormSubmit}
                    disabled={!isUnsavedChangeAvailable}
                    {...saveButtonProps}
                >
                    {t("tenantConfig.saveButton")}
                </Button>
            )}
            {children}
        </div>
    );
};

export default SettingsFooter;
