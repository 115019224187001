import useUrlToPathConversion from "hooks/useUrlToPathConvertation";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import css from "./BreadCrumb.module.css";
import { SelectPicker } from "rsuite";
import constants from "utils/constants";
import { IConvertedItem } from "utils/url-convertor";
import {
    ITenant,
    TenantStatus,
} from "redux/environments/tenants/tenants-types";
import { IEnvironment } from "redux/environments/environment-types";
import { ICustomer } from "redux/customers/customers-types";
import { IPartner } from "redux/partners/partners-types";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import { IRouteState } from "utils/types";
import { ITenantRouteState } from "utils/route-state-types";

interface ComponentProps {
    item: IConvertedItem;
    index: number;
    environments: IEnvironment[];
    activeEnvironmentName: string;
    tenants: ITenant[];
    customers: ICustomer[];
    partners: IPartner[];
    isUnsavedChangeAvailable: boolean;
    activeEnvMoniker: string;
    isActiveTenantSet: boolean;
    setActiveTenant: (tenant: ITenant) => void;
    setActiveEnvironmentById: (id: string) => void;
    setActiveEnvironmentTenantsMonikers: () => void;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setIsTenantFetched: (isFetched: boolean) => void;
    setIsDeleteFetched: (isDeleteFetched: boolean) => void;
}

const BreadCrumbSwitch: React.FC<ComponentProps> = ({
    item,
    index,
    environments,
    activeEnvironmentName,
    tenants,
    customers,
    partners,
    activeEnvMoniker,
    isUnsavedChangeAvailable,
    isActiveTenantSet,
    setActiveTenant,
    setActiveEnvironmentById,
    setActiveEnvironmentTenantsMonikers,
    setModalConfirmCallback,
    setRefreshActivated,
    setIsUnsavedChangeAvailable,
    setIsTenantFetched,
    setIsDeleteFetched,
}: ComponentProps) => {
    const itemPath = useUrlToPathConversion(item.url);
    const history = useHistory();
    const [pendingEntity, setPendingEntity] = useState(null);
    const composePath = (id: string) => {
        const newPath = constants.routePath.generalConfig
            .replace(":environmentName", activeEnvironmentName)
            .replace(":tenantId", id);
        return newPath;
    };

    const cropName = (name: string) => {
        if (name.length > constants.itemNameLengthLimit) {
            return name.slice(0, constants.itemNameLengthLimit) + "...";
        }
        return name;
    };

    const environmentsPickerList = environments
        ?.map((environment) => ({
            value: environment,
            label: cropName(environment.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const tenantsPickerList = isActiveTenantSet
        ? tenants
              .filter((tenant) => !tenant.enrollWorkflowId)
              ?.map((tenant) => ({
                  value: tenant,
                  label: cropName(
                      `${tenant.moniker} - ${tenant.versionInfo.name}`
                  ),
              }))
              .sort((a, b) =>
                  a.value.versionInfo.name.localeCompare(
                      b.value.versionInfo.name
                  )
              )
        : null;

    const customersPickerList = customers
        ?.map((customer) => ({
            value: customer,
            label: cropName(customer.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const partnersPickerList = partners
        ?.map((partner) => ({
            value: partner,
            label: cropName(partner.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        if (pendingEntity !== null) {
            onSelectHandler(pendingEntity);
            setPendingEntity(null);
        }
    }, [pendingEntity]);

    const handleTenantChange = (tenant: ITenant) => {
        setIsDeleteFetched(false);
        setIsTenantFetched(false);
        setActiveTenant(tenant);
        const futureRouteState: IRouteState<ITenantRouteState> = {
            data: {
                envMoniker: activeEnvMoniker,
                tenant: tenant,
            },
        };
        history.push(composePath(tenant.versionInfo.id), futureRouteState);
    };

    const handleEnvironmentChange = (environment: IEnvironment) => {
        setActiveEnvironmentById(environment.id);
        setActiveEnvironmentTenantsMonikers();
        history.push(
            constants.routePath.tenants.replace(
                ":environmentName",
                environment.name
            )
        );
    };

    const handleCustomerChange = (customer: ICustomer) => {
        history.push(
            constants.routePath.customerDetails.replace(
                ":customerId",
                customer.id
            )
        );
    };

    const handlePartnerChange = (partner: IPartner) => {
        history.push(
            constants.routePath.partnerDetails.replace(":partnerId", partner.id)
        );
    };

    let pickerData;
    let onSelectHandler: (entity: any) => void;

    if (itemPath.route?.includes(constants.routePath.tenantConfigs)) {
        pickerData = tenantsPickerList;
        onSelectHandler = handleTenantChange;
    } else if (itemPath.route?.includes(constants.routePath.tenants)) {
        pickerData = environmentsPickerList;
        onSelectHandler = handleEnvironmentChange;
    } else if (itemPath.route?.includes(constants.routePath.customerDetails)) {
        pickerData = customersPickerList;
        onSelectHandler = handleCustomerChange;
    } else if (itemPath.route?.includes(constants.routePath.partnerDetails)) {
        pickerData = partnersPickerList;
        onSelectHandler = handlePartnerChange;
    }

    const onSelectTabHandler = (entity: any) => {
        if (isUnsavedChangeAvailable) {
            setModalConfirmCallback({
                id: uuid(),
                isAutoDeleteResistant: false,
                isPageRedirect: true,
                callback: () => {
                    setIsUnsavedChangeAvailable(false);
                    setPendingEntity(entity);
                },
            });
            setRefreshActivated(true);
            return;
        }
        setPendingEntity(entity);
    };

    return (
        <>
            {pickerData ? (
                <SelectPicker
                    data={pickerData}
                    onSelect={onSelectTabHandler}
                    appearance="subtle"
                    className={css.picker}
                    cleanable={false}
                    placeholder={item.name}
                    value={null}
                />
            ) : (
                <NavLink
                    exact
                    to={item.url}
                    key={String(index)}
                    className={css.item}
                    activeClassName={css.selected}
                >
                    {`${item.name}`}
                </NavLink>
            )}
        </>
    );
};

export default BreadCrumbSwitch;
