import {
    ITenantVersionInfo,
    SET_TENANT_STATUS_INFO,
    SET_TENANT_STATUS_OPENED,
    SET_TENANT_MONIKER,
} from "./tenant-status-types";

export const setTenantStatusOpened = (isTenantStatusOpened: boolean) => ({
    type: SET_TENANT_STATUS_OPENED,
    isTenantStatusOpened,
});

export const setTenantStatusInfo = (versionInfo: ITenantVersionInfo) => ({
    type: SET_TENANT_STATUS_INFO,
    versionInfo,
});

export const setTenantMoniker = (tenantMoniker: string) => ({
    type: SET_TENANT_MONIKER,
    tenantMoniker,
});
