import { RootState } from "redux/store";
import Tenants from "./Tenants";
import { connect } from "react-redux";
import { fetchEnvironments } from "redux/environments/environment-thunks";
import { fetchTenants } from "redux/environments/tenants/tenants-thunks";
import {
    setActiveTenant,
    updateTenantVersionInfo,
} from "redux/environments/tenants/tenants-actions";
import { getFilteredTenantsSortedByNameAndStatus } from "redux/environments/tenants/tenants-selectors";
import {
    getActiveEnvironmentMoniker,
    getActiveEnvironmentName,
    getActiveEnvironmentTenantIds,
} from "redux/environments/environment-selectors";
import { pingTenantInD365 } from "redux/environments/tenants/tenant-ping/tenant-ping-thunk";
import { getTenantsPingInfoSelector } from "redux/environments/tenants/tenant-ping/tenant-ping-selectors";
import { getAppSettings } from "redux/app-settings/app-settings-selectors";
import {
    setErrorMessageInactiveTenant,
    setErrorResponseMessage,
} from "redux/common/initialization/initialization-actions";
import { setLoaderVisibility } from "redux/common/initialization/initialization-actions";
import { getIsUserPartner } from "redux/app-global/app-global-selectors";
import {
    setTenantMoniker,
    setTenantStatusInfo,
    setTenantStatusOpened,
} from "redux/environments/tenants/tenant-status/tenant-status-actions";

const mapStateToProps = (state: RootState) => {
    const envMoniker = getActiveEnvironmentMoniker(state);

    return {
        appSettings: getAppSettings(state),
        tenantsPingInfo: getTenantsPingInfoSelector(state, envMoniker),
        activeEnvName: getActiveEnvironmentName(state),
        activeEnvMoniker: envMoniker,
        activeEnvironmentTenantIds: getActiveEnvironmentTenantIds(state),
        modifiedTenants: getFilteredTenantsSortedByNameAndStatus(state),
        errorMessage:
            state.initializationReducer.errorResponseMessage ||
            state.initializationReducer.errorMessageInactiveEnvironment,
        isLoaderVisible: state.initializationReducer.loadersCount > 0,
        isUserPartner: getIsUserPartner(state),
    };
};
const TenantsContainer = connect(mapStateToProps, {
    fetchEnvironments,
    fetchTenants,
    setActiveTenant,
    pingTenantInD365,
    setErrorResponseMessage,
    setLoaderVisibility,
    setTenantStatusOpened,
    setTenantStatusInfo,
    setTenantMoniker,
    setErrorMessageInactiveTenant,
    updateTenantVersionInfo,
})(Tenants);

export default TenantsContainer;
