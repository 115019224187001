import React, { useEffect } from "react";
import StatusBlock from "components/Common/StatusBlock/StatusBlock";
import { useTranslation } from "react-i18next";
import { ConsentStatus, ICustomer } from "redux/customers/customers-types";
import "./CustomerFilters.less";
import constants from "utils/constants";

interface ComponentProps {
    customerFilterStatus: number;
    customers: ICustomer[];
    isStatusCalculated: boolean;
    setCustomerFilterStatus: (consentStatus: number) => void;
    setCustomersFilterStatusCalculated: () => void;
}
const CustomerFilters: React.FC<ComponentProps> = ({
    customerFilterStatus,
    customers,
    isStatusCalculated,
    setCustomerFilterStatus,
    setCustomersFilterStatusCalculated,
}: ComponentProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isStatusCalculated && inProgress.length) {
            setCustomerFilterStatus(ConsentStatus.InProgress);
            setCustomersFilterStatusCalculated();
        }
    });

    const inProgress: typeof customers = [];
    const completed: typeof customers = [];

    const onFilterClickHandler = (status: number) => {
        setCustomerFilterStatus(status);
    };

    customers.forEach((customer) => {
        if (customer.completedSteps === customer.totalStepsNumber) {
            completed.push(customer);
        }

        if (customer.completedSteps !== customer.totalStepsNumber) {
            inProgress.push(customer);
        }
    });

    return (
        <div className="filters__container">
            <StatusBlock
                isActive={customerFilterStatus === constants.defaultIndex}
                subTitle={customers.length.toString()}
                title={t("customers.consentStatuses.all")}
                onClick={() => onFilterClickHandler(constants.defaultIndex)}
            />
            <StatusBlock
                isActive={customerFilterStatus === ConsentStatus.InProgress}
                subTitle={inProgress.length.toString()}
                title={t("customers.consentStatuses.inProgress")}
                onClick={() => onFilterClickHandler(ConsentStatus.InProgress)}
            />
            <StatusBlock
                isActive={customerFilterStatus === ConsentStatus.Completed}
                subTitle={completed.length.toString()}
                title={t("customers.consentStatuses.completed")}
                onClick={() => onFilterClickHandler(ConsentStatus.Completed)}
            />
        </div>
    );
};
export default CustomerFilters;
