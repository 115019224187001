export interface IActivityLog {
    actionType: ActionType;
    actionTypeName: string;
    timeStamp: string;
    userName: string | null;
    failureReason: string | null;
    entryData: string;
}

export enum ActionType {
    "Create" = 0,
    "Update" = 1,
    "Delete" = 3,
    "SwitchingTenantFeature" = 4,
    "RetryFailedStep" = 5,
    "RenewSearchIndex" = 9,
    "CreatingMailbox" = 10,
}

export type ActivityLogPagination = {
    items: IActivityLog[];
    pageIndex: number;
    totalCount: number;
};
