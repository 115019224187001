import { AppThunk } from "../../../store";
import { IAxImport, IAxImportEntity } from "./data-import-types";
import constants from "utils/constants";
import {
    loadAxImportEntities,
    transferAllCefData,
    updateAxImportEntities,
} from "api/environments/tenants/tenant-ax-import-entities-api";
import { getErrorMessage } from "api/defaults";
import { setErrorResponseMessage } from "../../../common/initialization/initialization-actions";
import { CancelTokenSource } from "axios";

export const fetchTenantAxImport =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk<Promise<IAxImport | null>> =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await loadAxImportEntities(
            environmentMoniker,
            tenantMoniker,
            cancelTokenSource
        );

        if (payload && status === constants.statusCode.OK) {
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
        const isStatusOk = status === constants.statusCode.OK;
        return isStatusOk ? payload : null;
    };

export const updateTenantAxImportEntities =
    (
        envMoniker: string,
        tenantMoniker: string,
        axImportEntities: IAxImportEntity[]
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await updateAxImportEntities(
            envMoniker,
            tenantMoniker,
            axImportEntities
        );
        const isResponseStatusCodeExpected =
            status === constants.statusCode.NoContent ||
            status === constants.statusCode.OK;

        if (!isResponseStatusCodeExpected) {
            const errorMessage = getErrorMessage(String(payload), status);
            dispatch(setErrorResponseMessage(errorMessage));
        }
    };

export const transferCefData =
    (envMoniker: string, tenantMoniker: string, entityName: string): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] = await transferAllCefData(
            envMoniker,
            tenantMoniker,
            entityName
        );
        const isResponseStatusCodeExpected =
            status === constants.statusCode.NoContent ||
            status === constants.statusCode.OK;

        if (!isResponseStatusCodeExpected) {
            const errorMessage = getErrorMessage(String(payload), status);
            dispatch(setErrorResponseMessage(errorMessage));
        }
    };
