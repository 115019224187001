import {
    IConsentMetadata,
    INewCustomerFormValue,
    ISetConsentMetaData,
    ICleanAddNewCustomerData,
    ISetNewCustomerFormValue,
    ISetNewCustomerAdded,
    ISetDefaultNewCustomerFormValue,
    SET_CONSENT_METADATA,
    SET_NEW_CUSTOMER_FORM_VALUE,
    CLEAN_ADD_NEW_CUSTOMER_DATA,
    SET_NEW_CUSTOMER_ADDED,
    SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE,
} from "./add-new-customer-types";

export const setConsentMetaData = (
    consentMetaData: IConsentMetadata
): ISetConsentMetaData => ({
    type: SET_CONSENT_METADATA,
    consentMetaData,
});
export const cleanAddNewCustomerData = (): ICleanAddNewCustomerData => ({
    type: CLEAN_ADD_NEW_CUSTOMER_DATA,
});
export const setDefaultNewCustomerFormValue = (): ISetDefaultNewCustomerFormValue => ({
    type: SET_DEFAULT_NEW_CUSTOMER_FORM_VALUE,
});
export const setNewCustomerFormValue = (
    newCustomerFormValue: INewCustomerFormValue
): ISetNewCustomerFormValue => ({
    type: SET_NEW_CUSTOMER_FORM_VALUE,
    newCustomerFormValue,
});
export const setNewCustomerAdded = (
    isNewCustomerAdded: boolean
): ISetNewCustomerAdded => ({
    type: SET_NEW_CUSTOMER_ADDED,
    isNewCustomerAdded,
});
