import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserPermissions } from "./app-global-types";
import { getActiveTenantMoniker } from "redux/environments/tenants/tenants-selectors";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { getAppSettings } from "redux/app-settings/app-settings-selectors";

export const getUserPermissions = (state: RootState): UserPermissions => {
    return state.appGlobalReducer.userPermissions;
};

export const getIsEmailTemplatesAllowed = (state: RootState): boolean => {
    return state.appGlobalReducer.userPermissions.deliverablesPermissions
        .isTemplatesAccessAllowed;
};

export const getIsPackagesModificationAllowed = (state: RootState): boolean => {
    return state.appGlobalReducer.userPermissions.deliverablesPermissions
        .isPackagesModificationAllowed;
};

export const getIsPowerBiWorkspaceDeploymentAllowed = createSelector(
    [
        getUserPermissions,
        getAppSettings,
        getActiveEnvironmentMoniker,
        getActiveTenantMoniker,
    ],
    (
        userPermissions,
        appSettings,
        activeEnvironmentMoniker,
        activeTenantMoniker
    ) => {
        return (
            userPermissions.environmentPermissions
                .find(
                    (ep) =>
                        `${ep.dooapRegion}-${ep.moniker}` ===
                        activeEnvironmentMoniker
                )
                ?.tenantPermissions.find(
                    (tp) => tp.moniker === activeTenantMoniker
                )?.isPowerBiWorkspaceDeploymentAllowed === true &&
            appSettings.isPowerBiWorkspaceDeploymentEnabled
        );
    }
);

// TODO: Should be used permission-based approach
export const getIsUserPartner = createSelector(
    [getUserPermissions],
    (userPermissions) => {
        return (
            !userPermissions.partnerPermissions.length &&
            userPermissions.environmentPermissions.every(
                (perm) => !perm.isAllTenantsViewAllowed
            )
        );
    }
);
