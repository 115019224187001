import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Folder } from "assets/images/folder.svg";
import topMenuItemsList from "./TopMenuRouteMap";
import { UserPermissions } from "redux/app-global/app-global-types";
import { IAppSettings } from "redux/app-settings/app-settings-types";
import "./TopMenu.less";

interface ComponentProps {
    userPermissions: UserPermissions;
    appSettings: IAppSettings;
}

const TopMenu: React.FC<ComponentProps> = ({
    userPermissions,
    appSettings,
}: ComponentProps) => {
    const [menuItems, setMenuItems] = useState(topMenuItemsList.slice(0, 2));
    const topMenuItemNames = topMenuItemsList.map((item) => item.name);

    useEffect(() => {
        if (
            userPermissions.partnerPermissions.length ||
            userPermissions.environmentPermissions.some(
                (perm) => perm.isAllTenantsViewAllowed
            )
        ) {
            const partnersItem = topMenuItemsList.find(
                (item) => item.name === "Partners"
            );
            if (partnersItem) {
                setMenuItems([...menuItems, partnersItem]);
            }
        }
    }, [userPermissions]);

    useEffect(() => {
        if (appSettings.isDeliverablesEnabled) {
            const deliverablesItem = topMenuItemsList.find(
                (item) => item.name === "Deliverables"
            );
            if (deliverablesItem) {
                setMenuItems([...menuItems, deliverablesItem]);
            }
        }
    }, [appSettings]);

    const navBarItems = menuItems
        .sort(
            (a, b) =>
                topMenuItemNames.indexOf(a.name) -
                topMenuItemNames.indexOf(b.name)
        )
        .map((item, index) => (
            <NavLink
                to={item.route}
                activeClassName="top-menu-item__body--selected"
                className="top-menu-item__body"
                key={index}
            >
                <Folder className="top-menu-item__icon" />
                <span className="top-menu-item__text">{item.name}</span>
            </NavLink>
        ));

    return (
        <>
            <div className="top-menu__container">{navBarItems}</div>
        </>
    );
};

export default TopMenu;
