import { RootState } from "../store";
import { ConsentStatus } from "./customers-types";
import {
    getCustomerFilterSearchText,
    getCustomerFilterStatus,
} from "../filters/filters-selectors";
import { createSelector } from "reselect";
import constants from "../../utils/constants";

export const getCustomers = (state: RootState) => {
    return state.customersReducer.customers;
};

export const getCustomerName = (state: RootState) => {
    return state.customersReducer.activeCustomer.name;
};

export const getCustomerDetailsId = (state: RootState) => {
    return state.customerDetailsReducer.customerDetails?.id;
};

export const getCustomerTenantAmount = (state: RootState) => {
    return state.customersReducer.activeCustomer.tenantsAmount;
};

export const getCustomerNames = createSelector(
    [getCustomers, getCustomerDetailsId],
    (customers, id) =>
        customers
            .map((customer) => ({
                name: customer.name,
                id: customer.id,
            }))
            .filter((customer) => customer.id !== id)
);

export const getCustomersFilteredSelector = createSelector(
    [getCustomerFilterStatus, getCustomerFilterSearchText, getCustomers],
    (consentStatus, searchText, customerList) => {
        return customerList
            .filter((customer) => {
                if (
                    consentStatus === constants.defaultIndex ||
                    (customer.completedSteps === customer.totalStepsNumber &&
                        consentStatus === ConsentStatus.Completed)
                ) {
                    return true;
                }

                return (
                    customer.completedSteps !== customer.totalStepsNumber &&
                    consentStatus === ConsentStatus.InProgress
                );
            })
            .filter((customer) => {
                if (!searchText || searchText === "") {
                    return true;
                }

                const name = customer.name.toLowerCase();
                const id = customer.id.toLowerCase();

                return (
                    name.includes(searchText.toLowerCase()) ||
                    id.includes(searchText.toLowerCase())
                );
            });
    }
);
