import { RootState } from "../../store";

export const getErrorResponseMessage = (state: RootState): string =>
    state.initializationReducer.errorResponseMessage;

export const getErrorEnrollManualStepResponseMessage = (
    state: RootState
): string => state.initializationReducer.errorEnrollManualStepResponseMessage;

export const getErrorMessage = (state: RootState): string =>
    state.initializationReducer.errorResponseMessage ||
    state.initializationReducer.errorMessageInactiveEnvironment ||
    state.initializationReducer.errorMessageInactiveTenant;
