import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";

export const SET_TENANT_DELETE_WORKFLOW = "tenants/SET_TENANT_DELETE_WORKFLOW";
export const SET_IS_DELETE_FETCHED = "tenants/SET_IS_DELETE_FETCHED";

export enum DeletionType {
    EnrollDeletion = 0,
    TenantDeletion = 1,
}

export interface IDeleteWorkflowOptions {
    storeInvoiceDailyVolumes: boolean;
}

export interface ISetActiveTenantDeleteWorkflow {
    type: typeof SET_TENANT_DELETE_WORKFLOW;
    workflow: ITenantDeleteWorkflow | null;
}

export interface ISetIsDeleteFetched {
    type: typeof SET_IS_DELETE_FETCHED;
    isDeleteFetched: boolean;
}

export interface State {
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    isDeleteFetched: boolean;
}
export type Actions = ISetActiveTenantDeleteWorkflow | ISetIsDeleteFetched;
