import constants from "utils/constants";

export interface IMenuItem {
    path: string;
    name: string;
    children?: IMenuItem[];
}
const leftMenu: IMenuItem[] = [
    {
        name: "tenantConfig.leftMenu.generalConfigurations",
        path: constants.routePath.generalConfig,
    },
    {
        name: "tenantConfig.leftMenu.erpConfigurations",
        path: constants.routePath.erpConfig,
    },
    {
        name: "tenantConfig.leftMenu.featureConfigurations",
        path: constants.routePath.featureConfig,
    },
    {
        name: "tenantConfig.leftMenu.dataImport",
        path: constants.routePath.dataImport,
    },
    {
        name: "tenantConfig.leftMenu.customFields",
        path: constants.routePath.customFields,
    },
    {
        name: "tenantConfig.leftMenu.scheduler",
        path: constants.routePath.scheduler,
    },
    {
        name: "tenantConfig.leftMenu.regionalSettings",
        path: constants.routePath.cultureSettings,
    },
    {
        name: "tenantConfig.leftMenu.guestTenants",
        path: constants.routePath.guestTenants,
    },
    {
        name: "tenantConfig.leftMenu.subscriptions",
        path: constants.routePath.dopeSubscriptions,
    },
    {
        name: "tenantConfig.leftMenu.versionHistory",
        path: constants.routePath.versionHistory,
    },
    {
        name: "tenantConfig.leftMenu.dataRefreshHistory",
        path: constants.routePath.dataRefreshHistory,
    },
    {
        name: "tenantConfig.leftMenu.maintenanceMode",
        path: constants.routePath.maintenanceMode,
    },
    {
        name: "tenantConfig.leftMenu.mailboxManagement",
        path: constants.routePath.captureMailboxes,
    },
    {
        name: "tenantConfig.leftMenu.activityLog",
        path: constants.routePath.activityLog,
    },
];

export default leftMenu;
