import React, { useEffect, useRef, useState } from "react";
import { Alert } from "rsuite";
import { useTranslation } from "react-i18next";
import {
    ICustomerDetails,
    ConsentStatusType,
    IEmailDetails,
    ICustomerPartner,
    CustomerSubpage,
} from "redux/customers/customer-details/customer-details-types";
import css from "./CustomerDetails.module.css";
import { useParams } from "react-router-dom";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useRefreshPage from "hooks/useRefreshPage";
import constants from "utils/constants";
import { ICustomer } from "redux/customers/customers-types";
import SendEmailContainer from "components/SendEmail/SendEmailContainer";
import {
    getCustomerDetails,
    updateCustomerDetails,
} from "api/customers/customer-details-api";
import { getErrorMessage } from "api/defaults";
import "themes/required.less";
import CustomerNavigationContainer from "./CustomerNavigation/CustomerNavigationContainer";
import GeneralDetailsContainer from "./GeneralDetails/GeneralDetailsContainer";
import PackageDeliveryContainer from "./PackageDelivery/PackageDeliveryContainer";

interface IComponentProps {
    errorMessage: string | null;
    customerDetails: ICustomerDetails | null;
    activeCustomer: ICustomer;
    customers: ICustomer[];
    fetchCustomers: (customerId?: string) => void;
    setCustomerDetails: (customerDetails: ICustomerDetails | null) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setActiveCustomerById: (customerId: string) => void;
    setActiveCustomerName: (name: string) => void;
    sendEmailWithLink: (
        customerId: string,
        emailDetails: IEmailDetails
    ) => void;
    setEmailSent: (isEmailSent: boolean) => void;
    setLoaderVisibility: (visible: boolean) => void;
    setErrorResponseMessage: (error: string) => void;
}

interface IPageParameters {
    customerId: string;
}

const CustomerDetails: React.FC<IComponentProps> = ({
    activeCustomer,
    errorMessage,
    customerDetails,
    customers,
    fetchCustomers,
    setCustomerDetails,
    setIsUnsavedChangeAvailable,
    setActiveCustomerById,
    setActiveCustomerName,
    sendEmailWithLink,
    setEmailSent,
    setLoaderVisibility,
    setErrorResponseMessage,
}: IComponentProps) => {
    const { t } = useTranslation();
    const { customerId } = useParams<IPageParameters>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isSendByEmailClicked, setIsSendByEmailClicked] = useState(false);
    const [allConsentsPending, setAllConsentsPending] = useState(false);
    const [customerPartners, setCustomerPartners] = useState<
        ICustomerPartner[]
    >([]);
    const [activeTab, setActiveTab] = useState<string>(CustomerSubpage.General);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [originalDetails, setOriginalDetails] =
        useState<ICustomerDetails | null>(customerDetails);
    const [areDetailsFetched, setAreDetailsFetched] = useState<boolean>(false);

    const loadCustomerDetails = () => {
        if (customerId) {
            setLoaderVisibility(true);
            getCustomerDetails(customerId).then(([payload, status]) => {
                setLoaderVisibility(false);
                if (payload && status === constants.statusCode.OK) {
                    setCustomerDetails(payload);
                    setOriginalDetails(payload);
                }
                setAreDetailsFetched(true);
            });
        }
    };
    const loadCustomers = () => {
        if (!activeCustomer.id) {
            fetchCustomers(customerId);
        }
    };

    useEffect(() => {
        return () => {
            setCustomerDetails(null);
        };
    }, []);

    useEffect(loadCustomers, [fetchCustomers, activeCustomer, customerId]);

    useEffect(() => {
        if (!areDetailsFetched || activeCustomer.id !== customerId) {
            loadCustomerDetails();
        }
    }, [customerId, activeCustomer, areDetailsFetched]);

    useEffect(() => {
        if (areDetailsFetched) {
            setActiveTab(CustomerSubpage.General);
            setActiveCustomerById(customerId);
        }
    }, [customerId, areDetailsFetched]);

    useEffect(() => {
        if ((!activeCustomer.id || !activeCustomer.name) && customers.length) {
            setActiveCustomerById(customerId);
        }
    }, [activeCustomer, setActiveCustomerById, customerId, customers]);

    useEffect(() => {
        setAllConsentsPending(
            !!customerDetails?.regionConsents.every((region) =>
                region.consents.every(
                    (consent) =>
                        consent.status === ConsentStatusType.Pending ||
                        consent.status === ConsentStatusType.Failed
                )
            )
        );
        setCustomerPartners(customerDetails?.partners || []);
    }, [customerDetails]);

    useEffect(() => {
        if (customerDetails) {
            setCustomerDetails({
                ...customerDetails,
                partners: customerPartners,
            });
        }
    }, [customerPartners]);

    useEffect(() => {
        setErrorResponseMessage("");
    }, [customerId]);

    useRefreshPage(() => {
        loadCustomerDetails();
    });

    const handleFormSubmit = (isValid: boolean) => {
        if (isValid && customerDetails) {
            setLoaderVisibility(true);
            updateCustomerDetails(customerId, customerDetails).then(
                ([result, status]) => {
                    if (status === constants.statusCode.NoContent) {
                        setIsUnsavedChangeAvailable(false);
                        setActiveCustomerName(customerDetails.name);
                        Alert.success(
                            t("customerDetails.successfulUpdateAlertMessage"),
                            constants.alertDurationMsec
                        );
                    } else {
                        const errorMessage = getErrorMessage(
                            String(result),
                            status
                        );
                        Alert.error(errorMessage, constants.alertDurationMsec);
                    }
                    setAreDetailsFetched(false);
                    setLoaderVisibility(false);
                }
            );
        }
    };

    if (errorMessage) {
        return <ErrorSectionContainer />;
    }
    const handleSendEmail = (envMoniker: string, details: IEmailDetails) => {
        sendEmailWithLink(customerId, details);
    };

    return customerDetails !== null ? (
        <div ref={containerRef} className={css.pageContainer}>
            <SendEmailContainer
                isOpened={isSendByEmailClicked}
                setIsOpened={setIsSendByEmailClicked}
                userId={activeCustomer.id}
                sendEmailWithLink={handleSendEmail}
                setEmailSent={setEmailSent}
            />
            <h1 className={css.pageTitle}>{t("customerDetails.pageTitle")}</h1>
            <CustomerNavigationContainer
                activeTab={activeTab}
                isDataLoading={isDataLoading}
                setActiveTab={setActiveTab}
            />
            {activeTab === CustomerSubpage.General ? (
                <GeneralDetailsContainer
                    containerRef={containerRef}
                    allConsentsPending={allConsentsPending}
                    handleFormSubmit={handleFormSubmit}
                    setIsSendByEmailClicked={setIsSendByEmailClicked}
                    setCustomerPartners={setCustomerPartners}
                    setIsDataLoading={setIsDataLoading}
                />
            ) : (
                <PackageDeliveryContainer
                    containerRef={containerRef}
                    originalDetails={originalDetails}
                    handleFormSubmit={handleFormSubmit}
                    setIsDataLoading={setIsDataLoading}
                />
            )}
        </div>
    ) : null;
};

export default CustomerDetails;
