import {
    IErpPackageDetails,
    SetEmailsSendingOpened,
    SetManagePackageDetails,
    SetManagePackageOpened,
    SET_EMAILS_SENDING_OPENED,
    SET_MANAGE_PACKAGE_DETAILS,
    SET_MANAGE_PACKAGE_OPENED,
} from "./erp-packages-types";

export const setManagePackageOpened = (
    isManagePackageOpened: boolean
): SetManagePackageOpened => ({
    type: SET_MANAGE_PACKAGE_OPENED,
    isManagePackageOpened: isManagePackageOpened,
});

export const setEmailsSendingOpened = (
    isEmailsSendingOpened: boolean
): SetEmailsSendingOpened => ({
    type: SET_EMAILS_SENDING_OPENED,
    isEmailsSendingOpened: isEmailsSendingOpened,
});

export const setManagePackageDetails = (
    packageDetails: IErpPackageDetails
): SetManagePackageDetails => ({
    type: SET_MANAGE_PACKAGE_DETAILS,
    packageDetails: packageDetails,
});
