import { RootState } from "redux/store";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    getEnrollCache,
    getEnrollId,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import { fetchEnrollCache } from "redux/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import SuccessMessage from "./SuccessMessage";
import {
    getActiveEnvironmentMoniker,
    getActiveEnvironmentName,
} from "redux/environments/environment-selectors";
import withLoader from "hocs/withLoader/withLoader";

const mapStateToProps = (state: RootState) => ({
    enrollCache: getEnrollCache(state),
    enrollId: getEnrollId(state),
    environmentName: getActiveEnvironmentName(state),
    environmentMoniker: getActiveEnvironmentMoniker(state),
});

const SuccessMessageContainer = compose(
    connect(mapStateToProps, { fetchEnrollCache }),
    withLoader
)(SuccessMessage);

export default SuccessMessageContainer;
