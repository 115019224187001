import { RootState } from "redux/store";
import { connect } from "react-redux";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import TenantFeatureProgressModal from "./TenantFeatureProgressModal";
import { getIsUserPartner } from "redux/app-global/app-global-selectors";

const mapStateToProps = (state: RootState) => ({
    environmentMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: state.tenantsReducer.activeTenant.moniker,
    isUserPartner: getIsUserPartner(state),
});
const TenantFeatureProgressModalContainer = connect(mapStateToProps)(
    TenantFeatureProgressModal
);

export default TenantFeatureProgressModalContainer;
