import {
    UPDATE_ENVIRONMENT_PING_INFO,
    ITenantPingInfo,
    UPDATE_TENANT_PING_INFO,
} from "./tenant-ping-types";
import { IEnvironment } from "../../environment-types";

export const updateEnvironmentPingInfo = (envList: IEnvironment[]) => ({
    type: UPDATE_ENVIRONMENT_PING_INFO,
    envList,
});
export const updateTenantPingInfo = (
    envMoniker: string,
    pingInfo: ITenantPingInfo
) => ({
    type: UPDATE_TENANT_PING_INFO,
    envMoniker,
    pingInfo,
});
