import { API } from "../../../utils/api-config";
import {
    CustomFieldInvoiceType,
    ICustomField,
    ICustomFieldsResponse,
    ICustomFieldsUpdateResponse,
    ILineType,
} from "../../../components/Environments/Tenants/LeftMenu/CustomFields/custom-fields-types";
import { defaultErrorResponse } from "../../defaults";

export const loadCustomFields = async (
    envMoniker: string,
    tenantMoniker: string
): Promise<[ICustomFieldsResponse | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateCustomFields = async (
    envMoniker: string,
    tenantMoniker: string,
    customFieldsList: ICustomField[]
): Promise<[ICustomFieldsUpdateResponse | null, number]> => {
    if (!envMoniker || !tenantMoniker || !customFieldsList) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields`,
        customFieldsList
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const deleteCustomFields = async (
    envMoniker: string,
    tenantMoniker: string
): Promise<[ICustomFieldsUpdateResponse | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.delete(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadTargetTables = async (
    envMoniker: string,
    tenantMoniker: string,
    invoiceType: CustomFieldInvoiceType
): Promise<[string[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields/target-tables/${invoiceType}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadTargetFields = async (
    envMoniker: string,
    tenantMoniker: string,
    targetTable: string
): Promise<[string[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields/target-fields/${targetTable}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadLineTypes = async (
    envMoniker: string,
    tenantMoniker: string
): Promise<[ILineType[] | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/custom-fields/line-types`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
