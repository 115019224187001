import Axios, { CancelTokenSource } from "axios";
import { API } from "../../../utils/api-config";
import { ITenantDopeSubscription } from "components/Environments/Tenants/LeftMenu/Subscriptions/tenant-dope-subscriptions-types";
import { defaultErrorResponse } from "api/defaults";

export const loadTenantDopeSubscriptions = async (
    environmentMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantDopeSubscription[] | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/subscriptions`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const saveDopeSubscriptions = async (
    environmentMoniker: string,
    tenantMoniker: string,
    subscriptions: ITenantDopeSubscription[]
): Promise<number> => {
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/subscriptions`,
        subscriptions
    );

    return response?.status;
};

export const checkFiles = async (
    environmentMoniker: string,
    tenantMoniker: string,
    subscription: ITenantDopeSubscription
): Promise<[boolean, number]> => {
    const response = await API.post(
        `environments/${environmentMoniker}/tenants/${tenantMoniker}/subscriptions:isConfigured`,
        subscription
    );

    return [response.data, response.status];
};
