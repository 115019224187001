import { getActiveEnvironmentMoniker } from "../../environment-selectors";
import { RootState } from "../../../store";
import { IEnvironmentPingInfo } from "./tenant-ping-types";
import { memoize } from "lodash";
import { createSelector } from "reselect";

export const getPingInfo = (state: RootState) => {
    return state.pingReducer.pingInfo;
};
export const getEnvPingInfo = memoize(
    (state: RootState, envMoniker: string) =>
        state.pingReducer.pingInfo.find(
            (pingInfo) => pingInfo.envMoniker === envMoniker
        )?.tenantListPingInfo
);
export const getTenantsPingInfoSelector = createSelector(
    [getEnvPingInfo],
    (envPingInfo) => envPingInfo || []
);
export const getPingInfoTenantList = (state: RootState) => {
    const pingInfo = getPingInfo(state);
    const envMoniker = getActiveEnvironmentMoniker(state);

    if (!envMoniker) {
        return [];
    }

    return (
        pingInfo.find(
            (ping: IEnvironmentPingInfo) => ping.envMoniker === envMoniker
        )?.tenantListPingInfo || []
    );
};
