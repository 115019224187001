export const SET_ENVIRONMENTS = "environments/SET_ENVIRONMENTS";
export const SET_APP_REGISTRATIONS = "environments/SET_APP_REGISTRATIONS";
export const SET_ACTIVE_ENVIRONMENT_BY_ID =
    "environments/SET_ACTIVE_ENVIRONMENT_BY_ID";
export const SET_ACTIVE_ENVIRONMENT_BY_NAME =
    "environments/SET_ACTIVE_ENVIRONMENT_BY_NAME";
export const SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS =
    "environments/SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS";
export const SET_APP_REGISTRATION_INFO_OPENED =
    "environments/SET_APP_REGISTRATION_INFO_OPENED";

export enum Area {
    Dooap,
    D365,
}
export interface IDooapAppRegistration {
    clientId: string;
    description: string;
    area: Area;
}
export interface IAppRegistrationSecretExpiration {
    id: string;
    name: string;
    expirationDate: string;
    azureLink: string;
}
export interface ITenantStatusInfo {
    id: string;
    moniker: string;
    status: number;
}
export interface IEnvironment {
    id: string;
    isEnrollAllowed: boolean;
    isDeleteAllowed: boolean;
    moniker: string;
    name: string;
    status: number;
    tenantsStatusInfo: ITenantStatusInfo[];
}
export interface ISetEnvironments {
    type: typeof SET_ENVIRONMENTS;
    environments: IEnvironment[];
}
export interface ISetAppRegistrations {
    type: typeof SET_APP_REGISTRATIONS;
    appRegistrations: IDooapAppRegistration[];
}
export interface ISetActiveEnvironmentById {
    type: typeof SET_ACTIVE_ENVIRONMENT_BY_ID;
    id: string;
}
export interface ISetActiveEnvironmentByName {
    name: string;
    type: typeof SET_ACTIVE_ENVIRONMENT_BY_NAME;
}
export interface ISetActiveEnvironmentTenantsMonikers {
    type: typeof SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS;
}
export interface ISetAppRegistrationInfoOpened {
    type: typeof SET_APP_REGISTRATION_INFO_OPENED;
    isOpened: boolean;
}
export interface State {
    appRegistrations: IDooapAppRegistration[];
    environments: IEnvironment[];
    activeEnvironment: IEnvironment;
    activeEnvironmentTenantsMonikers: string[];
    isAppRegistrationInfoOpened: boolean;
    isActiveEnvironmentSet: boolean;
}
export type Actions =
    | ISetEnvironments
    | ISetActiveEnvironmentById
    | ISetActiveEnvironmentByName
    | ISetActiveEnvironmentTenantsMonikers
    | ISetAppRegistrationInfoOpened
    | ISetAppRegistrations;
