export const SET_MANAGE_PACKAGE_OPENED = "packages/SET_MANAGE_PACKAGE_OPENED";
export const SET_MANAGE_PACKAGE_DETAILS = "packages/SET_MANAGE_PACKAGE_DETAILS";
export const SET_EMAILS_SENDING_OPENED = "packages/SET_EMAILS_SENDING_OPENED";

export const erpPackageDefault = {
    id: "",
    packageTypeId: "",
    packageTargetId: "",
    packageTarget: {
        id: "",
        target: "",
    },
    version: "",
    description: "",
    creationDate: new Date(),
    uatStartDate: null,
    goLiveDate: null,
    statusPageLink: null,
    releaseNotesLink: null,
    blobLink: "",
    expirationDate: null,
};

export enum PackageType {
    Release = "Release",
    Patch = "Patch",
}

export interface IErpPackageType {
    id: string;
    type: string;
}

export interface IErpPackageTarget {
    id: string;
    target: string;
}

export interface IHistoryEntry {
    sender: string | null;
    receiver: string;
    status: SendingStatus;
    timeStamp: string;
    failureReason: string | null;
}

export enum SendingStatus {
    NotSent = 0,
    Sent = 1,
    Failed = 2,
}

export interface IErpPackageCommon {
    id: string;
    packageTypeId: string;
    packageTargetId: string;
    packageTarget: IErpPackageTarget;
    version: string;
    description: string;
    statusPageLink: string | null;
    releaseNotesLink: string | null;
    blobLink: string;
}

export interface IManageErpPackage extends IErpPackageCommon {
    creationDate: Date;
    uatStartDate: Date | null;
    goLiveDate: Date | null;
    expirationDate: Date | null;
}

export interface IErpPackage extends IErpPackageCommon {
    packageType: IErpPackageType;
    creationDate: string;
    uatStartDate: string | null;
    goLiveDate: string | null;
    expirationDate: string;
    status: SendingStatus;
}

export enum WorkflowStatus {
    NotStarted = 0,
    Running = 1,
    Completed = 2,
}

export interface IEmailsSendingWorkflow {
    id: string;
    totalRecipientsCount: number;
    processedEmailsCount: number;
    status: WorkflowStatus;
    packageId: string;
}

export interface IEmailRecipient {
    id: string;
    name: string;
    templateId: string;
}

export interface IErpPackageEmailsSending {
    recipients: IEmailRecipient[];
    packageId: string;
}

export interface IErpPackageDetails {
    title: string;
    confirmButtonName: string;
    erpPackage: IManageErpPackage;
    apiMethod: (erpPackage: IManageErpPackage) => Promise<[null, number]>;
}

export interface SetManagePackageOpened {
    type: typeof SET_MANAGE_PACKAGE_OPENED;
    isManagePackageOpened: boolean;
}

export interface SetEmailsSendingOpened {
    type: typeof SET_EMAILS_SENDING_OPENED;
    isEmailsSendingOpened: boolean;
}

export interface SetManagePackageDetails {
    type: typeof SET_MANAGE_PACKAGE_DETAILS;
    packageDetails: IErpPackageDetails;
}

export interface State {
    isManagePackageOpened: boolean;
    isEmailsSendingOpened: boolean;
    packageDetails: IErpPackageDetails;
}

export type Actions =
    | SetManagePackageDetails
    | SetManagePackageOpened
    | SetEmailsSendingOpened;
