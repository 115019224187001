import {
    SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT,
    SET_ERROR_MESSAGE_INACTIVE_TENANT,
    SET_ERROR_RESPONSE_MESSAGE,
    SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL,
    SET_LOADER_VISIBILITY,
    SET_ERROR_ENROLL_STEP_MESSAGE,
    Actions,
    IState,
    SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE,
    SET_ERROR_TENANT_DELETE_MESSAGE,
} from "./initialization-types";

const initialState = {
    loadersCount: 0,
    errorResponseMessage: "",
    errorResponseMessageForModal: "",
    errorMessageInactiveEnvironment: "",
    errorMessageInactiveTenant: "",
    errorMessageEnrollStep: "",
    errorEnrollManualStepResponseMessage: "",
    errorTenantDeleteMessage: "",
};
const initializationReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_LOADER_VISIBILITY:
            return {
                ...state,
                loadersCount:
                    action.isInitialized || false
                        ? state.loadersCount + 1
                        : state.loadersCount - 1,
            };

        case SET_ERROR_RESPONSE_MESSAGE:
            return {
                ...state,
                errorResponseMessage: action.message || "",
            };

        case SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT:
            return {
                ...state,
                errorMessageInactiveEnvironment: action.message || "",
            };

        case SET_ERROR_MESSAGE_INACTIVE_TENANT:
            return {
                ...state,
                errorMessageInactiveTenant: action.message || "",
            };

        case SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL:
            return {
                ...state,
                errorResponseMessageForModal: action.message || "",
            };

        case SET_ERROR_ENROLL_STEP_MESSAGE:
            return {
                ...state,
                errorMessageEnrollStep: action.message || "",
            };

        case SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE:
            return {
                ...state,
                errorEnrollManualStepResponseMessage: action.message || "",
            };

        case SET_ERROR_TENANT_DELETE_MESSAGE:
            return {
                ...state,
                errorTenantDeleteMessage: action.message || "",
            };

        default:
            return state;
    }
};

export default initializationReducer;
