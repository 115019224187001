import { RootState } from "redux/store";
import { connect } from "react-redux";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import TenantFeatureProgressModalFooter from "./TenantFeatureProgressModalFooter";

const mapStateToProps = (state: RootState) => ({
    environmentMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: state.tenantsReducer.activeTenant.moniker,
});
const TenantFeatureProgressModalFooterContainer = connect(mapStateToProps)(
    TenantFeatureProgressModalFooter
);

export default TenantFeatureProgressModalFooterContainer;
