import Collapsible from "components/Common/Collapsible/Collapsible";
import React, { useState } from "react";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import {
    ISearchService,
    ISftpUserWithIpAddresses,
    ITenantFeatureAppConfigurations,
    ITenantFeatureConfigurations,
    PdfImageExtractorService,
    TenantFeatureGroup,
    TenantMailerService,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import {
    Button,
    Checkbox,
    ControlLabel,
    FormControl,
    FormGroup,
    HelpBlock,
    Icon,
    InputGroup,
    InputNumber,
    Message,
    SelectPicker,
} from "rsuite";
import TenantFeatureSettingsCheckbox from "../TenantFeatureSettingsCheckbox/TenantFeatureSettingsCheckbox";
import { useTranslation } from "react-i18next";
import { IConfluenceDocumentLinks } from "redux/information-links/information-links-types";
import cn from "classnames";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import SftpIpAddressesContainer from "../SftpIpAddresses/SftpIpAddressesContainer";
import "./FeatureGroups.less";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import { isTenantFeatureSettingWithTypeEnabled } from "utils/tenant-workflows/tenant-workflows-helper";

interface ComponentProps {
    features: ITenantFeatureConfigurations;
    featureAppConfigSettings: ITenantFeatureAppConfigurations;
    confluenceDocumentLinks?: IConfluenceDocumentLinks | null;
    customerType: CustomerType;
    featureSettingWorkFlows: ITenantFeatureSettingWorkFlow[] | null;
    disabledSettingNames: string[];
    allWorkflows: {
        workflowType: TenantFeatureSettingWorkFlowType;
        getFeature: (feature: ITenantFeatureConfigurations) => boolean;
        text: string;
        helpText: string;
        value: string;
        checked: boolean | undefined;
        isDisabled: boolean;
        isAvailableForAx: boolean;
        group: TenantFeatureGroup;
    }[];
    sftpUserWithIpAddresses: ISftpUserWithIpAddresses;
    areSftpAddressesValid: boolean;
    isModifySearchDisabled: boolean;
    searchServices: ISearchService[];
    linkedSearchService: string | null;
    searchServiceSelectionWorkflow: ITenantFeatureSettingWorkFlow | null;
    setSftpUserWithIpAddresses: (
        sftpUserWithIpAddresses: ISftpUserWithIpAddresses
    ) => void;
    setSftpAddressesValid: (isValid: boolean) => void;
    handleTenantFeatureChange: (
        feature: keyof ITenantFeatureConfigurations,
        value: string | number | boolean
    ) => void;
    handleTenantFeatureAppConfigSettingChange: (
        name: keyof ITenantFeatureAppConfigurations,
        value: boolean
    ) => void;
    handleUpdateClick: () => void;
    handleRecreateClick: () => void;
    setLinkedSearchService: (searchService: string) => void;
    setIsUnsavedChangeAvailable: (isChangesAvailible: boolean) => void;
    setDataFetched: (isFetched: false) => void;
}

const TenantFeatureGroupsMapping = [
    TenantFeatureGroup.automaticVendorAssigment,
    TenantFeatureGroup.mlWorkflow,
    TenantFeatureGroup.mlCompany,
    TenantFeatureGroup.mlCoding,
    TenantFeatureGroup.autoTtp,
    TenantFeatureGroup.poSettings,
    TenantFeatureGroup.vendor,
    TenantFeatureGroup.sftp,
    TenantFeatureGroup.ocr,
    TenantFeatureGroup.search,
    TenantFeatureGroup.other,
];

const FeatureGroups: React.FC<ComponentProps> = ({
    features,
    featureAppConfigSettings,
    confluenceDocumentLinks,
    customerType,
    featureSettingWorkFlows,
    disabledSettingNames,
    allWorkflows,
    sftpUserWithIpAddresses,
    areSftpAddressesValid,
    isModifySearchDisabled,
    searchServices,
    linkedSearchService,
    searchServiceSelectionWorkflow,
    setSftpUserWithIpAddresses,
    setSftpAddressesValid,
    handleTenantFeatureChange,
    handleTenantFeatureAppConfigSettingChange,
    handleUpdateClick,
    handleRecreateClick,
    setLinkedSearchService,
    setIsUnsavedChangeAvailable,
    setDataFetched,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [expandedStates, setExpandedStates] = useState<boolean[]>(
        new Array(TenantFeatureGroupsMapping.length).fill(false)
    );
    const allExpanded = expandedStates.every((state) => state);
    const buttonText = allExpanded
        ? t("tenantConfig.featureConfigurations.group.buttonCollapse")
        : t("tenantConfig.featureConfigurations.group.buttonExpand");
    const upperIcon = allExpanded ? "down" : "up";
    const lowerIcon = allExpanded ? "up" : "down";

    const isSearchSettingsDisabled = !(searchServiceSelectionWorkflow !== null
        ? !disabledSettingNames.includes(
              searchServiceSelectionWorkflow.featureName
          ) &&
          isTenantFeatureSettingWithTypeEnabled(
              featureSettingWorkFlows,
              TenantFeatureSettingWorkFlowType.SearchServiceSelection
          )
        : true);

    const featureFlagTypeToSettingMapping = [
        {
            name: t(
                "tenantConfig.featureConfigurations.updateInvoiceWithPoData.title"
            ),
            value: "updateInvoiceWithPoData",
            tooltip: t(
                "tenantConfig.featureConfigurations.updateInvoiceWithPoData.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.updateInvoiceWithPoData,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.poPurchStatusInvoicedFilteringEnabled.title"
            ),
            value: "poPurchStatusInvoicedFilteringEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.poPurchStatusInvoicedFilteringEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.poPurchStatusInvoicedFilteringEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.dbaDefaultApprovers.title"
            ),
            value: "dbaDefaultApprovers",
            tooltip: t(
                "tenantConfig.featureConfigurations.dbaDefaultApprovers.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.dbaDefaultApprovers,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t("tenantConfig.featureConfigurations.dueDateFromPO.title"),
            value: "dueDateFromPO",
            tooltip: t(
                "tenantConfig.featureConfigurations.dueDateFromPO.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.dueDateFromPO,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.fillMissingProjectDimensionsFromVendorDefaults.title"
            ),
            value: "fillMissingProjectDimensionsFromVendorDefaults",
            tooltip: t(
                "tenantConfig.featureConfigurations.fillMissingProjectDimensionsFromVendorDefaults.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.fillMissingProjectDimensionsFromVendorDefaults,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.poStatusFilteringEnabled.title"
            ),
            value: "poStatusFilteringEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.poStatusFilteringEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.poStatusFilteringEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.vendorSearchByAddress.title"
            ),
            value: "vendorSearchByAddress",
            tooltip: t(
                "tenantConfig.featureConfigurations.vendorSearchByAddress.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.vendorSearchByAddress,
            confluenceLink: null,
            group: TenantFeatureGroup.vendor,
        },
        {
            name: t("tenantConfig.featureConfigurations.autoTTPEnabled.title"),
            value: "autoTTPEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.autoTTPEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.autoTTPEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.autoTtp,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.remitToAddressEnabled.title"
            ),
            value: "remitToAddressEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.remitToAddressEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.remitToAddressEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.filterDimensions.title"
            ),
            value: "dimensionDataLiveFiltering",
            tooltip: t(
                "tenantConfig.featureConfigurations.filterDimensions.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.dimensionDataLiveFiltering,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.positionHierarchy.title"
            ),
            value: "positionHierarchyEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.positionHierarchy.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.positionHierarchyEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.currentPostingDate.title"
            ),
            value: "currentPostingDate",
            tooltip: t(
                "tenantConfig.featureConfigurations.currentPostingDate.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.currentPostingDate,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t("tenantConfig.featureConfigurations.poLinkEnabled.title"),
            value: "poLinkEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.poLinkEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.poLinkEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.waitForPrEnabled.title"
            ),
            value: "waitForPrEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.waitForPrEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.waitForPrEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.bankAccountComparisonLogicEnabled.title"
            ),
            value: "bankAccountComparisonLogicEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.bankAccountComparisonLogicEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.bankAccountComparisonLogicEnabled,
            confluenceLink: confluenceDocumentLinks?.bankAccountValidation,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.useImportedSigningLimits.title"
            ),
            value: "useImportedSigningLimits",
            tooltip: t(
                "tenantConfig.featureConfigurations.useImportedSigningLimits.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.useImportedSigningLimits,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.codingDeliveryAddressEnabled.title"
            ),
            value: "codingDeliveryAddressEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.codingDeliveryAddressEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.codingDeliveryAddressEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t("tenantConfig.featureConfigurations.ssrEnabled.title"),
            value: "ssrEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.ssrEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.ssrEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.recurringInvoicesEnabled.title"
            ),
            value: "recurringInvoicesEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.recurringInvoicesEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.recurringInvoicesEnabled,
            confluenceLink: confluenceDocumentLinks?.recurringInvoices,
            group: TenantFeatureGroup.other,
        },
        {
            name: t("tenantConfig.featureConfigurations.axTurboSwitch.title"),
            value: "axTurboSwitch",
            tooltip: t(
                "tenantConfig.featureConfigurations.axTurboSwitch.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.axTurboSwitch,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.companyPredictionEnabled.title"
            ),
            value: "companyPredictionEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.companyPredictionEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.companyPredictionEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.mlCompany,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.poChargesGroupEnabled.title"
            ),
            value: "poChargesGroupEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.poChargesGroupEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.poChargesGroupEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.vendor,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.vendorPredictionEnabled.title"
            ),
            value: "vendorPredictionEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.vendorPredictionEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.vendorPredictionEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.automaticVendorAssigment,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.searchVendorBySearchName.title"
            ),
            value: "searchVendorBySearchName",
            tooltip: t(
                "tenantConfig.featureConfigurations.searchVendorBySearchName.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.searchVendorBySearchName,
            confluenceLink: null,
            group: TenantFeatureGroup.vendor,
        },
        {
            name: t("tenantConfig.featureConfigurations.improvedCapture.title"),
            value: "improvedCapture",
            tooltip: t(
                "tenantConfig.featureConfigurations.improvedCapture.description"
            ),
            isAvailableForAx: true,
            isEnabled: featureAppConfigSettings?.improvedCapture,
            confluenceLink: null,
            changeHandler: handleTenantFeatureAppConfigSettingChange,
            group: TenantFeatureGroup.ocr,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.procurementLineEnabled.title"
            ),
            value: "procurementLineEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.procurementLineEnabled.description"
            ),
            isAvailableForAx: false,
            isEnabled: features?.procurementLineEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.poSettings,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.ocrErrorReportingEnabled.title"
            ),
            value: "ocrErrorReportingEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.ocrErrorReportingEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.ocrErrorReportingEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.ocr,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.contractsEnabled.title"
            ),
            value: "contractsEnabled",
            tooltip: t(
                "tenantConfig.featureConfigurations.contractsEnabled.description"
            ),
            isAvailableForAx: true,
            isEnabled: features?.contractsEnabled,
            confluenceLink: null,
            group: TenantFeatureGroup.other,
        },
    ];

    const featureInputTypeToSettingMapping = [
        {
            name: t(
                "tenantConfig.featureConfigurations.autoTTPMaxBatchSize.title"
            ),
            value: "autoTTPMaxBatchSize",
            helpText: t(
                "tenantConfig.featureConfigurations.autoTTPMaxBatchSize.description"
            ),
            numberValue: features?.autoTTPMaxBatchSize,
            changeHandler: handleTenantFeatureChange,
            group: TenantFeatureGroup.autoTtp,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.userSessionIdleTimeout.title"
            ),
            value: "userSessionIdleTimeoutInMinutes",
            helpText: t(
                "tenantConfig.featureConfigurations.userSessionIdleTimeout.description"
            ),
            numberValue: features?.userSessionIdleTimeoutInMinutes,
            changeHandler: handleTenantFeatureChange,
            group: TenantFeatureGroup.other,
            inputAddon: true,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.workflowPredictionImportMinScore.title"
            ),
            value: "workflowPredictionImportMinScore",
            helpText: t(
                "tenantConfig.featureConfigurations.workflowPredictionImportMinScore.description"
            ),
            numberValue: features?.workflowPredictionImportMinScore,
            changeHandler: handleTenantFeatureChange,
            group: TenantFeatureGroup.mlWorkflow,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.companyPredictionImportMinScore.title"
            ),
            value: "companyPredictionImportMinScore",
            helpText: t(
                "tenantConfig.featureConfigurations.companyPredictionImportMinScore.description"
            ),
            numberValue: features?.companyPredictionImportMinScore,
            changeHandler: handleTenantFeatureChange,
            group: TenantFeatureGroup.mlCompany,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.codingPredictionImportMinScore.title"
            ),
            value: "codingPredictionImportMinScore",
            helpText: t(
                "tenantConfig.featureConfigurations.codingPredictionImportMinScore.description"
            ),
            numberValue: features?.codingPredictionImportMinScore,
            changeHandler: handleTenantFeatureChange,
            group: TenantFeatureGroup.mlCoding,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.vendorPredictionVendorAddressMinScore.title"
            ),
            value: "vendorPredictionVendorAddressMinScore",
            helpText: t(
                "tenantConfig.featureConfigurations.vendorPredictionVendorAddressMinScore.description"
            ),
            numberValue: features?.vendorPredictionVendorAddressMinScore,
            group: TenantFeatureGroup.automaticVendorAssigment,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.vendorPredictionVendorNameMinScore.title"
            ),
            value: "vendorPredictionVendorNameMinScore",
            helpText: t(
                "tenantConfig.featureConfigurations.vendorPredictionVendorNameMinScore.description"
            ),
            numberValue: features?.vendorPredictionVendorNameMinScore,
            group: TenantFeatureGroup.automaticVendorAssigment,
            isAvailableForAx: true,
        },
    ];

    const pdfImageExtractorData = [
        {
            label: t(
                "tenantConfig.featureConfigurations.pdfImageExtractor.labels.aspose"
            ),
            value: PdfImageExtractorService.Aspose,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.pdfImageExtractor.labels.pdfium"
            ),
            value: PdfImageExtractorService.Pdfium,
        },
    ];

    const mailerServiceData = [
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.notSet"
            ),
            value: TenantMailerService.NotSet,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.smtpClient"
            ),
            value: TenantMailerService.SmtpClient,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.sendGrid"
            ),
            value: TenantMailerService.SendGrid,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.pepipost"
            ),
            value: TenantMailerService.Pepipost,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.postmark"
            ),
            value: TenantMailerService.Postmark,
        },
        {
            label: t(
                "tenantConfig.featureConfigurations.mailerService.labels.office365Relay"
            ),
            value: TenantMailerService.Office365Relay,
        },
    ];

    const getSearchServiceDropdownValues = (
        searchServices: ISearchService[]
    ) => {
        return searchServices.map((searchService) => {
            return {
                label: `${searchService.name} (${searchService.indexCounter?.usage}/${searchService.indexCounter?.quota})`,
                value: searchService.name,
            };
        });
    };

    const getDisabledItemValues = (searchServices: ISearchService[]) => {
        return searchServices
            .filter((s) => s.indexCounter?.quota === s.indexCounter?.usage)
            .map((s) => s.name);
    };

    const featureDropdownTypeToSettingMapping = [
        {
            name: t(
                "tenantConfig.featureConfigurations.pdfImageExtractor.title"
            ),
            value: "pdfImageExtractor" as keyof ITenantFeatureConfigurations,
            helpText: t(
                "tenantConfig.featureConfigurations.pdfImageExtractor.description"
            ),
            selectedValue: features?.pdfImageExtractor,
            changeHandler: handleTenantFeatureChange,
            data: pdfImageExtractorData,
            group: TenantFeatureGroup.other,
            isAvailableForAx: true,
        },
        {
            name: t("tenantConfig.featureConfigurations.mailerService.title"),
            value: "mailerService" as keyof ITenantFeatureConfigurations,
            helpText: t(
                "tenantConfig.featureConfigurations.mailerService.description"
            ),
            selectedValue: features?.mailerService,
            changeHandler: handleTenantFeatureChange,
            data: mailerServiceData,
            group: TenantFeatureGroup.other,
            isAvailableForAx: true,
        },
    ];

    const featureButtonTypeToSettingMapping = [
        {
            name: t(
                "tenantConfig.featureConfigurations.modifySearchIndex.updateButton"
            ),
            disabled: isModifySearchDisabled || isSearchSettingsDisabled,
            helpText: t(
                "tenantConfig.featureConfigurations.modifySearchIndex.updateTooltip"
            ),
            clickHandler: handleUpdateClick,
            group: TenantFeatureGroup.search,
            isAvailableForAx: true,
        },
        {
            name: t(
                "tenantConfig.featureConfigurations.modifySearchIndex.recreateButton"
            ),
            disabled: isModifySearchDisabled || isSearchSettingsDisabled,
            helpText: t(
                "tenantConfig.featureConfigurations.modifySearchIndex.recreateTooltip"
            ),
            clickHandler: handleRecreateClick,
            group: TenantFeatureGroup.search,
            isAvailableForAx: true,
        },
    ];

    const filterSettings = (settings: any[], group: TenantFeatureGroup) => {
        if (customerType === CustomerType.AX2012) {
            settings = settings.filter((setting) => setting.isAvailableForAx);
        }
        return settings.filter((setting) => setting.group === group);
    };

    const expandAllGroups = () => {
        setExpandedStates(
            expandedStates.map(() =>
                expandedStates.some((state: boolean) => !state)
            )
        );
    };

    const expandGroup = (index: number) => {
        setExpandedStates((states) =>
            states.map((state, i) => (i === index ? !state : state))
        );
    };
    const getDropdownFeature = (setting: any, index: number) => {
        return (
            <FormGroup key={index} className={cn("feature-groups__field")}>
                <ControlLabel className={"feature-groups__label"}>
                    {setting.name}
                    <HelpBlock
                        className={"general-configurations__help"}
                        tooltip
                    >
                        {setting.helpText}
                    </HelpBlock>
                </ControlLabel>
                <FormControl
                    accepter={SelectPicker}
                    data={setting.data}
                    value={setting.selectedValue}
                    searchable={false}
                    className={"feature-configurations__form-control"}
                    onChange={(value: number) => {
                        setting.changeHandler(setting.value, value);
                    }}
                    cleanable={false}
                />
            </FormGroup>
        );
    };

    const getInputFeature = (setting: any, index: number) => {
        return (
            <FormGroup key={index} className={cn("feature-groups__field")}>
                <ControlLabel className={"feature-groups__label"}>
                    {setting.name}
                    <HelpBlock
                        className={"general-configurations__help"}
                        tooltip
                    >
                        {setting.helpText}
                    </HelpBlock>
                </ControlLabel>
                {setting.inputAddon ? (
                    <InputGroup
                        className={"feature-configurations__form-input-group"}
                    >
                        <FormControl
                            accepter={InputNumber}
                            className={
                                "feature-configurations__form-control-group"
                            }
                            name={setting.value}
                            step={10}
                            value={setting.selectedValue}
                            onChange={(value: string | number) => {
                                handleTenantFeatureChange(setting.value, value);
                            }}
                        />
                        {setting.inputAddon && (
                            <InputGroup.Addon
                                className={
                                    "feature-configurations__input-group-addon"
                                }
                            >
                                <div>{"min"}</div>
                            </InputGroup.Addon>
                        )}
                    </InputGroup>
                ) : (
                    <FormControl
                        accepter={InputNumber}
                        className={"general-configurations__form-control"}
                        name={setting.value}
                        step={10}
                        value={setting.selectedValue}
                        onChange={(value: string | number) => {
                            handleTenantFeatureChange(setting.value, value);
                        }}
                    />
                )}
            </FormGroup>
        );
    };

    const getModifySearchIndexButtonFeature = (setting: any, index: number) => {
        return (
            <div
                className={
                    "feature-configurations__modify-search-index-section"
                }
                key={index}
            >
                <FormControl
                    accepter={Button}
                    className={cn(
                        "feature-configurations__general-btn",
                        "feature-configurations__modify-search-index-btn"
                    )}
                    onClick={setting.clickHandler}
                    disabled={setting.disabled}
                >
                    {setting.name}
                </FormControl>
                <HelpBlock className={"general-configurations__help"} tooltip>
                    {setting.helpText}
                </HelpBlock>
            </div>
        );
    };

    const getActiveSearchServiceName = () => {
        if (isSearchSettingsDisabled) {
            const workflowData = JSON.parse(
                searchServiceSelectionWorkflow?.data
            );
            return workflowData.name ?? linkedSearchService;
        }
        return linkedSearchService;
    };

    const handleTerminateRefresh = () => {
        setDataFetched(false);
    };

    const isSearchServiceAvailable = () => {
        return (
            searchServices
                .map((s) => s.indexCounter?.quota === s.indexCounter?.usage)
                .filter((s) => !s).length > 0
        );
    };

    const getSftpTableFeature = () => {
        return (
            <SftpIpAddressesContainer
                isSftpExpanded={expandedStates[TenantFeatureGroup.sftp]}
                isSftpEnabled={features?.sftpEnabled ?? false}
                sftpUserWithIpAddresses={sftpUserWithIpAddresses}
                areSftpAddressesValid={areSftpAddressesValid}
                disabledSettingNames={disabledSettingNames}
                enableSftpWorkflow={
                    featureSettingWorkFlows?.find(
                        (workflow) =>
                            workflow.type ===
                            TenantFeatureSettingWorkFlowType.EnableSftp
                    ) ?? null
                }
                setSftpAddressesValid={setSftpAddressesValid}
                setSftpUserWithIpAddresses={setSftpUserWithIpAddresses}
            />
        );
    };

    const getCheckboxFeature = (setting: any, index: number) => {
        return (
            <FormGroup
                key={index}
                className={cn(
                    "general-configurations__grid-item",
                    "feature-configurations__less-height"
                )}
            >
                <Checkbox
                    className={"feature-configurations__checkbox"}
                    value={setting.value}
                    checked={setting.isEnabled}
                    disabled={featureSettingWorkFlows === null}
                    onChange={
                        setting.changeHandler ?? handleTenantFeatureChange
                    }
                >
                    {setting.name}
                    {setting.confluenceLink && (
                        <a
                            className={
                                "feature-configurations__confluence-link-button"
                            }
                            href={setting.confluenceLink}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t(
                                "tenantConfig.featureConfigurations.confluenceTitle"
                            )}
                        </a>
                    )}
                    <HelpBlock
                        tooltip
                        className={"feature-configurations__help-block"}
                    >
                        {setting.tooltip}
                    </HelpBlock>
                </Checkbox>
            </FormGroup>
        );
    };

    const getSearchInstanceSection = () => {
        return (
            <>
                <FormGroup
                    className={cn(
                        "feature-groups__field",
                        "feature-groups__search-dropdown"
                    )}
                >
                    <ControlLabel className={"feature-groups__label"}>
                        {t(
                            `tenantConfig.featureConfigurations.search.aISearchInstances`
                        )}
                    </ControlLabel>
                    <FormControl
                        accepter={SelectPicker}
                        data={getSearchServiceDropdownValues(searchServices)}
                        value={getActiveSearchServiceName()}
                        placeholder={t(
                            `tenantConfig.featureConfigurations.search.aISearchInstancesPlaceholder`
                        )}
                        searchable={false}
                        disabledItemValues={getDisabledItemValues(
                            searchServices
                        )}
                        className={"feature-configurations__form-control"}
                        onChange={(value: string) => {
                            setIsUnsavedChangeAvailable(true);
                            setLinkedSearchService(value);
                        }}
                        cleanable={false}
                        disabled={isSearchSettingsDisabled}
                    />
                    {searchServiceSelectionWorkflow !== null && (
                        <ProcessModalButton
                            workflow={searchServiceSelectionWorkflow}
                            workflowType={WorkflowModalType.Feature}
                            triggerRefresh={handleTerminateRefresh}
                        />
                    )}
                </FormGroup>
                <div className={"feature-configurations__search-index-section"}>
                    {filterSettings(
                        featureButtonTypeToSettingMapping,
                        TenantFeatureGroup.search
                    ).map((setting, index) => {
                        return getModifySearchIndexButtonFeature(
                            setting,
                            index
                        );
                    })}
                </div>
                {!isSearchServiceAvailable() && (
                    <Message
                        className={"feature-groups__search-index-warrning"}
                        showIcon
                        type="warning"
                        description={t(
                            `tenantConfig.featureConfigurations.search.availableSearchServicesMissing`
                        )}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <Button
                className="feature-groups__button"
                appearance="ghost"
                onClick={expandAllGroups}
            >
                <span>{buttonText}</span>
                <div>
                    <Icon icon={upperIcon} />
                    <Icon icon={lowerIcon} />
                </div>
            </Button>
            <div className="feature-groups__setting-groups">
                {TenantFeatureGroupsMapping.map((group, index) => {
                    const title = (
                        <div className={"feature-groups__group-title"}>
                            {t(
                                `tenantConfig.featureConfigurations.group.${TenantFeatureGroup[group]}`
                            )}
                        </div>
                    );
                    return (
                        <Collapsible
                            key={TenantFeatureGroup[group]}
                            title={title}
                            open={expandedStates[index]}
                            className="feature-groups__setting-group"
                            handleClick={() => {
                                expandGroup(index);
                            }}
                        >
                            <div>
                                {filterSettings(
                                    featureDropdownTypeToSettingMapping,
                                    group
                                ).map((setting, index) => {
                                    return getDropdownFeature(setting, index);
                                })}
                            </div>
                            <div>
                                {filterSettings(
                                    featureInputTypeToSettingMapping,
                                    group
                                ).map((setting, index) => {
                                    return getInputFeature(setting, index);
                                })}
                            </div>
                            <div>
                                {group === TenantFeatureGroup.sftp &&
                                    getSftpTableFeature()}
                            </div>
                            <div>
                                {filterSettings(allWorkflows, group).map(
                                    (setting) => {
                                        return (
                                            <>
                                                <TenantFeatureSettingsCheckbox
                                                    tenantFeatureSettingWorkFlows={
                                                        featureSettingWorkFlows
                                                    }
                                                    tenantFeatureSettingWorkFlowType={
                                                        setting.workflowType
                                                    }
                                                    disabledSettingNames={
                                                        disabledSettingNames
                                                    }
                                                    disabled={
                                                        setting.isDisabled
                                                    }
                                                    buttonText={setting.text}
                                                    helpText={setting.helpText}
                                                    value={setting.value}
                                                    checked={setting.checked}
                                                    onCheckboxChange={
                                                        handleTenantFeatureChange
                                                    }
                                                    triggerRefresh={
                                                        handleTerminateRefresh
                                                    }
                                                />
                                            </>
                                        );
                                    }
                                )}
                            </div>
                            <div>
                                {filterSettings(
                                    featureFlagTypeToSettingMapping,
                                    group
                                ).map((setting, index) => {
                                    return getCheckboxFeature(setting, index);
                                })}
                            </div>
                            {features.searchEnabled &&
                                group === TenantFeatureGroup.search &&
                                getSearchInstanceSection()}
                        </Collapsible>
                    );
                })}
            </div>
        </>
    );
};

export default FeatureGroups;
