import React from "react";
import { Icon } from "rsuite";
import { NavLink } from "react-router-dom";
import deliverablesMenu from "./deliverables-menu-structure";
import cn from "classnames";
import css from "./DeliverablesMenu.module.css";

const DeliverablesMenu: React.FC = () => {
    return (
        <ul className={css.menu}>
            {deliverablesMenu.map((item, index: number) => {
                return (
                    <li key={String(index)}>
                        <NavLink
                            className={cn(css.item)}
                            to={item.path}
                            activeClassName={css.active}
                        >
                            <Icon icon="folder-open-o" className={css.icon} />
                            <span className={css.itemName}>{item.name}</span>
                        </NavLink>
                    </li>
                );
            })}
        </ul>
    );
};

export default DeliverablesMenu;
