import { useState } from "react";
import { union, remove } from "lodash";

const useDisabledFeatureSettingNames = (): [
    string[],
    (valuesToAdd: string[]) => void,
    (valueToRemove: string) => void
] => {
    const [disabledSettingNames, setDisabledSettingNames] = useState<string[]>(
        []
    );
    const addNames = (valuesToAdd: string[]) => {
        setDisabledSettingNames(union(disabledSettingNames, valuesToAdd));
    };
    const removeName = (valueToRemove: string) => {
        setDisabledSettingNames(
            remove(disabledSettingNames, (name) => name === valueToRemove)
        );
    };
    return [disabledSettingNames, addNames, removeName];
};

export default useDisabledFeatureSettingNames;
