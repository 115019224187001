import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useRefreshPage from "hooks/useRefreshPage";
import { IActivePartner, IPartner } from "redux/partners/partners-types";
import PartnerCard from "./PartnerCard/PartnerCard";
import AddNewPartnerContainer from "./AddNewPartner/AddNewPartnerContainer";
import { useHistory } from "react-router-dom";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import SearchBarPartnersContainer from "components/SearchBar/SearchBarPartnersContainer";
import "./Partners.less";

interface ComponentProps {
    isAddNewPartnerOpened: boolean;
    errorMessage: string;
    searchText: string;
    partners: IPartner[];
    fetchPartners: () => void;
    setLoaderVisibility: (visible: boolean) => void;
    setAddNewPartner: (visible: boolean) => void;
    setActivePartner: (partner: IActivePartner) => void;
}

const Partners: React.FC<ComponentProps> = ({
    isAddNewPartnerOpened,
    errorMessage,
    searchText,
    partners,
    fetchPartners,
    setAddNewPartner,
    setActivePartner,
}: ComponentProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const routeHistory = useHistory();
    const [filteredPartners, setFilteredPartners] = useState<IPartner[]>([]);
    useEffect(() => {
        fetchPartners();
    }, [fetchPartners]);

    const comparePartners = (p1: IPartner, p2: IPartner): number => {
        const aDisplayName = p1.name.toLocaleLowerCase() || "";
        const bDisplayName = p2.name.toLocaleLowerCase() || "";

        if (aDisplayName < bDisplayName) {
            return -1;
        }

        if (aDisplayName > bDisplayName) {
            return 1;
        }

        return 0;
    };

    const filterPartners = () => {
        const filteredPartners = searchText
            ? partners.filter(
                  (value) =>
                      value.name
                          .toLocaleLowerCase()
                          .indexOf(searchText.toLocaleLowerCase()) >= 0
              )
            : partners;
        filteredPartners.sort(comparePartners);
        setFilteredPartners(filteredPartners);
    };

    useEffect(() => {
        filterPartners();
    }, [partners, searchText]);

    useRefreshPage(() => {
        containerRef.current?.scrollTo(0, 0);
        fetchPartners();
    });

    const handleRouteToPartners = (event: any, partner: IPartner) => {
        event.preventDefault();
        setActivePartner({ name: partner.name, id: partner.id });
        routeHistory.push(`/partners/${partner.id}/details`);
    };

    const partnerCards = filteredPartners.map(
        (partner: IPartner, index: number) => (
            <div key={String(index)}>
                <PartnerCard
                    onRoteTo={(event) => handleRouteToPartners(event, partner)}
                    partner={partner}
                />
            </div>
        )
    );

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    return (
        <>
            <AddNewPartnerContainer
                isOpened={isAddNewPartnerOpened}
                setIsOpened={setAddNewPartner}
                existingPartners={partners}
                refreshPartners={fetchPartners}
            />
            <div className="partners__container">
                <div className="partners__header">
                    <h3 className="partners__title">
                        {t("partners.partnersTitle")}
                    </h3>

                    <SearchBarPartnersContainer />
                </div>
                <div ref={containerRef} className="cards-layout">
                    {partnerCards}
                </div>
            </div>
        </>
    );
};
export default Partners;
