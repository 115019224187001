import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import AppRouter from "./AppRouter";

const mapStateToProps = (state: RootState) => {
    return {
        isLoaderVisible: state.initializationReducer.loadersCount > 0,
    };
};
const AppRouterContainer = connect(mapStateToProps)(AppRouter);

export default AppRouterContainer;
