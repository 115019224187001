import React from "react";
import { Popover, Whisper } from "rsuite";
import "./PopoverCell.less";
import { useTranslation } from "react-i18next";

interface ComponentProps {
    dataKey: string;
    rowData: any;
    className?: string;
}

const PopoverCell: React.FC<ComponentProps> = ({
    dataKey,
    rowData,
    className,
}: ComponentProps) => {
    const { t } = useTranslation();

    return (
        <Whisper
            trigger="hover"
            placement={"topStart"}
            delayShow={300}
            enterable
            speaker={
                <Popover className={"popover-cell"}>
                    <span className={className}>{rowData[dataKey]}</span>
                </Popover>
            }
        >
            <div className={className}>{rowData[dataKey] ?? t(`unknown`)}</div>
        </Whisper>
    );
};

export default PopoverCell;
