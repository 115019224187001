import { connect } from "react-redux";
import { RootState } from "redux/store";
import EnvironmentHistory from "./EnvironmentHistory";
import { setEnvironmentHistoryOpened } from "redux/environments/environment-history/environment-history-actions";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        isOpened: state.environmentHistoryReducer.isOpened,
    };
};
const EnvironmentHistoryContainer = connect(mapStateToProps, {
    setActionHistoryOpened: setEnvironmentHistoryOpened,
})(EnvironmentHistory);

export default EnvironmentHistoryContainer;
