import constants from "utils/constants";
import i18n from "utils/i18n";

export interface IDeliverablesMenuItem {
    path: string;
    name: string;
}

const deliverablesMenu: IDeliverablesMenuItem[] = [
    {
        name: i18n.t("deliverables.packages.title"),
        path: constants.routePath.deliverablesPackages,
    },
    {
        name: i18n.t("deliverables.emailTemplates.title"),
        path: constants.routePath.deliverablesTemplates,
    },
];

export default deliverablesMenu;
