import { RootState } from "redux/store";
import { connect } from "react-redux";
import SearchBar from "./SearchBar";
import { setEnvironmentFilterSearch } from "redux/filters/filters-actions";
import { getActiveEnvironmentId } from "redux/environments/environment-selectors";
import { getActiveEnvironmentFilterSearchTextSelector } from "redux/filters/filters-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        activeEnvironmentId: getActiveEnvironmentId(state),
        searchText: getActiveEnvironmentFilterSearchTextSelector(state) || "",
    };
};
const SearchBarTenantsContainer = connect(mapStateToProps, {
    setSearchText: setEnvironmentFilterSearch,
})(SearchBar);

export default SearchBarTenantsContainer;
