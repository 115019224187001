export interface ISchedulerTime {
    id?: string;
    intervalInMinutes: number | null;
    intervalType: number;
    lastError: string | null;
    lastStarted: string;
    timeOfDay: string | null;
    weekdays: number;
}
export interface IScheduler {
    enabled: boolean;
    event: ScheduleEventType;
    id?: string;
    scheduleTimes: ISchedulerTime[];
    subtype: ScheduleEventSubtype;
}
export enum ScheduleEventType {
    Email = 0,
    AxImport = 1,
    VendorPrepaymentRegisterCheck = 2,
    InvoiceWaitingForPrCheck = 3,
    MachineLearningModelReTraining = 4,
    RecurringInvoices = 5,
}
export enum ScheduleEventSubtype {
    OpenInvoiceEmail = 0,
    OnHoldMonthlyInvoiceEmail = 1,
    OnHoldDueDateInvoiceEmail = 2,
}
export interface ScheduleEvent {
    type: ScheduleEventType;
    subtype: ScheduleEventSubtype;
}
export type TPromise = [IScheduler | null, number];
