import { addErpPackage } from "api/deliverables/erp-packages-api";
import {
    Actions,
    erpPackageDefault,
    SET_EMAILS_SENDING_OPENED,
    SET_MANAGE_PACKAGE_DETAILS,
    SET_MANAGE_PACKAGE_OPENED,
    State,
} from "./erp-packages-types";

const initialState = {
    isManagePackageOpened: false,
    isEmailsSendingOpened: false,
    packageDetails: {
        title: "",
        confirmButtonName: "",
        erpPackage: erpPackageDefault,
        apiMethod: addErpPackage,
    },
};

const packagesReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_MANAGE_PACKAGE_OPENED:
            return {
                ...state,
                isManagePackageOpened: action.isManagePackageOpened,
            };
        case SET_EMAILS_SENDING_OPENED:
            return {
                ...state,
                isEmailsSendingOpened: action.isEmailsSendingOpened,
            };
        case SET_MANAGE_PACKAGE_DETAILS:
            return {
                ...state,
                packageDetails: action.packageDetails,
            };

        default:
            return state;
    }
};

export default packagesReducer;
