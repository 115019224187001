import {
    ISetActiveTenantDeleteWorkflow,
    ISetIsDeleteFetched,
    SET_IS_DELETE_FETCHED,
    SET_TENANT_DELETE_WORKFLOW,
} from "./tenant-delete-types";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";

export const setTenantDeleteWorkflow = (
    workflow: ITenantDeleteWorkflow | null
): ISetActiveTenantDeleteWorkflow => ({
    type: SET_TENANT_DELETE_WORKFLOW,
    workflow,
});

export const setIsDeleteFetched = (
    isDeleteFetched: boolean
): ISetIsDeleteFetched => ({ type: SET_IS_DELETE_FETCHED, isDeleteFetched });
