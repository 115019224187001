import React, { useEffect, useRef } from "react";
import { Icon } from "rsuite";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomerCard from "./CustomerCard/CustomerCard";
import CustomerFiltersContainer from "./CustomerFilters/CustomerFiltersContainer";
import useRefreshPage from "hooks/useRefreshPage";
import SearchBarCustomersContainer from "../SearchBar/SearchBarCustomersContainer";
import { ICustomer } from "redux/customers/customers-types";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import AddNewCustomerContainer from "components/Customers/AddNewCustomer/AddNewCustomerContainer";
import "./Customers.less";
import { IAppSettings } from "redux/app-settings/app-settings-types";

interface ComponentProps {
    filteredCustomers: ICustomer[];
    errorMessage: string;
    appSettings: IAppSettings;
    fetchCustomers: () => void;
    setActiveCustomerById: (customerId: string) => void;
}
const Customers: React.FC<ComponentProps> = ({
    filteredCustomers,
    errorMessage,
    appSettings,
    fetchCustomers,
    setActiveCustomerById,
}: ComponentProps) => {
    const { t } = useTranslation();
    const routeHistory = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    useRefreshPage(() => {
        containerRef.current?.scrollTo(0, 0);
        fetchCustomers();
    });

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    const onRouteHandler = (event: any, activeCustomerId: string) => {
        event.preventDefault();

        setActiveCustomerById(activeCustomerId);

        routeHistory.push(`/customers/${activeCustomerId}/details`);
    };

    const customersCards = filteredCustomers.map(
        (customer: ICustomer, index: number) => (
            <div key={String(index)}>
                <CustomerCard
                    onRoteTo={(event) => onRouteHandler(event, customer.id)}
                    customer={customer}
                    isDeliverablesEnabled={appSettings.isDeliverablesEnabled}
                />
            </div>
        )
    );

    return (
        <>
            <AddNewCustomerContainer />
            <div className="customers__container">
                <div className="customers__header">
                    <h3 className="customers__title">
                        {t("customers.customersHeader")}
                    </h3>
                    <div className="customers__search-section">
                        <SearchBarCustomersContainer />
                        <Icon
                            icon="filter"
                            className="customers__filter-icon"
                        />
                        <CustomerFiltersContainer />
                    </div>
                </div>
                <div ref={containerRef} className="cards-layout">
                    {customersCards}
                </div>
            </div>
        </>
    );
};

export default Customers;
