import React from "react";
import { useTranslation } from "react-i18next";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import { template } from "lodash";
import parse from "html-react-parser";
import "../ProgressModal.less";

interface IComponentProps {
    workflow: ITenantDeleteWorkflow;
}

const TenantDeleteUsernameMessage: React.FC<IComponentProps> = ({
    workflow,
}: IComponentProps) => {
    const { t } = useTranslation();
    return workflow.userName ? (
        <div className="progress-modal__username-section">
            {parse(
                template(t("tenantDelete.progressModal.turnedByUser"))({
                    username: workflow.userName,
                })
            )}
        </div>
    ) : (
        <></>
    );
};

export default TenantDeleteUsernameMessage;
