import { IEnvironment } from "../environments/environment-types";

export const UPDATE_ENVIRONMENT_FILTERS = "filters/UPDATE_ENVIRONMENT_FILTERS";
export const SET_ENVIRONMENT_FILTER_STATUS =
    "filters/SET_ENVIRONMENT_FILTER_STATUS";
export const SET_ENVIRONMENT_FILTER_SEARCH =
    "filters/SET_ENVIRONMENT_FILTER_SEARCH";
export const SET_CUSTOMER_FILTERS_STATUS =
    "filters/SET_CUSTOMER_FILTERS_STATUS";
export const SET_CUSTOMER_FILTERS_SEARCH =
    "filters/SET_CUSTOMER_FILTERS_SEARCH";
export const SET_CUSTOMERS_FILTER_STATUS_CALCULATED =
    "filters/SET_CUSTOMERS_FILTER_STATUS_CALCULATED";
export const SET_PARTNER_FILTERS_SEARCH = "filters/SET_PARTNER_FILTERS_SEARCH";

export interface IEnvironmentFilter {
    id: string;
    name: string;
    status: number;
    search: string;
}
export interface ICustomerFilter {
    status: number;
    search: string;
    isStatusCalculated: boolean;
}
export interface IPartnerFilter {
    search: string;
}
export interface IUpdateEnvironmentFilters {
    type: typeof UPDATE_ENVIRONMENT_FILTERS;
    envList: IEnvironment[];
}
export interface ISetEnvironmentFilterStatus {
    type: typeof SET_ENVIRONMENT_FILTER_STATUS;
    id: string;
    status: number;
}
export interface ISetCustomerFilterStatus {
    type: typeof SET_CUSTOMER_FILTERS_STATUS;
    status: number;
}
export interface ISetEnvironmentFilterSearch {
    type: typeof SET_ENVIRONMENT_FILTER_SEARCH;
    id: string;
    search: string;
}
export interface ISetCustomerFilterSearch {
    type: typeof SET_CUSTOMER_FILTERS_SEARCH;
    search: string;
}
export interface ISetCustomersFilterStatusCalculated {
    type: typeof SET_CUSTOMERS_FILTER_STATUS_CALCULATED;
}
export interface ISetPartnerFilterSearch {
    type: typeof SET_PARTNER_FILTERS_SEARCH;
    search: string;
}
export interface State {
    environmentFilters: IEnvironmentFilter[];
    customerFilters: ICustomerFilter;
    partnerFilters: IPartnerFilter;
}
export type Action =
    | IUpdateEnvironmentFilters
    | ISetEnvironmentFilterStatus
    | ISetCustomerFilterStatus
    | ISetEnvironmentFilterSearch
    | ISetCustomersFilterStatusCalculated
    | ISetCustomerFilterSearch
    | ISetPartnerFilterSearch;
