import { connect } from "react-redux";
import DeleteConfirmModal from "./DeleteConfirmModal";

const mapStateToProps = () => {
    return {};
};
const DeleteConfirmModalContainer = connect(
    mapStateToProps,
    {}
)(DeleteConfirmModal);

export default DeleteConfirmModalContainer;
