import React, { useEffect, useState } from "react";
import "themes/components/custom-modal.less";

import ProgressModal from "components/WorkflowLog/ProgressModal";
import { Message } from "rsuite";
import CopyContentTableToClipBoard from "components/Common/CopyContentTableToClipBoard/CopyContentTableToClipBoard";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import constants from "utils/constants";
import { useTranslation } from "react-i18next";

import TenantFeatureProgressModalHeader from "../TenantFeatureProgressModalHeader";
import TenantFeatureProgressModalFooterContainer from "../TenantFeatureProgressModalFooter/TenantFeatureProgressModalFooterContainer";
import TenantFeatureProgressMessage from "../TenantFeatureProgressMessage";
import StepList from "components/WorkflowLog/StepList/StepList";

import css from "./TenantFeatureProgressModal.module.css";
import TenantFeatureUsernameMessage from "../TenantFeatureUsernameMessage";
import { isEveryWorkflowStepCompleted } from "utils/tenant-workflows/tenant-workflows-helper";
import { loadSftpUserCredentials } from "api/environments/tenants/tenant-sftp-settings-api";
import { ISftpAccount } from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";

interface IComponentProps {
    isModalOpened: boolean;
    environmentMoniker: string;
    tenantMoniker: string;
    workflow: ITenantFeatureSettingWorkFlow;
    isUserPartner: boolean;
    onClose?: () => void;
    triggerRefresh?: () => void;
}

const TenantFeatureProgressModal: React.FC<IComponentProps> = ({
    isModalOpened,
    environmentMoniker,
    tenantMoniker,
    workflow,
    isUserPartner,
    onClose,
    triggerRefresh,
}: IComponentProps) => {
    const { t } = useTranslation();

    const [sftpCredentials, setSftpCredentials] = useState<ISftpAccount | null>(
        null
    );
    const [isSftpCredentialsFetched, setIsSftpCredentialsFetched] = useState<
        boolean
    >(false);

    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

    const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState<
        boolean
    >(true);

    const isCopySftpCredentialsVisible =
        workflow.type === TenantFeatureSettingWorkFlowType.EnableSftp &&
        (workflow.status === WorkflowStatus.WaitForApprove ||
            (isEveryWorkflowStepCompleted(workflow.steps) && isUserPartner));

    const getClassNameForSteps = () => {
        switch (workflow?.status) {
            case WorkflowStatus.Terminated:
                return css.terminatedSteps;
            default:
                return "";
        }
    };

    const handlePostCopyAction = () => setIsContinueButtonDisabled(false);

    useEffect(() => {
        if (workflow.status !== WorkflowStatus.WaitForApprove) {
            setIsContinueButtonDisabled(true);
        }
    }, [workflow.status]);

    useEffect(() => {
        if (!isModalOpened) {
            setIsSftpCredentialsFetched(false);
        }
    }, [isModalOpened]);

    useEffect(() => {
        if (
            isModalOpened &&
            isCopySftpCredentialsVisible &&
            !isSftpCredentialsFetched
        ) {
            setIsSftpCredentialsFetched(false);
            setIsLoaderVisible(true);
            loadSftpUserCredentials(environmentMoniker, workflow.tenantId).then(
                ([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setSftpCredentials(payload);
                        setIsSftpCredentialsFetched(true);
                    }
                    setIsLoaderVisible(false);
                }
            );
        }
    }, [
        environmentMoniker,
        tenantMoniker,
        isModalOpened,
        isCopySftpCredentialsVisible,
        isSftpCredentialsFetched,
    ]);

    const tenantFeatureProgressModalProps = {
        header: (
            <TenantFeatureProgressModalHeader
                tenantFeatureSettingWorkFlow={workflow}
            />
        ),
        footer: (
            <TenantFeatureProgressModalFooterContainer
                isContinueButtonDisabled={isContinueButtonDisabled}
                tenantFeatureSettingWorkFlow={workflow}
                onClose={onClose}
                triggerRefresh={triggerRefresh}
            />
        ),
        userMessage: <TenantFeatureUsernameMessage workflow={workflow} />,
        completeMessage: <TenantFeatureProgressMessage workflow={workflow} />,
        stepList: (
            <>
                <StepList
                    workflowSteps={workflow?.steps}
                    className={getClassNameForSteps()}
                />
                {isCopySftpCredentialsVisible && isSftpCredentialsFetched && (
                    <Message
                        className={css.copyToClipBoardContainer}
                        showIcon
                        type="warning"
                        description={
                            <CopyContentTableToClipBoard
                                postCopyAction={handlePostCopyAction}
                                description={t(
                                    "tenantFeatureSetting.progressModal.sftpCredentialsWarningMessage"
                                )}
                                copyTitle={t(
                                    "tenantFeatureSetting.progressModal.warningMessageWithCredentials.copyToClipBoardTitle"
                                )}
                                entries={[
                                    {
                                        keyName: t(
                                            "tenantFeatureSetting.progressModal.warningMessageWithCredentials.hostTitle"
                                        ),
                                        value: sftpCredentials?.host,
                                    },
                                    {
                                        keyName: t(
                                            "tenantFeatureSetting.progressModal.warningMessageWithCredentials.portTitle"
                                        ),
                                        value: sftpCredentials?.port,
                                    },
                                    {
                                        keyName: t(
                                            "tenantFeatureSetting.progressModal.warningMessageWithCredentials.usernameTitle"
                                        ),
                                        value: sftpCredentials?.username,
                                    },
                                    {
                                        keyName: t(
                                            "tenantFeatureSetting.progressModal.warningMessageWithCredentials.passwordKeyTitle"
                                        ),
                                        value: sftpCredentials?.password,
                                        maskedUnicodeDecCharacter: String.fromCharCode(
                                            constants.passwordSymbolCode
                                        ),
                                    },
                                ]}
                            />
                        }
                    />
                )}
            </>
        ),
    };

    return (
        <ProgressModal
            isModalOpened={isModalOpened}
            isModalLoaderVisible={isLoaderVisible}
            onClose={onClose}
            {...tenantFeatureProgressModalProps}
        />
    );
};

export default TenantFeatureProgressModal;
