import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Message } from "rsuite";
import {
    IEnrollStep,
    StepStatus,
    StepType,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import SelectCustomerContainer from "./SelectCustomer/SelectCustomerContainer";
import IntegrationInformationContainer from "./IntegrationInformation/IntegrationInformationContainer";
import AutomatedStepContainer from "../AutomatedStep/AutomatedStepContainer";
import SetAdminContainer from "./SetAdmin/SetAdminContainer";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import constants from "utils/constants";
import styles from "./StepFrame.module.css";
import { useTranslation } from "react-i18next";
import useRefreshPage from "hooks/useRefreshPage";
import GuestTenantsStepContainer from "./GuestTenants/GuestTenantsStepContainer";
import DataImportControlContainer from "./DataImportControl/DataImportControlContainer";

interface IComponentProps {
    runningStepIndex: number;
    activeStep: IEnrollStep | null;
    errorMessage: string;
    errorEnrollManualStepResponseMessage: string;
    isActiveStepApproved: boolean;
    isActiveStepRetried: boolean;
    environmentMoniker: string | null;
    enrollId: string;
    setIsStepFormValid: Dispatch<SetStateAction<() => boolean>>;
    fetchEnrollWorkflow: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => void;
}
const StepFrame: React.FC<IComponentProps> = ({
    activeStep,
    errorMessage,
    errorEnrollManualStepResponseMessage,
    isActiveStepApproved,
    isActiveStepRetried,
    runningStepIndex,
    environmentMoniker,
    enrollId,
    setIsStepFormValid,
    fetchEnrollWorkflow,
}: IComponentProps) => {
    const { t } = useTranslation();
    const activeStepIndex = activeStep?.order || 0;

    const isStepAutomated = activeStep?.type === StepType.Automated;
    const isStepFailed = activeStep?.status === StepStatus.Failed;
    const isStepSkipped = activeStep?.status === StepStatus.Skipped;
    const isActiveStepErrorMessageVisible =
        errorMessage.length > 0 && isStepFailed;
    const isManualStepErrorResponseMessageVisible =
        errorEnrollManualStepResponseMessage.length > 0 &&
        !isStepFailed &&
        activeStepIndex === runningStepIndex;

    const [isInfoMessageShown, setIsInfoMessageShown] =
        React.useState<boolean>(false);

    useRefreshPage(() => {
        if (environmentMoniker && enrollId) {
            fetchEnrollWorkflow(environmentMoniker, enrollId);
        }
    });

    useEffect(() => {
        isActiveStepRetried
            ? setIsInfoMessageShown(true)
            : setIsInfoMessageShown(false);
    }, [isActiveStepRetried]);

    const activeStepContainer = (stepIndex: number) => {
        switch (stepIndex) {
            case constants.enroll.STEP_1:
                return (
                    <SelectCustomerContainer
                        setIsStepFormValid={setIsStepFormValid}
                    />
                );
            case constants.enroll.STEP_2:
                return <GuestTenantsStepContainer />;
            case constants.enroll.STEP_3:
                return (
                    <IntegrationInformationContainer
                        setIsStepFormValid={setIsStepFormValid}
                    />
                );
            case constants.enroll.STEP_4:
            case constants.enroll.STEP_5:
            case constants.enroll.STEP_7:
            case constants.enroll.STEP_8:
            case constants.enroll.STEP_9:
            case constants.enroll.STEP_10:
            case constants.enroll.STEP_11:
                return <AutomatedStepContainer />;

            case constants.enroll.STEP_12:
                return <DataImportControlContainer />;

            case constants.enroll.STEP_6:
                return (
                    <SetAdminContainer
                        setIsStepFormValid={setIsStepFormValid}
                    />
                );

            default:
                return null;
        }
    };

    const getFrameBody = () => {
        if (!activeStep) {
            return (
                <Message
                    showIcon
                    type="info"
                    title={t("error.possibleFix")}
                    description={t(
                        "enrollTenant.stepFrame.dataRetrieveMessage"
                    )}
                />
            );
        } else {
            if (isManualStepErrorResponseMessageVisible) {
                return <ErrorSectionContainer isTitleVisible={false} />;
            } else if (!isActiveStepErrorMessageVisible || isStepSkipped) {
                if (
                    isActiveStepApproved &&
                    runningStepIndex >= 0 &&
                    runningStepIndex === activeStepIndex
                ) {
                    return (
                        <Message
                            showIcon
                            type="info"
                            title={t("error.possibleFix")}
                            description={t(
                                "enrollTenant.stepFrame.stepIsCompletedMessage"
                            )}
                        />
                    );
                } else {
                    return activeStepContainer(activeStepIndex);
                }
            } else if (isInfoMessageShown) {
                return (
                    <Message
                        showIcon
                        type="info"
                        title={t("error.possibleFix")}
                        description={t(
                            "enrollTenant.stepFrame.retryStepPendingMessage"
                        )}
                    />
                );
            } else {
                return isStepAutomated ? (
                    <>{activeStepContainer(activeStepIndex)}</>
                ) : (
                    <ErrorSectionContainer
                        isTitleVisible={false}
                        helpMessage={t("error.retryFix")}
                    />
                );
            }
        }
    };

    return <div className={styles.currentStep}>{getFrameBody()}</div>;
};

export default StepFrame;
