import ModalContainer from "components/Common/Modal/ModalContainer";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    ButtonToolbar,
    Checkbox,
    CheckPicker,
    Icon,
    Input,
    InputGroup,
    IconButton,
    TagGroup,
    Tag,
    List,
} from "rsuite";
import {
    IErpPackageEmailsSending,
    IEmailRecipient,
    IErpPackage,
} from "redux/deliverables/erp-packages/erp-packages-types";
import { sendErpPackageEmails } from "api/deliverables/erp-packages-api";
import { loadCustomersDeliverablesInfo } from "api/customers/customers-api";
import { ICustomerDeliverablesInfo } from "redux/customers/customers-types";
import { IEmailTemplateBase } from "redux/deliverables/email-templates/email-templates-types";
import { getErrorMessage } from "api/defaults";
import constants from "utils/constants";
import cn from "classnames";
import "./EmailsSending.less";
import {
    EmailRecipientType,
    IEmailRecipient as ICustomerEmailRecipient,
} from "redux/customers/customer-details/customer-details-types";

enum SortBy {
    CustomerName,
    RecipientCount,
}

enum SortOrder {
    Asc = "asc",
    Desc = "desc",
}

interface ComponentProps {
    isOpened: boolean;
    isModalBusy: boolean;
    erpPackage: IErpPackage | null;
    refreshPackages: () => void;
    setEmailsSendingOpened: (isOpened: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
    setErrorResponseMessageForModal: (message: string) => void;
}

const EmailsSending: React.FC<ComponentProps> = ({
    isOpened,
    isModalBusy,
    erpPackage,
    refreshPackages,
    setEmailsSendingOpened,
    setModalLoaderVisibility,
    setErrorResponseMessageForModal,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [selectedEmailTemplates, setSelectedEmailTemplates] = useState<
        IEmailTemplateBase[]
    >([]);
    const [emailTemplates, setEmailTemplates] = useState<Record<string, any>[]>(
        []
    );
    const [customers, setCustomers] = useState<ICustomerDeliverablesInfo[]>([]);
    const [allCustomers, setAllCustomers] = useState<
        ICustomerDeliverablesInfo[]
    >([]);
    const [checkedEnvs, setCheckedEnvs] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isAllChecked, setAllChecked] = useState<boolean>(false);
    const [checkedCustomers, setCheckedCustomers] = useState<IEmailRecipient[]>(
        []
    );
    const [emailSendingData, setEmailSendingData] =
        useState<IErpPackageEmailsSending | null>(null);
    const [expandedRowKey, setExpandedRowKey] = useState<string>("");

    const [sortBy, setSortBy] = useState<SortBy>(SortBy.CustomerName);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

    const fetchData = () => {
        setModalLoaderVisibility(true);
        loadCustomersDeliverablesInfo().then(([payload, status]) => {
            setModalLoaderVisibility(false);
            setIsDataFetched(true);
            if (payload && status === constants.statusCode.OK) {
                setAllCustomers(payload);
                setEmailTemplates(
                    Array.from(
                        new Map(
                            payload
                                .map((customer) => customer.emailTemplate)
                                .filter((template) => template !== null)
                                .map((template) => [template?.id, template])
                        ).values()
                    ).map(
                        (template) =>
                            ({
                                value: {
                                    id: template?.id,
                                    name: template?.name,
                                    locale: template?.locale,
                                },
                                label: template?.name,
                            } || [])
                    )
                );
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                setErrorResponseMessageForModal(errorMessage);
            }
        });
    };

    const handleCheckboxCheck = (value: ICustomerDeliverablesInfo) => {
        if (isCustomerChecked(value.id)) {
            setCheckedCustomers(
                checkedCustomers.filter((customer) => customer.id !== value.id)
            );
        } else if (value.emailTemplate !== null) {
            setCheckedCustomers([
                ...checkedCustomers,
                {
                    id: value.id,
                    name: value.name,
                    templateId: value.emailTemplate.id,
                },
            ]);
        }
    };

    const handleModalClose = () => {
        setEmailTemplates([]);
        setAllChecked(false);
        setSearchValue("");
        setCheckedEnvs([]);
        setAllChecked(false);
        setCheckedCustomers([]);
        setEmailSendingData(null);
        setIsDataFetched(false);
        setEmailsSendingOpened(false);
        setSelectedEmailTemplates([]);
    };

    const handleSendPackage = () => {
        if (erpPackage && emailSendingData) {
            setModalLoaderVisibility(true);
            sendErpPackageEmails(erpPackage?.id, emailSendingData).then(
                ([result, status]) => {
                    if (status === constants.statusCode.OK) {
                        handleModalClose();
                        refreshPackages();
                    } else {
                        Alert.error(getErrorMessage(String(result), status));
                    }
                    setModalLoaderVisibility(false);
                }
            );
        }
    };

    const handleRowExpand = (rowData: ICustomerDeliverablesInfo) => {
        let open = false;
        if (expandedRowKey === rowData.id) {
            open = true;
        }
        open ? setExpandedRowKey("") : setExpandedRowKey(rowData.id);
    };

    const getGroupedRecipients = (recipients: ICustomerEmailRecipient[]) => {
        const recipientGroups: any = {};
        recipients.forEach((recipient: ICustomerEmailRecipient) => {
            if (!recipientGroups[recipient.type]) {
                recipientGroups[recipient.type] = [];
            }
            recipientGroups[recipient.type].push(recipient);
        });
        return recipientGroups;
    };

    const isCustomerChecked = (customerId: string) => {
        return checkedCustomers.some((c) => c.id === customerId);
    };

    useEffect(() => {
        setCheckedCustomers(
            isAllChecked
                ? customers.map((customer) => ({
                      id: customer.id,
                      name: customer.name,
                      templateId: customer.emailTemplate!.id ?? null,
                  }))
                : []
        );
    }, [isAllChecked]);

    useEffect(() => {
        if (
            customers.length > 0 &&
            customers.length === checkedCustomers.length
        ) {
            setAllChecked(true);
        } else if (checkedCustomers.length === 0) {
            setAllChecked(false);
        }
    }, [checkedCustomers]);

    useEffect(() => {
        if (
            checkedCustomers.length &&
            selectedEmailTemplates &&
            erpPackage?.id
        ) {
            setEmailSendingData({
                ...emailSendingData,
                packageId: erpPackage.id,
                recipients: checkedCustomers,
            });
        }
    }, [checkedCustomers, selectedEmailTemplates, erpPackage]);

    useEffect(() => {
        if (!isDataFetched && isOpened) {
            fetchData();
        }
    }, [isDataFetched, isOpened]);

    useEffect(() => {
        const sortedCustomers = [...allCustomers]
            .filter(
                (c) =>
                    c.emailTemplate !== null &&
                    selectedEmailTemplates
                        .map((template) => template.id)
                        .includes(c.emailTemplate.id) &&
                    (!checkedEnvs.length ||
                        c.dooapEnvironments.some((env) =>
                            checkedEnvs.includes(env)
                        )) &&
                    c.name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .sort((a, b) => {
                const orderMultiplier = sortOrder === SortOrder.Asc ? 1 : -1;
                if (sortBy === SortBy.CustomerName) {
                    return orderMultiplier * a.name.localeCompare(b.name);
                } else if (sortBy === SortBy.RecipientCount) {
                    return (
                        orderMultiplier *
                        (a.emailRecipients.length - b.emailRecipients.length)
                    );
                }
                return 0;
            });

        setCustomers(sortedCustomers);
    }, [
        selectedEmailTemplates,
        searchValue,
        checkedEnvs,
        allCustomers,
        sortBy,
        sortOrder,
    ]);

    const renderFilterMenuItem = (label: ReactNode) => {
        return <div className="emails-sending__filter-item">{label}</div>;
    };

    const header = <h1>{t("deliverables.packages.sendModal.title")}</h1>;
    const footer = (
        <ButtonToolbar
            className={cn(
                "custom-modal__button-toolbar",
                "emails-sending__button-toolbar"
            )}
        >
            <Button
                type="submit"
                appearance="primary"
                onClick={handleSendPackage}
                disabled={!checkedCustomers.length || isModalBusy}
            >
                {t("deliverables.packages.sendModal.confirmBtn")}
            </Button>
            <Button
                appearance="ghost"
                onClick={handleModalClose}
                disabled={isModalBusy}
            >
                {t("deliverables.packages.sendModal.cancelBtn")}
            </Button>
        </ButtonToolbar>
    );

    return (
        <ModalContainer
            className="custom-modal__container"
            backdrop="static"
            show={isOpened}
            onHide={handleModalClose}
            header={header}
            footer={footer}
        >
            <div>
                <div className="emails-sending__details-section">
                    <div className="emails-sending__details-section-type">
                        {erpPackage?.packageType.type}
                    </div>
                    <div>{erpPackage?.description}</div>
                </div>
                <div className="emails-sending__target-section">
                    {t("deliverables.packages.sendModal.targetTitle") +
                        ": " +
                        erpPackage?.packageTarget.target}
                </div>
                <div className="emails-sending__form-section">
                    <p className="emails-sending__field-label">
                        {t("deliverables.packages.sendModal.templateTitle")}
                    </p>
                    <CheckPicker
                        placeholder={t(
                            "deliverables.packages.sendModal.templatePlaceholder"
                        )}
                        placement={"bottom"}
                        searchable={false}
                        data={emailTemplates}
                        values={selectedEmailTemplates}
                        onChange={(values: IEmailTemplateBase[]) => {
                            setSelectedEmailTemplates(values);
                            setExpandedRowKey("");
                        }}
                        className="emails-sending__select-picker"
                    />
                    <div className="emails-sending__filter-section">
                        <InputGroup className="emails-sending__search-section">
                            <InputGroup.Addon>
                                <Icon icon="search" />
                            </InputGroup.Addon>
                            <Input
                                value={searchValue}
                                onChange={setSearchValue}
                            />
                        </InputGroup>
                        <Icon
                            icon="filter"
                            className="customers__filter-icon"
                        />
                        <CheckPicker
                            placement={"bottom"}
                            searchable={false}
                            data={Array.from(
                                new Set(
                                    allCustomers
                                        .map(
                                            (customer) =>
                                                customer.dooapEnvironments
                                        )
                                        .flat()
                                )
                            ).map((env) => ({
                                value: env,
                                label: env,
                            }))}
                            value={checkedEnvs}
                            onChange={setCheckedEnvs}
                            renderMenuItem={renderFilterMenuItem}
                            className="emails-sending__check-picker"
                        />
                    </div>
                    <div>
                        <div className="emails-sending__customers-header">
                            <Checkbox
                                checked={isAllChecked}
                                onChange={() => {
                                    setAllChecked(!isAllChecked);
                                }}
                                disabled={!emailTemplates || !customers.length}
                            />
                            <div
                                onClick={() => {
                                    setSortBy(SortBy.CustomerName);
                                    setSortOrder(
                                        sortOrder === SortOrder.Asc
                                            ? SortOrder.Desc
                                            : SortOrder.Asc
                                    );
                                }}
                            >
                                {t(
                                    "deliverables.packages.sendModal.customersTitle"
                                )}
                                <Icon
                                    icon={
                                        sortBy === SortBy.CustomerName
                                            ? sortOrder === SortOrder.Asc
                                                ? "sort-up"
                                                : "sort-desc"
                                            : "sort"
                                    }
                                ></Icon>
                            </div>
                            <div
                                onClick={() => {
                                    setSortBy(SortBy.RecipientCount);
                                    setSortOrder(
                                        sortOrder === SortOrder.Asc
                                            ? SortOrder.Desc
                                            : SortOrder.Asc
                                    );
                                }}
                            >
                                {t(
                                    "deliverables.packages.sendModal.recipientCountTitle"
                                )}
                                <Icon
                                    icon={
                                        sortBy === SortBy.RecipientCount
                                            ? sortOrder === SortOrder.Asc
                                                ? "sort-up"
                                                : "sort-desc"
                                            : "sort"
                                    }
                                ></Icon>
                            </div>
                        </div>
                        {selectedEmailTemplates.length !== 0 ? (
                            <List
                                size="sm"
                                className="emails-sending__customers-list"
                            >
                                {customers.map((customer, index) => {
                                    return (
                                        <>
                                            <List.Item
                                                key={index}
                                                index={index}
                                            >
                                                <Checkbox
                                                    checked={isCustomerChecked(
                                                        customer.id
                                                    )}
                                                    onChange={() => {
                                                        handleCheckboxCheck(
                                                            customer
                                                        );
                                                    }}
                                                >
                                                    {customer.name}
                                                </Checkbox>
                                                <div
                                                    className={
                                                        customer.emailRecipients
                                                            .length
                                                            ? "emails-sending__customers-list-text--normal"
                                                            : isCustomerChecked(
                                                                  customer.id
                                                              )
                                                            ? "emails-sending__customers-list-text--error"
                                                            : "emails-sending__customers-list-text--warning"
                                                    }
                                                >
                                                    <span>
                                                        {customer
                                                            .emailRecipients
                                                            .length +
                                                            t(
                                                                "deliverables.packages.sendModal.recipientsTitle"
                                                            )}
                                                    </span>
                                                    <IconButton
                                                        size="xs"
                                                        appearance="subtle"
                                                        onClick={() => {
                                                            handleRowExpand(
                                                                customer
                                                            );
                                                        }}
                                                        disabled={
                                                            !customer
                                                                .emailRecipients
                                                                .length
                                                        }
                                                        icon={
                                                            <Icon
                                                                icon={
                                                                    expandedRowKey ===
                                                                    customer.id
                                                                        ? "arrow-up"
                                                                        : "arrow-down"
                                                                }
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </List.Item>
                                            {expandedRowKey === customer.id && (
                                                <div className="emails-sending__customers-list--expanded">
                                                    {Object.keys(
                                                        getGroupedRecipients(
                                                            customer.emailRecipients
                                                        )
                                                    ).map((type) => (
                                                        <div
                                                            key={type}
                                                            className="emails-sending__customers-recipients"
                                                        >
                                                            <span>
                                                                {Number(
                                                                    type
                                                                ) ===
                                                                EmailRecipientType.Main
                                                                    ? t(
                                                                          "customerDetails.packageDelivery.mainRecipientTitle"
                                                                      )
                                                                    : t(
                                                                          "customerDetails.packageDelivery.copyRecipientTitle"
                                                                      )}
                                                            </span>
                                                            <TagGroup>
                                                                {getGroupedRecipients(
                                                                    customer.emailRecipients
                                                                )[type].map(
                                                                    (
                                                                        recipient: ICustomerEmailRecipient,
                                                                        index: number
                                                                    ) => (
                                                                        <Tag
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                recipient.address
                                                                            }
                                                                        </Tag>
                                                                    )
                                                                )}
                                                            </TagGroup>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </List>
                        ) : (
                            <div className="emails-sending__customers-empty">
                                {t(
                                    "deliverables.packages.sendModal.emptyTableMessage"
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default EmailsSending;
