import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import {
    IAxImport,
    IAxImportEntity,
    IMessage,
    MessageType,
} from "redux/environments/tenants/data-import/data-import-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useRefreshPage from "hooks/useRefreshPage";
import useInactiveTenant from "hooks/useInactiveTenant";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import { Alert, Button, Message } from "rsuite";
import cn from "classnames";
import css from "./DataImport.module.css";
import constants from "utils/constants";
import {
    ITenantFeatureSettingWorkFlow,
    ChangeEntityFrameworkSettingToggleValue,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    updateTenantSettingWorkFlow,
    isEverySettingWorkFlowUpdated,
    isEveryWorkflowStepCompleted,
    compareEntities,
} from "utils/tenant-workflows/tenant-workflows-helper";
import {
    axImportNameToWorkflowTypeMapping,
    tenantFeatureSettingDefinitions,
} from "utils/tenant-workflows/tenant-workflows-definitions";
import { useParams } from "react-router-dom";
import SignalRTenantFeatureSetting from "components/WorkflowLog/TenantFeature/SignalRTenantFeatureSetting";
import { cloneDeep, template } from "lodash";
import usePreviousState from "hooks/usePreviousState";
import useDisabledFeatureSettingNames from "hooks/useDisabledFeatureSettingNames";
import { TypeAttributes } from "rsuite/lib/@types/common";
import {
    runAllCefEntities,
    runAllAxImports,
} from "api/environments/tenants/tenant-ax-import-entities-api";
import { getErrorMessage } from "api/defaults";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import AxImportEntitiesTable from "../../AxImportEntitiesTable/AxImportEntitiesTable";
import Axios, { CancelTokenSource } from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    customerType: CustomerType;
    isPartner: boolean;
    errorMessage: string;
    isRunAllEnabled: boolean;
    showLoader: (isVisible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    fetchTenantAxImport: (
        environmentMoniker: string,
        tenantId: string,
        cancelTokenSource?: CancelTokenSource
    ) => Promise<IAxImport | null>;
    fetchTenantChangeEntityFrameworkSettingWorkflows: (
        environmentMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<ITenantFeatureSettingWorkFlow[] | null>;
    updateTenantAxImportEntities: (
        envMoniker: string,
        tenantMoniker: string,
        axImportEntities: IAxImportEntity[]
    ) => Promise<void>;
    updateTenantChangeEntityFrameworkSettings: (
        envMoniker: string,
        tenantMoniker: string,
        settings: ChangeEntityFrameworkSettingToggleValue[]
    ) => Promise<void>;
    transferCefData: (
        envMoniker: string,
        tenantMoniker: string,
        entityName: string
    ) => Promise<void>;
}

const DataImport: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    customerType,
    isPartner,
    errorMessage,
    isRunAllEnabled,
    showLoader,
    setIsUnsavedChangeAvailable,
    fetchTenantAxImport,
    fetchTenantChangeEntityFrameworkSettingWorkflows,
    updateTenantAxImportEntities,
    updateTenantChangeEntityFrameworkSettings,
    transferCefData,
}: ComponentProps) => {
    const params: Record<string, string> = useParams();
    const { tenantId } = params;
    const [axImportEntities, setAxImportEntities] = useState<IAxImportEntity[]>(
        []
    );
    const [originalAxImportEntities, setOriginalAxImportEntities] = useState<
        IAxImportEntity[]
    >([]);
    const [axImportMessage, setAxImportMessage] = useState<IMessage | null>(
        null
    );
    const [isDataTransferAllowed, setIsDataTransferAllowed] =
        useState<boolean>(false);
    const [cefOriginalEntities, setCefOriginalEntities] = useState<
        IAxImportEntity[]
    >([]);
    const [isAxImportEntitiesDataLoaded, setIsAxImportEntitiesDataLoaded] =
        useState<boolean>(false);
    const [previousSettingWorkFlows, settingWorkFlows, setSettingWorkFlows] =
        usePreviousState<ITenantFeatureSettingWorkFlow[]>([]);

    const [isSettingWorkFlowsDataLoaded, setSettingWorkFlowsDataLoaded] =
        useState<boolean>(false);

    const [isRunAllAxImportsInProgress, setIsRunAllAxImportsInProgress] =
        useState<boolean>(false);

    const [isRunAllCefEntitiesInProgress, setIsRunAllCefEntitiesInProgress] =
        useState<boolean>(false);

    const [
        disabledSettingNames,
        addDisabledSettingNames,
        removeDisabledSettingName,
    ] = useDisabledFeatureSettingNames();
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();

    useEffect(() => {
        if (
            isEverySettingWorkFlowUpdated(
                previousSettingWorkFlows,
                settingWorkFlows
            )
        ) {
            Alert.success(
                t("tenantConfig.common.featureSettingsUpdateAlertMessage"),
                constants.alertDurationMsec
            );
        }
    }, [previousSettingWorkFlows, settingWorkFlows]);

    const handleRunAllAxImports = () => {
        setIsRunAllAxImportsInProgress(true);
        runAllAxImports(
            envMoniker,
            tenantMoniker,
            originalAxImportEntities
        ).then(([payload, statusCode]) => {
            if (statusCode === constants.statusCode.NoContent) {
                Alert.success(
                    t("tenantConfig.axImportControl.importAllSuccessMessage")
                );
            } else {
                const errorMessage = getErrorMessage(
                    String(payload),
                    statusCode
                );
                Alert.error(errorMessage, constants.alertDurationMsec);
            }
            setIsRunAllAxImportsInProgress(false);
        });
    };
    const handleRunAllCefEntities = () => {
        setIsRunAllCefEntitiesInProgress(true);
        runAllCefEntities(
            envMoniker,
            tenantMoniker,
            originalAxImportEntities
        ).then(([payload, statusCode]) => {
            if (statusCode === constants.statusCode.NoContent) {
                Alert.success(
                    t("tenantConfig.axImportControl.importAllSuccessMessage")
                );
            } else {
                const errorMessage = getErrorMessage(
                    String(payload),
                    statusCode
                );
                Alert.error(errorMessage, constants.alertDurationMsec);
            }
            setIsRunAllCefEntitiesInProgress(false);
        });
    };
    const getEntityFrameworkSettingToggleValues = (
        original: IAxImportEntity[]
    ) => {
        return axImportEntities.reduce(
            (
                accumulator: ChangeEntityFrameworkSettingToggleValue[],
                currentValue
            ) => {
                if (currentValue.isCefTurnedOn !== null) {
                    const originalEntity =
                        original.find(
                            (entity) =>
                                entity.importEntityName ===
                                currentValue.importEntityName
                        ) || null;

                    if (
                        originalEntity === null ||
                        originalEntity.isCefTurnedOn !==
                            currentValue.isCefTurnedOn
                    ) {
                        const workflowType =
                            axImportNameToWorkflowTypeMapping[
                                currentValue.importEntityName
                            ];
                        const settingDefinition =
                            workflowType !== null
                                ? tenantFeatureSettingDefinitions[workflowType]
                                : null;
                        const workflow = settingWorkFlows.find(
                            (value) =>
                                value.featureName ===
                                currentValue.importEntityName
                        );
                        const isWorkflowCompleted =
                            !workflow ||
                            isEveryWorkflowStepCompleted(workflow.steps);
                        if (settingDefinition !== null && isWorkflowCompleted) {
                            accumulator.push({
                                settingName:
                                    settingDefinition.urlParameterValue,
                                settingToggleValue: currentValue.isCefTurnedOn,
                                axImportToggleValue:
                                    currentValue.isAxImportTurnedOn ?? false,
                            });
                        }
                    }
                }
                return accumulator;
            },
            []
        );
    };

    const handleTransferData = (entityName: string) => {
        showLoader(true);

        transferCefData(envMoniker, tenantMoniker, entityName).then(() => {
            showLoader(false);
            Alert.success(
                template(
                    t(
                        "tenantConfig.axImportControl.dataTransfer.successMessage"
                    )
                )({
                    entityName: entityName,
                }),
                constants.alertDurationMsec
            );
        });
    };

    const handleSaveClick = () => {
        showLoader(true);

        // Before changing options we need to load original data from back because it could be changed during some time
        fetchTenantAxImport(envMoniker, tenantMoniker).then((value) => {
            if (!value) {
                showLoader(false);
                return;
            }

            setAxImportMessage(value.message);
            const original = getCEFOriginalEntities(value.axImportEntities);
            const promises: any[] = [];
            promises.push(
                updateTenantAxImportEntities(
                    envMoniker,
                    tenantMoniker,
                    axImportEntities
                ).then(() => {
                    setIsUnsavedChangeAvailable(false);
                    Alert.success(
                        t("tenantConfig.customFieldsPage.successSent"),
                        constants.alertDurationMsec
                    );
                })
            );
            const entityFrameworkSettingToggleValues =
                getEntityFrameworkSettingToggleValues(original);
            if (entityFrameworkSettingToggleValues.length !== 0) {
                addDisabledSettingNames(
                    entityFrameworkSettingToggleValues.map(
                        (toggleValue) => toggleValue.settingName
                    )
                );
                promises.push(
                    updateTenantChangeEntityFrameworkSettings(
                        envMoniker,
                        tenantMoniker,
                        entityFrameworkSettingToggleValues
                    ).then(() => {
                        fetchData(envMoniker, tenantMoniker);
                        setIsUnsavedChangeAvailable(false);
                    })
                );
            }

            Promise.all(promises).then(() => {
                showLoader(false);
            });
        });
    };
    const getCEFOriginalEntities = (
        entities: IAxImportEntity[]
    ): IAxImportEntity[] => {
        const filtered = cloneDeep(
            entities.filter((enitity) => enitity.isCefTurnedOn !== null)
        );

        return filtered;
    };
    const fetchData = (envMoniker: string, tenantMoniker: string) => {
        if (envMoniker && tenantMoniker) {
            showLoader(true);
            Promise.all([
                fetchTenantAxImport(
                    envMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .then((value) => {
                        if (!value) {
                            return;
                        }

                        setAxImportMessage(value.message);
                        setIsDataTransferAllowed(value.isDataTransferAllowed);
                        setAxImportEntities(
                            value.axImportEntities.sort(compareEntities)
                        );
                        setOriginalAxImportEntities(value.axImportEntities);
                        setCefOriginalEntities(
                            getCEFOriginalEntities(value.axImportEntities)
                        );
                        setIsAxImportEntitiesDataLoaded(true);
                    })
                    .catch((error: Error) => {
                        if (!Axios.isCancel(error)) {
                            Alert.error(error.message);
                        }
                    }),
                fetchTenantChangeEntityFrameworkSettingWorkflows(
                    envMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .then((value) => {
                        if (!value) {
                            return;
                        }
                        setSettingWorkFlows(value);
                        setSettingWorkFlowsDataLoaded(true);
                    })
                    .catch((error: Error) => {
                        if (!Axios.isCancel(error)) {
                            Alert.error(error.message);
                        }
                    }),
            ]).then(() => {
                showLoader(false);
            });
        }
    };
    const getMessageProps = (
        axImportMessage: IMessage | null
    ): {
        type: TypeAttributes.Status;
        title: string;
    } => {
        switch (axImportMessage?.type) {
            case MessageType.Information:
                return {
                    type: "info",
                    title: t("tenantConfig.emptyMessage.title.info"),
                };
            case MessageType.Warning:
            case MessageType.Error:
                return {
                    type: "warning",
                    title: t("tenantConfig.emptyMessage.title.warning"),
                };
            default:
                return {
                    type: "info",
                    title: "",
                };
        }
    };

    useInactiveTenant();
    useInactiveEnvironment();

    useRefreshPage(() => {
        fetchData(envMoniker, tenantMoniker);
    });
    useEffect(() => {
        fetchData(envMoniker, tenantMoniker);
    }, [envMoniker, tenantMoniker]);

    useEffect(() => {
        if (
            axImportEntities.some(
                (entity) => entity.isAxImportTurnedOn == null
            ) &&
            customerType === CustomerType.AX2012
        ) {
            setAxImportEntities(
                axImportEntities.filter(
                    (entity) => entity.isAxImportTurnedOn !== null
                )
            );
        }
    }, [axImportEntities]);

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    const messageProps = getMessageProps(axImportMessage);
    return (
        <div className={css.containerDataImport}>
            <div className={css.content}>
                {isAxImportEntitiesDataLoaded && isSettingWorkFlowsDataLoaded && (
                    <SignalRTenantFeatureSetting
                        tenantId={tenantId}
                        updateTenantFeatureSettingWorkFlows={(
                            settingWorkflow: ITenantFeatureSettingWorkFlow
                        ) => {
                            removeDisabledSettingName(
                                settingWorkflow.featureName
                            );
                            setSettingWorkFlows((settingWorkFlows) => {
                                return updateTenantSettingWorkFlow(
                                    settingWorkFlows,
                                    settingWorkflow
                                );
                            });
                        }}
                    >
                        <>
                            <h1 className={css.header}>
                                {t("tenantConfig.axImportControl.header")}
                            </h1>
                            {axImportMessage && (
                                <Message
                                    className={css.messageContainer}
                                    showIcon
                                    description={axImportMessage.message}
                                    {...messageProps}
                                />
                            )}
                            {axImportEntities.length !== 0 &&
                                !isPartner &&
                                isRunAllEnabled && (
                                    <div
                                        className={cn(
                                            css.buttonArea,
                                            customerType !== CustomerType.D365
                                                ? css.containerAx
                                                : isDataTransferAllowed
                                                ? css.containerFull
                                                : css.containerCef
                                        )}
                                    >
                                        <Button
                                            className={cn(
                                                css.transferAllImportBtn,
                                                css.actionBtn,
                                                css.withCef
                                            )}
                                            loading={
                                                isRunAllAxImportsInProgress
                                            }
                                            onClick={() => {
                                                handleRunAllAxImports();
                                            }}
                                            appearance="primary"
                                        >
                                            {t(
                                                "tenantConfig.axImportControl.runAllAxImports"
                                            )}
                                        </Button>
                                        {customerType === CustomerType.D365 && (
                                            <Button
                                                className={cn(
                                                    css.transferAllImportBtn,
                                                    css.actionBtn,
                                                    css.withCef
                                                )}
                                                loading={
                                                    isRunAllCefEntitiesInProgress
                                                }
                                                onClick={() => {
                                                    handleRunAllCefEntities();
                                                }}
                                                appearance="primary"
                                            >
                                                {t(
                                                    "tenantConfig.axImportControl.runAllCefEntities"
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                )}
                        </>
                        {axImportEntities.length !== 0 && (
                            <div
                                className={
                                    customerType !== CustomerType.D365
                                        ? css.containerAx
                                        : isDataTransferAllowed
                                        ? css.containerFull
                                        : css.containerCef
                                }
                            >
                                <AxImportEntitiesTable
                                    axImportEntities={axImportEntities}
                                    isD365Customer={
                                        customerType === CustomerType.D365
                                    }
                                    isDataTransferAllowed={
                                        isDataTransferAllowed
                                    }
                                    disabledSettingNames={disabledSettingNames}
                                    cefOriginalEntities={cefOriginalEntities}
                                    settingWorkFlows={settingWorkFlows}
                                    setAxImportEntities={setAxImportEntities}
                                    handleTransferData={handleTransferData}
                                    setIsUnsavedChangeAvailable={
                                        setIsUnsavedChangeAvailable
                                    }
                                />
                            </div>
                        )}
                    </SignalRTenantFeatureSetting>
                )}
            </div>
            <SettingsFooterContainer handleFormSubmit={handleSaveClick} />
        </div>
    );
};

export default DataImport;
