import { SET_APP_SETTINGS, State, Actions } from "./app-settings-types";

const initialState = {
    isAppSettingsFetched: false,
    appSettings: {
        pingTimeout: 0,
        isDeliverablesEnabled: false,
        isRunAllEnabled: false,
        isDataRefreshEnabled: false,
        isPowerBiWorkspaceDeploymentEnabled: false,
        isAlertRuleEstablishmentEnabled: false,
        isInvoiceVolumeStoringEnabled: false,
    },
};

const appSettingsReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_APP_SETTINGS:
            return {
                ...state,
                isAppSettingsFetched: true,
                appSettings: { ...action.appSettings },
            };

        default:
            return state;
    }
};

export default appSettingsReducer;
