import { RootState } from "redux/store";
import { connect } from "react-redux";
import { fetchCustomers } from "redux/customers/customers-thunks";
import {
    setConsentStatus,
    setCustomerName,
    setCustomerDetails,
} from "redux/customers/customer-details/customer-details-actions";
import {
    setActiveCustomerById,
    setActiveCustomerName,
} from "redux/customers/customers-actions";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import CustomerDetails from "./CustomerDetails";
import {
    getCustomers,
    getCustomerNames,
} from "redux/customers/customers-selectors";
import { sendEmailWithLink } from "redux/customers/customer-details/customer-details-thunk";
import { setEmailSent } from "redux/customers/customer-details/customer-details-actions";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "redux/common/initialization/initialization-actions";

const mapStateToProps = (state: RootState) => {
    return {
        errorMessage: state.initializationReducer.errorResponseMessage,
        customerDetails: state.customerDetailsReducer.customerDetails,
        activeCustomer: state.customersReducer.activeCustomer,
        customers: getCustomers(state),
        customerNames: getCustomerNames(state),
    };
};
const CustomerDetailsContainer = connect(mapStateToProps, {
    setConsentStatus,
    setCustomerName,
    setCustomerDetails,
    setIsUnsavedChangeAvailable,
    fetchCustomers,
    setActiveCustomerById,
    setActiveCustomerName,
    sendEmailWithLink,
    setEmailSent,
    setLoaderVisibility,
    setErrorResponseMessage,
})(CustomerDetails);

export default CustomerDetailsContainer;
