import React from "react";
import { useTranslation } from "react-i18next";
import { ITenantFeatureSettingWorkFlow } from "utils/tenant-workflows/tenant-workflows-types";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import { template } from "lodash";
import { isToggleValueEnabled } from "utils/tenant-workflows/tenant-workflows-helper";
import { ToggleValueType } from "utils/tenant-workflows/tenant-workflows-types";
interface IComponentProps {
    tenantFeatureSettingWorkFlow: ITenantFeatureSettingWorkFlow;
}

const TenantFeatureProgressModalHeader: React.FC<IComponentProps> = ({
    tenantFeatureSettingWorkFlow,
}: IComponentProps) => {
    const { t } = useTranslation();
    const settingDefinition =
        tenantFeatureSettingDefinitions[tenantFeatureSettingWorkFlow.type] ??
        null;
    const headerTextTemplate = template(
        t(
            isToggleValueEnabled(
                tenantFeatureSettingWorkFlow.toggleValue,
                ToggleValueType.Setting
            )
                ? "tenantFeatureSetting.progressModal.headerTextTemplate.activation"
                : "tenantFeatureSetting.progressModal.headerTextTemplate.deactivation"
        )
    );
    return settingDefinition !== null ? (
        <h1>
            {headerTextTemplate({
                workFlowName: settingDefinition.displayName,
            })}
        </h1>
    ) : null;
};

export default TenantFeatureProgressModalHeader;
