import Axios, { CancelTokenSource } from "axios";
import { useEffect, useMemo } from "react";

const useRequestCancellation = (): CancelTokenSource => {
    const cancelTokenSource = useMemo(() => Axios.CancelToken.source(), []);

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    return cancelTokenSource;
};

export default useRequestCancellation;
