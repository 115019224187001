import { RootState } from "redux/store";
import { connect } from "react-redux";
import {
    getActiveStep,
    getEnrollSteps,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import AutomatedStep from "./AutomatedStep";
import { setActiveStepData } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";

const mapStateToProps = (state: RootState) => ({
    activeStep: getActiveStep(state),
    stepList: getEnrollSteps(state),
});

const AutomatedStepContainer = connect(mapStateToProps, {
    setActiveStepData,
})(AutomatedStep);

export default AutomatedStepContainer;
