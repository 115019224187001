import { IAxImportEntity } from "redux/environments/tenants/data-import/data-import-types";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStepStatus,
    ToggleValueType,
    IWorkflowStepBase,
    IWorkflowBase,
    WorkflowStatus,
} from "./tenant-workflows-types";

export const getTenantFeatureSettingByType = (
    featureSettingWorkFlows: ITenantFeatureSettingWorkFlow[] | null,
    workFlowType: TenantFeatureSettingWorkFlowType
): ITenantFeatureSettingWorkFlow | null => {
    return (
        featureSettingWorkFlows?.find(
            (workFlow) => workFlow.type === workFlowType
        ) ?? null
    );
};

export const isTenantFeatureSettingWithTypeEnabled = (
    featureSettingWorkFlows: ITenantFeatureSettingWorkFlow[] | null,
    workFlowType: TenantFeatureSettingWorkFlowType
): boolean => {
    if (featureSettingWorkFlows === null) {
        return false;
    }
    const workFlow = getTenantFeatureSettingByType(
        featureSettingWorkFlows,
        workFlowType
    );

    if (workFlow && isWorkflowTerminated(workFlow)) {
        return true;
    }
    const settingSteps = workFlow?.steps || null;
    return settingSteps !== null
        ? isEveryWorkflowStepCompleted(settingSteps)
        : true;
};

export const isEveryWorkflowStepCompleted = (
    settingSteps: IWorkflowStepBase[]
): boolean => {
    return settingSteps.every(
        (step) =>
            step.status === WorkflowStepStatus.Completed ||
            step.status === WorkflowStepStatus.CompletedWithError
    );
};

export const isWorkflowWaitingForApprove = (
    workFlow: IWorkflowBase
): boolean => {
    return workFlow.status === WorkflowStatus.WaitForApprove;
};

export const isWorkflowTerminated = (workFlow: IWorkflowBase): boolean => {
    return workFlow.status === WorkflowStatus.Terminated;
};

export const isWorkflowFailed = (workFlow: IWorkflowBase): boolean => {
    return workFlow.steps.some(
        (step) => step.status === WorkflowStepStatus.Failed
    );
};

export const updateTenantSettingWorkFlow = (
    settingWorkFlows: ITenantFeatureSettingWorkFlow[] | null,
    settingWorkflow: ITenantFeatureSettingWorkFlow
): ITenantFeatureSettingWorkFlow[] => {
    if (settingWorkFlows === null) {
        return [settingWorkflow];
    } else if (
        settingWorkFlows.every((workFlow) => workFlow.id !== settingWorkflow.id)
    ) {
        return [...settingWorkFlows, settingWorkflow];
    } else {
        return settingWorkFlows.map((workFlow) => {
            if (workFlow.id !== settingWorkflow.id) {
                return workFlow;
            }
            return {
                ...workFlow,
                ...settingWorkflow,
            };
        });
    }
};

export const isEverySettingWorkFlowUpdated = (
    previousState: ITenantFeatureSettingWorkFlow[] | null,
    currentState: ITenantFeatureSettingWorkFlow[] | null
): boolean => {
    return (
        previousState !== null &&
        currentState !== null &&
        previousState.some(
            (workflow) => !isEveryWorkflowStepCompleted(workflow.steps)
        ) &&
        currentState.every((workflow) =>
            isEveryWorkflowStepCompleted(workflow.steps)
        )
    );
};

export const isSettingWorkFlowUpdated = (
    workFlowType: TenantFeatureSettingWorkFlowType,
    previousState: ITenantFeatureSettingWorkFlow[] | null,
    currentState: ITenantFeatureSettingWorkFlow[] | null
): boolean => {
    return (
        previousState !== null &&
        currentState !== null &&
        previousState.some(
            (workflow) =>
                workflow.type === workFlowType &&
                !isEveryWorkflowStepCompleted(workflow.steps)
        ) &&
        currentState.some(
            (workflow) =>
                workflow.type === workFlowType &&
                isEveryWorkflowStepCompleted(workflow.steps)
        )
    );
};

export const isToggleValueEnabled = (
    value: number,
    type: ToggleValueType
): boolean => {
    return (value & type) === type;
};

export const compareEntities = (
    e1: IAxImportEntity,
    e2: IAxImportEntity
): number => {
    const entityName1 = e1.importEntityName.toLocaleLowerCase() || "";
    const entityName2 = e2.importEntityName.toLocaleLowerCase() || "";
    if (entityName1 < entityName2) {
        return -1;
    }
    if (entityName1 > entityName2) {
        return 1;
    }
    return 0;
};
