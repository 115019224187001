import { RootState } from "redux/store";
import { connect } from "react-redux";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "redux/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { getErrorMessage } from "redux/common/initialization/initialization-selectors";
import CustomFields from "./CustomFields";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";
import { getActiveTenantMoniker } from "redux/environments/tenants/tenants-selectors";
import { getConfluenceDocumentLinks } from "redux/information-links/information-links-selectors";
import { fetchConfluenceDocumentLinks } from "redux/information-links/information-links-thunks";
import { setRefreshActivated } from "redux/common/page-refresh/page-refresh-actions";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    tenantMoniker: getActiveTenantMoniker(state),
    isConfluenceDocumentLinksFetched:
        state.informationLinksReducer.isConfluenceDocumentLinksFetched,
    confluenceDocumentLinks: getConfluenceDocumentLinks(state),
    isUnsavedChangeAvailable: state.unsavedDataReducer.isUnsavedChangeAvailable,
    errorMessage: getErrorMessage(state),
});
const CustomFieldsContainer = compose(
    connect(mapStateToProps, {
        setModalConfirmCallback,
        setRefreshActivated,
        setIsUnsavedChangeAvailable,
        fetchConfluenceDocumentLinks,
    }),
    withLoader
)(CustomFields);

export default CustomFieldsContainer;
