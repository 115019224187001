import { connect } from "react-redux";
import { RootState } from "redux/store";
import ManageErpPackage from "./ManageErpPackage";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import { setManagePackageOpened } from "redux/deliverables/erp-packages/erp-packages-actions";

const mapStateToProps = (state: RootState) => {
    return {
        isOpened: state.packagesReducer.isManagePackageOpened,
        packageDetails: state.packagesReducer.packageDetails,
    };
};

const ManageErpPackageContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setManagePackageOpened,
})(ManageErpPackage);

export default ManageErpPackageContainer;
