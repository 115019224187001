import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { template } from "lodash";
import { Button } from "rsuite";
import cn from "classnames";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

import { IEnrollCache } from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";

import styles from "./SuccessMessage.module.css";

interface IComponentProps {
    enrollCache: IEnrollCache | null;
    enrollId: string | null;
    environmentName: string;
    environmentMoniker: string;
    showLoader: (isVisible: boolean) => void;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
}

const SuccessMessage: React.FC<IComponentProps> = ({
    enrollCache,
    enrollId,
    environmentName,
    environmentMoniker,
    showLoader,
    fetchEnrollCache,
}: IComponentProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isFetched, setIsFetched] = useState<boolean>(false);

    const fetchData = (environmentMoniker: string, enrollId: string) => {
        if (enrollId && environmentMoniker) {
            showLoader(true);
            Promise.all([fetchEnrollCache(environmentMoniker, enrollId)]).then(
                () => {
                    showLoader(false);
                    setIsFetched(true);
                }
            );
        }
    };

    useEffect(() => {
        if (environmentMoniker && enrollId && !isFetched) {
            fetchData(environmentMoniker, enrollId);
        }
    }, [environmentMoniker, enrollId, isFetched]);

    const onClickSettingsHandler = () => {
        if (environmentName && enrollCache && enrollCache.tenantId) {
            history.push(
                `/environments/${environmentName}/tenants/${enrollCache.tenantId}/general-configs`
            );
            history.go(0);
        }
    };

    return (
        <div className={styles.messageContainer}>
            <div className={styles.mainMessage}>
                {t("enrollTenant.successMessage.mainMessage")}
            </div>
            <br />
            <div className={styles.subMessage}>
                {parse(
                    template(t("enrollTenant.successMessage.submessage"))({
                        tenantName: enrollCache?.tenantName,
                    })
                )}
                <br />
                <br />
                <p>
                    {t("enrollTenant.successMessage.axImportsMessage")}
                    <a
                        href={`/environments/${environmentName}/tenants/${enrollCache?.tenantId}/data-import`}
                        className={styles.dataImportLink}
                    >
                        {t("enrollTenant.successMessage.dataImportLinkTitle")}
                    </a>
                </p>
            </div>
            <div>
                <Button
                    type="submit"
                    onClick={onClickSettingsHandler}
                    className={cn(styles.generalBtn, styles.saveBtn)}
                >
                    {t("enrollTenant.successMessage.btnName")}
                </Button>
            </div>
        </div>
    );
};

export default SuccessMessage;
