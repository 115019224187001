import { useState, useRef, useEffect } from "react";

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;

const usePreviousState = <T>(
    initialState: T
): [T | null, T, Dispatch<SetStateAction<T>>] => {
    const previousState = useRef<T | null>(null);
    const [currentState, setCurrentState] = useState<T>(initialState);
    useEffect(() => {
        previousState.current = currentState;
    }, [currentState]);
    return [previousState.current, currentState, setCurrentState];
};

export default usePreviousState;
