import React from "react";
import { useTranslation } from "react-i18next";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import { template } from "lodash";
interface IComponentProps {
    tenantDeleteWorkflow: ITenantDeleteWorkflow;
}

const TenantDeleteProgressModalHeader: React.FC<IComponentProps> = ({
    tenantDeleteWorkflow,
}: IComponentProps) => {
    const { t } = useTranslation();
    const headerTextTemplate = template(
        t("tenantDelete.progressModal.headerTextTemplate.deletion")
    );
    return tenantDeleteWorkflow.tenantId !== null ? (
        <h1>{headerTextTemplate({})}</h1>
    ) : null;
};

export default TenantDeleteProgressModalHeader;
