import { RootState } from "../../redux/store";
import MainLayout from "./MainLayout";
import { connect } from "react-redux";
import { fetchAppSettings } from "../../redux/app-settings/app-settings-thunks";
import { fetchUserPermissions } from "../../redux/app-global/app-global-thunks";

const mapStateToProps = (state: RootState) => {
    return {
        isAppSettingsFetched: state.appSettingsReducer.isAppSettingsFetched,
        isLoaderVisible: state.initializationReducer.loadersCount > 0,
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};

const MainLayoutContainer = connect(mapStateToProps, {
    fetchAppSettings,
    fetchUserPermissions,
})(MainLayout);

export default MainLayoutContainer;
