import { connect } from "react-redux";
import { RootState } from "redux/store";
import TenantStatus from "./TenantStatus";
import { setTenantStatusOpened } from "redux/environments/tenants/tenant-status/tenant-status-actions";
import {
    getPingInfo,
    getTenantsPingInfoSelector,
} from "redux/environments/tenants/tenant-ping/tenant-ping-selectors";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { pingTenantInD365 } from "redux/environments/tenants/tenant-ping/tenant-ping-thunk";

const mapStateToProps = (state: RootState) => {
    const envMoniker = getActiveEnvironmentMoniker(state);
    return {
        activeEnvMoniker: envMoniker,
        tenantsPingInfo: getTenantsPingInfoSelector(state, envMoniker),
        tenantVersionInfo: state.tenantStatusReducer.tenantVersionInfo,
        isTenantStatusOpened: state.tenantStatusReducer.isTenantStatusOpened,
    };
};
const TenantStatusContainer = connect(mapStateToProps, {
    setTenantStatusOpened,
    getPingInfo,
    pingTenantInD365,
})(TenantStatus);

export default TenantStatusContainer;
