import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import ModalContainer from "../Common/Modal/ModalContainer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import "./UnsavedDataModal.less";

interface ComponentProps {
    isUnsavedChangeAvailable: boolean;
    isRefreshActivated: boolean;
    modalConfirmCallbacks: IModalCallback[];
    removeModalConfirmCallback: (
        predicate: (modalCallback: IModalCallback) => boolean
    ) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}

const UnsavedDataModal: React.FC<ComponentProps> = ({
    isUnsavedChangeAvailable,
    isRefreshActivated,
    modalConfirmCallbacks,
    removeModalConfirmCallback,
    setIsUnsavedChangeAvailable,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [currentUrl, setCurrentUrl] = useState("");
    const history = useHistory();
    const clearModalConfirmCallbacks = () => {
        removeModalConfirmCallback(
            (callback) => !callback.isAutoDeleteResistant
        );
    };
    const runModalConfirmCallbacks = () => {
        if (modalConfirmCallbacks.length !== 0) {
            if (modalConfirmCallbacks.some((item) => item.isPageRedirect)) {
                modalConfirmCallbacks
                    .filter((item) => item.isPageRedirect)
                    .forEach((item) => item.callback());
            } else {
                modalConfirmCallbacks.forEach((item) => item.callback());
            }
            clearModalConfirmCallbacks();
        }
        setRefreshActivated(false);
    };

    useEffect(() => {
        window.onpopstate = () => {
            setCurrentUrl(history.location.pathname);
        };
        return () => {
            window.onpopstate = () => {
                return;
            };
            setCurrentUrl("");
        };
    }, [setCurrentUrl]);
    useEffect(() => {
        // If there are no unsaved changes - just perform the action. No need to show a confirmation modal.
        if (isRefreshActivated && !isUnsavedChangeAvailable) {
            runModalConfirmCallbacks();
        }
    }, [
        isRefreshActivated,
        isUnsavedChangeAvailable,
        runModalConfirmCallbacks,
    ]);
    const confirmButtonOnClickHandler = () => {
        setCurrentUrl("");
        runModalConfirmCallbacks();
        // After confirmation "isUnsavedChangeAvailable" state should be cleared (set to false) in order
        // to prevent the showing of unsaved changes popup after redirection to the new page.
        setIsUnsavedChangeAvailable(false);
    };
    const cancelButtonOnClickHandler = () => {
        if (currentUrl) {
            window.history.replaceState({}, "", currentUrl);
        }
        clearModalConfirmCallbacks();
        setRefreshActivated(false);
    };
    return (
        <ModalContainer
            title={""}
            closeButton
            enforceFocus={true}
            className="confirm-modal"
            show={
                isRefreshActivated &&
                isUnsavedChangeAvailable &&
                modalConfirmCallbacks.length !== 0
            }
            onHide={() => cancelButtonOnClickHandler()}
            footer={
                <>
                    <Button onClick={confirmButtonOnClickHandler}>
                        {t("unsavedDataModal.button.leave")}
                    </Button>
                    <Button onClick={cancelButtonOnClickHandler}>
                        {t("unsavedDataModal.button.stay")}
                    </Button>
                </>
            }
        >
            {t("unsavedDataModal.mainMessage")}
        </ModalContainer>
    );
};

export default UnsavedDataModal;
