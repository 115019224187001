import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "utils/routes";

const AppRouter: React.FC = () => {
    const mainLayoutRoutes = routes.mainLayout.map((route, index) => {
        return (
            <Route
                exact={route.exact}
                path={route.path}
                key={"AppRouter" + String(index)}
                component={route.componentName}
            />
        );
    });

    return <Switch>{mainLayoutRoutes}</Switch>;
};

export default AppRouter;
