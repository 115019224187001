import React from "react";
import { Container, Content, Sidebar } from "rsuite";
import css from "./DeliverablesLayout.module.css";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "utils/routes";
import constants from "utils/constants";
import DeliverablesMenu from "components/Deliverables/DeliverablesMenu/DeliverablesMenu";

const DeliverablesLayout: React.FC = () => {
    const leftMenuRouters = routes.deliverablesLayout.map((route, index) => {
        return (
            <Route
                exact={route.exact}
                key={String(index)}
                path={route.path}
                component={route.componentName}
            />
        );
    });
    return (
        <>
            <Container className={css.container}>
                <Sidebar className={`${css.leftMenu} ${css.scrollableArea}`}>
                    <DeliverablesMenu />
                </Sidebar>
                <Content
                    className={`${css.contentSection} ${css.scrollableArea}`}
                >
                    <Switch>
                        <Route
                            exact
                            key={String(leftMenuRouters.length)}
                            path={constants.routePath.deliverables}
                        >
                            <Redirect
                                to={constants.routePath.deliverablesPackages}
                            />
                        </Route>
                        {leftMenuRouters}
                    </Switch>
                </Content>
            </Container>
        </>
    );
};

export default DeliverablesLayout;
