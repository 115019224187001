import React from "react";
import { Button } from "rsuite";
import { useTranslation } from "react-i18next";

import ContentEntryToCopy, {
    IEntry,
} from "./ContentEntryToCopy/ContentEntryToCopy";
import cn from "classnames";

import styles from "./CopyContentTableToClipBoard.module.css";

interface IContentProps {
    description: string;
    entries: IEntry[];
    copyTitle?: string;
    className?: string;
    postCopyAction?: () => void;
}

const CopyContentTableToClipBoard: React.FC<IContentProps> = ({
    description,
    entries,
    copyTitle,
    postCopyAction,
}: IContentProps) => {
    const { t } = useTranslation();
    copyTitle = copyTitle ?? t("copyContentTableToClipBoard.defaultCopyTitle");
    const contentTable = entries.map((entr, index) => {
        return <ContentEntryToCopy key={index.toString()} {...entr} />;
    });

    const copyContentToClipBoardAction = () => {
        let textToSaveToClipboard = "";
        const entriesLength = entries.length;
        entries.forEach((x, i) => {
            textToSaveToClipboard = textToSaveToClipboard.concat(
                x.keyName + ": " + x.value
            );
            if (i !== entriesLength - 1) {
                textToSaveToClipboard = textToSaveToClipboard.concat("\n");
            }
        });
        navigator.clipboard.writeText(textToSaveToClipboard).then(() => {
            if (postCopyAction) {
                postCopyAction();
            }
        });
    };

    return (
        <div className={styles.autoHeight}>
            <p>{description}</p>
            <div className={styles.credentialsSection}>
                <table className={cn(styles.contentTable)}>
                    <tbody>{contentTable}</tbody>
                </table>
                <Button
                    className={cn(styles.saveBtn, styles.generalBtn)}
                    onClick={() => copyContentToClipBoardAction()}
                >
                    <b>{copyTitle}</b>
                </Button>
            </div>
        </div>
    );
};

export default CopyContentTableToClipBoard;
