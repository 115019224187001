import * as React from "react";
import classNames from "classnames";
import { Panel, PanelProps } from "rsuite";

import "./StatusBlock.less";

interface IStatus extends PanelProps {
    isActive: boolean;
    title: string;
    subTitle: string;
}

const Status: React.FC<IStatus> = ({
    title,
    subTitle,
    isActive,
    ...rest
}: IStatus) => {
    return (
        <Panel
            bodyFill
            bordered={false}
            className={classNames("status-block", {
                "status-block--active": isActive,
            })}
            {...rest}
        >
            <div className="status-block__title">{`${title} (${subTitle})`}</div>
        </Panel>
    );
};

export default Status;
