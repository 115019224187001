import { defaultErrorResponse } from "api/defaults";
import { API } from "utils/api-config";
import {
    ITenantDataRefresh,
    IEmailRecipient,
} from "redux/environments/tenants/tenant-data-refresh/tenant-data-refresh-types";
import Axios, { CancelTokenSource } from "axios";

export const loadTenantErpDataRefreshHistory = async (
    environmentMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantDataRefresh | null, number]> => {
    if (!environmentMoniker || !tenantId) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `/environments/${environmentMoniker}/tenants/${tenantId}/erp/data-refresh/history`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateTenantErpDataRefreshEmailRecipients = async (
    environmentMoniker: string,
    tenantId: string,
    emailRecipients: IEmailRecipient[]
): Promise<[ITenantDataRefresh | null, number]> => {
    if (!environmentMoniker || !tenantId) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `/environments/${environmentMoniker}/tenants/${tenantId}/erp/data-refresh/email-recipients`,
        emailRecipients
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
