const constants = {
    keyCodeEnter: 13,
    keyCodeBackspace: 8,
    appVersionDefault: "v.1.0.0.0",
    pingTimeoutDefault: 60000,
    defaultIndex: -1,
    defaultTenantStatus: 0,

    // HTTP status codes
    statusCode: {
        OK: 200,
        NoContent: 204,
        BadRequest: 400,
        UnauthenticatedError: 401,
        AccessDenied: 403,
        NotFound: 404,
        InternalServerError: 500,
    },

    d365IntegrationAccountTokenUrl: "https://login.microsoftonline.com/",
    seqLogsFilter: `{0}#/events?filter=_TenantId='{1}' or TenantId='{1}'`,
    mailboxUrl: "http://outlook.office365.com/mail/",

    // Routes
    routePath: {
        root: "/",

        customers: "/customers",
        customerDetails: "/customers/:customerId/details",

        deliverables: "/deliverables",
        deliverablesPackages: "/deliverables/packages",
        deliverablesTemplates: "/deliverables/templates",

        environments: "/environments",
        partners: "/partners",
        partnerDetails: "/partners/:partnerId/details",
        tenants: "/environments/:environmentName/tenants",
        tenantConfigs: "/environments/:environmentName/tenants/:tenantId",
        generalConfig:
            "/environments/:environmentName/tenants/:tenantId/general-configs",
        featureConfig:
            "/environments/:environmentName/tenants/:tenantId/feature-configs",
        erpConfig:
            "/environments/:environmentName/tenants/:tenantId/erp-configs",
        customFields:
            "/environments/:environmentName/tenants/:tenantId/custom-fields",
        scheduler: "/environments/:environmentName/tenants/:tenantId/scheduler",
        cultureSettings:
            "/environments/:environmentName/tenants/:tenantId/culture-settings",
        dataImport:
            "/environments/:environmentName/tenants/:tenantId/data-import",
        guestTenants:
            "/environments/:environmentName/tenants/:tenantId/guest-tenants/list",
        dopeSubscriptions:
            "/environments/:environmentName/tenants/:tenantId/dope/list",
        versionHistory:
            "/environments/:environmentName/tenants/:tenantId/version-history",
        dataRefreshHistory:
            "/environments/:environmentName/tenants/:tenantId/data-refresh-history",
        maintenanceMode:
            "/environments/:environmentName/tenants/:tenantId/maintenance-mode",
        captureMailboxes:
            "/environments/:environmentName/tenants/:tenantId/mailboxes",
        activityLog:
            "/environments/:environmentName/tenants/:tenantId/activity-logs",

        enrollTenant:
            "/environments/:environmentName/enroll-tenant/:instanceId",
        enrollNewTenant: "/environments/:environmentName/enroll-new-tenant",
        guestTenantsEnroll:
            "/environments/:environmentName/enroll-tenant/:instanceId/guest-tenants",
        deletedTenant:
            "/environments/:environmentName/deleted-tenant/:tenantMoniker",
    },

    ranges: {
        minRangeValue0: 0,
        minRangeValue1: 1,
        maxRangeValue100: 100,
        dotnetInt32MaxValue: 2147483647,
    },
    defaultGuid: "00000000-0000-0000-0000-000000000000",
    alertDurationMsec: 5000,
    errorAlertDurationMsec: 8000,
    maxLengthLimit: 5000,
    maxGuestTenantNameLengthLimit: 500,
    maxPartnerNameLengthLimit: 500,
    minNameLengthLimit: 3,
    itemNameLengthLimit: 30,
    enrollNewSignalRMessageMethodName: "newMessage",
    retryFailedStepEventName: "RetryFailedStep",
    completeStepEventName: "CompleteStep",
    defaultAutoTTPMaxBatchSizeValue: 500,
    userSessionIdleTimeoutInMinutes: 30,
    companyPredictionMinScoreDefaultValue: 50,
    maxCustomFieldLimit: 500,
    maxSchedulerRowsLimit: 50,
    minSchedulerIntervalLimit: 1,
    maxSchedulerIntervalValueLimit: 1000000,
    maxSchedulerIntervalDigitsLimit: 7,
    maxInvoiceRowCountInCustomField: 50,
    urlRegex:
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    fileNameInvalidCharsRegex: /[\\\/:*?"<>| ]/g,
    versionRegex:
        /^(?:[1-9]\d*|0)\.(?:[1-9]\d*|0)\.(?:[1-9]\d*|0)\.(?:[1-9]\d*|0)$/,
    guidRegex:
        /({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}/g,
    cidrRegex: /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})(\/(\d{1,2}))?$/,
    enroll: {
        // Tenant and customer info
        STEP_1: 0,

        // Guest tenants
        STEP_2: 1,

        // Integration information
        STEP_3: 2,

        // Tenant DBs ann replication
        STEP_4: 3,

        // DB level access and PowerBI account
        STEP_5: 4,

        // Set the first admin to Dooap
        STEP_6: 5,

        // Tenant Azure resources
        STEP_7: 6,

        // Setup ERP
        STEP_8: 7,

        // Alter environment DB
        STEP_9: 8,

        // Activate tenant
        STEP_10: 9,

        // Enable Search
        STEP_11: 10,

        // Data Import Control
        STEP_12: 11,
    },
    applicationIdImage: "application-id.png",
    secretValueImage: "client-secret.png",
    notAcceptedDefaultKeyVaultCompany: "DAT",
    passwordSymbolCode: 9679,
    tenantCardNameLimit: 35,
    tenantCardHeaderLimit: 45,
    customerCardNameLimit: 41,
    partnerCardHeaderLimit: 50,
    imageFormats: {
        png: "png",
        jpg: "jpg",
    },
    localizations: {
        us: "en-US",
    },
    tenantListLengths: {
        long: 25,
        middle: 20,
        short: 15,
    },
};

Object.freeze(constants);

export default constants;
