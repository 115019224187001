import {
    SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT,
    SET_ERROR_MESSAGE_INACTIVE_TENANT,
    SET_ERROR_RESPONSE_MESSAGE,
    SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL,
    SET_LOADER_VISIBILITY,
    SET_ERROR_ENROLL_STEP_MESSAGE,
    ISetErrorMessageIEnrollStep,
    ISetErrorMessageInactiveTenant,
    ISetErrorMessageInactiveEnvironment,
    ISetErrorResponseMessageForModal,
    ISetErrorResponseMessage,
    ISetAppInitialization,
    SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE,
    ISetErrorEnrollManualStepResponseMessage,
    ISetErrorTenantDeleteMessage,
    SET_ERROR_TENANT_DELETE_MESSAGE,
} from "./initialization-types";

export const setLoaderVisibility = (
    isInitialized: boolean
): ISetAppInitialization => ({
    type: SET_LOADER_VISIBILITY,
    isInitialized,
});
export const setErrorResponseMessage = (
    message: string
): ISetErrorResponseMessage => ({
    type: SET_ERROR_RESPONSE_MESSAGE,
    message,
});
export const setErrorResponseMessageForModal = (
    message: string
): ISetErrorResponseMessageForModal => ({
    type: SET_ERROR_RESPONSE_MESSAGE_FOR_MODAL,
    message,
});
export const setErrorMessageInactiveEnvironment = (
    message: string
): ISetErrorMessageInactiveEnvironment => ({
    type: SET_ERROR_MESSAGE_INACTIVE_ENVIRONMENT,
    message,
});
export const setErrorMessageInactiveTenant = (
    message: string
): ISetErrorMessageInactiveTenant => ({
    type: SET_ERROR_MESSAGE_INACTIVE_TENANT,
    message,
});
export const setErrorMessageEnrollStep = (
    message: string
): ISetErrorMessageIEnrollStep => ({
    type: SET_ERROR_ENROLL_STEP_MESSAGE,
    message,
});

export const setErrorEnrollManualStepResponseMessage = (
    message: string
): ISetErrorEnrollManualStepResponseMessage => ({
    type: SET_ERROR_ENROLL_MANUAL_STEP_RESPONSE_MESSAGE,
    message,
});

export const setErrorTenantDeleteMessage = (
    message: string
): ISetErrorTenantDeleteMessage => ({
    type: SET_ERROR_TENANT_DELETE_MESSAGE,
    message,
});
