import {
    SET_REFRESH_ACTIVATED,
    ISetRefreshActivated,
} from "./page-refresh-types";

export const setRefreshActivated = (
    isRefreshActivated: boolean
): ISetRefreshActivated => ({
    type: SET_REFRESH_ACTIVATED,
    isRefreshActivated,
});
