import React from "react";
import GuestTenantsContainer from "./GuestTenantsContainer";

interface ComponentProps {
    tenantMoniker: string;
}

const GuestTenantsConfig: React.FC<ComponentProps> = ({
    tenantMoniker,
}: ComponentProps) => (
    <GuestTenantsContainer
        hideActivateColumn={false}
        tenantMoniker={tenantMoniker}
    />
);

export default GuestTenantsConfig;
