import { loadCustomerMetadata } from "api/customers/customers-api";
import constants from "utils/constants";
import { AppThunk } from "../../store";
import {
    setConsentMetaData,
    cleanAddNewCustomerData,
    setDefaultNewCustomerFormValue,
} from "./add-new-customer-actions";
import { setErrorResponseMessageForModal } from "redux/common/initialization/initialization-actions";
import { getErrorMessage } from "api/defaults";
export const fetchCustomerMetadata = (): AppThunk => async (dispatch) => {
    dispatch(cleanAddNewCustomerData());
    dispatch(setErrorResponseMessageForModal(""));

    const [payload, status] = await loadCustomerMetadata();

    if (payload && status === constants.statusCode.OK) {
        dispatch(setConsentMetaData(payload));
        dispatch(setDefaultNewCustomerFormValue());
    } else {
        const errorMessage = getErrorMessage(String(payload), status);

        dispatch(setErrorResponseMessageForModal(errorMessage));
    }
};
