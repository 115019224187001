import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
    CustomFieldInvoiceType,
    ICustomFieldInvoice,
    ILineType,
} from "../custom-fields-types";
import {
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    Icon,
    IconButton,
    InputPicker,
    Schema,
    TagPicker,
} from "rsuite";
import { useTranslation } from "react-i18next";

interface IFormData {
    enabled: boolean;
    targetTable: string;
    targetField: string;
    excludedLineTypes: string[];
}

interface ComponentProps {
    invoice: ICustomFieldInvoice;
    tablesPO: string[];
    tablesNonPO: string[];
    lineTypes: ILineType[];
    fieldsCache: any;
    deleteRow: (invoice: ICustomFieldInvoice) => void;
    setInvoiceRowProperty: (
        name: string,
        value: any,
        invoice: ICustomFieldInvoice
    ) => void;
}

// eslint-disable-next-line react/display-name
export const InvoiceRow = React.forwardRef(
    (
        {
            invoice,
            tablesPO,
            tablesNonPO,
            lineTypes,
            fieldsCache,
            deleteRow,
            setInvoiceRowProperty,
        }: ComponentProps,
        ref
    ) => {
        const { StringType } = Schema.Types;
        const { ArrayType } = Schema.Types;
        const { t } = useTranslation();
        const formRef = useRef<HTMLFormElement>();
        const [tables, setTables] = useState<Record<string, any>[]>([]);
        const [fields, setFields] = useState<Record<string, any>[]>([]);
        const [fieldsData, setFieldsData] = useState<string[]>([]);
        const [lines, setLines] = useState<Record<string, any>[]>([]);

        const [currentTable, setCurrentTable] = useState<
            string | null | undefined
        >();
        const [formModel, setFormModel] = useState<any>();

        const defaultFormData = {
            enabled: false,
            targetTable: "",
            targetField: "",
            excludedLineTypes: [],
        };
        const [formData, setFormData] = useState<IFormData>(defaultFormData);

        const formKey = {
            targetTable: "targetTable",
            targetField: "targetField",
            excludedLineTypes: "excludedLineTypes",
        };

        const handleFieldChange = (name: string, value: any) => {
            setFormData({
                ...formData,
                [name]: value,
            });
            setInvoiceRowProperty(name, value, invoice);
        };

        useEffect(() => {
            setFormData({
                enabled: invoice.enabled,
                targetField: invoice.targetField,
                targetTable: invoice.targetTable,
                excludedLineTypes: invoice.excludedLineTypes
                    ? invoice.excludedLineTypes
                    : [],
            });

            if (invoice && invoice.invoiceType === CustomFieldInvoiceType.Po) {
                setFormModel(
                    Schema.Model({
                        targetTable: StringType().isRequired(
                            t(
                                "tenantConfig.customFieldsPage.validation.required"
                            )
                        ),
                        targetField: StringType().addRule(
                            (value, data) => {
                                return !data.targetTable || !!value;
                            },
                            t(
                                "tenantConfig.customFieldsPage.validation.required"
                            ),
                            true
                        ),
                    })
                );
            } else {
                setFormModel(
                    Schema.Model({
                        targetTable: StringType().isRequired(
                            t(
                                "tenantConfig.customFieldsPage.validation.required"
                            )
                        ),
                        targetField: StringType().addRule(
                            (value, data) => {
                                return !data.targetTable || !!value;
                            },
                            t(
                                "tenantConfig.customFieldsPage.validation.required"
                            ),
                            true
                        ),
                        excludedLineTypes: ArrayType(),
                    })
                );
            }
            setCurrentTable(invoice.targetTable);
        }, [invoice]);

        useEffect(() => {
            if (invoice.invoiceType === CustomFieldInvoiceType.Po) {
                const t = tablesPO.map((value) => {
                    return {
                        label: value,
                        value: value,
                    };
                });
                setTables(t);
            } else {
                const t = tablesNonPO.map((value) => {
                    return {
                        label: value,
                        value: value,
                    };
                });
                setTables(t);
            }
        }, [tablesPO, tablesNonPO, invoice]);

        useEffect(() => {
            const t = lineTypes.map((value) => {
                return {
                    label: value.name,
                    value: value.type,
                };
            });
            setLines(t);
        }, [invoice, lineTypes]);

        useEffect(() => {
            const t = fieldsData.map((value) => {
                return {
                    label: value,
                    value: value,
                };
            });
            setFields(t);
        }, [fieldsData, invoice]);

        useEffect(() => {
            if (currentTable) {
                if (fieldsCache[currentTable]) {
                    setFieldsData(fieldsCache[currentTable]);
                }
            } else {
                setFieldsData([]);
            }
        }, [currentTable, fieldsCache]);

        useImperativeHandle(ref, () => ({ validate, cleanErrors }));

        function validate(): boolean {
            return formRef.current?.check();
        }

        function cleanErrors(): void {
            formRef.current?.cleanErrors();
        }

        const handleTargetTableChange = (value: string) => {
            setFormData({
                ...formData,
                [formKey.targetTable]: value,
                [formKey.targetField]: "",
            });
            invoice.targetField = "";
            setInvoiceRowProperty(formKey.targetTable, value, invoice);
            setCurrentTable(value);
        };

        return (
            <Form ref={formRef} model={formModel} formValue={formData}>
                <div className={"tableRowFields"}>
                    <div className="cellFields">
                        <Checkbox
                            value={invoice.enabled}
                            checked={invoice.enabled}
                            onChange={(value: any) => {
                                handleFieldChange("enabled", !value);
                            }}
                        ></Checkbox>
                    </div>
                    <div className="cellFields">
                        <FormControl
                            accepter={InputPicker}
                            data={tables}
                            value={invoice.targetTable}
                            virtualized={false}
                            cleanable={false}
                            placeholder={t(
                                "tenantConfig.customFieldsPage.table.targetTablePlaceholder"
                            )}
                            name={formKey.targetTable}
                            className={"tablePicker"}
                            onChange={(v) => handleTargetTableChange(v)}
                        />
                    </div>
                    <div className="cellFields">
                        <FormControl
                            accepter={InputPicker}
                            data={fields}
                            value={invoice.targetField}
                            virtualized={false}
                            cleanable={false}
                            disabled={!currentTable}
                            name={formKey.targetField}
                            placeholder={t(
                                "tenantConfig.customFieldsPage.table.targetFieldPlaceholder"
                            )}
                            className={`tablePicker ${
                                !currentTable ? "disabledPicker" : ""
                            }`}
                            onChange={(v) =>
                                handleFieldChange(formKey.targetField, v)
                            }
                        />
                    </div>
                    {invoice.invoiceType === CustomFieldInvoiceType.NonPo && (
                        <div className="cellFields">
                            <FormControl
                                accepter={TagPicker}
                                data={lines}
                                value={invoice.excludedLineTypes}
                                virtualized={false}
                                className={"tablePicker"}
                                name={formKey.excludedLineTypes}
                                placeholder={t(
                                    "tenantConfig.customFieldsPage.table.excludedLineTypesPlaceholder"
                                )}
                                onChange={(v) =>
                                    handleFieldChange(
                                        formKey.excludedLineTypes,
                                        v
                                    )
                                }
                            />
                        </div>
                    )}
                    <div className="cellFields action-cell">
                        <div>
                            <ButtonToolbar
                                className="table__button-toolbar"
                                style={{ alignItems: "center" }}
                            >
                                <IconButton
                                    icon={<Icon icon={"trash2"}></Icon>}
                                    onClick={() => {
                                        deleteRow(invoice);
                                    }}
                                />
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
);

export default InvoiceRow;
