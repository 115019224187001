import constants from "utils/constants";

const topMenuItems = [
    {
        route: constants.routePath.customers,
        name: "Customers",
    },
    {
        route: constants.routePath.environments,
        name: "Environments",
    },
    {
        route: constants.routePath.partners,
        name: "Partners",
    },
    {
        route: constants.routePath.deliverables,
        name: "Deliverables",
    },
];
export default topMenuItems;
