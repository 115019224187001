export const SET_TENANT_STATUS_OPENED =
    "tenant-status/SET_ENVIRONMENT_HISTORY_OPENED";

export const SET_TENANT_STATUS_INFO = "tenant-status/SET_TENANT_STATUS_INFO";

export const SET_TENANT_MONIKER = "tenant-status/SET_TENANT_MONIKER";

export interface ISetTenantStatusOpened {
    type: typeof SET_TENANT_STATUS_OPENED;
    isTenantStatusOpened: boolean;
}

export interface ISetTenantStatusInfo {
    type: typeof SET_TENANT_STATUS_INFO;
    versionInfo: ITenantVersionInfo;
}

export interface ISetTenantMoniker {
    type: typeof SET_TENANT_MONIKER;
    tenantMoniker: string;
}

export type ITenantVersionInfo = {
    name: string;
    id: string;
    d365ResponseTime: number;
    fetchErrorMessage: string | null;
    fetchStatus: number;
    refreshParametersErrorMessage: string | null;
    refreshParametersStatus: number;
    applicationVersion: string | null;
    dooapVersion: string | null;
    platformVersion: string | null;
    lastSyncTime: string | null;
    authenticationMethod: string | null;
    d365ThrottlingPriority: string | null;
    d365ThrottlingPriorityErrorMessage: string | null;
    d365ThrottlingPriorityStatus: number | null;
};

export interface State {
    tenantMoniker: string;
    tenantVersionInfo: ITenantVersionInfo;
    isTenantStatusOpened: boolean;
}

export type Actions =
    | ISetTenantStatusOpened
    | ISetTenantStatusInfo
    | ISetTenantMoniker;
