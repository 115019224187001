import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import constants from "utils/constants";
import { IHubConnection } from "utils/types";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import { ContainerProps } from "rsuite";
import { getDeleteTenantHubConnection } from "api/environments/tenants/tenant-delete-api";

interface IComponentProps extends ContainerProps {
    deleteWorkflowId: string | null;
    updateWorkflow: (workFlow: ITenantDeleteWorkflow) => void;
}

const SignalRTenantDelete: React.FC<IComponentProps> = ({
    deleteWorkflowId,
    children,
    updateWorkflow,
}: IComponentProps) => {
    const [hubInfo, setHubInfo] = useState<IHubConnection | null>(null);
    const [hubConnectionBuilder, setHubConnectionBuilder] =
        useState<HubConnectionBuilder | null>(null);

    const [hubConnection, setHubConnection] = useState<HubConnection | null>(
        null
    );
    useEffect(() => {
        if (deleteWorkflowId !== null && hubInfo === null) {
            getDeleteTenantHubConnection(deleteWorkflowId).then(
                ([payload, _]) => {
                    setHubInfo(payload);
                }
            );
        }
    }, [deleteWorkflowId, hubInfo]);

    useEffect(() => {
        if (hubInfo !== null) {
            if (hubConnectionBuilder === null) {
                setHubConnectionBuilder(
                    new HubConnectionBuilder()
                        .withAutomaticReconnect()
                        .withUrl(hubInfo.url, {
                            accessTokenFactory: () => hubInfo.accessToken,
                        })
                );
            }
        }
    }, [
        hubInfo,
        hubConnectionBuilder,
        updateWorkflow,
        setHubConnectionBuilder,
    ]);
    useEffect(() => {
        if (hubConnectionBuilder !== null) {
            setHubConnection(hubConnectionBuilder.build());
        }
    }, [hubConnectionBuilder]);
    useEffect(() => {
        if (hubConnection !== null) {
            hubConnection.on(
                constants.enrollNewSignalRMessageMethodName,
                (workFlow: string) => {
                    updateWorkflow(
                        JSON.parse(workFlow) as ITenantDeleteWorkflow
                    );
                }
            );
            hubConnection.start();
        }
        return () => {
            if (hubConnection !== null) {
                hubConnection.stop();
            }
        };
    }, [hubConnection]);
    return <>{children}</>;
};

export default SignalRTenantDelete;
