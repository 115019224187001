import { connect } from "react-redux";
import { compose } from "redux";
import { RootState } from "redux/store";
import BreadCrumbSwitch from "./BreadCrumbSwitch";
import {
    setActiveTenant,
    setIsTenantFetched,
} from "redux/environments/tenants/tenants-actions";
import {
    setActiveEnvironmentById,
    setActiveEnvironmentTenantsMonikers,
} from "redux/environments/environment-actions";
import {
    getActiveEnvironmentMoniker,
    getActiveEnvironmentName,
    getEnvironmentList,
} from "redux/environments/environment-selectors";
import {
    getIsActiveTenantSet,
    getTenants,
} from "redux/environments/tenants/tenants-selectors";
import { getCustomers } from "redux/customers/customers-selectors";
import { getPartners } from "redux/partners/partners-selectors";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "redux/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "redux/common/page-refresh/page-refresh-actions";
import { setIsDeleteFetched } from "redux/environments/tenants/tenant-delete/tenant-delete-actions";

const mapStateToProps = (state: RootState) => {
    return {
        environments: getEnvironmentList(state),
        activeEnvironmentName: getActiveEnvironmentName(state),
        tenants: getTenants(state),
        customers: getCustomers(state),
        partners: getPartners(state),
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
        activeEnvMoniker: getActiveEnvironmentMoniker(state),
        isActiveTenantSet: getIsActiveTenantSet(state),
    };
};

const BreadCrumbSwitchContainer = compose(
    connect(mapStateToProps, {
        setActiveTenant,
        setActiveEnvironmentById,
        setActiveEnvironmentTenantsMonikers,
        setModalConfirmCallback,
        setRefreshActivated,
        setIsUnsavedChangeAvailable,
        setIsTenantFetched,
        setIsDeleteFetched,
    })
)(BreadCrumbSwitch);

export default BreadCrumbSwitchContainer;
