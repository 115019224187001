import {
    CLEAR_SECRET_ACTIVE_STEP_DATA,
    SET_ACTIVE_STEP,
    SET_ACTIVE_STEP_DATA,
    SET_ENROLL_CACHE,
    SET_ENROLL_WORKFLOW,
    SET_INITIAL_STATE,
    SET_ORCHESTRATOR_INSTANCE_ID,
    UPDATE_STEP,
    Actions,
    IState,
} from "./enroll-tenant-types";

const initialState: IState = {
    orchestratorInstanceId: null,
    enrollTenant: null,
    enrollCache: null,
    hubInfo: null,
    activeStep: null,
};

const enrollTenantReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_ENROLL_WORKFLOW:
            return {
                ...state,
                enrollTenant: action.enrollTenant,
            };

        case UPDATE_STEP:
            const stepIndex =
                state.enrollTenant !== null
                    ? state.enrollTenant?.steps.findIndex(
                          (step) => step.name === action.step.name
                      )
                    : -1;
            if (state.enrollTenant === null || stepIndex === -1) {
                return state;
            }

            let activeStep = state.activeStep ? { ...state.activeStep } : null;

            activeStep = {
                ...state.enrollTenant?.steps[stepIndex],
                status: action.step.status,
                logs: action.step.logs,
                data: action.step.data,
            };

            return {
                ...state,
                enrollTenant: {
                    ...state.enrollTenant,
                    steps: state.enrollTenant.steps.map((step, index) => {
                        if (index !== stepIndex) {
                            return step;
                        }

                        return {
                            ...step,
                            status: action.step.status,
                            data: action.step.data,
                            logs: action.step.logs,
                        };
                    }),
                },
                activeStep: activeStep,
            };

        case SET_ORCHESTRATOR_INSTANCE_ID:
            return {
                ...state,
                orchestratorInstanceId: action.orchestratorInstanceId,
            };

        case SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.activeStep || null,
            };

        case SET_ACTIVE_STEP_DATA:
            if (!state.activeStep || !state.enrollTenant || !action.data) {
                return state;
            }

            return {
                ...state,
                activeStep: {
                    ...state.activeStep,
                    data: action.data,
                },
                enrollTenant: {
                    ...state.enrollTenant,
                    steps: state.enrollTenant.steps.map((step) => {
                        if (step.id === state.activeStep?.id) {
                            step.data = action.data;
                        }

                        return step;
                    }),
                },
            };

        case CLEAR_SECRET_ACTIVE_STEP_DATA:
            if (!state.activeStep || !state.enrollTenant) {
                return state;
            }

            return {
                ...state,
                enrollTenant: {
                    ...state.enrollTenant,
                    steps: state.enrollTenant.steps.map((step) => {
                        if (step.id === state.activeStep?.id) {
                            step.data = JSON.stringify({
                                ...action.data,
                                username: "",
                                password: "",
                                axClientSecret: "",
                            });
                        }

                        return step;
                    }),
                },
            };

        case SET_INITIAL_STATE:
            return {
                ...initialState,
            };

        case SET_ENROLL_CACHE:
            return {
                ...state,
                enrollCache: action.enrollCache,
            };

        default:
            return state;
    }
};

export default enrollTenantReducer;
