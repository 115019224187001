import { IEnvironmentFilter } from "./filters-types";
import { RootState } from "../store";
import { getActiveEnvironmentId } from "../environments/environment-selectors";
import { createSelector } from "reselect";

export const getEnvironmentFilters = (state: RootState) => {
    return state.filtersReducer.environmentFilters;
};
export const getActiveEnvironmentFilters = (state: RootState) => {
    const activeEnvironmentId = getActiveEnvironmentId(state);

    return getEnvironmentFilters(state).find(
        (filter: IEnvironmentFilter) => filter.id === activeEnvironmentId
    );
};
export const getCustomerFilterStatus = (state: RootState) => {
    return state.filtersReducer.customerFilters.status;
};
export const getCustomerStatusCalculated = (state: RootState) => {
    return state.filtersReducer.customerFilters.isStatusCalculated;
};
export const getCustomerFilterSearchText = (state: RootState) => {
    return state.filtersReducer.customerFilters.search;
};
export const getPartnerFilterSearchText = (state: RootState): string => {
    return state.filtersReducer.partnerFilters.search;
};
export const getActiveEnvironmentFilterStatusSelector = createSelector(
    [getActiveEnvironmentFilters],
    (filter) => {
        return filter?.status;
    }
);
export const getActiveEnvironmentFilterSearchTextSelector = createSelector(
    [getActiveEnvironmentFilters],
    (filter) => {
        return filter?.search;
    }
);
