import { connect } from "react-redux";
import SignalREnrollTenant from "./SignalREnrollTenant";
import { updateStep } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";
import { RootState } from "redux/store";

const mapStateToProps = (state: RootState) => {
    return {
        orchestratorInstanceId:
            state.enrollTenantReducer.orchestratorInstanceId,
    };
};

const SignalREnrollTenantContainer = connect(mapStateToProps, {
    updateStep,
})(SignalREnrollTenant);

export default SignalREnrollTenantContainer;
