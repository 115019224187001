import { API } from "utils/api-config";
import { TConfluenceDocumentLinks } from "redux/information-links/information-links-types";
import { defaultErrorResponse } from "./defaults";
import Axios, { CancelTokenSource } from "axios";

export const loadConfluenceDocumentLinks = async (
    cancelTokenSource?: CancelTokenSource
): Promise<TConfluenceDocumentLinks> => {
    const response = await API.get(`/links/confluence-documents`, {
        cancelToken: cancelTokenSource?.token,
    });

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const loadSeqLogsLink = async (
    cancelTokenSource?: CancelTokenSource
): Promise<[string | null, number]> => {
    const response = await API.get(`/links/seq-logs`, {
        cancelToken: cancelTokenSource?.token,
    });

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
