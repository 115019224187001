import React, { useEffect, useRef } from "react";
import EnvironmentCard from "./EnvironmentCard/EnvironmentCard";
import { IEnvironment } from "redux/environments/environment-types";
import { useTranslation } from "react-i18next";
import useRefreshPage from "hooks/useRefreshPage";
import ErrorSectionContainer from "../Common/ErrorSection/ErrorSectionContainer";
import "./Environments.less";

interface ComponentProps {
    environmentList: IEnvironment[];
    setActiveEnvironmentById: (id: string) => void;
    setActiveEnvironmentTenantsMonikers: () => void;
    fetchEnvironments: (EnvName?: string) => void;
    errorMessage: string;
}
const Environments: React.FC<ComponentProps> = ({
    environmentList,
    setActiveEnvironmentById,
    setActiveEnvironmentTenantsMonikers,
    fetchEnvironments,
    errorMessage,
}: ComponentProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const environmentsGetter = () => {
        fetchEnvironments();
    };

    useEffect(environmentsGetter, [fetchEnvironments]);
    useRefreshPage(() => {
        containerRef.current?.scrollTo(0, 0);
        environmentsGetter();
    });

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    return (
        <>
            <h1 className="environments__header">
                {t("environment.layoutHeader")}
            </h1>
            <div ref={containerRef} className="cards-layout">
                {environmentList.map((env, index: number) => {
                    return (
                        <div
                            key={String(index)}
                            onClick={() => {
                                setActiveEnvironmentById(env.id);
                                setActiveEnvironmentTenantsMonikers();
                            }}
                        >
                            <EnvironmentCard environment={env} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Environments;
