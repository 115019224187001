import { CustomerType } from "../../customers/add-new-customer/add-new-customer-types";

export const SET_TENANTS = "tenants/SET_TENANTS";
export const SET_ACTIVE_TENANT = "tenants/SET_ACTIVE_TENANT";
export const SET_ACTIVE_TENANT_BY_ID = "tenants/SET_ACTIVE_TENANT_BY_ID";
export const UPDATE_ACTIVE_TENANT_DISPLAY_NAME =
    "tenants/UPDATE_ACTIVE_TENANT_DISPLAY_NAME";
export const SET_IS_TENANT_FETCHED = "tenants/SET_IS_TENANT_FETCHED";
export const UPDATE_TENANT_VERSION_INFO = "tenants/UPDATE_TENANT_VERSION_INFO";

export type TPromise = [ITenant[] | null, number];
export interface IVersionInfo {
    name: string;
    id: string;
    d365ResponseTime: number;
    fetchErrorMessage: string | null;
    fetchStatus: number;
    refreshParametersErrorMessage: string | null;
    refreshParametersStatus: number;
    applicationVersion: string | null;
    dooapVersion: string | null;
    platformVersion: string | null;
    lastSyncTime: string | null;
    authenticationMethod: string | null;
    d365ThrottlingPriority: string | null;
    d365ThrottlingPriorityErrorMessage: string | null;
    d365ThrottlingPriorityStatus: number | null;
}
export interface ITenant {
    enrollWorkflowId: string | null;
    moniker: string;
    customerName: string | null;
    customerType: CustomerType | null;
    partners: string[];
    packageDeliveryGroup: string | null;
    versionInfo: IVersionInfo;
    dooapVersionStatus: TenantStatus;
    dooapStatusMessage: string;
}
export interface ITenantNameInfo {
    name: string;
    id: string;
}
export interface ITenantPingInfo {
    tenantId: string;
    errorMessage: string;
}
export enum TenantStatus {
    Normal = 0,
    Warning = 1,
    Error = 2,
    Enroll = 3,
    Deleting = 4,
}

export enum D365RefreshParametersStatus {
    Success = 0,
    Failed = 1,
}

export enum SecretExpirationStatus {
    Normal = 0,
    Warning = 1,
    Error = 2,
}

export enum DeletionType {
    EnrollDeletion = 0,
    TenantDeletion = 1,
}

export enum ViewMode {
    Card = 0,
    List = 1,
}

export interface ISetTenants {
    type: typeof SET_TENANTS;
    tenants: ITenant[];
}
export interface ISetActiveTenant {
    type: typeof SET_ACTIVE_TENANT;
    activeTenant: ITenant;
}
export interface ISetActiveTenantById {
    type: typeof SET_ACTIVE_TENANT_BY_ID;
    id: string;
}
export interface IUpdateActiveTenantDisplayName {
    type: typeof UPDATE_ACTIVE_TENANT_DISPLAY_NAME;
    name: string;
}
export interface ISetIsTenantFetched {
    type: typeof SET_IS_TENANT_FETCHED;
    isFetched: boolean;
}

export interface IUpdateTenantVersionInfo {
    type: typeof UPDATE_TENANT_VERSION_INFO;
    moniker: string;
    versionInfo: IVersionInfo;
}

export interface State {
    tenants: ITenant[];
    activeTenant: ITenant;
    isActiveTenantSet: boolean;
    isTenantFetched: boolean;
}
export type Actions =
    | ISetTenants
    | ISetActiveTenant
    | ISetActiveTenantById
    | IUpdateActiveTenantDisplayName
    | ISetIsTenantFetched
    | IUpdateTenantVersionInfo;
