export const SET_USER_PERMISSIONS = "app-global/SET_USER_PERMISSIONS";

export interface PartnerPermissions {
    id: string;
    partnerName: string;
    aadGroupName: string;
}

export interface TenantPermissions {
    id: string;
    moniker: string;
    isPowerBiWorkspaceDeploymentAllowed: boolean;
}

export interface CustomerPermissions {
    id: string;
    customerName: string;
}

export interface DooapEnvironmentPermissions {
    moniker: string;
    controlAppRegion: string;
    dooapRegion: string;
    isEnrollAllowed: boolean;
    isDeleteAllowed: boolean;
    isAllTenantsViewAllowed: boolean;
    tenantPermissions: TenantPermissions[];
}

export interface UserPermissions {
    isControlAppAccessAllowed: boolean;
    partnerPermissions: PartnerPermissions[];
    environmentPermissions: DooapEnvironmentPermissions[];
    customerPermissions: CustomerPermissions[];
    deliverablesPermissions: {
        isPackagesModificationAllowed: boolean;
        isTemplatesAccessAllowed: boolean;
    };
}

export interface SetUserPermissions {
    type: typeof SET_USER_PERMISSIONS;
    userPermissions: UserPermissions;
}

export interface State {
    userPermissions: UserPermissions;
}

export type Actions = SetUserPermissions;
