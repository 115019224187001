export interface IAxImportEntity {
    id: string;
    isAxImportTurnedOn: boolean | null;
    isCefTurnedOn: boolean | null;
    importEntityName: string;
}

export enum MessageType {
    Information = 0,
    Warning,
    Error,
}

export interface IMessage {
    message: string;
    type: MessageType;
}

export interface IAxImport {
    axImportEntities: IAxImportEntity[];
    message: IMessage | null;
    isDataTransferAllowed: boolean;
}
