import { defaultErrorResponse } from "api/defaults";
import { IAppRegistrationSecretExpiration } from "redux/environments/environment-types";
import { API } from "utils/api-config";

export const loadDooapAppRegistrationInfo = async (
    environmentMoniker: string
): Promise<[IAppRegistrationSecretExpiration | null, number]> => {
    const response = await API.get(
        `/environments/${environmentMoniker}/app-registrations/web`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
