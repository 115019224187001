export const SET_USER_NAME = "Authentication/SET_USER_NAME";

//  types
export interface State {
    userName: string;
}
export interface ISetUserName {
    type: typeof SET_USER_NAME;
    userName: string;
}

export type Actions = ISetUserName;
