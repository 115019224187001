import React from "react";
import { useTranslation } from "react-i18next";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { Nav } from "rsuite";
import { v4 as uuid } from "uuid";
import "./CustomerNavigation.less";
import { CustomerSubpage } from "redux/customers/customer-details/customer-details-types";
import { IAppSettings } from "redux/app-settings/app-settings-types";

interface ComponentProps {
    activeTab: string;
    isDataLoading: boolean;
    isUnsavedChangeAvailable: boolean;
    appSettings: IAppSettings;
    setActiveTab: (activeTab: string) => void;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}

const CustomerNavigation: React.FC<ComponentProps> = ({
    activeTab,
    isDataLoading,
    isUnsavedChangeAvailable,
    appSettings,
    setActiveTab,
    setModalConfirmCallback,
    setIsUnsavedChangeAvailable,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();

    const onSelectTabHandler = (eventKey: string) => {
        if (isUnsavedChangeAvailable && eventKey !== activeTab) {
            setModalConfirmCallback({
                id: uuid(),
                isAutoDeleteResistant: false,
                isPageRedirect: true,
                callback: () => {
                    setIsUnsavedChangeAvailable(false);
                    setActiveTab(eventKey);
                },
            });
            setRefreshActivated(true);
            return;
        }
        setActiveTab(eventKey);
    };

    return (
        <Nav
            appearance="subtle"
            activeKey={activeTab}
            onSelect={onSelectTabHandler}
            className={"navbar__container"}
        >
            <Nav.Item
                eventKey={CustomerSubpage.General}
                disabled={isDataLoading}
            >
                {t("customerDetails.nav.general")}
            </Nav.Item>
            {appSettings.isDeliverablesEnabled && (
                <Nav.Item
                    eventKey={CustomerSubpage.PackageDelivery}
                    disabled={isDataLoading}
                >
                    {t("customerDetails.nav.packageDelivery")}
                </Nav.Item>
            )}
        </Nav>
    );
};

export default CustomerNavigation;
