import React, { useEffect, useRef, useState } from "react";
import { Icon } from "rsuite";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
    D365RefreshParametersStatus,
    ITenant,
    ITenantPingInfo,
    IVersionInfo,
    TenantStatus,
    ViewMode,
} from "redux/environments/tenants/tenants-types";
import FiltersContainer from "./TenantFilters/TenantFiltersContainer";
import ToggleButton from "./ToggleButton/ToggleButton";
import SearchBar from "components/SearchBar/SearchBarTenantsContainer";
import useRefreshPage from "hooks/useRefreshPage";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import { IAppSettings } from "redux/app-settings/app-settings-types";
import constants from "utils/constants";
import { IRouteState } from "utils/types";
import { ITenantRouteState } from "utils/route-state-types";
import { loadD365RefreshParametersConfig } from "api/environments/tenants/tenant-info-api";
import { getErrorMessage } from "api/defaults";
import CardView from "./TenantViews/CardView/CardView";
import ListView from "./TenantViews/ListView/ListView";
import cn from "classnames";
import "./Tenants.less";
import { loadSeqLogsLink } from "api/information-links-api";
import { ITenantVersionInfo } from "redux/environments/tenants/tenant-status/tenant-status-types";
import { CancelTokenSource } from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";

interface ComponentProps {
    appSettings: IAppSettings;
    activeEnvName: string;
    activeEnvMoniker: string;
    activeEnvironmentTenantIds: string[];
    modifiedTenants: ITenant[];
    errorMessage: string;
    tenantsPingInfo: ITenantPingInfo[];
    isLoaderVisible: boolean;
    isUserPartner: boolean;
    fetchEnvironments: (EnvName?: string) => void;
    fetchTenants: (id: string) => void;
    setActiveTenant: (tenant: ITenant) => void;
    pingTenantInD365: (
        envMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
    setErrorResponseMessage: (msg: string) => void;
    setLoaderVisibility: (visible: boolean) => void;
    setTenantStatusOpened: (isOpened: boolean) => void;
    setTenantStatusInfo: (versionInfo: ITenantVersionInfo) => void;
    setTenantMoniker: (tenantMoniker: string) => void;
    setErrorMessageInactiveTenant: (errorMsg: string) => void;
    updateTenantVersionInfo: (
        tenantMoniker: string,
        versionInfo: IVersionInfo
    ) => void;
}
const Tenants: React.FC<ComponentProps> = ({
    appSettings,
    activeEnvName,
    activeEnvMoniker,
    activeEnvironmentTenantIds,
    modifiedTenants,
    errorMessage,
    tenantsPingInfo,
    isLoaderVisible,
    isUserPartner,
    fetchEnvironments,
    fetchTenants,
    setActiveTenant,
    pingTenantInD365,
    setErrorResponseMessage,
    setLoaderVisibility,
    setTenantStatusOpened,
    setTenantStatusInfo,
    setTenantMoniker,
    setErrorMessageInactiveTenant,
    updateTenantVersionInfo,
}: ComponentProps) => {
    const { t } = useTranslation();
    const routeHistory = useHistory();
    const location = useLocation();
    const containerRef = useRef<HTMLDivElement>(null);
    const activeEnvironmentName: string = location.pathname.split("/")[2];
    const tenantPingTimeout =
        Number(appSettings.pingTimeout) || constants.pingTimeoutDefault;
    const [isD365ConfigLoaded, setIsD365ConfigLoaded] = useState<boolean>(
        false
    );
    const [
        isD365ParametersRefreshAllowed,
        setIsD365ParametersRefreshAllowed,
    ] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.List);
    const [seqLogsLink, setSeqLogsLink] = useState<string>("");
    const [isSeqLogsLinkLoaded, setSeqLogsLinkLoaded] = useState<boolean>(
        false
    );

    const cancelTokenSource = useRequestCancellation();

    const routeToTenantConfiguration = (tenant: ITenant) => {
        setErrorMessageInactiveTenant("");
        if (!activeEnvName || !activeEnvMoniker || !tenant) {
            return;
        }

        const futureRouteState: IRouteState<ITenantRouteState> = {
            data: {
                envMoniker: activeEnvMoniker,
                tenant: tenant,
            },
        };
        routeHistory.push(
            `/environments/${activeEnvName}/tenants/${tenant.versionInfo.id}/general-configs`,
            futureRouteState
        );
    };

    const routeToTenantEnrollment = (enrollWorkflowId: string | null) => {
        if (!activeEnvName || !enrollWorkflowId) {
            return;
        }

        routeHistory.push(
            `/environments/${activeEnvName}/enroll-tenant/${enrollWorkflowId}/`
        );
    };
    const fetchD365ParametersConfig = (activeEnvMoniker: string) => {
        setErrorResponseMessage("");
        loadD365RefreshParametersConfig(activeEnvMoniker).then(
            ([payload, status]) => {
                if (payload !== null && status === constants.statusCode.OK) {
                    setIsD365ParametersRefreshAllowed(payload);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            }
        );
        setIsD365ConfigLoaded(true);
    };

    const fetchSeqLogsLink = () => {
        setErrorResponseMessage("");
        setLoaderVisibility(true);
        loadSeqLogsLink().then(([payload, statusCode]) => {
            if (payload && statusCode === constants.statusCode.OK) {
                setSeqLogsLink(payload);
            } else {
                setErrorResponseMessage(
                    getErrorMessage(String(payload), statusCode)
                );
            }
            setLoaderVisibility(false);
        });
        setSeqLogsLinkLoaded(true);
    };

    const routeToTenant = (tenant: ITenant) => {
        setActiveTenant(tenant);
        if (tenant.versionInfo.fetchStatus === TenantStatus.Enroll) {
            routeToTenantEnrollment(tenant.enrollWorkflowId);
        } else {
            routeToTenantConfiguration(tenant);
        }
    };

    const getVersionStatusMessage = (tenant: ITenant) => {
        if (tenant.versionInfo.dooapVersion) {
            if (tenant.dooapVersionStatus === TenantStatus.Warning) {
                return `${tenant.versionInfo.dooapVersion} (${t(
                    "tenantDashboard.statusMessages.dooapVersionStatusWarning"
                )})`;
            } else if (tenant.dooapVersionStatus === TenantStatus.Error) {
                return `${tenant.versionInfo.dooapVersion} (${t(
                    "tenantDashboard.statusMessages.dooapVersionStatusError"
                )})`;
            } else {
                return tenant.versionInfo.dooapVersion;
            }
        } else {
            return t("unknown");
        }
    };

    const getErpVersion = (tenant: ITenant, value: string | null) => {
        return (
            <span
                className={cn(
                    {
                        "tenant-warning_status":
                            tenant.dooapVersionStatus === TenantStatus.Warning,
                    },
                    {
                        "tenant-error_status":
                            tenant.dooapVersionStatus === TenantStatus.Error &&
                            tenant.versionInfo.dooapVersion,
                    }
                )}
            >
                {value ?? getVersionStatusMessage(tenant)}
            </span>
        );
    };

    const getFetchStatusText = (fetchStatus: TenantStatus) => {
        switch (fetchStatus) {
            case TenantStatus.Error:
                return t("status.error");

            case TenantStatus.Enroll:
                return t("status.enroll");

            case TenantStatus.Deleting:
                return t("status.deleting");

            default:
                return t("status.normal");
        }
    };

    const getPingStatusText = (pingInfo: ITenantPingInfo | undefined) => {
        if (pingInfo?.errorMessage) {
            return t("status.error");
        } else {
            return t("status.normal");
        }
    };

    const getRefreshParametersText = (
        status: number,
        message: string | null
    ) => {
        switch (status) {
            case D365RefreshParametersStatus.Failed:
                return !message ? "-" : t("status.failed");

            default:
                return t("status.success");
        }
    };

    useEffect(() => {
        if (activeEnvMoniker && !isD365ConfigLoaded) {
            fetchD365ParametersConfig(activeEnvMoniker);
        }
    }, [isD365ConfigLoaded, setIsD365ConfigLoaded, fetchD365ParametersConfig]);

    useEffect(() => {
        if (activeEnvMoniker && !isSeqLogsLinkLoaded) {
            fetchSeqLogsLink();
        }
    }, [isSeqLogsLinkLoaded, fetchSeqLogsLink]);

    useEffect(() => {
        if (!activeEnvMoniker) {
            fetchEnvironments(activeEnvironmentName);
        }
    }, [activeEnvMoniker, activeEnvironmentName, fetchEnvironments]);

    useEffect(() => {
        fetchTenants(activeEnvMoniker);
    }, [activeEnvMoniker]);

    //  Show Error on UI on accessing non-existing Env
    useInactiveEnvironment();

    useEffect(() => {
        const interval = setInterval(() => {
            activeEnvironmentTenantIds.forEach(async (tenantId) => {
                await pingTenantInD365(
                    activeEnvMoniker,
                    tenantId,
                    cancelTokenSource
                );
            });
        }, tenantPingTimeout);

        return () => clearInterval(interval);
    }, [
        activeEnvironmentTenantIds,
        activeEnvMoniker,
        pingTenantInD365,
        tenantPingTimeout,
    ]);

    useRefreshPage(() => {
        if (activeEnvMoniker) {
            containerRef.current?.scrollTo(0, 0);
            fetchEnvironments(activeEnvironmentName);
            fetchTenants(activeEnvMoniker);
        }
    });

    if (errorMessage.length > 0) {
        return <ErrorSectionContainer />;
    }

    const tenantsView = () => {
        switch (viewMode) {
            case ViewMode.Card:
                return (
                    <CardView
                        modifiedTenants={modifiedTenants}
                        activeEnvMoniker={activeEnvMoniker}
                        activeEnvironmentName={activeEnvironmentName}
                        tenantsPingInfo={tenantsPingInfo}
                        isD365ParametersRefreshAllowed={
                            isD365ParametersRefreshAllowed
                        }
                        containerRef={containerRef}
                        seqLogsLink={seqLogsLink}
                        isUserPartner={isUserPartner}
                        isDeliverablesEnabled={
                            appSettings.isDeliverablesEnabled
                        }
                        routeToTenant={routeToTenant}
                        getErpVersion={getErpVersion}
                        getFetchStatusText={getFetchStatusText}
                        getPingStatusText={getPingStatusText}
                        getRefreshParametersText={getRefreshParametersText}
                        pingTenantInD365={pingTenantInD365}
                    />
                );
            default:
                return (
                    <ListView
                        modifiedTenants={modifiedTenants}
                        activeEnvMoniker={activeEnvMoniker}
                        activeEnvironmentName={activeEnvironmentName}
                        tenantsPingInfo={tenantsPingInfo}
                        isD365ParametersRefreshAllowed={
                            isD365ParametersRefreshAllowed
                        }
                        containerRef={containerRef}
                        isLoaderVisible={isLoaderVisible}
                        seqLogsLink={seqLogsLink}
                        isUserPartner={isUserPartner}
                        isDeliverablesEnabled={
                            appSettings.isDeliverablesEnabled
                        }
                        routeToTenant={routeToTenant}
                        getErpVersion={getErpVersion}
                        getVersionStatusMessage={getVersionStatusMessage}
                        getFetchStatusText={getFetchStatusText}
                        getPingStatusText={getPingStatusText}
                        getRefreshParametersText={getRefreshParametersText}
                        setTenantStatusOpened={setTenantStatusOpened}
                        setTenantStatusInfo={setTenantStatusInfo}
                        setTenantMoniker={setTenantMoniker}
                        updateTenantVersionInfo={updateTenantVersionInfo}
                    />
                );
        }
    };

    return (
        <div className="tenants__container">
            <div className="tenants__header">
                <h3 className="tenants__title">
                    {t("tenantDashboard.tenantsHeader")}
                </h3>
                <div className="tenants__search-section">
                    <SearchBar />
                    <Icon icon="filter" className="tenants__filter-icon" />
                    <FiltersContainer />
                    <ToggleButton
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />
                </div>
            </div>
            {tenantsView()}
        </div>
    );
};

export default Tenants;
