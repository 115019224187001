export const SET_APP_SETTINGS = "tenants/GET_APP_SETTINGS";

export type TAppSettings = [IAppSettings | null, number];

export interface IAppSettings {
    pingTimeout: number;
    isDeliverablesEnabled: boolean;
    isPowerBiWorkspaceDeploymentEnabled: boolean;
    isRunAllEnabled: boolean;
    isDataRefreshEnabled: boolean;
    isAlertRuleEstablishmentEnabled: boolean;
    isInvoiceVolumeStoringEnabled: boolean;
}

export interface IGetAppSettings {
    type: typeof SET_APP_SETTINGS;
    appSettings: IAppSettings;
}

export interface State {
    appSettings: IAppSettings;
    isAppSettingsFetched: boolean;
}

export type Actions = IGetAppSettings;
