import { RootState } from "redux/store";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    setConsentStatus,
    setCustomerName,
} from "redux/customers/customer-details/customer-details-actions";
import GeneralDetails from "./GeneralDetails";
import { getCustomerNames } from "redux/customers/customers-selectors";
import withLoader from "hocs/withLoader/withLoader";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";

const mapStateToProps = (state: RootState) => {
    return {
        customerNames: getCustomerNames(state),
        customerDetails: state.customerDetailsReducer.customerDetails,
    };
};
const GeneralDetailsContainer = compose(
    connect(mapStateToProps, {
        setConsentStatus,
        setCustomerName,
        setIsUnsavedChangeAvailable,
    }),
    withLoader
)(GeneralDetails);

export default GeneralDetailsContainer;
