import React, { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Icon,
    InputGroup,
    Schema,
    Tag,
    TagGroup,
    Tooltip,
    Whisper,
} from "rsuite";
import cn from "classnames";
import "./EmailAddressesBox.less";

interface ComponentProps {
    formRef: MutableRefObject<HTMLFormElement | undefined>;
    emailAddress: string;
    resultEmailAddresses: string[];
    isResultArray: boolean;
    boxLabel: string;
    tooltipText: string | null;
    setEmailAddress: (emailAddressValue: string) => void;
    setResultEmailAddresses: (value: React.SetStateAction<string[]>) => void;
    readOnly?: boolean;
}

const EmailAddressesBox: React.FC<ComponentProps> = ({
    formRef,
    emailAddress,
    resultEmailAddresses,
    isResultArray,
    boxLabel,
    tooltipText,
    setEmailAddress,
    setResultEmailAddresses,
    readOnly,
}: ComponentProps) => {
    const { t } = useTranslation();

    const addMoreClickHandler = () => {
        if (!formRef.current?.check()) {
            return;
        }
        setResultEmailAddresses((oldArray: string[]) => [
            ...oldArray,
            emailAddress,
        ]);
        setEmailAddress("");
    };

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        emailAddress: StringType()
            .isEmail(t("sendEmail.isCorrectEmail"))
            .addRule((value) => {
                return resultEmailAddresses.every(
                    (address) => address !== value
                );
            }, t("sendEmail.isAddressUnique")),
    });
    const formValue = { emailAddress: emailAddress };

    return (
        <Form
            ref={formRef}
            readOnly={readOnly}
            model={model}
            className={"email-box__container"}
            formValue={formValue}
        >
            <FormGroup>
                <ControlLabel>
                    {boxLabel}
                    {tooltipText ? (
                        <Whisper
                            trigger={"hover"}
                            placement={"right"}
                            speaker={<Tooltip>{tooltipText}</Tooltip>}
                        >
                            <Icon
                                icon={"question2"}
                                className={"email-box__tootip"}
                            />
                        </Whisper>
                    ) : null}
                </ControlLabel>
                <InputGroup
                    className={cn(
                        "email-box__input-field",
                        "email-box__input-field--standard"
                    )}
                >
                    <FormControl
                        placeholder={t("sendEmail.emailAddressPlaceholder")}
                        name="emailAddress"
                        onChange={(value) => {
                            setEmailAddress(value);
                        }}
                    />
                    {emailAddress && (
                        <InputGroup.Button
                            className={"email-box__add-more-btn"}
                            onClick={addMoreClickHandler}
                        >
                            {t("sendEmail.addMoreButton")}
                        </InputGroup.Button>
                    )}
                </InputGroup>
            </FormGroup>
            {isResultArray && (
                <Container
                    className={cn(
                        "email-box__input-field",
                        "email-box__result-addresses"
                    )}
                >
                    <TagGroup>
                        {resultEmailAddresses.map((item, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => {
                                    setResultEmailAddresses(
                                        resultEmailAddresses.filter(
                                            (address) => address !== item
                                        )
                                    );
                                }}
                            >
                                {item}
                            </Tag>
                        ))}
                    </TagGroup>
                </Container>
            )}
        </Form>
    );
};

export default EmailAddressesBox;
