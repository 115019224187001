export const SET_ADD_NEW_PARTNER_OPENED =
    "app-global/SET_ADD_NEW_PARTNER_OPENED";
export const SET_ACTIVE_PARTNER = "app-global/SET_ACTIVE_PARTNER";
export const SET_PARTNERS = "app-global/SET_PARTNERS";

export interface IPartner {
    id: string;
    name: string;
    tenantsAmount: number;
    usersAmount: number;
    aadGroupName: string;
}

export interface IActivePartner {
    id: string;
    name: string;
}

export interface INewPartner {
    name: string;
    aadGroupName: string;
}

export interface ICustomer {
    id: string;
    name: string;
    tenants: ITenant[];
}

export interface ITenant {
    id: string;
    name: string;
    moniker: string;
}

export interface IAadGroupUser {
    displayName: string;
    userPrincipalName: string;
}

export interface IPartnerCustomer extends ICustomer {
    partnerId: string;
}

export interface IPartnerDetails {
    id: string;
    name: string;
    aadGroupName: string;
    customers: IPartnerCustomer[];
    aadGroupUsers: IAadGroupUser[];
}

export interface SetAddNewPartnerOpened {
    type: typeof SET_ADD_NEW_PARTNER_OPENED;
    isAddNewPartnerOpened: boolean;
}

export interface SetActivePartner {
    type: typeof SET_ACTIVE_PARTNER;
    activePartner: IActivePartner;
}

export interface SetPartners {
    type: typeof SET_PARTNERS;
    partners: IPartner[];
}

export interface State {
    partners: IPartner[];
    activePartner: {
        name: string;
        id: string;
    };
    isAddNewPartnerOpened: boolean;
}

export type Actions = SetPartners | SetActivePartner | SetAddNewPartnerOpened;
