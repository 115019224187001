import { connect } from "react-redux";
import { RootState } from "redux/store";
import GuestTenantConsents from "./GuestTenantConsents";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import {
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
} from "redux/common/unsaved-data/unsaved-data-actions";
import { setRefreshActivated } from "redux/common/page-refresh/page-refresh-actions";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        isUnsavedChangeAvailable:
            state.unsavedDataReducer.isUnsavedChangeAvailable,
    };
};

const GuestTenantConsentsContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
    setModalConfirmCallback,
    setRefreshActivated,
    setIsUnsavedChangeAvailable,
})(GuestTenantConsents);

export default GuestTenantConsentsContainer;
