import { RootState } from "redux/store";
import { createSelector } from "reselect";
import { IEnvironment, ITenantStatusInfo } from "./environment-types";
import constants from "utils/constants";

export const getEnvironmentList = (state: RootState) => {
    return state.environmentsReducer.environments.sort(compareEnvironments);
};
export const getActiveEnvironment = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment;
};
export const getActiveEnvironmentId = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.id || "";
};
export const getActiveEnvironmentName = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.name || "";
};
export const getActiveEnvironmentMoniker = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.moniker || "";
};
export const getIsEnrollAllowed = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.isEnrollAllowed;
};
export const getIsDeleteAllowed = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.isDeleteAllowed;
};
export const getActiveEnvironmentStatus = (state: RootState) => {
    return (
        state.environmentsReducer.activeEnvironment?.status ||
        constants.defaultIndex
    );
};
export const getActiveEnvironmentTenantsInfo = (state: RootState) => {
    return state.environmentsReducer.activeEnvironment?.tenantsStatusInfo || [];
};
export const getIsActiveEnvironmentSet = (state: RootState) => {
    return state.environmentsReducer.isActiveEnvironmentSet;
};
export const getAppRegistrations = (state: RootState) => {
    return state.environmentsReducer.appRegistrations || [];
};
export const getActiveEnvironmentTenantIds = createSelector(
    [getActiveEnvironmentTenantsInfo],
    (tenantList) => {
        const tenantIds: string[] = [];

        tenantList.forEach((tenant: ITenantStatusInfo) => {
            tenantIds.push(String(tenant.id));
        });

        return tenantIds;
    }
);
const compareEnvironments = (e1: IEnvironment, e2: IEnvironment): number => {
    const environmentName1 = e1.name.toLocaleLowerCase() || "";
    const environmentName2 = e2.name.toLocaleLowerCase() || "";
    if (environmentName1 < environmentName2) {
        return -1;
    }
    if (environmentName1 > environmentName2) {
        return 1;
    }
    return 0;
};
