import * as React from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "redux/customers/customers-types";
import Card from "components/Common/Card/Card";
import constants from "utils/constants";
import { Popover, Whisper } from "rsuite";
import "./CustomerCard.less";

interface ComponentProps {
    customer: ICustomer;
    isDeliverablesEnabled: boolean;
    onRoteTo: (event: any) => void;
}

const CustomerCard: React.FC<ComponentProps> = ({
    customer,
    isDeliverablesEnabled,
    onRoteTo,
}: ComponentProps) => {
    const { t } = useTranslation();

    const partners = customer.partners?.join(", ");

    const isPartnersTooltipVisible = customer.partners
        ? partners.length > constants.customerCardNameLimit
        : false;

    return (
        <>
            <Card
                status={
                    customer.completedSteps !== customer.totalStepsNumber
                        ? "warning"
                        : "normal"
                }
                onClick={onRoteTo}
                bordered
                shaded
                bodyFill
                className="customer-card__container"
            >
                <div className="customer-card__layout">
                    <h2 className="customer-card__header-title">
                        {customer.name}
                    </h2>

                    <span>
                        {t("customers.customerCard.consentStatus") + ":"}
                    </span>
                    <span>{`${customer.completedSteps}/${customer.totalStepsNumber}`}</span>

                    <span>{t("customers.customerCard.customerId") + ":"}</span>
                    <span>{customer.id}</span>

                    <span>{t("customers.customerCard.tenants") + ":"}</span>
                    <span>{customer.tenantsAmount}</span>

                    <span>{t("customers.customerCard.partners") + ":"}</span>
                    <span className="customer-card_name">
                        {isPartnersTooltipVisible ? (
                            <Whisper
                                trigger="hover"
                                placement={"bottomStart"}
                                speaker={
                                    <Popover
                                        className="tenant-card_popover"
                                        visible={true}
                                    >
                                        {partners}
                                    </Popover>
                                }
                            >
                                <span>{partners}</span>
                            </Whisper>
                        ) : (
                            <span>
                                {customer.partners.length ? partners : "-"}
                            </span>
                        )}
                    </span>

                    <span>{t("customers.customerCard.consentLink") + ":"}</span>
                    <a
                        className={"customer-card__consent-link"}
                        href={customer.consentWizardAppLink}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {customer.consentWizardAppLink}
                    </a>
                    {isDeliverablesEnabled && (
                        <>
                            <span>
                                {t(
                                    "customers.customerCard.packageDeliveryGroup"
                                ) + ":"}
                            </span>
                            <span>{customer.emailTemplate?.name ?? "-"}</span>
                        </>
                    )}
                </div>
            </Card>
        </>
    );
};

export default CustomerCard;
