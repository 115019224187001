import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import css from "./BreadCrumb.module.css";
import { ReactComponent as Home } from "assets/images/home.svg";
import RoutesMap from "./BreadCrumbRouteMap";
import urlConverter, { IConvertedItem } from "utils/url-convertor";
import useUrlToPathConversion from "hooks/useUrlToPathConvertation";
import { Button, Icon, IconButton, Popover, Whisper } from "rsuite";
import constants from "utils/constants";
import DeleteTenantBarContainer from "../Environments/Tenants/DeleteTenant/DeleteTenantBar/DeleteTenantBarContainer";
import DeleteTenantEnrollBarContainer from "../Environments/Tenants/DeleteTenant/DeleteTenantEnrollBar/DeleteTenantEnrollBarContainer";
import DeletePartnerButtonContainer from "../Partners/DeletePartnerButton/DeletePartnerButtonContainer";
import {
    erpPackageDefault,
    IErpPackageDetails,
} from "redux/deliverables/erp-packages/erp-packages-types";
import { addErpPackage } from "api/deliverables/erp-packages-api";
import {
    emailTemplateDefault,
    IEmailTemplateDetails,
} from "redux/deliverables/email-templates/email-templates-types";
import { addEmailTemplate } from "api/deliverables/email-templates-api";
import BreadCrumbSwitchContainer from "./BreadCrumbSwitchContainer";
import DeleteCustomerButtonContainer from "components/Customers/CustomerDetails/DeleteCustomerButton/DeleteCustomerButtonContainer";

interface ComponentProps {
    tenantDisplayName: string;
    tenantMoniker: string;
    customerName: string;
    partnerName: string;
    isEnrollAllowed: boolean;
    isDeleteAllowed: boolean;
    // TODO: Should be used permission-based approach
    isUserPartner: boolean;
    isActiveEnvironment: boolean;
    isActiveTenant: boolean;
    isPackagesModificationAllowed: boolean;
    isTemplatesAccessAllowed: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    setAddNewGuestTenant: (setAddNewGuestTenant: boolean) => void;
    setEnvironmentHistoryOpened: (isOpened: boolean) => void;
    setAppRegistrationInfoOpened: (isOpened: boolean) => void;
    setAddNewPartner: (isOpened: boolean) => void;
    setManagePackageOpened: (isOpened: boolean) => void;
    setManagePackageDetails: (packageDetails: IErpPackageDetails) => void;
    setManageTemplateOpened: (isOpened: boolean) => void;
    setManageTemplateDetails: (templateDetails: IEmailTemplateDetails) => void;
    setCreateMailboxesOpened: (isOpened: boolean) => void;
}

const BreadCrumb: React.FC<ComponentProps> = ({
    tenantDisplayName,
    tenantMoniker,
    customerName,
    partnerName,
    isEnrollAllowed,
    isDeleteAllowed,
    isUserPartner,
    isActiveEnvironment,
    isActiveTenant,
    isPackagesModificationAllowed,
    isTemplatesAccessAllowed,
    setIsModalOpen,
    setAddNewGuestTenant,
    setEnvironmentHistoryOpened,
    setAppRegistrationInfoOpened,
    setAddNewPartner,
    setManagePackageOpened,
    setCreateMailboxesOpened,
    setManagePackageDetails,
    setManageTemplateOpened,
    setManageTemplateDetails,
}: ComponentProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { route, params } = useUrlToPathConversion(pathname);
    const currentMappedRoute = RoutesMap.find((r) => r.path === route);

    let convertedArr: IConvertedItem[] = [];
    if (currentMappedRoute) {
        convertedArr = [...urlConverter(currentMappedRoute.breadcrumb, params)];
    }

    const setBreadCrumbText = (parameter: string, text: string) => {
        let itemIndex;
        const convertedItem = convertedArr.find((convertedItem, index) => {
            if (convertedItem.name === parameter) {
                itemIndex = index;
                return true;
            }
            return false;
        });

        if (convertedItem && typeof itemIndex === "number") {
            convertedItem.name = text;
            convertedArr[itemIndex] = convertedItem;
        }
    };

    if (params.tenantId && tenantDisplayName && tenantMoniker) {
        setBreadCrumbText(
            params.tenantId,
            `${tenantMoniker} - ${tenantDisplayName}`
        );
    } else if (params.customerId && customerName) {
        setBreadCrumbText(params.customerId, customerName);
    } else if (params.partnerId && partnerName) {
        setBreadCrumbText(params.partnerId, partnerName);
    } else if (
        currentMappedRoute?.path === constants.routePath.enrollTenant ||
        currentMappedRoute?.path === constants.routePath.enrollNewTenant
    ) {
        convertedArr[1].name = t("enrollTenant.pageTitle");
    } else if (
        currentMappedRoute?.path === constants.routePath.guestTenantsEnroll
    ) {
        convertedArr[1].name = t("enrollTenant.pageTitle");
        convertedArr[2].name = t("enrollTenant.guestTenants.stepName");
    }

    const homeRoute = "/" + route?.split("/")[1];
    const addNewCustomerBtn = route === constants.routePath.customers && (
        <Button
            onClick={() => {
                setIsModalOpen(true);
            }}
            appearance="primary"
            className={css.addNewCustomerBtn}
        >
            {t("customers.addNewCustomer")}
        </Button>
    );

    const envName =
        homeRoute === "/environments" ? params.environmentName : null;

    const enrollTenantBtn = route === constants.routePath.tenants &&
        isEnrollAllowed && (
            <NavLink to={`/environments/${envName}/enroll-new-tenant`}>
                <Button appearance="primary" className={css.addNewCustomerBtn}>
                    {t("enrollTenant.enrollTenantBtn")}
                </Button>
            </NavLink>
        );

    const newGuestTenant = (route === constants.routePath.guestTenants ||
        route === constants.routePath.guestTenantsEnroll) &&
        isActiveEnvironment &&
        isActiveTenant && (
            <Button
                onClick={() => {
                    setAddNewGuestTenant(true);
                }}
                appearance="primary"
                className={css.addNewCustomerBtn}
            >
                {t("tenantConfig.guestTenants.newGuestTenantButton")}
            </Button>
        );

    const newPartner = route === constants.routePath.partners &&
        !isUserPartner && (
            <Button
                onClick={() => {
                    setAddNewPartner(true);
                }}
                appearance="primary"
                className={css.addNewCustomerBtn}
            >
                {t("partners.newPartner")}
            </Button>
        );

    const handleNewPackageClick = () => {
        setManagePackageOpened(true);
        setManagePackageDetails({
            title: t("deliverables.packages.add.title"),
            confirmButtonName: t("deliverables.packages.add.create"),
            erpPackage: erpPackageDefault,
            apiMethod: addErpPackage,
        });
    };

    const newPackage = route === constants.routePath.deliverablesPackages &&
        isPackagesModificationAllowed && (
            <Button
                onClick={handleNewPackageClick}
                appearance="primary"
                className={css.addNewCustomerBtn}
            >
                {t("deliverables.packages.add.title")}
            </Button>
        );

    const actionHistory = route === constants.routePath.tenants && (
        <IconButton
            size="lg"
            className={css.actionButton}
            icon={<Icon icon={"history"}></Icon>}
            onClick={() => {
                setEnvironmentHistoryOpened(true);
            }}
        />
    );

    const actionAppInfo = route === constants.routePath.tenants && (
        <IconButton
            size="lg"
            className={css.actionButton}
            icon={<Icon icon={"info"}></Icon>}
            onClick={() => {
                setAppRegistrationInfoOpened(true);
            }}
        />
    );

    const handleNewTemplateClick = () => {
        setManageTemplateOpened(true);
        setManageTemplateDetails({
            title: t("deliverables.emailTemplates.modal.header"),
            confirmButtonName: t("deliverables.emailTemplates.modal.create"),
            actionName: t("deliverables.emailTemplates.createdAction"),
            emailTemplate: emailTemplateDefault,
            apiMethod: addEmailTemplate,
        });
    };

    const newEmailTemplate = route ===
        constants.routePath.deliverablesTemplates &&
        isTemplatesAccessAllowed && (
            <Button
                onClick={handleNewTemplateClick}
                appearance="primary"
                className={css.addNewCustomerBtn}
            >
                {t("deliverables.emailTemplates.newTemplateButton")}
            </Button>
        );

    const createMailboxesBtn = route ===
        constants.routePath.captureMailboxes && (
        <Button
            onClick={() => {
                setCreateMailboxesOpened(true);
            }}
            appearance="primary"
            className={css.addNewCustomerBtn}
        >
            {t("tenantConfig.captureMailboxes.mailboxCreation.title")}
        </Button>
    );

    const deleteBar = isDeleteAllowed &&
        route?.startsWith(constants.routePath.tenantConfigs) &&
        isActiveEnvironment &&
        isActiveTenant && <DeleteTenantBarContainer />;

    const deleteEnrollBar = isDeleteAllowed &&
        (route?.startsWith(constants.routePath.enrollTenant) ||
            route?.startsWith(constants.routePath.enrollNewTenant)) && (
            <DeleteTenantEnrollBarContainer />
        );

    const deletePartnerButton = route ===
        constants.routePath.partnerDetails && <DeletePartnerButtonContainer />;

    const deleteCustomerButton = route ===
        constants.routePath.customerDetails && (
        <DeleteCustomerButtonContainer />
    );

    return (
        <div className={css.container}>
            <NavLink to={homeRoute} className={css.item}>
                <Home />
            </NavLink>
            {convertedArr &&
                convertedArr.length !== 0 &&
                convertedArr.map((item: IConvertedItem, index: number) => {
                    if (item.name.length > constants.itemNameLengthLimit) {
                        return (
                            <Whisper
                                trigger="hover"
                                placement={"bottomStart"}
                                key={String(index)}
                                speaker={
                                    <Popover
                                        className="tenant-card_popover"
                                        visible={true}
                                    >
                                        {`${item.name}`}
                                    </Popover>
                                }
                            >
                                <BreadCrumbSwitchContainer
                                    item={item}
                                    index={index}
                                />
                            </Whisper>
                        );
                    } else {
                        return (
                            <>
                                <BreadCrumbSwitchContainer
                                    item={item}
                                    index={index}
                                />
                            </>
                        );
                    }
                })}

            <div className={css.actionsPanel}>
                {deleteBar}
                {deleteEnrollBar}
                {addNewCustomerBtn || enrollTenantBtn || newGuestTenant}
                {newPartner}
                {newPackage}
                {deletePartnerButton}
                {actionHistory}
                {actionAppInfo}
                {newEmailTemplate}
                {createMailboxesBtn}
                {deleteCustomerButton}
            </div>
        </div>
    );
};

export default BreadCrumb;
