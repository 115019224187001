import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import constants from "utils/constants";
import { IHubConnection } from "utils/types";
import { ITenantFeatureSettingWorkFlow } from "utils/tenant-workflows/tenant-workflows-types";
import { getHubConnection } from "api/environments/tenants/tenant-feature-settings-api";
import { ContainerProps } from "rsuite";

interface IComponentProps extends ContainerProps {
    tenantId: string | null;
    updateTenantFeatureSettingWorkFlows: (
        workFlow: ITenantFeatureSettingWorkFlow
    ) => void;
}

const SignalRTenantFeatureSetting: React.FC<IComponentProps> = ({
    tenantId,
    children,
    updateTenantFeatureSettingWorkFlows,
}: IComponentProps) => {
    const [hubInfo, setHubInfo] = useState<IHubConnection | null>(null);
    const [hubConnectionBuilder, setHubConnectionBuilder] =
        useState<HubConnectionBuilder | null>(null);
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(
        null
    );
    useEffect(() => {
        if (tenantId !== null && hubInfo === null) {
            getHubConnection(tenantId).then(([payload, _]) => {
                setHubInfo(payload);
            });
        }
    }, [tenantId, hubInfo, getHubConnection]);
    useEffect(() => {
        if (hubInfo !== null) {
            if (hubConnectionBuilder === null) {
                setHubConnectionBuilder(
                    new HubConnectionBuilder()
                        .withAutomaticReconnect()
                        .withUrl(hubInfo.url, {
                            accessTokenFactory: () => hubInfo.accessToken,
                        })
                );
            }
        }
    }, [
        hubInfo,
        hubConnectionBuilder,
        updateTenantFeatureSettingWorkFlows,
        setHubConnectionBuilder,
    ]);
    useEffect(() => {
        if (hubConnectionBuilder !== null) {
            setHubConnection(hubConnectionBuilder.build());
        }
    }, [hubConnectionBuilder]);
    useEffect(() => {
        if (hubConnection !== null) {
            hubConnection.on(
                constants.enrollNewSignalRMessageMethodName,
                (workFlow: string) => {
                    updateTenantFeatureSettingWorkFlows(
                        JSON.parse(workFlow) as ITenantFeatureSettingWorkFlow
                    );
                }
            );
            hubConnection.start();
        }
        return () => {
            if (hubConnection !== null) {
                hubConnection.stop();
            }
        };
    }, [hubConnection]);
    return <>{children}</>;
};

export default SignalRTenantFeatureSetting;
