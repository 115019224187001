import { AppThunk } from "../../../store";
import constants from "utils/constants";
import {
    getTenantChangeEntityFrameworkSettingWorkflows,
    toggleTenantChangeEntityFrameworkSettings,
} from "api/environments/tenants/tenant-feature-settings-api";
import { getErrorMessage } from "api/defaults";
import { setErrorResponseMessage } from "../../../common/initialization/initialization-actions";
import {
    ITenantFeatureSettingWorkFlow,
    ChangeEntityFrameworkSettingToggleValue,
} from "utils/tenant-workflows/tenant-workflows-types";
import { CancelTokenSource } from "axios";

export const fetchTenantChangeEntityFrameworkSettingWorkflows =
    (
        environmentMoniker: string,
        tenantMoniker: string,
        cancelTokenSource?: CancelTokenSource
    ): AppThunk<Promise<ITenantFeatureSettingWorkFlow[] | null>> =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] =
            await getTenantChangeEntityFrameworkSettingWorkflows(
                environmentMoniker,
                tenantMoniker,
                cancelTokenSource
            );

        const isStatusOk = status === constants.statusCode.OK;

        if (!payload || !isStatusOk) {
            const errorMessage = getErrorMessage(String(payload), status);
            dispatch(setErrorResponseMessage(errorMessage));
        }

        return isStatusOk ? payload : null;
    };

export const updateTenantChangeEntityFrameworkSettings =
    (
        envMoniker: string,
        tenantMoniker: string,
        settings: ChangeEntityFrameworkSettingToggleValue[]
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] =
            await toggleTenantChangeEntityFrameworkSettings(
                envMoniker,
                tenantMoniker,
                settings
            );
        const isResponseStatusCodeExpected =
            status === constants.statusCode.NoContent ||
            status === constants.statusCode.OK;

        if (!isResponseStatusCodeExpected) {
            const errorMessage = getErrorMessage(String(payload), status);
            dispatch(setErrorResponseMessage(errorMessage));
        }
    };
