import { getErrorMessage } from "api/defaults";
import {
    clearKeyVaultParametersExceptDefaultCompany,
    resetDooapUrl,
} from "api/environments/tenants/tenant-erp-configurations-api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, HelpBlock } from "rsuite";
import constants from "utils/constants";

interface IComponentProps {
    envMoniker: string;
    tenantId: string;
}

const ResetD365Parameters: React.FC<IComponentProps> = ({
    envMoniker,
    tenantId,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [disabledButtons, setDisabledButtons] = useState({
        resetParameters: false,
        resetDooapUrl: false,
    });

    const handleResetParameters = () => {
        setDisabledButtons({ ...disabledButtons, resetParameters: true });

        clearKeyVaultParametersExceptDefaultCompany(envMoniker, tenantId).then(
            ([result, status]) => {
                if (status === constants.statusCode.NoContent) {
                    Alert.success(
                        t(
                            "tenantConfig.d365InformationPage.resetParameters.successMessage"
                        )
                    );
                } else {
                    Alert.error(getErrorMessage(String(result), status));
                }
                setDisabledButtons({
                    ...disabledButtons,
                    resetParameters: false,
                });
            }
        );
    };

    const handleResetDooapUrl = () => {
        setDisabledButtons({ ...disabledButtons, resetDooapUrl: true });

        resetDooapUrl(envMoniker, tenantId).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                Alert.success(
                    t(
                        "tenantConfig.d365InformationPage.resetDooapUrl.successMessage"
                    )
                );
            } else {
                Alert.error(getErrorMessage(String(result), status));
            }
            setDisabledButtons({ ...disabledButtons, resetDooapUrl: false });
        });
    };

    return (
        <div className={"general-configurations__dooap-refresh"}>
            <p className={"general-configurations__label"}>
                {t("tenantConfig.d365InformationPage.resetParametersLabel")}
            </p>
            <div>
                <Button
                    className={"general-configurations__button"}
                    onClick={handleResetParameters}
                    disabled={disabledButtons.resetParameters}
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetParameters.buttonTitle"
                    )}
                </Button>
                <HelpBlock className={"general-configurations__help"} tooltip>
                    {t(
                        "tenantConfig.d365InformationPage.resetParameters.tooltip"
                    )}
                </HelpBlock>
            </div>
            <div>
                <Button
                    className={"general-configurations__button"}
                    onClick={handleResetDooapUrl}
                    disabled={disabledButtons.resetDooapUrl}
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetDooapUrl.buttonTitle"
                    )}
                </Button>
                <HelpBlock className={"general-configurations__help"} tooltip>
                    {t(
                        "tenantConfig.d365InformationPage.resetDooapUrl.tooltip"
                    )}
                </HelpBlock>
            </div>
        </div>
    );
};

export default ResetD365Parameters;
