import { defaultErrorResponse } from "api/defaults";
import Axios, { AxiosResponse, CancelTokenSource } from "axios";

export const handleApiResponse = async (
    response: AxiosResponse<any>,
    cancelTokenSource?: CancelTokenSource
): Promise<[any | null, number]> => {
    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
