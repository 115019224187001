import constants from "./../utils/constants";
import { useLocation } from "react-router-dom";

const useUrlParams = () => {
    const { pathname } = useLocation();
    let parameters: Record<string, string> = {};
    const urlArr: string[] = pathname.split("/");
    const valuesArr = [...Object.values(constants.routePath)];

    valuesArr.forEach((route) => {
        const routeArr = route.split("/");

        if (routeArr.length === urlArr.length) {
            for (let i = 0; i < routeArr.length; i++) {
                if (routeArr[i] !== urlArr[i]) {
                    if (routeArr[i].charAt(0) === ":") {
                        const routeKey = routeArr[i].replace(":", "");

                        parameters = {
                            ...parameters,
                            [routeKey]: urlArr[i],
                        };
                    }
                }
            }
        }
    });

    return parameters;
};

export default useUrlParams;
