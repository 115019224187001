import { UserPermissions } from "redux/app-global/app-global-types";
import { API } from "../utils/api-config";
import { defaultErrorResponse } from "./defaults";

export const loadUserPermissions = async (): Promise<
    [UserPermissions | null, number]
> => {
    const response = await API.get(`/user-permissions`);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
