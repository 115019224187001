import { RootState } from "redux/store";
import { connect } from "react-redux";
import {
    getActiveStep,
    getEnrollCache,
    getEnrollId,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-selectors";
import DataImportControl from "./DataImportControl";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { getCustomerDetails } from "redux/customers/customer-details/customer-details-selectors";
import { compose } from "redux";
import withLoader from "hocs/withLoader/withLoader";
import { fetchTenantAxImport } from "redux/environments/tenants/data-import/data-import-thunks";
import { setActiveStepData } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";
import { fetchEnrollCache } from "redux/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import { getIsDeleteAllowed } from "redux/environments/environment-selectors";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { getCustomerDetailsWithoutLoader as fetchCustomerDetails } from "redux/customers/customer-details/customer-details-thunk";

const mapStateToProps = (state: RootState) => ({
    environmentMoniker: getActiveEnvironmentMoniker(state),
    activeStep: getActiveStep(state),
    enrollCache: getEnrollCache(state),
    customerDetails: getCustomerDetails(state),
    enrollId: getEnrollId(state),
    isDeleteAllowed: getIsDeleteAllowed(state),
});

const DataImportControlContainer = compose(
    connect(mapStateToProps, {
        fetchTenantAxImport,
        setActiveStepData,
        fetchEnrollCache,
        fetchCustomerDetails,
        setIsUnsavedChangeAvailable,
    }),
    withLoader
)(DataImportControl);

export default DataImportControlContainer;
