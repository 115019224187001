import {
    Actions,
    State,
    SET_TENANT_DELETE_WORKFLOW,
    SET_IS_DELETE_FETCHED,
} from "./tenant-delete-types";

const initialState: State = {
    activeTenantDeleteWorkflow: null,
    isDeleteFetched: false,
};
const tenantDeleteReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_TENANT_DELETE_WORKFLOW:
            return {
                ...state,
                activeTenantDeleteWorkflow: action.workflow,
            };
        case SET_IS_DELETE_FETCHED:
            return {
                ...state,
                isDeleteFetched: action.isDeleteFetched,
            };
        default:
            return state;
    }
};

export default tenantDeleteReducer;
