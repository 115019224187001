import {
    IEmailTemplateDetails,
    SetEmailTemplateDetails,
    SetManageTemplateOpened,
    SET_MANAGE_TEMPLATE_DETAILS,
    SET_MANAGE_TEMPLATE_OPENED,
} from "./email-templates-types";

export const setManageTemplateOpened = (
    isAddNewTemplateOpened: boolean
): SetManageTemplateOpened => ({
    type: SET_MANAGE_TEMPLATE_OPENED,
    isManageTemplateOpened: isAddNewTemplateOpened,
});

export const setManageTemplateDetails = (
    emailTemplateDetails: IEmailTemplateDetails
): SetEmailTemplateDetails => ({
    type: SET_MANAGE_TEMPLATE_DETAILS,
    templateDetails: emailTemplateDetails,
});
