import { AppThunk } from "redux/store";
import { pingTenant } from "api/environments/tenants/tenant-erp-info-api";
import constants from "utils/constants";
import i18n from "utils/i18n";
import { updateTenantPingInfo } from "./tenant-ping-actions";
import { TenantStatus } from "../tenants-types";
import { CancelTokenSource } from "axios";

export const pingTenantInD365 =
    (
        envMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        const [payload, statusCode] = await pingTenant(
            envMoniker,
            tenantId,
            cancelTokenSource
        );

        if (cancelTokenSource.token.reason) {
            return;
        }

        let pingStatus = {
            tenantId,
            errorMessage: "",
            tenantPingStatus: TenantStatus.Normal,
        };

        if (statusCode === constants.statusCode.OK) {
            if (!payload) {
                pingStatus = {
                    ...pingStatus,
                    tenantPingStatus: TenantStatus.Error,
                    errorMessage: i18n.t(
                        "tenantDashboard.statusMessages.pingResponseFalse"
                    ),
                };
            }
        } else {
            pingStatus = {
                ...pingStatus,
                tenantPingStatus: TenantStatus.Error,
                errorMessage:
                    typeof payload == "string"
                        ? payload
                        : JSON.stringify(payload),
            };
        }

        dispatch(updateTenantPingInfo(envMoniker, pingStatus));
    };
