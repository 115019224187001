import { RootState } from "redux/store";
import { connect } from "react-redux";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { getErrorMessage } from "redux/common/initialization/initialization-selectors";
import GuestTenantsEnroll from "./GuestTenantsEnroll";
import { fetchEnvironments } from "redux/environments/environment-thunks";
import {
    fetchEnrollCache,
    fetchEnrollWorkflow,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-thunks";
import { setActiveStepData } from "redux/environments/tenants/enroll-tenant/enroll-tenant-actions";
import { getActiveTenantDeleteWorkflow } from "redux/environments/tenants/tenant-delete/tenant-delete-selectors";
import { fetchTenants } from "redux/environments/tenants/tenants-thunks";
import { getActiveTenantMoniker } from "redux/environments/tenants/tenants-selectors";
import { flowRight } from "lodash";
import withScroll from "hocs/withScroll/withScroll";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    errorMessage: getErrorMessage(state),
    activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
    tenantMoniker: getActiveTenantMoniker(state),
});
const Container = connect(mapStateToProps, {
    fetchEnvironments,
    fetchEnrollCache,
    fetchEnrollWorkflow,
    setActiveStepData,
    fetchTenants,
})(GuestTenantsEnroll);

const GuestTenantsEnrollContainer = flowRight(withScroll)(Container);

export default GuestTenantsEnrollContainer;
