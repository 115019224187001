import { defaultErrorResponse } from "api/defaults";
import {
    IEmailTemplate,
    IEmailTemplateVariable,
} from "redux/deliverables/email-templates/email-templates-types";
import { API } from "utils/api-config";

export const loadEmailTemplates = async (): Promise<
    [IEmailTemplate[] | null, number]
> => {
    const response = await API.get("/deliverables/email-templates");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const addEmailTemplate = async (
    newEmailTemplate: IEmailTemplate
): Promise<[null, number]> => {
    const response = await API.post(
        "/deliverables/email-templates",
        newEmailTemplate
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateEmailTemplate = async (
    emailTemplate: IEmailTemplate
): Promise<[null, number]> => {
    const response = await API.put(
        `/deliverables/email-templates/${emailTemplate.id}`,
        emailTemplate
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const deleteEmailTemplate = async (
    emailTemplateId: string
): Promise<[null, number]> => {
    const response = await API.delete(
        `/deliverables/email-templates/${emailTemplateId}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadEmailTemplateVariables = async (): Promise<
    [IEmailTemplateVariable[] | null, number]
> => {
    const response = await API.get("/deliverables/email-variables");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadLocales = async (): Promise<[string[] | null, number]> => {
    const response = await API.get("/deliverables/email-templates/locales");

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
