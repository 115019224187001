import { Actions, SET_USER_PERMISSIONS, State } from "./app-global-types";

const initialState = {
    userPermissions: {
        isControlAppAccessAllowed: false,
        partnerPermissions: [],
        environmentPermissions: [],
        customerPermissions: [],
        deliverablesPermissions: {
            isPackagesModificationAllowed: false,
            isTemplatesAccessAllowed: false,
        },
    },
};

//TODO refactor all global dialogs actions that are dispatched from toolbar
const appGlobalReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.userPermissions,
            };
        default:
            return state;
    }
};

export default appGlobalReducer;
