import React from "react";
import { Checkbox, CheckboxProps, FormGroup, HelpBlock } from "rsuite";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowModalType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    getTenantFeatureSettingByType,
    isTenantFeatureSettingWithTypeEnabled,
} from "utils/tenant-workflows/tenant-workflows-helper";
import "../FeatureConfigurations.less";
import cn from "classnames";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import css from "./TenantFeatureSettingsCheckbox.module.css";
import { ITenantFeatureConfigurations } from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";

interface IComponentProps extends CheckboxProps {
    tenantFeatureSettingWorkFlows: ITenantFeatureSettingWorkFlow[] | null;
    disabledSettingNames: string[];
    tenantFeatureSettingWorkFlowType: TenantFeatureSettingWorkFlowType;
    buttonText: string;
    helpText: string;
    onCheckboxChange: (
        name: keyof ITenantFeatureConfigurations,
        value: boolean
    ) => void;
    triggerRefresh?: () => void;
}

const TenantFeatureSettingsCheckbox: React.FC<IComponentProps> = ({
    tenantFeatureSettingWorkFlows,
    disabledSettingNames,
    tenantFeatureSettingWorkFlowType,
    buttonText,
    helpText,
    value,
    checked,
    disabled,
    onCheckboxChange,
    triggerRefresh,
}: IComponentProps) => {
    const tenantFeatureSettingWorkFlow = getTenantFeatureSettingByType(
        tenantFeatureSettingWorkFlows,
        tenantFeatureSettingWorkFlowType
    );
    const isEnabled =
        tenantFeatureSettingWorkFlow !== null &&
        tenantFeatureSettingWorkFlow.status !== WorkflowStatus.Terminated
            ? !disabledSettingNames.includes(
                  tenantFeatureSettingWorkFlow.featureName
              ) &&
              isTenantFeatureSettingWithTypeEnabled(
                  tenantFeatureSettingWorkFlows,
                  tenantFeatureSettingWorkFlowType
              )
            : true;
    return (
        <FormGroup
            className={cn("gridItem", "feature-configurations__less-height")}
        >
            <Checkbox
                className={"feature-configurations__checkbox"}
                value={value}
                checked={checked}
                disabled={!isEnabled || disabled}
                onChange={onCheckboxChange}
            >
                {buttonText}
                {tenantFeatureSettingWorkFlow !== null && (
                    <ProcessModalButton
                        workflow={tenantFeatureSettingWorkFlow}
                        className={css.processModalButton}
                        workflowType={WorkflowModalType.Feature}
                        triggerRefresh={triggerRefresh}
                    />
                )}
                <HelpBlock
                    tooltip
                    className={"feature-configurations__help-block"}
                >
                    {helpText}
                </HelpBlock>
            </Checkbox>
        </FormGroup>
    );
};

export default TenantFeatureSettingsCheckbox;
