import { RootState } from "redux/store";
import { connect } from "react-redux";
import PackageDelivery from "./PackageDelivery";
import withLoader from "hocs/withLoader/withLoader";
import { compose } from "redux";
import { setCustomerDetails } from "redux/customers/customer-details/customer-details-actions";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { getIsUserPartner } from "redux/app-global/app-global-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        customerDetails: state.customerDetailsReducer.customerDetails,
        isPartner: getIsUserPartner(state),
    };
};
const PackageDeliveryContainer = compose(
    connect(mapStateToProps, {
        setCustomerDetails,
        setIsUnsavedChangeAvailable,
    }),
    withLoader
)(PackageDelivery);

export default PackageDeliveryContainer;
