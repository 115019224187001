import React, { FC } from "react";
import css from "./withScroll.module.css";

interface IComponentProps {
    [key: string]: any;
}
const withScroll = (Component: FC<any>): FC<IComponentProps> => ({
    ...props
}: IComponentProps) => {
    return (
        <>
            <div className={css.scrollableContainer}>
                <Component {...props} />
            </div>
        </>
    );
};

export default withScroll;
