import { API } from "../../../utils/api-config";
import { defaultErrorResponse } from "../../defaults";

export const checkIfTenantLogoExists = async (
    envMoniker: string,
    tenantMoniker: string,
    logoName: string
): Promise<[boolean | null, number]> => {
    if (!envMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `/environments/${envMoniker}/tenants/${tenantMoniker}/logo/${logoName}`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const uploadLogoToBlobContainer = async (
    envMoniker: string,
    tenantMoniker: string,
    logoFile: number[] | null,
    logoName: string
): Promise<[void | null, number]> => {
    if (!envMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `/environments/${envMoniker}/tenants/${tenantMoniker}/logo/${logoName}`,
        logoFile
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
