import { StepStatus } from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import {
    ITenantPingInfo,
    TenantStatus,
} from "redux/environments/tenants/tenants-types";

const TENANT_ERROR_STATUS = "error";
const TENANT_ENROLL_STATUS = "enroll";
const TENANT_NORMAL_STATUS = "normal";
const TENANT_DELETING_STATUS = "deleting";

type TenantStatusString =
    | typeof TENANT_ERROR_STATUS
    | typeof TENANT_ENROLL_STATUS
    | typeof TENANT_NORMAL_STATUS
    | typeof TENANT_DELETING_STATUS;

const tenantStatusStringMap: { [key: number]: TenantStatusString } = {
    [TenantStatus.Error]: TENANT_ERROR_STATUS,
    [TenantStatus.Enroll]: TENANT_ENROLL_STATUS,
    [TenantStatus.Deleting]: TENANT_DELETING_STATUS,
};

export const getComplexStatusString = (
    fetchStatus: number,
    pingStatus: number
): TenantStatusString => {
    if (pingStatus === TenantStatus.Error) {
        return TENANT_ERROR_STATUS;
    }

    return tenantStatusStringMap[fetchStatus] || TENANT_NORMAL_STATUS;
};

export const getComplexStatus = (
    fetchStatus: TenantStatus,
    pingStatus: TenantStatus
): TenantStatus => {
    if (pingStatus === TenantStatus.Error) {
        return TenantStatus.Error;
    }

    switch (fetchStatus) {
        case TenantStatus.Error:
        case TenantStatus.Enroll:
        case TenantStatus.Deleting:
            return fetchStatus;
        default:
            return TenantStatus.Normal;
    }
};

export const convertStepStatusToString = (status: StepStatus) => {
    switch (status) {
        case StepStatus.NotStarted:
            return "wait";
        case StepStatus.Running || StepStatus.Pending:
            return "process";
        case StepStatus.Failed:
            return "error";
        case StepStatus.Success:
            return "finish";
    }
};

export const getPingStatus = (
    pingInfo: ITenantPingInfo | undefined
): number => {
    return pingInfo?.errorMessage ? 2 : 0;
};
