import withError from "hocs/withError/withError";
import { flowRight } from "lodash";
import { connect } from "react-redux";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import { RootState } from "redux/store";
import ActivityLog from "./ActivityLog";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: state.tenantsReducer.activeTenant.moniker,
    };
};

const Container = connect(mapStateToProps, {})(ActivityLog);

const ActivityLogContainer = flowRight(withError)(Container);

export default ActivityLogContainer;
