import { API } from "../../../utils/api-config";
import {
    IScheduler,
    ScheduleEventType,
    ScheduleEventSubtype,
    TPromise,
} from "../../../redux/environments/tenants/tenant-scheduler/tenant-scheduler-types";
import { defaultErrorResponse } from "../../defaults";
import Axios, { CancelTokenSource } from "axios";

export const getTenantScheduler = async (
    envMoniker: string,
    tenantMoniker: string,
    eventType: ScheduleEventType,
    subtype: ScheduleEventSubtype,
    cancelTokenSource?: CancelTokenSource
): Promise<TPromise> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `/environments/${envMoniker}/tenants/${tenantMoniker}/scheduler-settings/${eventType}/${subtype}`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
export const putTenantScheduler = async (
    envMoniker: string,
    tenantMoniker: string,
    scheduler: IScheduler
): Promise<[IScheduler[] | null, number]> => {
    if (!envMoniker || !tenantMoniker || !scheduler) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `/environments/${envMoniker}/tenants/${tenantMoniker}/scheduler-settings`,
        scheduler
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
