import React from "react";
import { IWorkflowStepBase } from "utils/tenant-workflows/tenant-workflows-types";
import StepListItem from "./StepListItem/StepListItem";
import css from "./StepList.module.css";
import cn from "classnames";

interface IComponentProps {
    workflowSteps: IWorkflowStepBase[];
    className?: string;
}

const StepList: React.FC<IComponentProps> = ({
    workflowSteps,
    className,
}: IComponentProps) => {
    return (
        <ul className={cn(css.container, className)}>
            {workflowSteps.map((step, index) => (
                <StepListItem key={index} step={step} />
            ))}
        </ul>
    );
};

export default StepList;
