import { IMenuItem } from "../components/Environments/Tenants/LeftMenu/left-menu-structure";

export interface IConvertedItem {
    path: string;
    name: string;
    children?: IConvertedItem[];
    url: string;
}
export const useRoutesConvertor = (
    menu: IMenuItem[],
    paramsArr: Record<string, string>
): IConvertedItem[] => {
    const keysArr = [...Object.keys(paramsArr)];
    const valuesArr = [...Object.values(paramsArr)];

    const _fnConvert = (menuArr: IMenuItem[]) => {
        let convertedItem: IConvertedItem;
        const convertedArr: IConvertedItem[] = [];

        menuArr.forEach((menuItem) => {
            let url: string = menuItem.path;
            const { name, path } = menuItem;

            keysArr.forEach((item, index) => {
                url = url.replace(":" + keysArr[index], valuesArr[index]);
            });

            convertedItem = {
                name,
                path,
                url,
            };

            if (menuItem.children && menuItem.children.length) {
                const kids: IConvertedItem[] = _fnConvert(menuItem.children);
                convertedItem.children = [...kids];
            }

            convertedArr.push(convertedItem);
        });

        return convertedArr;
    };

    const convertedMenuArr = _fnConvert(menu);

    return [...convertedMenuArr];
};
