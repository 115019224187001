export const SET_MANAGE_TEMPLATE_OPENED =
    "email-templates/SET_MANAGE_TEMPLATE_OPENED";
export const SET_MANAGE_TEMPLATE_DETAILS =
    "email-templates/SET_MANAGE_TEMPLATE_DETAILS";

export const emailTemplateDefault: IEmailTemplate = {
    id: "",
    name: "",
    locale: "",
    subject: "",
    body: "",
};

export interface IEmailTemplateBase {
    id: string;
    name: string;
    locale: string;
}

export interface IEmailTemplate extends IEmailTemplateBase {
    subject: string;
    body: string;
}

export interface IEmailTemplateVariable {
    id: string;
    name: string;
    description: string;
}

export interface IEmailTemplateDetails {
    title: string;
    confirmButtonName: string;
    actionName: string;
    emailTemplate: IEmailTemplate;
    apiMethod: (emailTemplate: IEmailTemplate) => Promise<[null, number]>;
}

export interface SetManageTemplateOpened {
    type: typeof SET_MANAGE_TEMPLATE_OPENED;
    isManageTemplateOpened: boolean;
}

export interface SetEmailTemplateDetails {
    type: typeof SET_MANAGE_TEMPLATE_DETAILS;
    templateDetails: IEmailTemplateDetails;
}

export interface State {
    isManageTemplateOpened: boolean;
    templateDetails: IEmailTemplateDetails;
}

export type Actions = SetManageTemplateOpened | SetEmailTemplateDetails;
