import React, { useEffect, useState } from "react";
import { Alert, Drawer, Loader } from "rsuite";
import { useTranslation } from "react-i18next";
import { IEnvironmentHistoryItem } from "redux/environments/environment-history/environment-history-types";
import { loadActionHistory } from "api/environments/environments-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import css from "./EnvironmentHistory.module.css";
import parse from "html-react-parser";
import { format } from "date-fns";
import cn from "classnames";

interface ComponentProps {
    envMoniker: string;
    isOpened: boolean;
    setActionHistoryOpened: (isOpened: boolean) => void;
}

const EnvironmentHistory: React.FC<ComponentProps> = ({
    envMoniker,
    isOpened,
    setActionHistoryOpened,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [history, setHistory] = useState<IEnvironmentHistoryItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpened && envMoniker) {
            getActionHistory();
        }
    }, [isOpened, envMoniker]);

    function getActionHistory() {
        setLoading(true);
        loadActionHistory(envMoniker).then(([payload, status]) => {
            setLoading(false);
            if (payload && status === constants.statusCode.OK) {
                setHistory(
                    payload.sort(
                        (a, b) =>
                            new Date(b.dateTime).getTime() -
                            new Date(a.dateTime).getTime()
                    )
                );
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                Alert.error(errorMessage);
            }
        });
    }

    const items = history.map((item, index) => {
        return (
            <div
                className={css.actionHistoryItem}
                key={String("rowIndex_" + index)}
            >
                <span className={css.actionHistoryItemDate}>
                    {format(new Date(item.dateTime), "d MMMM y - HH:mm:ss")}
                </span>
                {item.name && (
                    <span className={css.actionHistoryItemUser}>
                        {item.name}
                    </span>
                )}
                <span className={css.actionHistoryItemContent}>
                    {parse(item.description)}
                </span>
            </div>
        );
    });

    return (
        <Drawer
            size={"xs"}
            placement={"right"}
            show={isOpened}
            onHide={() => setActionHistoryOpened(false)}
            className={css.drawerContainer}
        >
            <Drawer.Header>
                <Drawer.Title>{t("actionHistory.menuTitle")}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                {loading && (
                    <Loader
                        center
                        size="md"
                        speed="slow"
                        content={t("loader.defaultContent")}
                        className={cn(css.loader)}
                    />
                )}
                {!loading && items}
            </Drawer.Body>
        </Drawer>
    );
};

export default EnvironmentHistory;
