import { connect } from "react-redux";
import { setModalLoaderVisibility } from "redux/common/modal-window/modal-window-actions";
import AddNewPartner from "./AddNewPartner";

const mapStateToProps = () => {
    return {};
};

const AddNewPartnerContainer = connect(mapStateToProps, {
    setModalLoaderVisibility,
})(AddNewPartner);

export default AddNewPartnerContainer;
