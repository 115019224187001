import { RootState } from "../store";
import { IPartner } from "./partners-types";

export const getPartnerName = (state: RootState): string => {
    return state.partnersReducer.activePartner.name;
};

export const getPartnerId = (state: RootState): string => {
    return state.partnersReducer.activePartner.id;
};

export const getPartners = (state: RootState): IPartner[] => {
    return state.partnersReducer.partners;
};
