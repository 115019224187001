import { defaultErrorResponse } from "api/defaults";
import Axios, { CancelTokenSource } from "axios";
import {
    ISearchService,
    ITenantFeatureAppConfigurations,
    ITenantFeatureConfigurations,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { API } from "utils/api-config";
import { handleApiResponse } from "utils/api-request-cancellation";

export const loadFeatureConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureConfigurations | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/configurations`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateFeatureConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    featureConfigurations: ITenantFeatureConfigurations
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/configurations`,
        featureConfigurations
    );

    return [null, response.status];
};

export const loadFeatureAppConfigurationSettings = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureAppConfigurations | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/app-configurations`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateFeatureAppConfigurationSettings = async (
    envMoniker: string,
    tenantMoniker: string,
    feattureAppConfigSettings: ITenantFeatureAppConfigurations
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/app-configurations`,
        feattureAppConfigSettings
    );

    return [response.data, response.status];
};

export const checkTenantDatabaseAlertRuleExistence = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[boolean | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/alert-rule-exists`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const refreshSearchIndex = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `/environments/${envMoniker}/tenants/${tenantId}/features/refresh-search-index`;
    const response = await API.post(routeUrl);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateSearchIndex = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `/environments/${envMoniker}/tenants/${tenantId}/features/update-search-index`;
    const response = await API.post(routeUrl);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const loadSearchServices = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ISearchService[] | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/features/search-services`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const loadSearchServiceLinked = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ISearchService | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/features/search-services/linked`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};
