export const validateContent = (
    val: string,
    regexExpression: string | RegExp
): boolean => {
    if (regexExpression instanceof RegExp) {
        return regexExpression.test(val);
    } else {
        return new RegExp(regexExpression).test(val);
    }
};

export const formatString = (str: string, ...args: string[]): string => {
    return str.replace(/{([0-9]+)}/g, (match: string, index: number) => {
        return typeof args[index] !== "undefined" ? args[index] : match;
    });
};
