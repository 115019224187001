import { RootState } from "redux/store";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setErrorMessageInactiveTenant } from "redux/common/initialization/initialization-actions";
import { useTranslation } from "react-i18next";
import { getIsActiveTenantSet } from "redux/environments/tenants/tenants-selectors";
import { getIsActiveEnvironmentSet } from "redux/environments/environment-selectors";

const useInactiveTenant = (): void => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isActiveEnvironment = useSelector((state: RootState) =>
        getIsActiveEnvironmentSet(state)
    );
    const isActiveTenant = useSelector((state: RootState) =>
        getIsActiveTenantSet(state)
    );

    useEffect(() => {
        dispatch(
            setErrorMessageInactiveTenant(
                !(isActiveEnvironment && isActiveTenant)
                    ? t("error.noActiveTenant")
                    : ""
            )
        );
    }, [isActiveEnvironment, isActiveTenant]);
};

export default useInactiveTenant;
