import { IEnvironment } from "../environments/environment-types";
import {
    SET_CUSTOMER_FILTERS_SEARCH,
    SET_CUSTOMER_FILTERS_STATUS,
    SET_CUSTOMERS_FILTER_STATUS_CALCULATED,
    SET_ENVIRONMENT_FILTER_SEARCH,
    SET_ENVIRONMENT_FILTER_STATUS,
    UPDATE_ENVIRONMENT_FILTERS,
    SET_PARTNER_FILTERS_SEARCH,
    ISetPartnerFilterSearch,
} from "./filters-types";

export const updateEnvironmentFilters = (envList: IEnvironment[]) => ({
    type: UPDATE_ENVIRONMENT_FILTERS,
    envList,
});
export const setEnvironmentFilterStatus = (id: string, status: number) => ({
    type: SET_ENVIRONMENT_FILTER_STATUS,
    id,
    status,
});
export const setEnvironmentFilterSearch = (search: string, id: string) => ({
    type: SET_ENVIRONMENT_FILTER_SEARCH,
    id,
    search,
});
export const setCustomerFilterStatus = (status: number) => ({
    type: SET_CUSTOMER_FILTERS_STATUS,
    status,
});
export const setCustomerFilterSearch = (search: string) => ({
    type: SET_CUSTOMER_FILTERS_SEARCH,
    search,
});
export const setCustomersFilterStatusCalculated = () => ({
    type: SET_CUSTOMERS_FILTER_STATUS_CALCULATED,
});
export const setPartnerFilterSearch = (
    search: string
): ISetPartnerFilterSearch => ({
    type: SET_PARTNER_FILTERS_SEARCH,
    search,
});
