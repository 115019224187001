import React from "react";
import { Loader } from "rsuite";
import { StandardProps } from "rsuite/lib/@types/common";
import css from "./Preloader.module.css";
import cn from "classnames";
import { useTranslation } from "react-i18next";
interface ComponentProps extends StandardProps {
    isLoaderVisible: boolean;
}
const Preloader: React.FC<ComponentProps> = ({
    isLoaderVisible,
    className,
}: ComponentProps) => {
    const { t } = useTranslation();
    if (!isLoaderVisible) {
        return null;
    }

    return (
        <Loader
            center
            size="md"
            speed="slow"
            content={t("loader.defaultContent")}
            className={cn(className, css.loader)}
        />
    );
};

export default Preloader;
