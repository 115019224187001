import {
    ISetModalMessage,
    ISetModalTitle,
    ISetIsModalOpen,
    SET_MODAL_MESSAGE,
    SET_MODAL_TITLE,
    SET_IS_MODAL_OPEN,
    SET_MODAL_LOADER_VISIBILITY,
    ISetModalLoaderVisibility,
} from "./modal-window-types";

export const setModalTitle = (title: string): ISetModalTitle => ({
    type: SET_MODAL_TITLE,
    title,
});
export const setModalMessage = (message: string): ISetModalMessage => ({
    type: SET_MODAL_MESSAGE,
    message,
});
export const setIsModalOpen = (isModalOpen: boolean): ISetIsModalOpen => ({
    type: SET_IS_MODAL_OPEN,
    isModalOpen,
});
export const setModalLoaderVisibility = (
    isModalLoaderVisible: boolean
): ISetModalLoaderVisibility => ({
    type: SET_MODAL_LOADER_VISIBILITY,
    isModalLoaderVisible,
});
