export const SET_CULTURE_SETTINGS =
    "tenantCultureSettings/SET_CULTURE_SETTINGS";
export const SET_CULTURE = "tenantCultureSettings/SET_CULTURE";
export const SET_NUMBER_AND_DATE_CULTURE =
    "tenantCultureSettings/SET_NUMBER_AND_DATE_CULTURE";
export const SET_PRECISE_SCALE = "tenantCultureSettings/SET_PRECISE_SCALE";
export const SET_DEFAULT_CURRENCY =
    "tenantCultureSettings/SET_DEFAULT_CURRENCY";
export const SET_CURRENCIES = "tenantCultureSettings/SET_CURRENCIES";
export const SET_CULTURE_SETTINGS_SAVED =
    "tenantCultureSettings/SET_CULTURE_SETTINGS_SAVED";
export const SET_SUPPORTED_CULTURES =
    "tenantCultureSettings/SET_SUPPORTED_CULTURES";
export const SET_CULTURES = "tenantCultureSettings/SET_CULTURES";

export interface ICultureSettings {
    culture: string | null;
    numberAndDateCulture: string | null;
    preciseScale: number;
    defaultCurrency: string | null;
}

export type TCultureSettings = [ICultureSettings | null, number];
export type TCurrency = [ICurrency[] | null, number];

export interface ICurrency {
    code: string;
    name: string;
}

export interface State extends ICultureSettings {
    currencies: ICurrency[];
    supportedCultures: string[];
    cultures: string[];
    isFetched: boolean;
    isCultureSettingsSaved: boolean;
}

export interface ISetCultureSettings {
    type: typeof SET_CULTURE_SETTINGS;
    cultureSettings: ICultureSettings | null;
}

export interface ISetCulture {
    type: typeof SET_CULTURE;
    culture: string;
}

export interface ISetNumberAndDateCulture {
    type: typeof SET_NUMBER_AND_DATE_CULTURE;
    numberAndDateCulture: string;
}
export interface ISetPreciseScale {
    type: typeof SET_PRECISE_SCALE;
    preciseScale: number;
}

export interface ISetDefaultCurrency {
    type: typeof SET_DEFAULT_CURRENCY;
    defaultCurrency: string;
}

export interface ISetCurrencies {
    type: typeof SET_CURRENCIES;
    currencies: ICurrency[];
}

export interface ISetCultureSettingsSaved {
    type: typeof SET_CULTURE_SETTINGS_SAVED;
    isSaved: boolean;
}

export interface ISetSupportedCultures {
    type: typeof SET_SUPPORTED_CULTURES;
    supportedCultures: string[];
}

export interface ISetCultures {
    type: typeof SET_CULTURES;
    cultures: string[];
}

export type Actions =
    | ISetCultureSettings
    | ISetCulture
    | ISetNumberAndDateCulture
    | ISetPreciseScale
    | ISetDefaultCurrency
    | ISetCurrencies
    | ISetCultureSettingsSaved
    | ISetSupportedCultures
    | ISetCultures;
