import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Checkbox,
    CheckPicker,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Loader,
    Schema,
    Notification,
} from "rsuite";
import {
    IEnrollCache,
    IEnrollStep,
    IFirstAdminApproveData,
    StepStatus,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import cn from "classnames";
import { template } from "lodash";
import parse from "html-react-parser";
import { IDooapUser } from "redux/environments/tenants/tenant-users/tenant-users-types";
import { ItemDataType } from "rsuite/lib/@types/common";
import useRefreshPage from "hooks/useRefreshPage";
import {
    CustomerType,
    ICustomerDetails,
} from "redux/customers/customer-details/customer-details-types";
import {
    loadErpUsers,
    checkIntegrationUserValidity,
    checkIntegrationUserErpValidity,
    loadUsers,
} from "api/environments/tenants/tenant-users-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";

import styles from "./SetAdmin.module.css";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import { loadAuthenticationMethods } from "api/environments/tenants/tenant-erp-configurations-api";
import { AuthenticationType } from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";

type FirstAdminFormValue = {
    isD365RoleDooapRequired: boolean;
    users: IDooapUser[];
    integrationUser?: IDooapUser;
} & typeof defaultFirstAdminFormValue;

const defaultFirstAdminFormValue = {
    isD365RoleDooapRequired: false,
    users: [] as IDooapUser[],
};

interface IComponentProps {
    activeStep: IEnrollStep | null;
    enrollCache: IEnrollCache | null;
    environmentMoniker: string;
    enrollId: string;
    customerDetails: ICustomerDetails | null;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
    fetchCustomerDetails: (customerId: string) => void;
    setIsStepFormValid: Dispatch<SetStateAction<() => boolean>>;
    setActiveStepData: (data: string | null) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}
const SetAdmin: React.FC<IComponentProps> = ({
    activeStep,
    environmentMoniker,
    enrollCache,
    enrollId,
    customerDetails,
    fetchEnrollCache,
    setIsStepFormValid,
    fetchCustomerDetails,
    setActiveStepData,
    setIsUnsavedChangeAvailable,
    showLoader,
}: IComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>();
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [userListData, setUserListData] = useState<Record<string, any>[]>([]);
    const [firstAdminValue, setFirstAdminValue] = useState<FirstAdminFormValue>(
        defaultFirstAdminFormValue
    );
    const [isFetchInProgress, setIsFetchInProgress] = useState<boolean>(false);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
    const [isIntegrationUserValid, setIsIntegrationUserValid] =
        useState<boolean>(true);
    const [isPasswordAuthentication, setIsPasswordAuthentication] = useState<
        boolean | null
    >(null);

    const fetchData = () => {
        const promises: any[] = [];
        if (!enrollCache || !enrollCache?.authenticationMethod) {
            promises.push(fetchEnrollCache(environmentMoniker, enrollId));
        }
        if (!customerDetails?.type && enrollCache?.customerId) {
            promises.push(fetchCustomerDetails(enrollCache.customerId));
        }
        if (promises.length > 0) {
            setIsFetchInProgress(true);
            showLoader(true);
            Promise.all(promises).then(() => {
                showLoader(false);
                setIsFetchInProgress(false);
            });
        }
    };

    const handleUserFetch = (payload: IDooapUser[] | null, status: number) => {
        if (payload && status === constants.statusCode.OK) {
            setUserListData(
                payload.map((user) => ({
                    label: user.name || "",
                    value: { ...user },
                }))
            );
        } else {
            const errorMessage = getErrorMessage(String(payload), status);
            const errorAlert = template(
                t("enrollTenant.setAdmin.errorMessageTemplate")
            )({
                error: errorMessage,
            });
            Alert.error(errorAlert, constants.errorAlertDurationMsec);
        }
        setIsUsersLoading(false);
    };

    useRefreshPage(() => {
        if (enrollId) {
            if (activeStep?.status !== StepStatus.Success) {
                setFirstAdminValue(defaultFirstAdminFormValue);
                setUserListData([]);
                Notification.closeAll();
            }
            if (formRef && formRef.current) {
                formRef.current.cleanErrors();
            }
        }
    });

    useEffect(() => {
        setIsStepFormValid(() => () => formRef?.current?.check() || false);
    }, [setIsStepFormValid, formRef]);

    useEffect(() => {
        if (environmentMoniker && enrollId && !isFetchInProgress) {
            fetchData();
        }
    }, [environmentMoniker, enrollId, isFetchInProgress, fetchData]);

    useEffect(() => {
        if (
            activeStep?.status === StepStatus.Running &&
            environmentMoniker &&
            enrollCache?.tenantId &&
            enrollCache?.authenticationMethod &&
            isPasswordAuthentication === null
        )
            loadAuthenticationMethods(
                environmentMoniker,
                enrollCache?.tenantId
            ).then(([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    const isPasswordAuthentication =
                        payload.find(
                            (authMethod) =>
                                authMethod.authenticationDescription ===
                                enrollCache.authenticationMethod
                        )?.authenticationType ===
                        AuthenticationType.UsernamePassword;
                    setIsPasswordAuthentication(isPasswordAuthentication);
                }
            });
    }, [
        activeStep?.status,
        enrollCache?.tenantId,
        environmentMoniker,
        isPasswordAuthentication,
        enrollCache?.authenticationMethod,
    ]);

    useEffect(() => {
        if (
            activeStep?.status === StepStatus.Running &&
            environmentMoniker &&
            enrollCache?.tenantMoniker &&
            customerDetails?.type &&
            isPasswordAuthentication
        ) {
            if (customerDetails.type === CustomerType.D365) {
                checkIntegrationUserErpValidity(
                    environmentMoniker,
                    enrollCache?.tenantMoniker
                ).then(([payload, status]) => {
                    if (
                        payload !== null &&
                        status === constants.statusCode.OK
                    ) {
                        setIsIntegrationUserValid(payload);
                    }
                });
            } else {
                checkIntegrationUserValidity(
                    environmentMoniker,
                    enrollCache?.tenantMoniker
                ).then(([payload, status]) => {
                    if (
                        payload !== null &&
                        status === constants.statusCode.OK
                    ) {
                        setIsIntegrationUserValid(payload);
                    }
                });
            }
        }
    }, [
        activeStep?.status,
        enrollCache?.tenantMoniker,
        environmentMoniker,
        customerDetails,
        isPasswordAuthentication,
    ]);

    useEffect(() => {
        if (
            activeStep?.status === StepStatus.Running &&
            environmentMoniker &&
            enrollCache?.tenantMoniker &&
            customerDetails?.type &&
            !userListData.length
        ) {
            setIsUsersLoading(true);
            if (customerDetails.type === CustomerType.D365) {
                loadErpUsers(
                    environmentMoniker,
                    enrollCache?.tenantMoniker
                ).then(([payload, statusCode]) =>
                    handleUserFetch(payload, statusCode)
                );
            } else {
                loadUsers(environmentMoniker, enrollCache?.tenantMoniker).then(
                    ([payload, statusCode]) => {
                        handleUserFetch(payload, statusCode);
                    }
                );
            }
        }
    }, [
        activeStep?.status,
        enrollCache?.tenantMoniker,
        environmentMoniker,
        customerDetails,
        userListData,
    ]);

    useEffect(() => {
        if (!isIntegrationUserValid && !isUsersLoading) {
            handleNotificationOpen();
        }
    }, [isIntegrationUserValid, isUsersLoading]);

    useEffect(() => {
        Notification.close();
    }, []);

    useEffect(() => {
        if (activeStep) {
            try {
                const stepHistory: IFirstAdminApproveData = JSON.parse(
                    activeStep.data ?? ""
                );

                const integrationUserIndex = stepHistory.users.findIndex(
                    (user) => user.isIntegrationUser
                );

                let integrationUser;
                if (integrationUserIndex !== -1) {
                    const integrationUsers = stepHistory.users.splice(
                        integrationUserIndex,
                        1
                    );
                    integrationUser = integrationUsers[0];
                }

                if (stepHistory) {
                    setFirstAdminValue({
                        isD365RoleDooapRequired:
                            stepHistory.isD365RoleDooapRequired,
                        users: stepHistory.users,
                        integrationUser: integrationUser,
                    });
                    if (activeStep?.status === StepStatus.Success) {
                        setUserListData(
                            stepHistory.users.map((user) => ({
                                label: user.name || "",
                                value: { ...user },
                            }))
                        );
                        setIsFormDisabled(true);
                    }
                }
            } catch (error) {}
        }
    }, []);

    useEffect(() => {
        if (activeStep?.status === StepStatus.Running && firstAdminValue) {
            setActiveStepData(JSON.stringify(firstAdminValue ?? ""));
        }
    }, [activeStep?.status, firstAdminValue]);

    const handleNotificationOpen = () => {
        Notification.warning({
            title: "Warning",
            duration: 0,
            className: styles.warningNotification,
            description: (
                <div>
                    <p>
                        {t(
                            "enrollTenant.setAdmin.integrationUserWrongDomainMessage"
                        )}
                    </p>
                </div>
            ),
        });
    };

    const handleFormDataChange = (userFormData: any) => {
        const newUserFormData = userFormData as FirstAdminFormValue;

        if (newUserFormData) {
            setFirstAdminValue(userFormData);
            setActiveStepData(JSON.stringify(newUserFormData ?? ""));
        }

        setIsUnsavedChangeAvailable(true);
    };

    const handleDooapD365RoleRequiredChange = (checked: boolean) => {
        setIsUnsavedChangeAvailable(true);
        setFirstAdminValue({
            ...firstAdminValue,
            isD365RoleDooapRequired: checked,
        });
    };

    const model = Schema.Model({
        users: Schema.Types.ArrayType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule((value) => {
                return value.every(
                    (user: IDooapUser) => user.axId !== "" && user.axId !== null
                );
            }, t("enrollTenant.stepFormCommon.validationMessages.axIdIsRequired")),
    });

    const renderDropdownItem = (label: ReactNode, item: ItemDataType) => {
        return (
            <div key={item.value.id}>
                <div>{label}</div>
                <div>{item.value.userPrincipalName}</div>
            </div>
        );
    };

    const searchByHandler = (
        keyword: string,
        label: ReactNode,
        item: ItemDataType
    ) => {
        return (
            item.value?.userPrincipalName
                ?.toLowerCase()
                .includes(keyword.toLowerCase()) ||
            label?.toString()?.toLowerCase().includes(keyword.toLowerCase())
        );
    };

    return (
        activeStep && (
            <Form
                ref={formRef}
                model={model}
                formValue={firstAdminValue}
                onChange={handleFormDataChange}
            >
                {customerDetails?.type === CustomerType.D365 ? (
                    <>
                        <p className={styles.descriptionText}>
                            {t(
                                "enrollTenant.setAdmin.requireD365role.description"
                            )}
                        </p>
                        <FormGroup
                            className={cn(styles.gridItem, styles.lessHeight)}
                        >
                            <Checkbox
                                disabled={isFormDisabled}
                                className={styles.checkbox}
                                value={"isD365RoleDooapRequired"}
                                checked={
                                    firstAdminValue.isD365RoleDooapRequired
                                }
                                defaultChecked={
                                    firstAdminValue.isD365RoleDooapRequired
                                }
                                onChange={(_, checked) =>
                                    handleDooapD365RoleRequiredChange(checked)
                                }
                            >
                                {t(
                                    "enrollTenant.setAdmin.requireD365role.title"
                                )}
                            </Checkbox>
                        </FormGroup>
                        <p className={styles.descriptionText}>
                            {firstAdminValue.isD365RoleDooapRequired
                                ? t("enrollTenant.setAdmin.instructionCase1")
                                : t("enrollTenant.setAdmin.instructionCase2")}
                        </p>
                    </>
                ) : (
                    <p className={styles.descriptionText}>
                        {t("enrollTenant.setAdmin.instructionCase3")}
                    </p>
                )}
                <b>{t("enrollTenant.setAdmin.actionTitle")}</b>
                <FormGroup className={styles.inputSection}>
                    <ControlLabel className={styles.label}>
                        {t("enrollTenant.setAdmin.userTitle")}
                    </ControlLabel>
                    {activeStep.status === StepStatus.Running ? (
                        <>
                            <FormControl
                                disabled={isFormDisabled || isUsersLoading}
                                accepter={CheckPicker}
                                data={userListData}
                                renderMenuItem={renderDropdownItem}
                                virtualized={false}
                                searchBy={searchByHandler}
                                name={"users"}
                                placeholder={t(
                                    "enrollTenant.setAdmin.userPlaceholder"
                                )}
                                className={cn(
                                    styles.formControl,
                                    styles.userInputPicker
                                )}
                            />
                            {!isUsersLoading ? (
                                <RefreshButton
                                    onClick={() => {
                                        setFirstAdminValue({
                                            ...firstAdminValue,
                                            users: [],
                                        });
                                        setUserListData([]);
                                    }}
                                    disabled={false}
                                    tooltip={t(
                                        "enrollTenant.setAdmin.refreshTooltip"
                                    )}
                                />
                            ) : (
                                <Loader size="sm" />
                            )}
                        </>
                    ) : (
                        <>
                            <ul className={styles.bulletedList}>
                                {firstAdminValue.users.map(
                                    (user: IDooapUser, index: number) => (
                                        <li
                                            key={String(index)}
                                            className={styles.listElement}
                                        >
                                            <p className={styles.userLine}>
                                                {user.name}
                                            </p>
                                            <p className={styles.userLine}>
                                                {user.userPrincipalName}
                                            </p>
                                        </li>
                                    )
                                )}
                            </ul>
                            {firstAdminValue.integrationUser && (
                                <span>
                                    {parse(
                                        template(
                                            t(
                                                "enrollTenant.setAdmin.integrationUser"
                                            )
                                        )({
                                            name: firstAdminValue
                                                .integrationUser?.axId,
                                            email: firstAdminValue
                                                .integrationUser
                                                ?.userPrincipalName,
                                        })
                                    )}
                                </span>
                            )}
                        </>
                    )}
                </FormGroup>
            </Form>
        )
    );
};

export default SetAdmin;
