import React from "react";
import "./JSONViewer.less";

interface JSONViewerProps {
    json: JSON;
}

const JSONViewer: React.FC<JSONViewerProps> = ({ json }: JSONViewerProps) => {
    const jsonString = JSON.stringify(json, null, 2);
    const lines = jsonString.split("\n");

    return (
        <pre className="json-viewer">
            <div className="line-numbers-rows">
                {lines.map((_, i) => (
                    <span key={i}>{i + 1}</span>
                ))}
            </div>
            <code>{jsonString}</code>
        </pre>
    );
};

export default JSONViewer;
