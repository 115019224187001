import { RootState } from "redux/store";
import { connect } from "react-redux";
import { setIsUnsavedChangeAvailable } from "redux/common/unsaved-data/unsaved-data-actions";
import { getActiveEnvironmentMoniker } from "redux/environments/environment-selectors";
import GuestTenants from "./GuestTenants";
import { setEmailSent } from "redux/customers/customer-details/customer-details-actions";
import { setAddNewGuestTenant } from "redux/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-actions";
import withLoader from "hocs/withLoader/withLoader";
import withError from "hocs/withError/withError";
import { flowRight } from "lodash";

const mapStateToProps = (state: RootState) => ({
    envMoniker: getActiveEnvironmentMoniker(state),
    isAddNewGuestTenantOpened:
        state.guestTenantsReducer.isAddNewGuestTenantOpened,
});
const Container = connect(mapStateToProps, {
    setIsUnsavedChangeAvailable,
    setEmailSent,
    setAddNewGuestTenant,
})(GuestTenants);

const GuestTenantsContainer = flowRight(withLoader, withError)(Container);

export default GuestTenantsContainer;
