import React from "react";
import { Popover } from "rsuite";
import "./Scheduler.less";

interface ComponentProps {
    isSaveBtnClicked: boolean;
    validationMessage: string | null;
}

const SchedulerPopover: React.FC<ComponentProps> = ({
    isSaveBtnClicked,
    validationMessage,
}: ComponentProps) => {
    return isSaveBtnClicked && validationMessage ? (
        <Popover className={"popover"} visible>
            {validationMessage}
        </Popover>
    ) : (
        <></>
    );
};

export default SchedulerPopover;
